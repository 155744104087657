import React, { useRef } from 'react'
import no_data_icon from "../Media/no_data_icon.gif";
import styles from "../Styles/Sales_analysis.module.css";
import { Typography, Box, Divider } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {

  if (active) {
    return (
      <Box id={styles.tooltipBox} >
        <Typography sx={{textAlign:"center"}} variant="subtitle1">{` ${payload[0]?.payload?.date}`}</Typography>
        {/* <Typography  sx={{color:"#007cc3"}} variant="body1">{`Percentage: ${payload[0]?.value}%`}</Typography> */}
        {Object.entries(payload[0]?.payload).map(([k, v]) => (
                  <>
                    {k=="date"?<></>:<Typography sx={{color:"#007cc3",textAlign:"left"}} variant="body1" >{k} : {v}</Typography>}
                    </>
            ))}
      </Box>
    );
  }
  return null;
};
const CustomLegend = ({ payload }) => {
  return (
  // <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
  <Box sx={{display:"flex",position:"absolute",top:0}}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ color: entry.color }}>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: entry.color,
              marginRight: "10px",
            }}
          ></span>
          {entry.value}
        </li>
      ))}
      </Box>
    // </ul>
      )
};

function Last_seven_leads_graphs({ data, labels }) {

  const windowHeight = useRef(window.innerHeight);

  const COLORS = [
    "#1F77B4", "#FF7F0E", "#2CA02C", "#D62728", "#9467BD", 
    "#8C564B", "#E377C2", "#7F7F7F", "#BCBD22", "#17BECF", 
    "#AEC7E8", "#FFBB78", "#98DF8A", "#FF9896", "#C5B0D5", 
    "#C49C94", "#F7B6D2", "#C7C7C7", "#DBDB8D", "#9EDAE5", 
    "#393B79", "#637939", "#8C6D31", "#843C39", "#7B4173", 
    "#5254A3", "#8CA252", "#BD9E39", "#AD494A", "#A55194", 
    "#6B6ECF", "#B5CF6B", "#E7BA52", "#D6616B", "#CE6DBD", 
    "#9C9EDE", "#CEDB9C", "#E7CB94", "#E7969C", "#DE9ED6", 
    "#17A2B8", "#6C757D", "#5A6268", "#28A745", "#DC3545", 
    "#FFC107", "#007BFF", "#6610F2", "#6F42C1", "#E83E8C", 
    "#20C997", "#FD7E14", "#343A40", "#868E96", "#CED4DA", 
    "#F8F9FA", "#343A40", "#495057", "#6C757D", "#ADB5BD", 
    "#FFC107", "#FF8C00", "#FF6347", "#FF1493", "#FFD700", 
    "#FF4500", "#8A2BE2", "#32CD32", "#00CED1", "#4682B4"
  ];
  return (
    <div id="invoice_graph">
      
      {data?.length > 0 ? (
        // <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={windowHeight?.current>650?770:700}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 40,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="date" />
          <YAxis 
           label={{ value: 'Total Tags', angle: -90, dx:-20, position: 'insideLeft' }} 
          />
          {/* <Legend content={<CustomLegend /> }/> */}

          <Tooltip wrapperStyle={{zIndex: 1000,textAlign:"left"}} />
          {/* <Tooltip wrapperStyle={{zIndex: 1000}} content={<CustomTooltip />}  /> */}
         {labels?.map((ele,index)=>(
          <Bar dataKey={ele} stackId="a" barSize={data?.length<3?100:"auto"} fill={COLORS[index]} />
         ))} 
        </BarChart>
      // </ResponsiveContainer>
      ) : (
        <>
          {" "}
          <div
            className={styles.noData}
            style={{ marginLeft: "-1%", marginTop: "0%" }}
          >
            <img
              src={no_data_icon}
              alt=""
              style={{ height: "200px", width: "200px" }}
            />
            <h4>No Data Added</h4>
          </div>
        </>
      )}
    </div>
  )
}

export default Last_seven_leads_graphs