import React from 'react'
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MiniDrawer from './Minidrawer';

function Category_leadsource_count({
  count_load,
    selected_cat,
    cat_data,
    check_loading,
    get_total,
    handleCategory,
    cat_drop,
    selected_source,
    setSelected_Source,
    setSelected_Cat,
    source_drop,
    select_leadStage,
    setSelected_leadStage,
    leadStage_drop,
    handleLeadStageChange,
    marketing_flag,
}) {

    const handleSource = (e)=>{
        if(e.target.value!= ""){
          setSelected_Source(e.target.value)
        }
       }

    const options = {
        filterType: "checkbox",
      };
      const columns = [
        {
          name: "Leadcat",
          label: "Lead Category",
          options: {
            filter: true,
            sort: false,
    
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                left: "0",
                background: "white",
                zIndex: 0,
                // minWidth:"200px",
                // maxWidth:"200px"
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 1000,
              },
            }),
    
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "left", paddingRight: "20px" }}>
                  <p style={{ marginLeft: "20%" }}>{row}</p>
                </div>
              );
            },
          },
        },
        {
          name: "LeadSource",
          label: "Lead Source",
          options: {
            filter: true,
            sort: false,
    
            setCellProps: () => ({
              style: { minWidth: "250px", maxWidth: "auto" },
            }),
            customBodyRender: (row) => {
              return (
                <div style={{ textAlign: "left", paddingRight: "10px" }}>
                  <p style={{ marginLeft: "30%" }}>{row}</p>
                </div>
              );
            },
          },
        },
        {
          name: "M",
          label: "M",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              // return <>{row}</>;
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "M1",
          label: "M1",
          options: {
            filter: false,
            sort: true,
            tooltips: false,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "M2",
          label: "M2",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "M3",
          label: "M3",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "M4",
          label: "M4",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "M5",
          label: "M5",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "M6",
          label: "M6",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Q",
          label: "Q",
          options: {
            filter: false,
            sort: true,
            rowsPerPageOptions: [10, 25, 50, 100],
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Q1",
          label: "Q1",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Q2",
          label: "Q2",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Q3",
          label: "Q3",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Q4",
          label: "Q4",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Q5",
          label: "Q5",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Q6",
          label: "Q6",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Y",
          label: "Y",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Y1",
          label: "Y1",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
    
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Y2",
          label: "Y2",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Y3",
          label: "Y3",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Y4",
          label: "Y4",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Y5",
          label: "Y5",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
    
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
        {
          name: "Y6",
          label: "Y6",
          options: {
            filter: false,
            sort: true,
            setCellProps: (cellValue) => ({
              style: {
                backgroundColor:
                  Number(cellValue?.props?.children) >= 0 &&
                  Number(cellValue?.props?.children) < 20
                    ? "#dff3ff"
                    : Number(cellValue?.props?.children) >= 20 &&
                      Number(cellValue?.props?.children) < 40
                    ? "#9fdcff"
                    : Number(cellValue?.props?.children) >= 40 &&
                      Number(cellValue?.props?.children) < 60
                    ? "#60c5ff"
                    : Number(cellValue?.props?.children) >= 60 &&
                      Number(cellValue?.props?.children) < 80
                    ? "#20aeff"
                    : Number(cellValue?.props?.children) >= 80 &&
                      Number(cellValue?.props?.children) <= 600
                    ? "#008edf"
                    : Number(cellValue?.props?.children) >= 600
                    ? "#1D5D9B"
                    : "white",
                color:
                  (Number(cellValue?.props?.children) >= 80 &&
                    Number(cellValue?.props?.children) <= 600) ||
                  Number(cellValue?.props?.children) >= 600
                    ? "white"
                    : "black",
              },
            }),
            customBodyRender: (row) => {
              return (
                <>
                  {row}
                </>
              );
            },
          },
        },
      ];
    
      return (
        <>
          <MiniDrawer />
          {count_load ? (
            <img src={report_loader} id={styles.loader} />
          ) : (
            <div
              className="container-fluid pt-3"
              id={cat_data?.length == 0 ? "" : "table_s6_category_tbl"}
            >
              <MUIDataTable
                title={
                  marketing_flag ? (
                    <h5 style={{ textAlign: "left" }}>
                      Categorywise Leadsource - Marketing
                    </h5>
                  ) : (
                    <h5 style={{ textAlign: "left" }}>
                      Categorywise Leadsource - Sales
                    </h5>
                  )
                }
                data={cat_data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  filter: false,
                  print: false,
                  responsive: "standard",
                  download: true,
                  fixedHeader: true,
                  rowsPerPageOptions: [10, 25, 50, 100],
                  tableBodyMaxHeight: "57vh",
                  sortOrder: {
                    name: "Q",
                    direction: "desc",
                  },
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  customToolbar: () => {
                    return (
                      <>
                      <select  id={styles.payment_drop} name=""  value={selected_cat} onChange={handleCategory}>
                       {/* <option value="ALL" disabled selected>Select Category</option> */}
                       {/* <option value="">Select Cateogory</option> */}
          
                       <option value="ALL"
                     //  style={{display:revenue_flag?"block":"none"}}
                     //  selected={revenue_flag?true:false}
                      >All Category</option>
                       
                       {cat_drop?.map((ele)=>(
                         <option value={ele.Leadcat}>{ele.Leadcat}</option>
                       ))}
                      </select>
                      <select id={styles.source_drop} name="" value={selected_source} onChange={handleSource} >
                       {/* <option  value="ALL" disabled selected >Select Lead Source</option> */}
                       <option value="" selected>Select Source</option>
                     <option value="ALL" selected>All Source</option>
                       {source_drop?.map((ele)=>(
                         <option value={ele.Leadsource}>{ele.Leadsource}</option>
                       ))}
                      </select>
                      
                   </>
                    );
                  },
    
                  customTableBodyFooterRender: (
                    count,
                    page,
                    rowsPerPage,
                    changeRowsPerPage,
                    changePage,
                    data
                  ) => {
                    const startIndex = page * rowsPerPage;
                    const endIndex = (page + 1) * rowsPerPage;
                    return (
                      <TableFooter id={styles.table_footer}>
                        <TableRow>
                          <TableCell id={styles.first_col}>
                            <p>Total</p>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <p>{get_total("M")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("M1")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("M2")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("M3")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("M4")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("M5")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("M6")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Q")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Q1")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Q2")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Q3")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Q4")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Q5")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Q6")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Y")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Y1")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Y2")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Y3")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Y4")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Y5")}</p>
                          </TableCell>
                          <TableCell>
                            <p>{get_total("Y6")}</p>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    );
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available",
                    },
                  },
                }}
              />
            </div>
          )}
          {/* <div></div> */}
        </>
  )
}

export default Category_leadsource_count