import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import styles from "../Styles/Table.module.css";
import shopping_img from "../Media/carts.png";
import MUIDataTable from "mui-datatables";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import report_loader from "../Media/ezgif.com-optimize.gif";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";

function Purchase_order_report() {
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");
    
  const [selectedFromDate, setSelectedFromDate] = useState(new Date(y, m, 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date(y, m + 1, 0));

  const [salesperson_data, setSalesperson_Data] = useState([]);
  const [company_list, setCompany_List] = useState([]);
  const [purchase_list, setPurchase_List] = useState([]);
  const [tab_data, setTab_Data] = useState([]);
  const [purchase_data, setPurchase_Data] = useState([]);

  const [selected_purchase, setSelected_Purchase] = useState("all");
  const [selected_company, setSelected_Company] = useState("all");
  const [selected_salesperson, setSelected_Salesperson] = useState("all");

  const [count_load, setCount_Load] = useState(false);
  const [tbl_load, setTbl_Load] = useState(false);
  const [sales_load, setSales_Load] = useState(false);
  const [company_load, setCompany_Load] = useState(false);
  const [purchase_load, setPurchase_Load] = useState(false);

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      //   setParams("MTD");
      //   setRange_Flag(false);
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date(y, m + 1, 0));
    }
  };

  const get_total = (param_val) => {
    if (param_val == "PO_Expire_Alert") {
      let total_count = purchase_data?.reduce((acc, ele) => {
        return acc + Number(ele?.PO_Expire_Alert);
      }, 0);
      return total_count;
    } else if (param_val == "due_today") {
      let total_count = purchase_data?.reduce((acc, ele) => {
        return acc + Number(ele?.due_today);
      }, 0);
      return total_count;
    } else if (param_val == "over_due_by_2") {
      let total_count = purchase_data?.reduce((acc, ele) => {
        return acc + Number(ele?.over_due_by_2);
      }, 0);
      return total_count;
    } else {
      let total_count = purchase_data?.reduce((acc, ele) => {
        return acc + Number(ele?.over_due_by_4_and_more);
      }, 0);
      return total_count;
    }
  };

  const get_salesperson_list = async () => {
    setSales_Load(true);
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/corporate_salesperson"
      );
      let data = await res.json();
      setSales_Load(false);
      setSalesperson_Data(data?.Data);
    } catch (error) {
      setSales_Load(false);
    }
  };

  const get_company_name_list = async () => {
    setCompany_Load(true);
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/corporate_purchase_order_company_name",
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            start_date: moment(selectedFromDate).format("YYYY-MM-DD"),
            end_date: moment(selectedToDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setCompany_Load(false);
      setCompany_List(data?.data);
    } catch (error) {
      setCompany_Load(false);
    }
  };

  const get_purchase_order_list = async () => {
    setPurchase_Load(true);
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/corporate_purchase_order_no",
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            start_date: moment(selectedFromDate).format("YYYY-MM-DD"),
            end_date: moment(selectedToDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setPurchase_Load(false);
      setPurchase_List(data?.data);
    } catch (error) {
      setPurchase_Load(false);
    }
  };

  const get_salesperson_tab_count = async () => {
    setCount_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/purchase_order_all_count`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            start_date: moment(selectedFromDate).format("YYYY-MM-DD"),
            end_date: moment(selectedToDate).format("YYYY-MM-DD"),
            purchase_order_no: selected_purchase,
            company_name: selected_company,
            salesperson: selected_salesperson,
          }),
        }
      );
      let data = await res.json();
      setCount_Load(false);
      setTab_Data(data?.Data);
    } catch (error) {
      setCount_Load(false);
    }
  };

  const get_purchase_data = async () => {
    setTbl_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/purchase_order_report`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            start_date: moment(selectedFromDate).format("YYYY-MM-DD"),
            end_date: moment(selectedToDate).format("YYYY-MM-DD"),
            purchase_order_no: selected_purchase,
            company_name: selected_company,
            salesperson: selected_salesperson,
          }),
        }
      );
      let data = await res.json();
      setTbl_Load(false);
      setPurchase_Data(data?.Data);
    } catch (error) {
      setTbl_Load(false);
    }
  };

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "salesperson",
      label: `Sales Person`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "PO_Expire_Alert",
      label: "PO Expire Alert",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "over_due_by_4_and_more",
      label: "Overdue More than 4 days",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "over_due_by_2",
      label: "Overdue by 2 days",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "due_today",
      label: "Due Today",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    }
  ];

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_salesperson_list();
    get_company_name_list();
    get_purchase_order_list();
    }
  }, []);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_salesperson_tab_count();
    get_purchase_data();
    }
  }, [
    selectedFromDate,
    selected_company,
    selected_purchase,
    selected_salesperson,
  ]);

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last week",
      value: [
        startOfWeek(addWeeks(new Date(), -1)),
        endOfWeek(addWeeks(new Date(), -1)),
      ],
      placement: "left",
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];

  return (
    <>
      <MiniDrawer />
      {count_load || tbl_load ? (
        <div>
          <img src={report_loader} id={styles.purchase_loader} />
        </div>
      ) : (
        <div className="container-fluid pt-3">
          <div className="row">
            <div className="col-9">
              <div className="row">
                <div className="col-3">
                  <div id={styles.po_container}>
                    <p>Total PO</p>
                    <div>
                      <img src={shopping_img} alt="" />
                      <div>
                        {tab_data?.[0]?.order_count == undefined ||
                        tab_data?.[0]?.order_count == null ? (
                          <h5>0</h5>
                        ) : tab_data?.[0]?.order_count.toString()?.length >
                          3 ? (
                          <marquee
                            id={styles.order_count_moving}
                            style={{ marginTop: "-7px" }}
                            scrollamount="2"
                          >
                            {tab_data?.[0]?.order_count == undefined ||
                            tab_data?.[0]?.order_count == null
                              ? 0
                              : new Intl.NumberFormat("en-IN").format(
                                  tab_data?.[0]?.order_count
                                )}
                          </marquee>
                        ) : (
                          <h5>
                            {tab_data?.[0]?.order_count == undefined ||
                            tab_data?.[0]?.order_count == null
                              ? 0
                              : tab_data?.[0]?.order_count}
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-9">
                  <h4>Purchase Order Report Monthly Digest</h4>
                </div>
              </div>
              <div className="row mt-3" id={styles.po_tab_container}>
                <div style={{ marginLeft: "1%" }}>
                  <p>Due Today</p>
                  <h5>
                    {tab_data?.[0]?.due_today == undefined ||
                    tab_data?.[0]?.due_today == null
                      ? 0
                      : tab_data?.[0]?.due_today}
                  </h5>
                </div>
                <div>
                  <p>Over due by 1 Days</p>
                  <h5>
                    {tab_data?.[0]?.over_due_by_1 == undefined ||
                    tab_data?.[0]?.over_due_by_1 == null
                      ? 0
                      : tab_data?.[0]?.over_due_by_1}
                  </h5>
                </div>
                <div>
                  <p>Over due by 2 Days</p>
                  <h5>
                    {tab_data?.[0]?.over_due_by_2 == undefined ||
                    tab_data?.[0]?.over_due_by_2 == null
                      ? 0
                      : tab_data?.[0]?.over_due_by_2}
                  </h5>
                </div>
                <div>
                  {" "}
                  <p>Over due by 3 Days</p>
                  <h5>
                    {tab_data?.[0]?.over_due_by_3 == undefined ||
                    tab_data?.[0]?.over_due_by_3 == null
                      ? 0
                      : tab_data?.[0]?.over_due_by_3}
                  </h5>
                </div>
                <div>
                  {" "}
                  <p>Over due by 4 Days</p>
                  <h5>
                    {tab_data?.[0]?.over_due_by_4_and_more == undefined ||
                    tab_data?.[0]?.over_due_by_4_and_more == null
                      ? 0
                      : tab_data?.[0]?.over_due_by_4_and_more}
                  </h5>
                </div>
                <div>
                  {" "}
                  <p>PO Expire Alert</p>
                  <h5>
                    {tab_data?.[0]?.PO_Expire_Alert == undefined ||
                    tab_data?.[0]?.PO_Expire_Alert == null
                      ? 0
                      : tab_data?.[0]?.PO_Expire_Alert}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="date_contain" id={styles.filter_container}>
                <DateRangePicker
                  style={{ width: "100%" }}
                  // ranges={predefinedRanges}
                  format="dd MMM yyyy"
                  value={[selectedFromDate, selectedToDate]}
                  onChange={handleDateChange}
                  size="md"
                  placement="bottomEnd"
                  preventOverflow
                />
              </div>

              <div id={styles.filter_container}>
                <Box sx={{ width: "270px", marginTop: "10px" }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Purchase Order No
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Purchase Order No"
                      value={selected_purchase}
                      onChange={(e) => setSelected_Purchase(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {purchase_list?.map((ele) => (
                        <MenuItem value={ele.purchase_order_no}>
                          {ele.purchase_order_no}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div id={styles.filter_container}>
                <Box sx={{ width: "220px", marginTop: "10px" }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Company Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Company Name"
                      value={selected_company}
                      onChange={(e) => setSelected_Company(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {company_list?.map((ele) => (
                        <MenuItem value={ele.company_name}>
                          {ele.company_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <div id={styles.filter_container}>
                <Box sx={{ width: "170px", marginTop: "10px" }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Sales Person
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Salesperson"
                      value={selected_salesperson}
                      onChange={(e) => setSelected_Salesperson(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {salesperson_data?.map((ele) => (
                        <MenuItem value={ele.Salesperson}>
                          {ele.Salesperson}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div style={{ marginBottom: "40px" }} id="purchase_tbl">
              <MUIDataTable
                title={
                  <h5 style={{ textAlign: "left" }}>Due Days / Sales person</h5>
                }
                data={purchase_data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  rowsPerPageOptions: [10, 25, 50, 100],
                  filter: false,
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available",
                    },
                  },
                  customTableBodyFooterRender: (
                    count,
                    page,
                    rowsPerPage,
                    changeRowsPerPage,
                    changePage,
                    data
                  ) => {
                    const startIndex = page * rowsPerPage;
                    const endIndex = (page + 1) * rowsPerPage;
                    return (
                      <TableFooter id={styles.table_footer}>
                        <TableRow>
                          <TableCell id={styles.first_col}>
                            <p style={{ textAlign: "left" }}>Grand Total (₹)</p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("PO_Expire_Alert")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("over_due_by_4_and_more")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("over_due_by_2")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("due_today")}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Purchase_order_report;
