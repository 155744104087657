// Get salesperson oh data 

export const GET_SALESPERSON_OH_REQUEST = "GET_SALESPERSON_OH_REQUEST";
export const GET_SALESPERSON_OH_SUCCESS = "GET_SALESPERSON_OH_SUCCESS";
export const GET_SALESPERSON_OH_ERROR = "GET_SALESPERSON_OH_ERROR";

// Get client reports by company

export const GET_CLIENT_REPORT_COMPANY_REQUEST = "GET_CLIENT_REPORT_COMPANY_REQUEST";
export const GET_CLIENT_REPORT_COMPANY_SUCCESS = "GET_CLIENT_REPORT_COMPANY_SUCCESS";
export const GET_CLIENT_REPORT_COMPANY_ERROR = "GET_CLIENT_REPORT_COMPANY_ERROR";

// Get client reports by Team lead

export const GET_CLIENT_REPORT_TEAMLEAD_REQUEST = "GET_CLIENT_REPORT_TEAMLEAD_REQUEST";
export const GET_CLIENT_REPORT_TEAMLEAD_SUCCESS = "GET_CLIENT_REPORT_TEAMLEAD_SUCCESS";
export const GET_CLIENT_REPORT_TEAMLEAD_ERROR = "GET_CLIENT_REPORT_TEAMLEAD_ERROR";

// Get client reports by Business Type

export const GET_CLIENT_REPORT_BUSINESS_TYPE_REQUEST = "GET_CLIENT_REPORT_BUSINESS_TYPE_REQUEST";
export const GET_CLIENT_REPORT_BUSINESS_TYPE_SUCCESS = "GET_CLIENT_REPORT_BUSINESS_TYPE_SUCCESS";
export const GET_CLIENT_REPORT_BUSINESS_TYPE_ERROR = "GET_CLIENT_REPORT_BUSINESS_TYPE_ERROR";

// Get Corporate exp details

export const GET_CORPORATE_EXP_DETAILS_REQUEST = "GET_CORPORATE_EXP_DETAILS_REQUEST";
export const GET_CORPORATE_EXP_DETAILS_SUCCESS = "GET_CORPORATE_EXP_DETAILS_SUCCESS";
export const GET_CORPORATE_EXP_DETAILS_ERROR = "GET_CORPORATE_EXP_DETAILS_ERROR";

// Get Oh Count graph data

export const GET_OH_COUNT_GRAPH_DATA_REQUEST = "GET_OH_COUNT_GRAPH_DATA_REQUEST";
export const GET_OH_COUNT_GRAPH_DATA_SUCCESS = "GET_OH_COUNT_GRAPH_DATA_SUCCESS";
export const GET_OH_COUNT_GRAPH_DATA_ERROR = "GET_OH_COUNT_GRAPH_DATA_ERROR";


// Get Oh Report List

export const GET_OH_REPORT_LIST_REQUEST = "GET_OH_REPORT_LIST_REQUEST";
export const GET_OH_REPORT_LIST_SUCCESS = "GET_OH_REPORT_LIST_SUCCESS";
export const GET_OH_REPORT_LIST_ERROR = "GET_OH_REPORT_LIST_ERROR";


// Get Oh Report Tbl Data

export const GET_OH_REPORT_DATA_REQUEST = "GET_OH_REPORT_DATA_REQUEST";
export const GET_OH_REPORT_DATA_SUCCESS = "GET_OH_REPORT_DATA_SUCCESS";
export const GET_OH_REPORT_DATA_ERROR = "GET_OH_REPORT_DATA_ERROR";


// Get Relavant Marketing Performance

export const GET_RELEVANT_MARKETING_DATA_REQUEST = "GET_RELEVANT_MARKETING_DATA_REQUEST";
export const GET_RELEVANT_MARKETING_DATA_SUCCESS = "GET_RELEVANT_MARKETING_DATA_SUCCESS";
export const GET_RELEVANT_MARKETING_DATA_ERROR = "GET_RELEVANT_MARKETING_DATA_ERROR";

// Get assigned Marketing Performance

export const GET_ASSIGNED_MARKETING_DATA_REQUEST = "GET_ASSIGNED_MARKETING_DATA_REQUEST";
export const GET_ASSIGNED_MARKETING_DATA_SUCCESS = "GET_ASSIGNED_MARKETING_DATA_SUCCESS";
export const GET_ASSIGNED_MARKETING_DATA_ERROR = "GET_ASSIGNED_MARKETING_DATA_ERROR";


// Get Marketing leads created on

export const GET_MARKETING_LEADS_CREATED_REQUEST = "GET_MARKETING_LEADS_CREATED_REQUEST";
export const GET_MARKETING_LEADS_CREATED_SUCCESS = "GET_MARKETING_LEADS_CREATED_SUCCESS";
export const GET_MARKETING_LEADS_CREATED_ERROR = "GET_MARKETING_LEADS_CREATED_ERROR";


// Get Marketing leads updated on

export const GET_MARKETING_LEADS_UPDATED_REQUEST = "GET_MARKETING_LEADS_UPDATED_REQUEST";
export const GET_MARKETING_LEADS_UPDATED_SUCCESS = "GET_MARKETING_LEADS_UPDATED_SUCCESS";
export const GET_MARKETING_LEADS_UPDATED_ERROR = "GET_MARKETING_LEADS_UPDATED_ERROR";