import React from 'react'
import Sample_business_report from '../pages/Sample_business_report'

function Sample_business_report_fnb() {
  return (
    <>
      <Sample_business_report/>
    </>
  )
}

export default Sample_business_report_fnb
