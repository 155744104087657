import React, { useState, useEffect, useRef, useCallback } from "react";
// import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import styles from "../Styles/Sales_analysis.module.css";
import no_data_icon from "../Media/no_data_icon.gif";
import report_loader from "../Media/ezgif.com-optimize.gif";

// import { PieChart } from "@mui/x-charts/PieChart";

import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";

function Tags_leads_pieChart({
  pie_load,
  pieData,
}) {
  const windowHeight = useRef(window.innerHeight);
  const [activeIndex, setActiveIndex] = useState(1);

  let dataArray = [];
  // pieData?.forEach((ele, ind) => {
  //   dataArray.push({
  //     id: ind,
  //     value: Number(ele.non_testing),
  //     name: "Non-Testing",
  //   });
  //   dataArray.push({
  //     id: ind,
  //     value: Number(ele.testing),
  //     name: "Testing",
  //   });
  // });

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text x={cx} y={cy} dy={8}  style={{fontSize:"12px"}} textAnchor="middle" fill={"black"}>
          {payload.tag?.split(" ")?.join("\n")}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {value}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const COLORS = [
    "#1F77B4", "#FF7F0E", "#2CA02C", "#D62728", "#9467BD", 
    "#8C564B", "#E377C2", "#7F7F7F", "#BCBD22", "#17BECF", 
    "#AEC7E8", "#FFBB78", "#98DF8A", "#FF9896", "#C5B0D5", 
    "#C49C94", "#F7B6D2", "#C7C7C7", "#DBDB8D", "#9EDAE5", 
    "#393B79", "#637939", "#8C6D31", "#843C39", "#7B4173", 
    "#5254A3", "#8CA252", "#BD9E39", "#AD494A", "#A55194", 
    "#6B6ECF", "#B5CF6B", "#E7BA52", "#007cc3", "#CE6DBD", 
    "#9C9EDE", "#CEDB9C", "#E7CB94", "#E7969C", "#DE9ED6", 
    "#17A2B8", "#6C757D", "#5A6268", "#28A745", "#DC3545", 
    "#FFC107", "#007BFF", "#6610F2", "#6F42C1", "#E83E8C", 
    "#20C997", "#FD7E14", "#343A40", "#868E96", "#CED4DA", 
    "#F8F9FA", "#343A40", "#495057", "#6C757D", "#ADB5BD", 
    "#FFC107", "#FF8C00", "#FF6347", "#FF1493", "#FFD700", 
    "#FF4500", "#8A2BE2", "#32CD32", "#00CED1", "#4682B4"
  ];

  return (
    <>
      {pie_load ? (
        <div style={{margin:"auto"}}className={styles.noData}>
        <img src={report_loader} id={styles.loader_tag_pie} />
        </div>
      ) : (
        <div>
          {pieData?.length == 0 ? (
            <>
              {" "}
              <div style={{margin:"auto"}}className={styles.noData}>
                <img
                  src={no_data_icon}
                  alt=""
                  style={{ height: "200px", width: "200px" }}
                />
                <h4>No Data Added</h4>
              </div>
            </>
          ) : (
            <>

              
              <div
                style={{ marginTop: "7%" }}
                // style={{border:"1px solid red"}}
                // style={{marginLeft:windowHeight?.current<950?"7vw":"0vw" }}
              >
                
                <PieChart
                  // width={windowHeight?.current > 950 ? 850 : 510}
                  // height={windowHeight?.current > 950 ? 680 : 380}
                  width={700}
                  height={420}
                >
                  
                  {/* <PieChart  width={510} height={380} > */}
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={pieData}
                    // cx={250}
                    // cy={windowHeight?.current > 950 ? 300 : 150}
                    // innerRadius={windowHeight?.current > 950 ? 120 : 50}
                    // outerRadius={windowHeight?.current > 950 ? 400 : 90}
                    
                    cx={230}
                    cy={150}
                    innerRadius={70}
                    // cx={250}
                    // cy={150}
                    // innerRadius={50}
                    outerRadius={110}
                    fill="#007cc3"
                    dataKey="lead"
                    onMouseEnter={onPieEnter}
                    
                    // paddingAngle={1}
                  >
                    {pieData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  {/* <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="middle"
                    iconType="square"
                    key="tag"
                  /> */}
                </PieChart>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Tags_leads_pieChart;
