import React ,{useState,useEffect} from 'react'
import MiniDrawer from '../components/Minidrawer'
import styles from "../Styles/Sales_analysis.module.css";
import { useDispatch } from 'react-redux';
import Yearly_sales from '../components/Yearly_sales';
import dayjs from "dayjs";
import moment from "moment";
import Quaterly_sales from '../components/Quaterly_sales';
import { get_monthly_non_testing, get_monthly_sales, get_quaterly_non_testing, get_yearly_non_testing } from '../Latest_addition_redux/actions';
import Monthly_sales from '../components/Monthly_sales';
import { DatePicker, Space } from "antd";

function Non_testing_service() {
    const dispatch = useDispatch();
    const storage = window.sessionStorage;
    let Role_id = storage.getItem("Role_id")

    const [monthly_data, setMonthly_Data] = useState([]);
    const [fnb_sales_data_month, setFnb_Sales_Data_Month] = useState([]);
    const [corporate_sales_data_month, setCorporate_Sales_Data_Month] = useState(
      []
    );
    
    const [fnb_sales_data_year,setFnb_Sales_Data_Year] = useState([])
    const [corporate_sales_data_year,setCorporate_Sales_Data_Year] = useState([])
    const [fnb_sales_data_quater,setFnb_Sales_Data_Quater] = useState([])
    const [corporate_sales_data_quater,setCorporate_Sales_Data_Quater] = useState([])
    const [years_data,setYears_Data] = useState([])
    const [monthly_flag, setMonthly_Flag] = useState(true);
    const [quaterly_flag, setQuaterly_Flag] = useState(false);
    const [yearly_flag, setYearly_Flag] = useState(false);
    const [load_data, setLoad_Data] = useState(false);
    const formattedMonth = dayjs(new Date());
    let new_year = moment(formattedMonth.$d).format("YYYY");
    const [quater_year,setQuater_Year] = useState("2023-2024");
    const [selectedMonth, setSelectedMonth] = useState();
    
    const [send_month, setSend_month] = useState("ALL");
    const [send_year, setSend_year] = useState("ALL");
    const [date_flag,setDate_flag] = useState(false)
    

    const handleFlag = (val) => {
        if (val == "month") {
          setMonthly_Flag(true);
          setQuaterly_Flag(false);
          setYearly_Flag(false);
          setDate_flag(false)
          setSend_month(" ")
          setSend_year("ALL")
          setSelectedMonth(null)
          dispatch(
            get_monthly_non_testing(
              setLoad_Data,
              setMonthly_Data,
              setFnb_Sales_Data_Month,
              setCorporate_Sales_Data_Month,
              send_month,
              send_year
            )
          );
        } else if (val == "quater") {
          setQuaterly_Flag(true);
          setMonthly_Flag(false);
          setYearly_Flag(false);
          setQuater_Year("2023-2024")
          dispatch(
            get_quaterly_non_testing(
              setLoad_Data,
              setFnb_Sales_Data_Quater,
              setCorporate_Sales_Data_Quater,
              "2023-2024"
            )
          );
        } else if (val == "year") {
          setYearly_Flag(true);
          setQuaterly_Flag(false);
          setMonthly_Flag(false);
    
          dispatch(
            get_yearly_non_testing(
              setLoad_Data,
              setFnb_Sales_Data_Year,
              setCorporate_Sales_Data_Year,
              setYears_Data
            )
          );
        } else {
          let x = 1;
        }
      };

      const handleQuater =(e)=>{
        setQuater_Year(e.target.value)
        dispatch(
          get_quaterly_non_testing(
            setLoad_Data,
            setFnb_Sales_Data_Quater,
            setCorporate_Sales_Data_Quater,
            e.target.value
          )
        );
      }

      const handleMonth = (date, dateString) => {
        if(date){ setSelectedMonth(date);
        setDate_flag(true)
        let formattedMon = moment(date?.$d)?.format("MM");
        let formattedYear = moment(date?.$d)?.format("YYYY");
        setSend_year(formattedYear);
        setSend_month(formattedMon);
        }else{
          setDate_flag(false);
          setSelectedMonth(null);
          setSend_year("ALL");
          setSend_month(" ");
        }
        // if (send_year == formattedYear) {
        //   setMonthFlag(true);
        // }
      };
    
    
      useEffect(() => {
        if(Role_id != "" && Role_id != null && Role_id != "null"){
        dispatch(
          get_monthly_non_testing(
            setLoad_Data,
            setMonthly_Data,
            setFnb_Sales_Data_Month,
            setCorporate_Sales_Data_Month,
            send_month,
            send_year
          )
        );
      }
      }, [send_month]);
    

  return (
    <>
    <MiniDrawer/>
    <div className='container-fluid'>
    <div id={styles.tab_contain} className="row mt-3">
    <div className={yearly_flag?"col-md-8":"col-md-6"}  style={{textAlign:"left",border:"1px solid white"}}>
            <p style={{fontWeight:"bold",fontSize:"24px",color:"#007cc3"}} className="p mt-4">Non Testing Service</p>
          </div>
    {quaterly_flag?    <div className="col-sm-12 col-md-2" style={{textAlign:"left",border:"1px solid white"}}>
              <span style={{fontSize:"12px",color:"grey"}}>Select Year</span>
            <select
              style={{ width: "150px" }}
              className="form-select"
              name=""
              id=""
              value={quater_year}
              onChange={handleQuater}
            >
  
              <option value="2015-2016">2015-16</option>
              <option value="2016-2017">2016-17</option>
              <option value="2017-2018">2017-18</option>
              <option value="2018-2019">2018-19</option>
              <option value="2019-2020">2019-20</option>
              <option value="2020-2021">2020-21</option>
              <option value="2021-2022">2021-22</option>
              <option value="2022-2023">2022-23</option>
              <option value="2023-2024">2023-24</option>
            </select>
            </div>:<></>}

        {monthly_flag?  <div className="col-sm-12 col-md-2 mt-4" style={{border:"1px solid white"}}>
          <Space direction="vertical">
              <DatePicker
                onChange={handleMonth}
                picker="month"
                value={selectedMonth}
                format="MMM-YYYY"
              />
            </Space>
          </div>:<></>}

          {/* <div  className='col-sm-0 col-md-9'></div> */}
          <div
            onClick={() => handleFlag("month")}
            style={{
              backgroundColor: monthly_flag ? "#007cc3" : "white",
              color: monthly_flag ? "white" : "#007cc3",
               height:"40px"
            }}
            className="col-sm-12 col-md-1 pt-2 mt-4"
          >
            Monthly
          </div>
          <div
            onClick={() => handleFlag("quater")}
            style={{
              backgroundColor: quaterly_flag ? "#007cc3" : "white",
              color: quaterly_flag ? "white" : "#007cc3",
              height:"40px"
            }}
            className="col-sm-12 col-md-1 pt-2 mt-4"
          >
            Quarterly
          </div>
          <div
            onClick={() => handleFlag("year")}
            style={{
              backgroundColor: yearly_flag ? "#007cc3" : "white",
              color: yearly_flag ? "white" : "#007cc3",
              height:"40px"
            }}
            className="col-sm-12 col-md-1 pt-2 mt-4"
          >
            Yearly
          </div>
        </div>

        {monthly_flag ? (
            <Monthly_sales
              data={monthly_data}
              fnb_sales_data={fnb_sales_data_month}
              setFnb_Sales_Data={setFnb_Sales_Data_Month}
              corporate_sales_data={corporate_sales_data_month}
              setCorporate_Sales_Data={setCorporate_Sales_Data_Month}
              handleMonth={handleMonth}
              selectedMonth={selectedMonth}
              load_monthly={load_data}
              date_flag = {date_flag}
              width_flag={"non_testing"}
            />

          ) : (
            <></>
          )}


    {quaterly_flag ? (
            <Quaterly_sales
              fnb_sales_data={fnb_sales_data_quater}
              setFnb_Sales_Data={setFnb_Sales_Data_Quater}
              corporate_sales_data={corporate_sales_data_quater}
              setCorporate_Sales_Data={setCorporate_Sales_Data_Quater}
              load_data={load_data}
              quater_year={quater_year}
              setQuater_Year={setQuater_Year}
              handleQuater={handleQuater}
              width_flag={"non_testing"}
            />
          ) : (
            <></>
          )}

    {yearly_flag ? (
            <Yearly_sales
              fnb_sales_data={fnb_sales_data_year}
              setFnb_Sales_Data={setFnb_Sales_Data_Year}
              corporate_sales_data={corporate_sales_data_year}
              setCorporate_Sales_Data={setCorporate_Sales_Data_Year}
              load_data={load_data}
              years_data={years_data}
              width_flag={"non_testing"}
              // handleMonth={handleMonth}
              // selectedMonth={selectedMonth}
              // load_monthly={load_monthly}
            />
          ) : (
            <></>
          )}
              </div>
    </>
  )
}

export default Non_testing_service