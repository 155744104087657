import React from 'react'
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";

function Salesperson_invoice_tbl({data,setToday_Count,
  setToday_Amount,
  setMtd_Count,
  setMtd_Amount}) {


    
    const get_total =(param)=>{
      if(param == "invoice_count"){
        let total_count = data?.reduce((acc,ele)=>{
          return acc+Number(ele?.Today_Invoice_Count)
       },0)
       setToday_Count(total_count);
       return total_count;
      }
      else if(param == "invoice_amount"){
        let total_count = data?.reduce((acc,ele)=>{
          return acc+Number(ele?.Today_Invoice_Amount)
       },0)
       setToday_Amount(new Intl.NumberFormat("en-IN").format(total_count));
       return new Intl.NumberFormat("en-IN").format(total_count);
      }
      else if(param == "mtd_count"){
        let total_count = data?.reduce((acc,ele)=>{
          return acc+Number(ele?.MTD_Invoice_Count)
       },0)
       setMtd_Count(total_count);
       return total_count;
      }
      else if(param == "mtd_amount"){
        let total_count = data?.reduce((acc,ele)=>{
          return acc+Number(ele?.MTD_Invoice_Amount)
       },0)
       setMtd_Amount(new Intl.NumberFormat("en-IN").format(total_count));
       return new Intl.NumberFormat("en-IN").format(total_count);
      }
      else if(param == "ytd_count"){
        let total_count = data?.reduce((acc,ele)=>{
          return acc+Number(ele?.YTD_Invoice_Count)
       },0)
       return total_count;
      }
      else{
        let total_count = data?.reduce((acc,ele)=>{
          return acc+Number(ele?.YTD_Invoice_Amount)
       },0)
       return new Intl.NumberFormat("en-IN").format(total_count);
      }


  }

    const options = {
        filterType: "checkbox",
      };
      const columns = [
    
        {
          name: "salesperson",
          label: `Sales Person`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{textAlign:"left"}}>
                  <p>{row}</p>
                </div>
              )
            }
          },
        },
       
        {
          name: "Today_Invoice_Count",
          label: "Today's Invoice Count",
          options: {
            filter: false,
            sort: true,
           customBodyRender:(row)=>{
            return(
              <>{new Intl.NumberFormat("en-IN").format(row)}</>
            )
           }
          },
        },
        {
          name: "Today_Invoice_Amount",
          label: "Today's Invoice Amount (₹)",
          options: {
            filter: false,
            sort: true,
           customBodyRender:(row)=>{
            return(
              <>{new Intl.NumberFormat("en-IN").format(row)}</>
            )
           }
          },
        },
        {
            name: "MTD_Invoice_Count",
            label: "MTD Invoice Count",
            options: {
              filter: false,
              sort: true,
             customBodyRender:(row)=>{
              return(
                <>{new Intl.NumberFormat("en-IN").format(row)}</>
              )
             }
            },
          },
          {
            name: "MTD_Invoice_Amount",
            label: "MTD Invoice Amount (₹)",
            options: {
              filter: false,
              sort: true,
             customBodyRender:(row)=>{
              return(
                <>{new Intl.NumberFormat("en-IN").format(row)}</>
              )
             }
            },
          },
        {
            name: "YTD_Invoice_Count",
            label: "YTD Invoice Count",
            options: {
              filter: false,
              sort: true,
             customBodyRender:(row)=>{
              return(
                <>{new Intl.NumberFormat("en-IN").format(row)}</>
              )
             }
            },
          },
          {
            name: "YTD_Invoice_Amount",
            label: "YTD Invoice Amount (₹)",
            options: {
              filter: false,
              sort: true,
             customBodyRender:(row)=>{
              return(
                <>{new Intl.NumberFormat("en-IN").format(row)}</>
              )
             }
            },
          },
      ];
  return (
    <div id="corporate_tbl">
    <MUIDataTable
         title={<h5 style={{ textAlign: "left" }}>Salesperson Invoice Details</h5>}
         data={data}
         columns={columns}
         options={{
           options: options,
           selectableRows: "none",
           viewColumns: false,
           print: false,
           responsive: "standard",
           download: false,
           fixedHeader: true,
           // tableBodyMaxHeight: "50vh",
           rowsPerPageOptions:[10,25,50,100],
           filter:false,
           setTableProps: () => {
             return {
               padding: 0,
             };
           },
           textLabels: {
             body: {
               noMatch: "Data Not Available"
             },
           },
           customTableBodyFooterRender: (
               count,
               page,
               rowsPerPage,
               changeRowsPerPage,
               changePage,
               data
             ) => {
               const startIndex = page * rowsPerPage;
               const endIndex = (page + 1) * rowsPerPage;
               return (
                 <TableFooter id={styles.table_footer}>
                   <TableRow>
                     <TableCell id={styles.first_col}>
                       <p style={{textAlign:"left"}}>Grand Total</p>
                     </TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("invoice_count")}</p></TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("invoice_amount")}</p></TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("mtd_count")}</p></TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("mtd_amount")}</p></TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("ytd_count")}</p></TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("ytd_amount")}</p></TableCell>
                   
                   </TableRow>
                 </TableFooter>
               );
             },
          
         }}
       />
</div>
  )
}

export default Salesperson_invoice_tbl