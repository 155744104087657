import React, { useEffect, useState } from 'react'
import MiniDrawer from '../components/Minidrawer'
import { DateRangePicker } from 'rsuite'
import "rsuite/dist/rsuite.min.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useDispatch } from "react-redux";
import Total_lead_assigned_tbl from '../components/Total_lead_assigned_tbl';
import Fnb_assigned_leads_tbl from '../components/Fnb_assigned_leads_tbl';
import Corporate_assigned_leads_tbl from '../components/Assigned_leads_tbl';
import split_img from "../Media/split.png"
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "../Styles/Table.module.css";
import { get_marketing_leads_created, get_marketing_leads_updated } from '../DataStudioRedux/actions';
import Assigned_leads_tbl from '../components/Assigned_leads_tbl';

function Marketing_lead_assigned() {
  const dispatch = useDispatch();
  let date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
  const [selectedFromDate, setSelectedFromDate] = useState(new Date(y, m, 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date(y, m + 1, 0));
  const [range_flag, setRange_Flag] = useState(false);

  const [tab_selected, setTab_Selected] = useState("created_on");
  const [params, setParams] = useState("MTD");

  const [created_marketing_load,setCreated_Marketing_Load] = useState(false);
  const [created_fnb_load,setCreated_FnB_Load] = useState(false);
  const [created_corporate_load,setCreated_Corporate_Load] = useState(false);

  const [updated_marketing_load,setUpdated_Marketing_Load] = useState(false);
  const [updated_fnb_load,setUpdated_Fnb_Load] = useState(false);
  const [updated_corporate_load,setUpdated_Corporate_Load] = useState(false);

  const [created_on_marketing_data,setCreated_On_Marketing_Data] = useState([]);
  const [created_on_fnb_data,setCreated_On_Fnb_Data] = useState([]);
  const [created_on_corporate_data,setCreated_On_Corporate_Data] = useState([]);

  const [updated_on_marketing_data,setUpdated_On_Marketing_Data] = useState([]);
  const [updated_on_fnb_data,setUpdated_On_Fnb_Data] = useState([]);
  const [updated_on_corporate_data,setUpdated_On_Corporate_Data] = useState([]);

  const [updated_on_data,setUpdated_On_data] = useState([])

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date(y, m + 1, 0));
      setParams("MTD");
      setRange_Flag(false);
    }
  };

  const handleCreatedOn = () => {
    setTab_Selected("created_on");
  };
  const handleUpdatedOn = () => {
    setTab_Selected("updated_on");
  };

  
  const handleParamChange = (e) => {
    setParams(e.target.value);
    if (e.target.value == "Custom Date") {
      setRange_Flag(true);
    } else {
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      setRange_Flag(false);
    }
  };

  
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (tab_selected == "created_on") {

      dispatch(
        get_marketing_leads_created(
          setCreated_Marketing_Load,
          setCreated_On_Marketing_Data,
          selectedFromDate,
          selectedToDate,
          params,
          "Marketing",
          signal
        )
      );
      dispatch(
        get_marketing_leads_created(
          setCreated_FnB_Load,
          setCreated_On_Fnb_Data,
          selectedFromDate,
          selectedToDate,
          params,
          "Fnb Sales",
          signal
        )
      );
      dispatch(
        get_marketing_leads_created(
          setCreated_Corporate_Load,
          setCreated_On_Corporate_Data,
          selectedFromDate,
          selectedToDate,
          params,
          "Corporate Sales",
          signal
        )
      );
 
    } else{
      dispatch(
        get_marketing_leads_updated(
          setUpdated_Marketing_Load,
          setUpdated_On_Marketing_Data,
          selectedFromDate,
          selectedToDate,
          params,
          "Marketing",
          signal
        )
      );
      dispatch(
        get_marketing_leads_updated(
          setUpdated_Fnb_Load,
          setUpdated_On_Fnb_Data,
          selectedFromDate,
          selectedToDate,
          params,
          "Fnb Sales",
          signal
        )
      );
      dispatch(
        get_marketing_leads_updated(
          setUpdated_Corporate_Load,
          setUpdated_On_Corporate_Data,
          selectedFromDate,
          selectedToDate,
          params,
          "Corporate Sales",
          signal
        )
      );
    }
    return () => {
      controller.abort();
    };
  }, [

    selectedToDate,
    selectedFromDate,
    tab_selected,
  ]);


  return (
    <>
    <MiniDrawer/>
    <div className='container-fluid' style={{paddingTop:"1em",paddingLeft:"1em"}}>
        <div className='row'>
          <h4>Marketing Leads Assigned</h4>
          <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "5px",
            marginTop:"10px"
          }}
        >
          {/* <button
            className={
              tab_selected == "created_on"
                ? "btn btn-primary btn-sm"
                : "btn btn-outline-primary btn-sm"
            }
            style={{ marginRight: "10px" }}
            onClick={handleCreatedOn}
          >
            Created On
          </button>
          <button
            className={
              tab_selected == "updated_on"
                ? "btn btn-primary btn-sm"
                : "btn btn-outline-primary btn-sm"
            }
            style={{ marginRight: "10px" }}
            onClick={handleUpdatedOn}
          >
            Updated On
          </button> */}
          <div>
            <FormControl size="small" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={styles.table_dropdown}
                onChange={handleParamChange}
                value={params}
              >
                <MenuItem value="" selected disabled>
                  Select Date Range
                </MenuItem>

                <MenuItem value="Today">Today</MenuItem>
                <MenuItem value="MTD">MTD</MenuItem>
                <MenuItem value="QTD">QTD</MenuItem>
                <MenuItem value="YTD">YTD</MenuItem>
                <MenuItem value="FYTD">FYTD</MenuItem>
                <MenuItem value="Custom Date">Custom Date</MenuItem>
              </Select>
            </FormControl>
          </div>

          {range_flag ? (
            <div style={{ marginLeft: "10px" }}>
              <DateRangePicker
                // defaultValue={[new Date(), new Date()]}
                format="dd MMM yyyy"
                value={[selectedFromDate, selectedToDate]}
                onChange={handleDateChange}
                style={{ width: "250px" }}
                size="md"
                placement="bottomEnd"
                preventOverflow
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        






        </div>

        <div className='row mt-3'>
          <div style={{margin:"auto"}} className='col-12'>
          {(tab_selected == "created_on" && created_marketing_load || tab_selected == "updated_on" && updated_marketing_load) ? 
            <img src={report_loader} id={styles.leads_loader} />
           : 
            <Total_lead_assigned_tbl data={tab_selected == "created_on"?created_on_marketing_data:updated_on_marketing_data} />}
            </div>
        </div>
        {/* <div className='row mt-2'>
          <img style={{height:"50px",width:"auto",margin:"auto"}} src={split_img} alt="" />
        </div> */}
        <div className='row mt-4 mb-5'>
          <div className='col-sm-12 col-md-6'>
          {(tab_selected == "created_on" && created_fnb_load || tab_selected == "updated_on" && updated_fnb_load) ?         <img src={report_loader} id={styles.leads_loader} />
           :  <Assigned_leads_tbl  data={tab_selected == "created_on"?created_on_fnb_data:updated_on_fnb_data} flag= {"fnb"}/>}
          </div>
          <div className='col-sm-12 col-md-6'>
          {(tab_selected == "created_on" && created_corporate_load || tab_selected == "updated_on" && updated_corporate_load) ?         <img src={report_loader} id={styles.leads_loader} />
           : <Assigned_leads_tbl data={tab_selected == "created_on"?created_on_corporate_data:updated_on_corporate_data} flag= {"corporate"}/>}
          </div>
        </div>
    </div>
    </>
  )
}

export default Marketing_lead_assigned
