import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import Service_peneatration_tbl from "../components/Service_peneatration_tbl";
import { useDispatch } from "react-redux";
import { get_service_penetration_data } from "../Latest_addition_redux/actions";
import { DatePicker, Space } from "antd";
import moment from "moment";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
const { RangePicker } = DatePicker;

function Service_penetration() {
  const storage = window.sessionStorage;
  let department = storage.getItem("DEPT");
  let Role_id = storage.getItem("Role_id");

  const [service_data, setService_Data] = useState([]);
  const [service_load, setService_Load] = useState(false);
  const dispatch = useDispatch();

  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  const [start_date, setStart_Date] = useState(new Date());
  const [end_date, setEnd_Date] = useState(new Date());

  const [range_flag, setRange_Flag] = useState(false);
  const [params, setParams] = useState("Current Month");

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
      let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
      setStart_Date(newStartDate);
      setEnd_Date(newEndDate);
      // setShowBtn(true);
    } else {
      setParams("Current Month")
      setRange_Flag(false);
      setSelectedFromDate();
      setSelectedToDate();
      setStart_Date(new Date());
      setEnd_Date(new Date());

      dispatch(get_service_penetration_data(setService_Data,setService_Load,"Current Month",start_date,end_date,department))
    }
  };

  const handleClick = () => {
    dispatch(
      get_service_penetration_data(
        setService_Data,
        setService_Load,
        params,
        start_date,
        end_date,
        department
      )
    );
  };

  const handleDropdown = (e) => {
    setParams(e.target.value);
    if (e.target.value == "Custom Date") {
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setSelectedFromDate();
      setSelectedToDate();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      dispatch(
        get_service_penetration_data(
          setService_Data,
          setService_Load,
          e.target.value,
          start_date,
          end_date,
          department
        )
      );
    }
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    dispatch(
      get_service_penetration_data(
        setService_Data,
        setService_Load,
        params,
        start_date,
        end_date,
        department
      )
    );
      }
  }, []);
  return (
    <>
      <MiniDrawer />
      <div className="container-fluid">
      {service_load ? 
            <img src={report_loader} id={styles.loader_sample} />
           : <> <div className="row mt-1">
          <div className={range_flag ? "col-6" : "col-10"}></div>
          <div className="col-2 mt-1">
            <select
              className="form-select"
              onChange={handleDropdown}
              value={params}
              name=""
              id=""
            >
              <option value="Current Month">Current Month</option>
              <option value="Last Month">Last Month</option>
              <option value="Current Quarter">Current Quarter</option>
              <option value="Last Quarter">Last Quarter</option>
              <option value="Current Year">Current Year</option>
              <option value="Last Year">Last Year</option>
              <option value="Custom Date">Custom Date</option>
            </select>
          </div>

          {range_flag ? (
            <div className="col-4 mt-2">
              <RangePicker
                // defaultValue={dayjs(value, "YYYY-MM-DD")} format={dateFormat}
                format={"DD-MM-YYYY"}
                value={[selectedFromDate, selectedToDate]}
                onChange={(new_val) => handleDateChange(new_val)}
              />
              <button
                className="btn btn-primary btn-sm ml-2"
                onClick={handleClick}
                style={{
                  //   display: showBtn ? "block" : "none",
                  marginLeft: "5px",
                  //   color:"#007cc3"
                }}
              >
                Apply
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="row">
     
            <Service_peneatration_tbl data={service_data} />
          
        </div>
    
    </>}
    </div>
    </>
  );
}

export default Service_penetration;
