import React from 'react'
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
// import { DatePicker, Space } from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

// const { RangePicker } = DatePicker;

function Salesperson_Sample_Type_count({
    handleDateRange,
    range_flag,
    selectedFromDate,
    selectedToDate,
    handleDateChange,
    data,
    sample_load,
    handleClick,
    client_list,
    display1,
    params_count
}) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const columns = [
    {
      name: "Sales_Person",
      label: "sales person",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender:(row)=>{
          return (
            <div style={{textAlign:"left",padding:"3px"}}>
            <span>{row}</span>
            </div>
          )
        }

      },
    },
    {
      name: "Audit",
      label: "Audit",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
      },
    },
    {
      name: "Beverages Testing",
      label: "Beverages Testing",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
      },
    },
    {
      name: "Compliance",
      label: "Compliance",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
      },
    },
    {
      name: "Environment Testing",
      label: "Environment Testing",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
      },
    },
    {
      name: "Food Microbiology",
      label: "Food Microbiology",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Indoor Air Quality Testing",
      label: "Indoor Air Quality Testing",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Monitoring",
      label: "Monitoring",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Not Defined",
      label: "Not Defined",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Nutrition Value Calculation",
      label: "Nutrition Value Calculation",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Others",
      label: "Others",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Shelf Life Testing",
      label: "Shelf Life Testing",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Study",
      label: "Study",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Swab Testing",
      label: "Swab Testing",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Training",
      label: "Training",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
    {
      name: "Water Testing",
      label: "Water Testing",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return (
            <span style={{textAlign:"center",padding:"5px"}}>{row == undefined?"0":new Intl.NumberFormat("en-IN").format(row)}</span>
          )
        }
    }
  },
   
  ];   
   const options = {
   filterType: "checkbox",
  };
  return (
    <>
    {sample_load ? 
      <img src={report_loader} id={styles.loader_sample} />
     : 
     <div id="client_type_tbl">
         <MUIDataTable
            title={<h5 style={{ textAlign: "left" }}>Salesperson Sample Type Revenue</h5>}
            data={data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              print: false,
              search: false,
              filter: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              pagination: true,
              tableBodyMaxHeight: "50vh",
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customToolbar: () => {
                return (
                  <>
                    <div style={{ display: "inline-flex" }}>
                      <select
                        onChange={handleDateRange}
                        value={params_count}
                        className="form-select"
                        style={{ marginRight: "20px", width: "160px" }}
                      >
                        <option value="" selected disabled>
                          Please Select
                        </option>
                        <option value="Today">Today</option>
                        <option value="MTD">MTD</option>
                        <option value="QTD">QTD</option>
                        <option value="YTD">YTD</option>
                        <option value="FYTD">FYTD</option>
                        <option value="Custom Date">Custom Date</option>
                      </select>
                      {range_flag ? (
                        <>

<DateRangePicker
                            // defaultValue={[new Date(), new Date()]}
                            format = "dd MMM yyyy"
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateChange}
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          />
                          {/* <RangePicker
                            // defaultValue={dayjs(value, "YYYY-MM-DD")} format={dateFormat}
                            format={"DD-MM-YYYY"}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={(new_val) => handleDateChange(new_val)}
                          />
                          <button
                            className="btn btn-light btn-sm ml-2"
                            onClick={handleClick}
                            style={{

                              // display: display2 ? "flex" : "none",
                              marginLeft: "5px",
                              //   color:"#007cc3"
                            }}

                          >
                            Apply
                          </button> */}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
    </div>

  }
  </>
  )
}

export default Salesperson_Sample_Type_count


  // <Paper
  //         sx={{

  //           mb: 2,
  //           padding: "0px",
  //           marginLeft: "2%",
  //           marginTop: "2%",
  //           width: "96%",
  //         }}
  //         id={styles.table_container}
  //       >
  //         <TableContainer sx={{ maxHeight: "60vh" }}>
  //           <Table stickyHeader aria-label="sticky table">
  //             <TableHead
  //               sx={{
  //                 backgroundColor: "#007cc3",
  //                 color: "#444a4f !important",
  //                 position: "sticky",
  //                 top: 0,
  //                 zIndex: "999",
  //               }}
  //             >
  //               <TableRow>
  //                 <TableCell  id={styles.th_unit1}
  //                  colspan={41}

  //                  >
  //                   <div style={{display:"flex"}}>

  //                   <div>
  //                   <h5
  //                     className="h5 mt-2"
  //                     style={{

  //                       display: "flex",
  //                       alignItems: "center",
  //                       justifyContent: "flex-start",
  //                       height: "50px",
  //                       paddingLeft: "20px",
  //                       textAlign:"left"
  //                     }}
  //                   >
  //                     Salesperson Sample Type Count
  //                   </h5>
  //                   </div>
  //                   <div style={{ display: "flex", justifyContent: "center",position:"fixed",marginLeft:range_flag?"55vw":"73vw",marginTop:"0.4%" }}>
  //                     {range_flag ? (
  //                       <>
  //                       <DateRangePicker
        
  //                            format = "dd MMM yyyy"
  //                            value={[selectedFromDate, selectedToDate]}
  //                            onChange={handleDateChange}
  //                            size='md'
  //                          placement='bottomEnd'
  //                          preventOverflow
  //                          />
           
  //                       </>
  //                     ) : (
  //                       <></>
  //                     )}
  //                     <select
  //                       onChange={handleDateRange}
  //                       value={params_count}
  //                       className="form-select"
  //                       style={{ marginLeft: "20px", width: "160px" }}
  //                     >
  //                       <option value="" selected disabled>
  //                         Please Select
  //                       </option>
  //                       <option value="Today">Today</option>
  //                       <option value="MTD">MTD</option>
  //                       <option value="QTD">QTD</option>
  //                       <option value="YTD">YTD</option>
  //                       <option value="FYTD">FYTD</option>
  //                       <option value="Custom Date">Custom Date</option>
  //                     </select>
  //                   </div>
  //                   </div>
  //                 </TableCell>
                
  //               </TableRow>
  //               <TableRow>
  //                 <TableCell className={styles.first_header} id={styles.th_unit1} style={{ textAlign: "left" }}>
  //                   <h6
  //                     className="h6 mt-2"
  //                     id={styles.hub_title}

  //                   >
  //                     Sales Person
  //                   </h6>
  //                 </TableCell>
  //                 {data[0]?.sample_data?.map((ele)=>(
  //                   <TableCell
  //                   id={styles.th_unit1}
  //                   style={{ textAlign: "center",maxWidth:"400px" }}
  //                 >
  //                   <h6
  //                     className="h6 mt-2"
  //                     style={{ color: "white", textAlign: "center" }}
  //                   >
  //                         {ele?.sample_type_name}
  //                   </h6>
  //                 </TableCell>
  //                 ))}
                  
                
  //               </TableRow>
  //             </TableHead>
  //             {data?.length == "0" ? (
  //               <TableBody>
  //                 <TableRow>
  //                   <TableCell id={styles.td_unit} colSpan={11}>
         
  //                     <p style={{ textAlign: "center", padding: "0px" }}>
  //                       No Data Available
  //                     </p>
  //                   </TableCell>
             
  //                 </TableRow>
  //               </TableBody>
  //             ) : (
  //               <TableBody id={styles.body_content}>
  //                  {(rowsPerPage > 0
  //                 ? data?.slice(
  //                     page * rowsPerPage,
  //                     page * rowsPerPage + rowsPerPage
  //                   )
  //                 : data
  //               )?.map((ele) => (
  //                   <TableRow>
  //                     <TableCell id={styles.td_unit}>
  //                       <p
  //                         style={{ textAlign: "left",padding:"10px" }}
  //                         className="p mt-2 ml-4"
  //                       >
  //                         {ele?.salesperson}
  //                       </p>
  //                     </TableCell>
  //                     {ele?.sample_data?.map((el)=>(
  //             <TableCell id={styles.td_unit}>
  //             <p style={{padding:"10px"}} className="p mt-2">{el?.value}</p>
  //           </TableCell>
  //           ))}
                   
  //                   </TableRow>
  //                 ))}
                  
  //               </TableBody>
  //             )}
  //           </Table>
  //         </TableContainer>
  //         <TablePagination
  //         id={styles.footer_div}
  //         class="footer_div"
  //         sx={{ backgroundColor: "#007cc3", color: "white" }}
  //         rowsPerPageOptions={[10, 25, 50, 100]}
  //         component="div"
  //         count={data?.length}
  //         rowsPerPage={rowsPerPage}
  //         page={page}
  //         onPageChange={handleChangePage}
  //         onRowsPerPageChange={handleChangeRowsPerPage}
  //       />
  //       </Paper>





//     <div id="client_type_tbl">
//        <MUIDataTable
//     title={<h5 style={{ textAlign: "left" }}>Salesperson Sample Type Count</h5>}
//     data={data}
//     columns={columns}
//     options={{
//       options: options,
//       selectableRows: "none",
//       viewColumns: false,
//       print: false,
//       search: false,
//       filter: false,
//       responsive: "standard",
//       download: false,
//       fixedHeader: true,
//       pagination: true,
//       tableBodyMaxHeight: "57vh",
//       setTableProps: () => {
//         return {
//           padding: "default",
//         };
//       },
//       customToolbar: () => {
//         return (
//           <>
//              <div style={{ display: "inline-flex" }}>
//                       <select
//                         onChange={handleDateRange}
//                         value={params_count}
//                         className="form-select"
//                         style={{ marginRight: "20px", width: "160px" }}
//                       >
//                         <option value="" selected disabled>
//                           Please Select
//                         </option>
//                         <option value="Today">Today</option>
//                         <option value="MTD">MTD</option>
//                         <option value="QTD">QTD</option>
//                         <option value="YTD">YTD</option>
//                         <option value="FYTD">FYTD</option>
//                         <option value="Custom Date">Custom Date</option>
//                       </select>
//                       {range_flag ? (
//                         <>
//                           {/* <RangePicker
//                             format={"DD-MM-YYYY"}
//                             value={[selectedFromDate, selectedToDate]}
//                             onChange={(new_val) => handleDateChange(new_val)}
//                           />
//                           <button
//                             className="btn btn-light btn-sm ml-2"
//                             onClick={handleClick}
//                             style={{
//                               // display: display1 ? "flex" : "none",
//                               marginLeft: "5px",

//                             }}
//                           >
//                             Apply
//                           </button> */}

// <DateRangePicker
//                             // defaultValue={[new Date(), new Date()]}
//                             format = "dd MMM yyyy"
//                             value={[selectedFromDate, selectedToDate]}
//                             onChange={handleDateChange}
//                             size='md'
//                           placement='bottomEnd'
//                           preventOverflow
//                           />

//                         </>
//                       ) : (
//                         <></>
//                       )}
//                     </div>
//           </>
//         );
//       },
//       textLabels: {
//         body: {
//           noMatch: "Data Not Available",
//         },
//       },
//     }}
//   /></div>