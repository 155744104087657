import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import no_data_icon from "../Media/no_data_icon.gif";
import styles from "../Styles/Sales_analysis.module.css";

function Invoice_amt_graph({ data, xLabels }) {
  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format;
  return (
    <div id="invoice_graph">
      {data?.length > 0 ? (
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: xLabels,
              label: "Sales Person",
              categoryGapRatio: data?.length == 1?0.8:0.4,
              valueFormatter: (value) => value.split(" ").join("\n"),
            },
          ]}
          yAxis={[
            {
              id: "linearAxis",
              scaleType: "linear",
              label: "Invoice Amount",
              //   valueFormatter: (value) => currencyFormatter(value),
              //   valueFormatter: (value) => currencyFormatter(value/100000)+"L",
              valueFormatter: (value) =>
                value >= 100000
                  ? currencyFormatter(value / 100000) + "L"
                  : currencyFormatter(value),
            },
          ]}
          series={[
            {
              data: data,
              label: "Invoice Amount",
              valueFormatter: (value) =>
                // (value == null || value == 0) ? null : value.toString(),
                value == null || value == 0
                  ? null
                  : value >= 100000
                  ? currencyFormatter(value / 100000) + "L"
                  : currencyFormatter(value),
              color: "#007cc3",
            },
          ]}

          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translate(-50px, 0)",
              fontWeight: "bold",
            },
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              fontWeight: "bold",
              transform: "translate(0, 40px)",
              marginTop: "20px",
            },
          }}
          // width={undefined}
          width={data.length <= 6 ? 700 : data.length >= 7 && data?.length <= 12?
            2000
            :data.length > 20 && data?.length <=25?3300:data.length > 25?4500: 2700}
          height={400}
          margin={{ left: 100, bottom: 100 }}
        />
      ) : (
        <>
          {" "}
          <div
            className={styles.noData}
            style={{ marginLeft: "-1%", marginTop: "0%" }}
          >
            <img
              src={no_data_icon}
              alt=""
              style={{ height: "200px", width: "200px" }}
            />
            <h4>No Data Added</h4>
          </div>
        </>
      )}
    </div>
  );
}

export default Invoice_amt_graph;
