import React from 'react'
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";

function Invoice_corporate_tbl({data}) {

  const get_total =()=>{
        let total_count = data?.reduce((acc,ele)=>{
           return acc+Number(ele?.Invoice_amount)
        },0)
        return new Intl.NumberFormat("en-IN").format(total_count);
  }

    const options = {
        filterType: "checkbox",
      };
      const columns = [
    
        {
          name: "Sales_person",
          label: `Sales Person`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{textAlign:"left"}}>
                  <p>{row}</p>
                </div>
              )
            }
          },
        },
        {
          name: "company_name",
          label: "client Name",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{width:"100px",maxWidth:"auto",textAlign:"left"}}>
                  <p>{row}</p>
                </div>
              )
            }
          },
        },
        {
          name: "Credit_not_status",
          label: "Credit Note",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{display:"flex"}}><span style={{marginLeft:"2vw"}}>{row}</span></div>
                
              )
            }
          },
        },
        {
          name: "Invoice_Status",
          label: "Status",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{display:"flex"}}><p >{row}</p></div>
                
              )
            }
          },
        },
        {
          name: "invoice_no",
          label: "Invoice No",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{display:"flex"}}><p >{row}</p></div>
                
              )
            }
          },
        },
        {
          name: "Invoice_date",
          label: "Invoice Date",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{display:"flex"}}><p >{row}</p></div>
                
              )
            }
          },
        },
        {
          name: "state",
          label: "State",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{display:"flex"}}><p >{row}</p></div>
                
              )
            }
          },
        },
        {
          name: "Invoice_amount",
          label: "Invoice Amount (₹)",
          options: {
            filter: false,
            sort: true,
           customBodyRender:(row)=>{
            return(
              <>{new Intl.NumberFormat("en-IN").format(row)}</>
            )
           }
          },
        },
      
  
      ];
  return (
    <div id="corporate_tbl">
           <div id={styles.graph_total_container}><p>Total (₹) : {get_total()}</p></div>
         <MUIDataTable
              title={<h5 style={{ textAlign: "left" }}>Salesperson Invoice Details</h5>}
              data={data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                // tableBodyMaxHeight: "50vh",
                rowsPerPageOptions:[10,25,50,100],
                filter:false,
                search :false,
                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available"
                  },
                },
                // customTableBodyFooterRender: (
                //     count,
                //     page,
                //     rowsPerPage,
                //     changeRowsPerPage,
                //     changePage,
                //     data
                //   ) => {
                //     const startIndex = page * rowsPerPage;
                //     const endIndex = (page + 1) * rowsPerPage;
                //     return (
                //       <TableFooter id={styles.table_footer}>
                //         <TableRow>
                //         <TableCell colSpan={6}></TableCell>
                //           <TableCell id={styles.first_col}>
                //             <p style={{textAlign:"left"}}>Total (₹)</p>
                //           </TableCell>
                //           <TableCell><p style={{textAlign:"center"}}>{get_total()}</p></TableCell>
                        
                //         </TableRow>
                //       </TableFooter>
                //     );
                //   },
               
              }}
            />
    </div>
  )
}

export default Invoice_corporate_tbl