import React from 'react'
import Interested_lead_report from '../pages/Interested_lead_report'

function Interested_lead_report_fnb() {
  return (
    <>
      <Interested_lead_report/>
    </>
  )
}

export default Interested_lead_report_fnb
