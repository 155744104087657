import React from 'react'
import Invoice_monthly_report from '../pages/Invoice_monthly_report'

function Invoice_monthly_fnb() {
  return (
    <>
      <Invoice_monthly_report/>
    </>
  )
}

export default Invoice_monthly_fnb
