import React from 'react'
import Salespersonwise_invoice from '../pages/Salespersonwise_invoice'

function Salespersonwise_invoice_fnb() {
  return (
    <>
      <Salespersonwise_invoice/>
    </>
  )
}

export default Salespersonwise_invoice_fnb
