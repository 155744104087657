import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { DatePicker, Space } from "antd";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import MiniDrawer from "../components/Minidrawer";
import { marketing_performance } from "../Latest_addition_redux/actions";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function Marketing_Performance() {
  const storage = window.sessionStorage;
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");
  let DEPT = storage.getItem("DEPT");

  const data = useSelector((state) => state.data.data);
  const dispatch = useDispatch();

  const [leadsourceProgressionData, setLeadsourceProgressionData] = useState(
    []
  );
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [start_date, setStart_Date] = useState("");
  const [end_date, setEnd_Date] = useState("");
  const [range_flag, setRange_Flag] = useState(false);
  const [params, setParams] = useState("MTD");
  const [data_load, setData_load] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [leadsourceList, setLeadsourceList] = useState([]);
  const [selectedCategory, setSelectedcategory] = useState("ALL");
  const [selectedLeadsource, setSelectedLeadsource] = useState("ALL");
  const [total_leads, setTotal_Leads] = useState(0);

  const get_category = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_category");
      let data = await res.json();
      if (data.response?.code == "200") {
        let temp_data = [];
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Leadcat != "Fnb Zomato Lead") {
            temp_data?.push(data?.Data[i]);
          }
        }
        setCategoryList(temp_data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {}
  };

  const get_total = (param) => {
    let total_value = 0;

    if (param == "new_lead") {
      let total_count = leadsourceProgressionData?.reduce((acc, ele) => {
        return acc + Number(ele?.new_lead);
      }, 0);
      total_value += Number(total_count);
      return total_count ? total_count : 0;
    } else if (param == "relevant") {
      let total_count = leadsourceProgressionData?.reduce((acc, ele) => {
        return acc + Number(ele?.relevant);
      }, 0);
      total_value += Number(total_count);
      return total_count ? total_count : 0;
    } else {
      let total_count = leadsourceProgressionData?.reduce((acc, ele) => {
        return acc + Number(ele?.customer);
      }, 0);
      total_value += Number(total_count);
      return total_count ? total_count : 0;
    }
  };

  // const get_total_newLead = () => {

  //   let total_count = leadsourceProgressionData?.reduce((acc, ele) => {
  //     return acc + Number(ele?.new_lead);
  //   }, 0);
  //   return total_count ? total_count : 0;
  // }

  // const get_total_relevant = () => {
  //   let total_count = leadsourceProgressionData?.reduce((acc, ele) => {
  //     return acc + Number(ele?.relevant);
  //   }, 0);
  //   return total_count ? total_count : 0;
  // }

  // const get_total_customer = () => {
  //   let total_count = leadsourceProgressionData?.reduce((acc, ele) => {
  //     return acc + Number(ele?.customer);
  //   }, 0);
  //   return total_count ? total_count : 0;
  // }

  const get_source = async (val) => {
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/get_leadsource_by_category",
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            CATEGORY: val,
          }),
        }
      );
      let data = await res.json();
      if (data.response?.code == "200") {
        let temp_data = [];
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.Leadsource != "Fnb Zomato Lead") {
            temp_data?.push(data?.Data[i]);
          }
        }
        setLeadsourceList(temp_data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      let newStartDate = "";
      let newEndDate = "";
      if (selectedFromDate != "") {
        newStartDate = moment(selectedFromDate).format("YYYY-MM-DD");
      } else {
        setSelectedFromDate(new Date());
      }

      if (selectedToDate.length > 0 || selectedToDate != "") {
        newEndDate = moment(selectedToDate).format("YYYY-MM-DD");
      } else {
        setSelectedToDate(new Date());
      }

      dispatch(
        marketing_performance(
          setLeadsourceProgressionData,
          selectedCategory,
          selectedLeadsource,
          params,
          newStartDate,
          newEndDate,
          // start_date,
          // end_date,
          setData_load,
          setTotal_Leads
        )
      );
    }
  }, [selectedCategory, selectedLeadsource]);

  const onChangecategory = (e) => {
    setSelectedcategory(e.target.value);
    if (e.target.value == "") {
      setLeadsourceList([]);
      setSelectedcategory("ALL");
      setSelectedLeadsource("ALL");
    } else {
      setSelectedLeadsource("ALL");
      get_source(e.target.value);
    }
  };

  const onChangeLeadsource = (e) => {
    if (e.target.value != "") {
      setSelectedLeadsource(e.target.value);
    }
  };

  const handleDateRange = (e) => {
    if (e.target.value == "Custom Date") {
      setParams("Custom Date");
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setParams(e.target.value);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      // setSelectedFromDate(new Date());
      // setSelectedToDate(new Date());
      setStart_Date("");
      setEnd_Date("");
      dispatch(
        marketing_performance(
          setLeadsourceProgressionData,
          selectedCategory,
          selectedLeadsource,
          e.target.value,
          start_date,
          end_date,
          setData_load,
          setTotal_Leads
        )
      );
    }
  };

  const handleSelectDateRange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);

      let newStartDate = moment(new_val[0]).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]).format("YYYY-MM-DD");

      dispatch(
        marketing_performance(
          setLeadsourceProgressionData,
          selectedCategory,
          selectedLeadsource,
          params,
          newStartDate,
          newEndDate,
          setData_load,
          setTotal_Leads
        )
      );
    } else {
      setParams("MTD");
      setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
      dispatch(
        marketing_performance(
          setLeadsourceProgressionData,
          selectedCategory,
          selectedLeadsource,
          "MTD",
          new_date,
          new_date,
          setData_load,
          setTotal_Leads
        )
      );
    }
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      get_category();
    }
  }, []);

  const columns = [
    {
      name: "category",
      label: "Category",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left", padding: "3px" }}>
              <span>{row}</span>
            </div>
          );
        },
      },
    },
    {
      name: "leadsource",
      label: "Leadsource",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left", padding: "8px" }}>
              <span>{row}</span>
            </div>
          );
        },
      },
    },
    {
      name: "new_lead",
      label: "New Leads",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minWidth: "12vw",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: "8px",
                    marginBottom: "0.4rem",
                  }}
                >
                  New Leads
                </p>
              </div>
            </>
          );
        },
        customBodyRender: (row) => {
          return (
            <span style={{ textAlign: "center", padding: "5px" }}>
              {row == undefined
                ? "0"
                : new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "relevant",
      label: "Relevant",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minWidth: "12vw",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: "8px",
                    marginBottom: "0.4rem",
                  }}
                >
                  Relevant
                </p>
              </div>
            </>
          );
        },
        customBodyRender: (row) => {
          return (
            <span style={{ textAlign: "center", padding: "5px" }}>
              {row == undefined
                ? "0"
                : new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "customer",
      label: "Customer",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minWidth: "12vw",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: "8px",
                    marginBottom: "0.4rem",
                  }}
                >
                  Customer
                </p>
              </div>
            </>
          );
        },
        customBodyRender: (row) => {
          return (
            <span style={{ textAlign: "center", padding: "5px" }}>
              {row == undefined
                ? "0"
                : new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />
      {data_load ? (
        <img src={report_loader} id={styles.loader_sample} />
      ) : (
        <div
          id="client_type_tbl"
          style={{ marginLeft: "1%", marginTop: "1%", width: "98%" }}
        >
          <MUIDataTable
            title={
              <h5 style={{ textAlign: "left" }}>
                Marketing Performance{" "}
                <button id={styles.leads_container}>
                  Total : {total_leads}
                </button>
              </h5>
            }
            data={leadsourceProgressionData}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              print: false,
              search: false,
              filter: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              pagination: true,
              tableBodyMaxHeight: "55vh",
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customToolbar: () => {
                return (
                  <>
                    <div style={{ display: "inline-flex", gap: "5px" }}>
                      <Box
                        sx={{
                          width: "160px",
                          backgroundColor: "white",
                          height: "36px",
                          borderRadius: "5px",
                        }}
                      >
                        <FormControl size="small" fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{
                              width: "160px",
                              height: "36px",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                            value={selectedCategory}
                            onChange={onChangecategory}
                          >
                            <MenuItem value="ALL">All Category</MenuItem>
                            {categoryList?.map((ele) => (
                              <MenuItem value={ele.Leadcat}>
                                {ele.Leadcat}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {/* <select
                       className="form-select"
                      id={styles.marketing_payment_drop}
                      style={{  width: "160px", padding:"4px" }}
                      name=""
                      value={selectedCategory}
                      onChange={onChangecategory}
                    >
                      <option value="ALL">All Category</option>
                      {categoryList?.map((ele) => (
                        <option value={ele.Leadcat}>{ele.Leadcat}</option>
                      ))}
                    </select> */}

                      <Box
                        sx={{
                          width: "160px",
                          backgroundColor: "white",
                          height: "36px",
                          borderRadius: "5px",
                        }}
                      >
                        <FormControl size="small" fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{
                              width: "160px",
                              height: "36px",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                            value={selectedLeadsource}
                            onChange={onChangeLeadsource}
                          >
                            <MenuItem value="">Select Source</MenuItem>
                            <MenuItem value="ALL">All Source</MenuItem>
                            {leadsourceList?.map((ele) => (
                              <MenuItem value={ele.Leadsource}>
                                {ele.Leadsource}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {/* <select
                       className="form-select"
                      id={styles.marketing_source_drop}
                      style={{  width: "160px", padding:"4px" }}
                      name=""
                      value={selectedLeadsource}
                      
                      onChange={onChangeLeadsource}
                    >
                      <option value="">Select Source</option>
                      <option value="ALL">All Source</option>
                      {leadsourceList?.map((ele) => (
                        <option value={ele.Leadsource}>{ele.Leadsource}</option>
                      ))}
                    </select> */}
                      <Box
                        sx={{
                          width: "160px",
                          backgroundColor: "white",
                          height: "36px",
                          borderRadius: "5px",
                        }}
                      >
                        <FormControl size="small" fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{
                              width: "160px",
                              height: "36px",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                            onChange={handleDateRange}
                            value={params}
                          >
                            <MenuItem value="">Please Select</MenuItem>
                            <MenuItem value="Today">Today</MenuItem>
                            <MenuItem value="MTD">MTD</MenuItem>
                            <MenuItem value="QTD">QTD</MenuItem>
                            <MenuItem value="YTD">YTD</MenuItem>
                            <MenuItem value="FYTD">FYTD</MenuItem>
                            <MenuItem value="Custom Date">Custom Date</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      {/* <select
                        onChange={handleDateRange}
                        value={params}
                        className="form-select"
                        style={{  width: "160px", padding:"4px" }}
                      >
                        <option value="" disabled>
                          Please Select
                        </option>
                        <option value="Today">Today</option>
                        <option value="MTD">MTD</option>
                        <option value="QTD">QTD</option>
                        <option value="YTD">YTD</option>
                        <option value="FYTD">FYTD</option>
                        <option value="Custom Date">Custom Date</option>
                      </select> */}
                      {range_flag ? (
                        <>
                          <DateRangePicker
                            // defaultValue={[new Date(), new Date()]}
                            format="dd MMM yyyy"
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleSelectDateRange}
                            size="md"
                            placement="bottomEnd"
                            preventOverflow
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              },
              customTableBodyFooterRender: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                data
              ) => {
                return (
                  <TableFooter id={styles.table_footer}>
                    <TableRow>
                      <TableCell id={styles.first_col} colspan={2}>
                        <p style={{ textAlign: "left" }}>Total</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("new_lead")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("relevant")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("customer")}</p>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}

export default Marketing_Performance;
