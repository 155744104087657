import React from "react";
import MUIDataTable from "mui-datatables";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";

import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";

const { RangePicker } = DatePicker;


function Client_type_revenue_tbl({
  handleDateRange,
  range_flag,
  selectedFromDate,
  selectedToDate,
  handleDateChange,
  data,
  revenue_load,
  handleClick,
  display2,
  params_revenue,
}) {

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last week",
      value: [
        startOfWeek(addWeeks(new Date(), -1)),
        endOfWeek(addWeeks(new Date(), -1)),
      ],
      placement: "left",
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];

  const columns = [
    {
      name: "Sample_Type",
      label: "Sample Type",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            // minWidth: "130px",
            // maxWidth: "auto",
            padding: "0px",
            textAlign: "left",
          },
        }),

        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left", marginLeft: "10px",paddingRight:"20px" }}>
              <span>{row}</span>
            </div>
          );
        },
      },
    },
    {
      name: "Corporate - Enterprise",
      label: "Corporate - Enterprise",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "Corporate - Environmental",
      label: "Corporate - Environmental",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "Corporate - Industrial",
      label: "Corporate - Industrial",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "FNB - B2C",
      label: "FNB - B2C",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "FNB - Horeca",
      label: "FNB - Horeca",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "FNB - Hospitals/schools",
      label: "FNB - Hospitals/schools",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "FNB - Manufacturer",
      label: "FNB - Manufacturer",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "Partner",
      label: "Partner",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "PCVT",
      label: "PCVT",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "QA",
      label: "QA",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
    {
      name: "Sustainability",
      label: "Sustainability",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          padding: "10px",
        }),
        customBodyRender: (row) => {
          if (row == undefined) return <>0</>;
          return (
            <span style={{ padding: "10px" }}>
              {new Intl.NumberFormat("en-IN").format(row)}
            </span>
          );
        },
      },
    },
  ];
  const options = {
    filterType: "checkbox",
  };
  return (
    <>
      {revenue_load ? (
        <img src={report_loader} id={styles.loader_sample} />
      ) : (
        <div id={data?.length == 0 ? "" : "client_s6"}>
          <MUIDataTable
            title={<h5 style={{ textAlign: "left" }}>Client Type Sample Type Revenue</h5>}
            data={data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              print: false,
              search: false,
              filter: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              rowsPerPageOptions:[10,25,50,100],
              // pagination: false,
              // tableBodyMaxHeight: "60vh",
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customToolbar: () => {
                return (
                  <>
                    <div style={{ display: "inline-flex" }}>
                      <select
                        onChange={handleDateRange}
                        value={params_revenue}
                        className="form-select"
                        style={{ marginRight: "20px", width: "160px" }}
                      >
                        <option value="" selected disabled>
                          Please Select
                        </option>
                        <option value="Today">Today</option>
                        <option value="MTD">MTD</option>
                        <option value="QTD">QTD</option>
                        <option value="YTD">YTD</option>
                        <option value="FYTD">FYTD</option>
                        <option value="Custom Date">Custom Date</option>
                      </select>
                      {range_flag ? (
                        <>
                          {/* <RangePicker
                            // defaultValue={dayjs(value, "YYYY-MM-DD")} format={dateFormat}
                            format={"DD-MM-YYYY"}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={(new_val) => handleDateChange(new_val)}
                          />
                          <button
                            className="btn btn-light btn-sm ml-2"
                            onClick={handleClick}
                            style={{
                              // display: display2 ? "flex" : "none",
                              marginLeft: "5px",
                              //   color:"#007cc3"
                            }}
                          >
                            Apply
                          </button> */}

<DateRangePicker
                            defaultValue={[new Date(), new Date()]}
                            // ranges={predefinedRanges}
                            format = "dd MMM yyyy"
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateChange}
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          />

                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}

export default Client_type_revenue_tbl;
