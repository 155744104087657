import React, { useRef } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { DatePicker, Space } from "antd";
import styles from "../Styles/Sales_analysis.module.css";
import no_data_icon from "../Media/no_data_icon.gif";
import report_loader from "../Media/ezgif.com-optimize.gif";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ChartsXAxis, ChartsYAxis } from "@mui/x-charts";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import moment from "moment";

function Monthly_sales({
  data,
  fnb_sales_data,
  setFnb_Sales_Data,
  corporate_sales_data,
  setCorporate_Sales_Data,
  handleMonth,
  selectedMonth,
  load_monthly,
  date_flag,
  width_flag,
}) {
  let xLabels = [
    "",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];

  const storage = window.sessionStorage;
  let department = storage.getItem("DEPT");

  let sample = [20000, 40000, 80000, 100000, 200000, 1000000];
  const windowHeight = useRef(window.innerHeight);

  let current_year = new Date().getFullYear();
  let next_year = current_year+1;

  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format;

  return (
    <>
      {load_monthly ? (
        <img src={report_loader} id={styles.loader_operation} />
      ) : (
        <div
          id={styles.graph_contain}
          style={{ paddingLeft: width_flag == "overall" ? 0 : "17%" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            {width_flag == "testing" ? (
              <>
                {" "}
                {date_flag ? (
                  <p style={{fontWeight:"bold"}}>
                    Testing Service Revenue for{" "}
                    {moment(selectedMonth?.$d).format("MMM-YYYY")}
                  </p>
                ) : (
                  <p style={{fontWeight:"bold"}}>Testing Service Revenue for Year {current_year}-{next_year}</p>
                )}
              </>
            ) : (
              <></>
            )}
            {width_flag == "non_testing" ? (
              <>
                {" "}
                {date_flag ? (
                  <p style={{fontWeight:"bold"}}>
                    Non Testing Service Revenue for{" "}
                    {moment(selectedMonth?.$d).format("MMM-YYYY")}
                  </p>
                ) : (
                  <p style={{fontWeight:"bold"}}>Non Testing Service Revenue for Year {current_year}-{next_year}</p>
                )}
              </>
            ) : (
              <></>
            )}
            {width_flag == "overall" ? (
              <>
                {" "}
                {date_flag ? (
                  <p style={{fontWeight:"bold"}}>
                    Overall Revenue for{" "}
                    {moment(selectedMonth?.$d).format("MMM-YYYY")}
                  </p>
                ) : (
                  <p style={{fontWeight:"bold"}}>Overall Revenue for Year {current_year}-{next_year}</p>
                )}
              </>
            ) : (
              <></>
            )}

            {/* <Space direction="vertical">
              <DatePicker
                onChange={handleMonth}
                picker="month"
                value={selectedMonth}
                format="MMM-YYYY"
              />
            </Space> */}
          </div>

          {fnb_sales_data?.length > 0 && corporate_sales_data?.length > 0 ? (
            <div>
              <LineChart 
                xAxis={[{ scaleType: "point", data: xLabels, label: "Months" }]}
                yAxis={[
                  {
                    id: "linearAxis",
                    scaleType: "linear",
                    label: "Revenue",
                    valueFormatter: (value) => currencyFormatter(value),
                    // valueFormatter: (value) => currencyFormatter(value/100000)+"L",
                    // valueFormatter: (value) => (value/100000)+"L",
                  },
                ]}
              series={[
              {
                    curve: date_flag ? "curve" : "linear",
                    data:    (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "FnB Sales")? fnb_sales_data:[null],
                    label:   (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "FnB Sales")? "Fnb-Sales":"",
                    color:  (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "FnB Sales")?  "#C70039":"white",
                    valueFormatter: (value) =>
                      // (value == null || value == 0) ? null : value.toString(),
                      date_flag
                        ? value == null || value == 0
                          ? null
                          : currencyFormatter(value)
                        : value == null
                        ? null
                        : currencyFormatter(value),
                    showMark: (value) =>
                      value?.value == 0 && date_flag ? false : true,
                  },
           {
                    curve: date_flag ? "curve" : "linear",
                    data:(department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "Corporate Sales")? corporate_sales_data:[null],
                    label: (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "Corporate Sales")? "Corporate-Sales":"",
                    color: (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "Corporate Sales")? "#C683D7":"white",
                    valueFormatter: (value) =>
                      date_flag
                        ? value == null || value == 0
                          ? null
                          : currencyFormatter(value)
                        : value == null
                        ? null
                        : currencyFormatter(value),
                    showMark: (value) =>
                      value?.value == 0 && date_flag ? false : true,
                  }
                ]}
                label="Month"
                tooltipStyle={{
                  width: "100px",
                }}
                sx={{
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "translate(-85px, 0)",
                    fontWeight: "bold",
                  },
                  [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                    fontWeight: "bold",
                    transform: "translate(0, 5px)",
                    marginTop: "20px",
                  },
                }}
                // width={windowHeight?.current < 600 ? 700 : 1000}
                // width={windowHeight?.current<800?800:1100}
                width={(windowHeight?.current>900&&width_flag == "overall")?950:
                (windowHeight?.current>900&&width_flag != "overall")?1250:
                width_flag == "overall"?780:900}
                height={windowHeight?.current>900?500:380}
                margin={{ left:windowHeight?.current<600?120:140 }}
              ></LineChart>
            </div>
          ) : (
            <>
              {" "}
              <div
                className={styles.noData}
                style={{ marginLeft: "-15%", marginTop: "0%" }}
              >
                <img
                  src={no_data_icon}
                  alt=""
                  style={{ height: "200px", width: "200px" }}
                />
                <h4>No Data Added</h4>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Monthly_sales;
