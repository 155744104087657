import React, { useEffect, useRef, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import { useDispatch } from "react-redux";
import { get_testing_location_graph_data } from "../Latest_addition_redux/actions";
import { LineChart } from "@mui/x-charts";
import { ChartsXAxis, ChartsYAxis } from "@mui/x-charts";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import styles from "../Styles/Sales_analysis.module.css";
import no_data_icon from "../Media/no_data_icon.gif";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useNavigate } from "react-router-dom";
import { saveAs } from 'file-saver'; 

function Testing_location_graph() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");

  const dispatch = useDispatch();
  const [testing_graph_data_mum, setTesting_Graph_Data_Mum] = useState([]);
  const [testing_graph_data_ban, setTesting_Graph_Data_Ban] = useState([]);
  const [testing_graph_data_hyd, setTesting_Graph_Data_Hyd] = useState([]);
  const [testing_graph_data_chen, setTesting_Graph_Data_Chen] = useState([]);
  const [testing_graph_data_kol, setTesting_Graph_Data_Kol] = useState([]);
  const [testing_graph_data_noid, setTesting_Graph_Data_Noid] = useState([]);
  const [testing_location, setTesting_Location] = useState("Mumbai");
  const [testing_location_data, setTesting_location_Data] = useState([]);

  const [graph_load, setGraph_Load] = useState(false);
  const navigate = useNavigate();
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  const get_location = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_location");
      let data = await res.json();
      setTesting_location_Data(data?.Data);
    } catch (error) {
      ;
    }
  };

  // useEffect(()=>{
  //   if(testing_location != ""){
  //     dispatch(get_testing_location_graph_data(setGraph_Load,setTesting_Graph_Data,testing_location))
  //   }
  // },[testing_location])

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    dispatch(
      get_testing_location_graph_data(
        setGraph_Load,
        setTesting_Graph_Data_Mum,
        setTesting_Graph_Data_Ban,
        setTesting_Graph_Data_Hyd,
        setTesting_Graph_Data_Chen,
        setTesting_Graph_Data_Kol,
        setTesting_Graph_Data_Noid,
        "ALL"
      )
    );
      }
    // dispatch(
    //   get_testing_location_graph_data(
    //     setGraph_Load,
    //     setTesting_Graph_Data_Ban,
    //     "Bangalore"
    //   )
    // );
    // dispatch(
    //   get_testing_location_graph_data(
    //     setGraph_Load,
    //     setTesting_Graph_Data_Hyd,
    //     "Hyderabad"
    //   )
    // );
    // dispatch(
    //   get_testing_location_graph_data(
    //     setGraph_Load,
    //     setTesting_Graph_Data_Chen,
    //     "Chennai"
    //   )
    // );
    // dispatch(
    //   get_testing_location_graph_data(
    //     setGraph_Load,
    //     setTesting_Graph_Data_Kol,
    //     "Kolkata"
    //   )
    // );
    // dispatch(
    //   get_testing_location_graph_data(
    //     setGraph_Load,
    //     setTesting_Graph_Data_Noid,
    //     "Noida"
    //   )
    // );
  }, []);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_location();
    }
  }, []);

  let xLabels = [
    "",
    "M",
    "M1",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "M7",
    "M8",
    "M9",
    "M10",
    "M11",
    "M12",
  ];

  const check_value = (value)=>{
    let new_value = "    "+value;
    return new_value
  }

  // const handleSave =()=>{
  //   const canvasSave = document.getElementById('graph_img');
  //   canvasSave?.toBlob(function (blob) {
  //       saveAs(blob, "testing.png")
  //   })
  // }

  return (
    <>
      <MiniDrawer />
      <div style={{ marginTop: "1%", marginLeft: "1%", textAlign: "left" }}>
        <button
          className="btn btn-sm"
          onClick={() => navigate("/testing_location")}
          style={{backgroundColor:"#007cc3",color:"white"}}
        >
          View Report
        </button>
      </div>
      {graph_load ? (
        <img src={report_loader} id={styles.loader_operation} />
      ) : 
        // ) :<div className= "container-fluid" style={{display:"flex",flexDirection:"column", alignItems:"center", marginTop:"10vh" }}>
        <div className="container-fluid">
        <div class="location_graph" className="row mt-5 mb-5">
        {/* <button onClick={handleSave}>download Img</button> */}
            {testing_graph_data_mum?.length > 0 ? (
              // <div className="col-sm-12 col-md-12" style={{display:"flex"}}>
                <LineChart
                // id="graph_img"
                  xAxis={[
                    { scaleType: "point", data: xLabels, label: "Months" },
                  ]}
                  yAxis={[{ label: "Sample Count" }]}
                  // yAxis={[{ id: "money" }, { id: "quantities" },
                  // {hideTooltip:true} ]}

                  series={[
                    {
                      curve: "linear",
                      data: testing_graph_data_mum,
                      label: "Mumbai",
                      color: "#00A9FF",
                    },
                    {
                      curve: "linear",
                      data: testing_graph_data_ban,
                      label: "Bangalore",
                      color: "#89CFF3",
                    },
                    {
                      curve: "linear",
                      data: testing_graph_data_kol,
                      label: "Kolkata",
                      color: "#068DA9",
                    },
                    {
                      curve: "linear",
                      data: testing_graph_data_chen,
                      label: "Chennai",
                      color: "#0C356A",
                    },
                    {
                      curve: "linear",
                      data: testing_graph_data_noid,
                      label: "Noida",
                      color: "#0079FF",
                    },
                    {
                      curve: "linear",
                      data: testing_graph_data_hyd,
                      label: "Hyderabad",
                      color: "#007cc3",
                      valueFormatter: value => check_value(value)
                    },
                  ]}
                  label="Month"
                  tooltipStyle={{
                    width: "100px",
                  }}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-30px, 0)",
                      fontWeight: "bold",
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      fontWeight: "bold",
                      transform: "translate(0, 5px)",
                      marginTop: "20px",
                    },
                  }}
                  // width={windowHeight?.current < 650 ? 800 : 1000}
                  width={1000}
                  height={windowHeight?.current < 650 ?400:500}
                  margin={{ left: 70 }}
                ></LineChart>
              // </div>
              
            ) : (
              <>
                {" "}
                <div
                  className={styles.noData}
                  style={{ marginLeft: "-15%", marginTop: "0%" }}
                >
                  <img
                    src={no_data_icon}
                    alt=""
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>No Data Available</h4>
                </div>
              </>
            )}


          {/* <div className="row mt-2 mb-5">
            {testing_graph_data_mum?.length > 0 ? (
              <div className="col-sm-12 col-md-6" style={{display:"flex"}}>
                <LineChart
                  xAxis={[
                    { scaleType: "point", data: xLabels, label: "Mumbai" },
                  ]}
                  yAxis={[{ label: "Sample Count" }]}
                  // yAxis={[{ id: "money" }, { id: "quantities" },
                  // {hideTooltip:true} ]}

                  series={[
                    {
                      curve: "linear",
                      data: testing_graph_data_mum,
                      label: "Sample Count",
                      color: "#C70039",
                    },
                  ]}
                  label="Month"
                  tooltipStyle={{
                    width: "100px",
                  }}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-30px, 0)",
                      fontWeight: "bold",
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      fontWeight: "bold",
                      transform: "translate(0, 5px)",
                      marginTop: "20px",
                    },
                  }}
                  width={windowHeight?.current < 650 ? 500 : 700}
                  height={400}
                  margin={{ left: 70 }}
                ></LineChart>
              </div>
            ) : (
              <>
                {" "}
                <div
                  className={styles.noData}
                  style={{ marginLeft: "-15%", marginTop: "0%" }}
                >
                  <img
                    src={no_data_icon}
                    alt=""
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>No Data Available</h4>
                </div>
              </>
            )} */}

            {/* {testing_graph_data_ban?.length > 0 ? (
              <div className="col-sm-12 col-md-6" style={{display:"flex"}}>
                <LineChart
                  xAxis={[
                    { scaleType: "point", data: xLabels, label: "Bangalore" },
                  ]}
                  yAxis={[{ label: "Sample Count" }]}
                  // yAxis={[{ id: "money" }, { id: "quantities" },
                  // {hideTooltip:true} ]}

                  series={[
                    {
                      curve: "linear",
                      data: testing_graph_data_ban,
                      label: "Sample Count",
                      color: "#C70039",
                    },
                  ]}
                  label="Month"
                  tooltipStyle={{
                    width: "100px",
                  }}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-30px, 0)",
                      fontWeight: "bold",
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      fontWeight: "bold",
                      transform: "translate(0, 5px)",
                      marginTop: "20px",
                    },
                  }}
                  width={windowHeight?.current < 650 ? 500 : 700}
                  height={400}
                  margin={{ left: 70 }}
                ></LineChart>
              </div>
            ) : (
              <>
                {" "}
                <div
                  className={styles.noData}
                  style={{ marginLeft: "-15%", marginTop: "0%" }}
                >
                  <img
                    src={no_data_icon}
                    alt=""
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>No Data Available</h4>
                </div>
              </>
            )} */}
            {/* {testing_graph_data_hyd?.length > 0 ? (
              <div className="col-sm-12 col-md-6" style={{display:"flex"}}>
                <LineChart
                  xAxis={[
                    { scaleType: "point", data: xLabels, label: "Hyderabad" },
                  ]}
                  yAxis={[{ label: "Sample Count" }]}
                  // yAxis={[{ id: "money" }, { id: "quantities" },
                  // {hideTooltip:true} ]}

                  series={[
                    {
                      curve: "linear",
                      data: testing_graph_data_hyd,
                      label: "Sample Count",
                      color: "#C70039",
                    },
                  ]}
                  label="Month"
                  tooltipStyle={{
                    width: "100px",
                  }}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-30px, 0)",
                      fontWeight: "bold",
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      fontWeight: "bold",
                      transform: "translate(0, 5px)",
                      marginTop: "20px",
                    },
                  }}
                  width={windowHeight?.current < 650 ? 500 : 700}
                  height={400}
                  margin={{ left: 70 }}
                ></LineChart>
              </div>
            ) : (
              <>
                {" "}
                <div
                  className={styles.noData}
                  style={{ marginLeft: "-15%", marginTop: "0%" }}
                >
                  <img
                    src={no_data_icon}
                    alt=""
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>No Data Available</h4>
                </div>
              </>
            )}
            {testing_graph_data_chen?.length > 0 ? (
              <div className="col-sm-12 col-md-6" style={{display:"flex"}}>
                <LineChart
                  xAxis={[
                    { scaleType: "point", data: xLabels, label: "Chennai" },
                  ]}
                  yAxis={[{ label: "Sample Count" }]}
                  // yAxis={[{ id: "money" }, { id: "quantities" },
                  // {hideTooltip:true} ]}

                  series={[
                    {
                      curve: "linear",
                      data: testing_graph_data_chen,
                      label: "Sample Count",
                      color: "#C70039",
                    },
                  ]}
                  label="Month"
                  tooltipStyle={{
                    width: "100px",
                  }}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-30px, 0)",
                      fontWeight: "bold",
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      fontWeight: "bold",
                      transform: "translate(0, 5px)",
                      marginTop: "20px",
                    },
                  }}
                  width={windowHeight?.current < 650 ? 500 : 700}
                  height={400}
                  margin={{ left: 70 }}
                ></LineChart>
              </div>
            ) : (
              <>
                {" "}
                <div className="col-sm-12 col-md-6">
                  <img
                    src={no_data_icon}
                    alt=""
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>No Data Available</h4>
                </div>
              </>
            )} */}
            {/* {testing_graph_data_kol?.length > 0 ? (
              <div className="col-sm-12 col-md-6" style={{display:"flex"}}>
                <LineChart
                  xAxis={[
                    { scaleType: "point", data: xLabels, label: "Kolkata" },
                  ]}
                  yAxis={[{ label: "Sample Count" }]}
                  // yAxis={[{ id: "money" }, { id: "quantities" },
                  // {hideTooltip:true} ]}

                  series={[
                    {
                      curve: "linear",
                      data: testing_graph_data_kol,
                      label: "Sample Count",
                      color: "#C70039",
                    },
                  ]}
                  label="Month"
                  tooltipStyle={{
                    width: "100px",
                  }}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-30px, 0)",
                      fontWeight: "bold",
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      fontWeight: "bold",
                      transform: "translate(0, 5px)",
                      marginTop: "20px",
                    },
                  }}
                  width={windowHeight?.current < 650 ? 500 : 700}
                  height={400}
                  margin={{ left: 70 }}
                ></LineChart>
              </div>
            ) : (
              <>
                {" "}
                <div className="col-sm-12 col-md-6">
                  <img
                    src={no_data_icon}
                    alt=""
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>No Data Available</h4>
                </div>
              </>
            )}
            {testing_graph_data_noid?.length > 0 ? (
              <div className="col-sm-12 col-md-6" style={{display:"flex"}}>
                <LineChart
                  xAxis={[
                    { scaleType: "point", data: xLabels, label: "Noida" },
                  ]}
                  yAxis={[{ label: "Sample Count" }]}
                  // yAxis={[{ id: "money" }, { id: "quantities" },
                  // {hideTooltip:true} ]}

                  series={[
                    {
                      curve: "linear",
                      data: testing_graph_data_noid,
                      label: "Sample Count",
                      color: "#C70039",
                    },
                  ]}
                  label="Month"
                  tooltipStyle={{
                    width: "100px",
                  }}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-30px, 0)",
                      fontWeight: "bold",
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      fontWeight: "bold",
                      transform: "translate(0, 5px)",
                      marginTop: "20px",
                    },
                  }}
                  width={windowHeight?.current < 650 ? 500 : 700}
                  height={400}
                  margin={{ left: 70 }}
                ></LineChart>
              </div>
            ) : (
              <>
                <div className="col-sm-12 col-md-6">
                  <img
                    src={no_data_icon}
                    alt=""
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>No Data Available</h4>
                </div>
              </>
            )} */}
          {/* </div> */}
        </div>
        </div>
      }
    </>
  );
}

export default Testing_location_graph;
