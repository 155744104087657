import React, { useEffect, useState } from 'react'
import MiniDrawer from '../components/Minidrawer'
import styles from "../Styles/Table.module.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import report_loader from "../Media/ezgif.com-optimize.gif";
import no_data_icon from "../Media/no_data_icon.gif";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Select2 from "react-select";


const customStyles = {
    option: (provided) => ({
      ...provided,
      textAlign: "left",
      fontSize: "14px",
      color:"black"
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "60px",
      overflowY: "auto",
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 99999, // Set this to the desired z-index
      }),
      menuPortal: (base) => ({ 
        ...base, 
        zIndex: 99999 
      }),
  };


function Interested_lead_report() {
    const storage = window.sessionStorage;
    let Role_id = storage.getItem("Role_id");
    let dept = storage.getItem("DEPT");
    let admin_param = storage.getItem("admin_param");
    
    const [params, setParams] = useState("MTD");
    const [range_flag, setRange_Flag] = useState(false);
    const [date_change_flag,setDate_change_Flag] = useState(false);

    const [selectedFromDate,setSelectedFromDate] = useState(new Date());
    const [selectedToDate,setSelectedToDate] = useState(new Date());

    const [leads_data,setLeads_Data] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [leads_load,setLeads_Load] = useState(false);
    const [salesperson_list,setSalesperson_List] = useState([]);
const [selected_salesperson,setSelected_salesperson] = useState([]);
const [salesperson_load,setSalesperson_Load] = useState([]);

    const [category_list,setCategory_List] = useState([]);
const [selected_category,setSelected_Category] = useState([]);
const [category_load,setCategory_Load] = useState([]);


    
  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leads_data?.length) : 0;

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

    const get_interested_leads = async()=>{
      setLeads_Load(true)
      let send_salesperson = [];
      for(let i=0; i<selected_salesperson?.length; i++){
          send_salesperson.push(selected_salesperson[i]?.label)
      }
        try {
            let res = await fetch(`https://reportapi.equinoxlab.com/interested_leads_by_bde`,{
            // let res = await fetch(`https://reportapi.equinoxlab.com/interested_leads_by_corporate_bde`,{
                method:"POST",
                headers:{"Content-Type":"Application/Json"},
                body: JSON.stringify({
                    START_DATE: params == "Custom Date"?moment(selectedFromDate).format("YYYY-MM-DD"):"",
                    END_DATE: params == "Custom Date"? moment(selectedToDate).format("YYYY-MM-DD"):"",
                    DEPT_ID:  admin_param == "1"?"C697279E-946B-4402-A428-B4E22E6073C7":"C697279E-946B-4402-A428-B4E22E6073C8",
                    GET_DATE:params == "Custom Date"?"":params,
                    CATEGORY: (selected_category == null || selected_category?.length == 0)?"ALL":selected_category?.value,
                    SALESPERSON:selected_salesperson?.length == "0"?"ALL":send_salesperson?.toString()
                })
              })
            let data = await res.json();
            setLeads_Load(false)
            setLeads_Data(data?.Data);
        } catch (error) {
          setLeads_Load(false)
        }
    }

    const get_salesperson = async () => {
      setSalesperson_Load(true);
      try {
        let res = await fetch(
          `https://reportapi.equinoxlab.com/get_salesperson_for_performance`,{
            method: "POST",
            headers:{"Content-Type":"Application/JSON"},
            body:JSON.stringify({
              dept_id: admin_param == "1"?"C697279E-946B-4402-A428-B4E22E6073C7":"C697279E-946B-4402-A428-B4E22E6073C8",
            })
          }
        );
        let data = await res.json();
        setSalesperson_Load(false);
        let dropdown_data = [];
        for (let i = 0; i < data?.Data?.length; i++) {
          dropdown_data.push({
            label: data?.Data[i]?.text,
            value: data?.Data[i]?.text,
          });
        }
        setSalesperson_List(dropdown_data);

      } catch (error) {
        setSalesperson_Load(false);
      }
    };

    const get_category = async () => {
      setCategory_Load(true);
      try {
        let res = await fetch(
          `https://reportapi.equinoxlab.com/get_category`
        );
        let data = await res.json();
        setCategory_Load(false);
        let dropdown_data = [];
        for (let i = 0; i < data?.Data?.length; i++) {
          dropdown_data.push({
            label: data?.Data[i]?.Leadcat,
            value: data?.Data[i]?.Leadcat,
          });
        }
        setCategory_List(dropdown_data);

      } catch (error) {
        setCategory_Load(false);
      }
    };

    useEffect(()=>{
      if(Role_id != "" && Role_id != null && Role_id != "null"){
     get_interested_leads();
      }
    },[selectedFromDate,selectedToDate,selected_salesperson,selected_category])

    useEffect(()=>{
      get_salesperson();
      get_category();
    },[])

    const handleDateChange = (new_val)=>{
        if (new_val !== null) {
          setDate_change_Flag(true)
          setSelectedFromDate(new_val[0]);
          setSelectedToDate(new_val[1]);
        } else {
          setParams("MTD");
          setRange_Flag(false);
          setSelectedFromDate(new Date());
          setSelectedToDate(new Date()); 
          setDate_change_Flag(false)  
        }
      }

      const handleDateRange = (e) => {
        if (e.target.value == "Custom Date") {
          setParams("Custom Date");
          setRange_Flag(true);
        } else {
          setRange_Flag(false);
          setParams(e.target.value);
          setSelectedFromDate(new Date());
          setSelectedToDate(new Date());

     
        }
      };

   
  return (
    <>
    <MiniDrawer/>
    {leads_load || salesperson_load?<div className='container-fluid pt-3'><img src={report_loader} id={styles.loader} /></div>:  <div className='container-fluid pt-3'>
   <div style={{display:"flex",justifyContent:"right",gap:"10px"}}>
    <div style={{width:"250px"}} >
    <Select2
                      isMulti
                  name="label"
                  options={salesperson_list}
               className="basic-multi-select"
                        classNamePrefix="select"
                  styles={customStyles}
                  style={{backgroundColor:"white",height:"34px"}}
                  value={selected_salesperson}
                  onChange={(e)=>setSelected_salesperson(e)}
                  placeholder="Select Salespersons"
                  isClearable={true}
                  maxMenuHeight="180px"
                  required
                />
    </div>
    <div style={{width:"250px"
      // ,marginLeft:"-10px"
      }} >
    <Select2
            
                  name="label"
                  options={category_list}
           className="basic-single"
                  classNamePrefix="select"
                  styles={customStyles}
                  style={{backgroundColor:"white",height:"34px"}}
                  value={selected_category}
                  onChange={(e)=>setSelected_Category(e)}
                  placeholder="Select Category"
                  isClearable={true}
                  maxMenuHeight="180px"
                  required
                />
    </div>

{/*               
                        <Box
                          sx={{
                            width: "160px",
                            backgroundColor: "white",
                            height: "37px",
                            borderRadius: "5px",
                           marginLeft:"-10px"
    
                          }}
                        >
                          <FormControl size="small" fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{
                                width: "160px",
                                height: "37px",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                              onChange={handleDateRange}
                              value={params}
                            >
                              <MenuItem value="">Please Select</MenuItem>
                              <MenuItem value="Today">Today</MenuItem>
                              <MenuItem value="MTD">MTD</MenuItem>
                              <MenuItem value="QTD">QTD</MenuItem>
                              <MenuItem value="YTD">YTD</MenuItem>
                              <MenuItem value="FYTD">FYTD</MenuItem>
                              <MenuItem value="Custom Date">Custom Date</MenuItem>
                            </Select>
                          </FormControl>
                        </Box> */}
                        <Box
                          sx={{
                            width: "160px",
                            backgroundColor: "white",
                            height: "37px",
                            borderRadius: "5px",
                          //  marginLeft:"10px"
                          }}
                        >
                          <FormControl size="small" fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{
                                width: "160px",
                                height: "37px",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                              onChange={handleDateRange}
                              value={params}
                            >
                              <MenuItem value="" disabled>Please Select</MenuItem>
                              <MenuItem value="Today">Today</MenuItem>
                              <MenuItem value="MTD">MTD</MenuItem>
                              <MenuItem value="QTD">QTD</MenuItem>
                              <MenuItem value="YTD">YTD</MenuItem>
                              <MenuItem value="FYTD">FYTD</MenuItem>
                              <MenuItem value="Custom Date">Custom Date</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                       {range_flag? <DateRangePicker
                            style={{width:"20%"}}
                            // defaultValue={[new Date(), new Date()]}
                            format = "dd MMM yyyy"
                            value={date_change_flag?[selectedFromDate, selectedToDate]:[]}
                            onChange={handleDateChange}
                            placeholder="Select Date Range"
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          />:<></>}

   </div>
   {leads_data?.length>0? 
   <Paper sx={{marginTop:"15px"}}>
                <TableContainer id={styles.leads_tbl_Container} >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead
                      sx={{
                        backgroundColor: "#007cc3",
                        color: "#444a4f !important",
                        position: "sticky",
                        top: 0,
                        zIndex: "999",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                          colspan={5}
                        >
                          {/* <h5
                            className="ml-2"
                            style={{
                              color: "white",
                              textAlign: "left",
                              marginLeft: "1%",
                            }}
                          >
                            {" "}
                            Interested Leads Report (Corporate Sales)
                          </h5> */}
                          {
            dept == "Tech" || dept == "Admin" || dept == "Management" ? (
              <h5 className="ml-2"
                            style={{
                              color: "white",
                              textAlign: "left",
                              marginLeft: "1%",
                            }}>
                Interested Leads Report ({" "}
                {admin_param == "1" ? "FnB Sales" : "Corporate Sales"} )
              </h5>
            ) : (
              <h5 className="ml-2"
                            style={{
                              color: "white",
                              textAlign: "left",
                              marginLeft: "1%",
                            }}>Interested Leads Report</h5>
            )
          }
                        </TableCell>
                      
                      </TableRow>
                      <TableRow>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "left" }}
                          >
                            Lead Owner
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "left" }}
                          >
                            Client Name
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "center" }}
                          >
                            Last Updated Date
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "center" }}
                          >
                           Mobile No
                          </h6>
                        </TableCell>
                        <TableCell
                          id={styles.th_unit1}
                          style={{ borderBottom: "1px solid #89BBE0" }}
                        >
                          {" "}
                          <h6
                            className="h6 mt-2"
                            style={{ color: "white", textAlign: "center" }}
                          >
                          Category
                          </h6>
                        </TableCell>
   
                      </TableRow>
                    </TableHead>
                    {leads_data?.length == "0" ? (
                      <TableBody>
                        {" "}
                        <TableRow>
                          <TableCell colSpan={11} className={styles.th_unit}>
                            {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                            <p style={{ textAlign: "center" }}>
                              No Data Available
                            </p>
                          </TableCell>
                          {/* </div> */}
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody id={styles.body_content}>
                        {(rowsPerPage > 0
                          ? leads_data?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : leads_data
                        )?.map((ele,index) => (
                          <TableRow>
                            <TableCell
                              sx={{
                                border:
                                  ele?.flag != "1" ? "1px solid white" : "",
                                borderLeft:
                                  ele?.flag != "1"
                                    ? "1px solid rgb(201, 201, 201);"
                                    : "",
                                borderBottom:
                                  (ele?.flag == "1" && (index != leads_data?.length-1 && index != rowsPerPage-1)) ? "1px solid white" : "",
                                borderRight: ele?.flag == "1" ? "0px" : "",
                              }}
                              className={styles.th_unit}
                            >
                              <p
                                className="p mt-2"
                                style={{
                                  textAlign: "left",
                                  display: ele?.flag == "1" ? "block" : "none",
                                }}
                              >
                                {ele.lead_owner}
                              </p>
                            </TableCell>
                          
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "left" }}
                              >
                                {ele.company_name}
                              </p>
                            </TableCell>
                          
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "center" }}
                              >
                                {ele.last_updated_date}
                              </p>
                            </TableCell>
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "center" }}
                              >
                                {ele.mobile_no}
                              </p>
                            </TableCell>
                            <TableCell className={styles.th_unit}>
                              <p
                                className="p mt-2"
                                style={{ textAlign: "center" }}
                              >
                                {ele.category}
                              </p>
                            </TableCell>
   
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={5} />
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                    {/* <TableFooter id={styles.table_footer} >
                      <TableRow sx={{height:"40px"}}>
                        <TableCell  colSpan={5} className={styles.th_unit}>
                          <h5 style={{ textAlign: "left", paddingLeft:"10px" }}>Grand Total (₹)</h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "left", paddingLeft:"10px" }}>
    
                          </h5>
                        </TableCell>
                      </TableRow>
                    </TableFooter> */}
                  </Table>
                </TableContainer>
                <TablePagination
                  id={styles.interested_footer_div}
                  class="footer_div"
                  sx={{ backgroundColor: "#007cc3", color: "white" }}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={leads_data?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
       :<>
         <div
            className={styles.noData}
            style={{ marginLeft: "-1%", marginTop: "0%" }}
          >
            <img
              src={no_data_icon}
              alt=""
              style={{ height: "200px", width: "200px" }}
            />
            <h4>No Data Added</h4>
          </div>
       </>} 
    </div>}
    </>
  )
}

export default Interested_lead_report