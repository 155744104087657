import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import { useDispatch } from "react-redux";
import { get_qc_utilization_data } from "../Latest_addition_redux/actions";
import report_loader from "../Media/ezgif.com-optimize.gif";

function Qc_utilization() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id")
  const [qc_data, setQc_Data] = useState([]);
  const [qc_load, setQc_Load] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    dispatch(get_qc_utilization_data(setQc_Load, setQc_Data));
    }
  }, []);

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "Name",
      label: "Name",
      options: {
        filter: true, 
        sort: false,
        setCellProps: () => ({
          style: {
            width: "200px",
            maxWidth: "auto",
            padding: "0px",
          },          
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p style={{ marginLeft: "0px" }}>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "D",
      label: "D",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
            padding: "0px",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D1",
      label: "D1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
            padding: "0px",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D2",
      label: "D2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
            padding: "0px",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D3",
      label: "D3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
            padding: "0px",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D4",
      label: "D4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
            padding: "0px",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D5",
      label: "D5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
            padding: "0px",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D6",
      label: "D6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
            color:
              (Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600) ||
              Number(cellValue?.props?.children) >= 600
                ? "white"
                : "black",
            padding: "0px",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
  ];
  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
        {qc_load ? (
          <img
            src={report_loader}
            id={styles.loader}
            style={{ marginTop: "6em" }}
          />
        ) : (
          <div id="table_s6_category_tbl" style={{ margin: "auto" }}>
            <MUIDataTable
              title={
                <h5 style={{ textAlign: "left" }}>QC Utilization Details</h5>
              }
              data={qc_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "65vh",
                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
              }}
            />{" "}
          </div>
        )}
      </div>
    </>
  );
}

export default Qc_utilization;
