import React from 'react'
import MUIDataTable from 'mui-datatables';
import styles from "../Styles/Table.module.css";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

function CreatedBy_oh_tbl({data, setTotal_Order_Count, seTotal_Order_Value}) {

  const get_total =(val)=>{
    if(val == "order_value"){
      let total_count = data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_order_value)
     },0)
     seTotal_Order_Value(total_count)
     return new Intl.NumberFormat("en-IN").format(total_count);

    }else{
      let total_count = data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_order)
     },0)
     setTotal_Order_Count(total_count)
     return total_count;
    }

}

    const options = {
        filterType: "checkbox",
      };
      const columns = [
    
        {
          name: "created_by",
          label: `Created By`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{textAlign:"left"}}>
                  <p>{row}</p>
                </div>
              )
            }
          },
        },
      
        {
          name: "total_order_value",
          label: `Order Value`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{textAlign:"left"}}>
                  <p> {row == undefined ||
                      row == null
                        ? 0
                        : new Intl.NumberFormat("en-IN").format(
                            row
                          )}</p>
                </div>
              )
            }
          },
        },
      
        {
          name: "total_order",
          label: `OH count`,
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{textAlign:"left"}}>
                  <p>{row}</p>
                </div>
              )
            }
          },
        },
      
  
      ];
  return (
    <div id="created_by_tbl">
       <MUIDataTable
              title={<h5 style={{ textAlign: "left" }}>Order Detail - Sales Person</h5>}
              data={data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "40vh",
                rowsPerPageOptions:[10,30,50,100],
                // rowsPerPage: "",
                sortOrder: {
                  name: 'InvoiceAmount',
                  direction: 'desc'
                },
                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available"
                  },
                },
                customTableBodyFooterRender: (
                    count,
                    page,
                    rowsPerPage,
                    changeRowsPerPage,
                    changePage,
                    data
                  ) => {
                    const startIndex = page * rowsPerPage;
                    const endIndex = (page + 1) * rowsPerPage;
                    return (
                      <TableFooter id={styles.table_footer}>
                        <TableRow>
                          <TableCell id={styles.first_col}>
                            <p style={{textAlign:"left"}}>Grand Total</p>
                          </TableCell>
                          <TableCell><p style={{textAlign:"left"}}>{get_total("order_value")}</p></TableCell>
                          <TableCell><p style={{textAlign:"left"}}>{get_total("order_count")}</p></TableCell>
                        
                        </TableRow>
                      </TableFooter>
                    );
                  },
              }}
            />
    </div>
  )
}

export default CreatedBy_oh_tbl
