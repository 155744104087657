import { BILLING_DATA_ERROR, BILLING_DATA_REQUEST, BILLING_DATA_SUCCESS, CATEGORY_DATA_CREATED_ON_ERROR, CATEGORY_DATA_CREATED_ON_REQUEST, CATEGORY_DATA_CREATED_ON_SUCCESS, CATEGORY_DATA_ERROR, CATEGORY_DATA_REQUEST, CATEGORY_DATA_REVENUE_ERROR, CATEGORY_DATA_REVENUE_REQUEST, CATEGORY_DATA_REVENUE_SUCCESS, CATEGORY_DATA_SUCCESS, CATEGORY_DATA_UPDATED_ON_ERROR, CATEGORY_DATA_UPDATED_ON_REQUEST, CATEGORY_DATA_UPDATED_ON_SUCCESS, GEOGRAPHY_DATA_ERROR, GEOGRAPHY_DATA_REQUEST, GEOGRAPHY_DATA_SUCCESS, GET_ERROR, GET_REQUEST, GET_SUCCESS, HUB_DATA_ERROR, HUB_DATA_REQUEST, HUB_DATA_SUCCESS, HUB_WISE_ERROR, HUB_WISE_REQUEST, HUB_WISE_SUCCESS, INDIVIDUAL_USER_ERROR, INDIVIDUAL_USER_REQUEST, INDIVIDUAL_USER_SUCCESS, KAE_DATA_ERROR, KAE_DATA_REQUEST, KAE_DATA_SUCCESS, LAB_DATA_ERROR, LAB_DATA_REQUEST, LAB_DATA_SUCCESS, LEADS_ERROR, LEADS_REQUEST, LEADS_SUCCESS, NPS_DATA_ERROR, NPS_DATA_REQUEST, NPS_DATA_SUCCESS, OPERATION_DATA_ERROR, OPERATION_DATA_REQUEST, OPERATION_DATA_SUCCESS, ORDER_ERROR, ORDER_REQUEST, ORDER_SUCCESS, PAYMENT_DATA_ERROR, PAYMENT_DATA_REQUEST, PAYMENT_DATA_SUCCESS, REGISTRATION_DATA_ERROR, REGISTRATION_DATA_REQUEST, REGISTRATION_DATA_SUCCESS, TEAM_S6_ERROR, TEAM_S6_REQUEST, TEAM_S6_SUCCESS, TRANSECTION_DATA_ERROR, TRANSECTION_DATA_REQUEST, TRANSECTION_DATA_SUCCESS, USER_DATA_ERROR, USER_DATA_REQUEST, USER_DATA_SUCCESS } from "./actionTypes"

let initState ={
    loading:false,
    error: false,
    data:[]
}

export const dataReducer = (state=initState,action)=>{
    switch(action.type){
        case GET_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case GET_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case GET_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case ORDER_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case ORDER_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case ORDER_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case LEADS_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case LEADS_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case LEADS_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case CATEGORY_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case CATEGORY_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case CATEGORY_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }




        case CATEGORY_DATA_CREATED_ON_SUCCESS:{
            return{
                ...state,
                loading:false,
                error:false,
                data:[]
            }
        }
        case CATEGORY_DATA_CREATED_ON_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case CATEGORY_DATA_CREATED_ON_REQUEST: {
            return{
                ...state,
                loading:true,
                error:false,
                data: action.payload
            }
        }

        case CATEGORY_DATA_UPDATED_ON_SUCCESS:{
            return{
                ...state,
                loading:false,
                error:false,
                data:[]
            }
        }
        case CATEGORY_DATA_UPDATED_ON_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case CATEGORY_DATA_UPDATED_ON_REQUEST: {
            return{
                ...state,
                loading:true,
                error:false,
                data: action.payload
            }
        }

        case CATEGORY_DATA_REVENUE_SUCCESS:{
            return{
                ...state,
                loading:false,
                error:false,
                data:[]
            }
        }
        case CATEGORY_DATA_REVENUE_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case CATEGORY_DATA_REVENUE_REQUEST: {
            return{
                ...state,
                loading:true,
                error:false,
                data: action.payload
            }
        }






        case USER_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case USER_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case USER_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case INDIVIDUAL_USER_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case INDIVIDUAL_USER_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case INDIVIDUAL_USER_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case HUB_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case HUB_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case HUB_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case GEOGRAPHY_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case GEOGRAPHY_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case GEOGRAPHY_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case LAB_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case LAB_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case LAB_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case HUB_WISE_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case HUB_WISE_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case HUB_WISE_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case REGISTRATION_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case REGISTRATION_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case REGISTRATION_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }

        case OPERATION_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case OPERATION_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case OPERATION_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }

        case NPS_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case NPS_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case NPS_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }

        case PAYMENT_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case PAYMENT_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case PAYMENT_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }

        case TRANSECTION_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case TRANSECTION_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case TRANSECTION_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }

        case KAE_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case KAE_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case KAE_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }

        case BILLING_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case BILLING_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case BILLING_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }

        case TEAM_S6_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case TEAM_S6_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case TEAM_S6_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        default:{
            {return state}
        }

    }
}