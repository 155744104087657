import React from 'react'
import Invoice_corporate from '../pages/Invoice_corporate'

function Invoice_dashboard_fnb() {
  return (
    <>
      <Invoice_corporate/>
    </>
  )
}

export default Invoice_dashboard_fnb
