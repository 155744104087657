// Transaction Report

export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_ERROR = "GET_TRANSACTION_ERROR";


// Get  Invoice creation Daily data requests

export const GET_DAILY_REQUEST = "GET_DAILY_REQUEST";
export const GET_DAILY_SUCCESS = "GET_DAILY_SUCCESS";
export const GET_DAILY_ERROR = "GET_DAILY_ERROR";


// Get  Invoice creation Monthly data requests

export const GET_MONTHLY_REQUEST = "GET_MONTHLY_REQUEST";
export const GET_MONTHLY_SUCCESS = "GET_MONTHLY_SUCCESS";
export const GET_MONTHLY_ERROR = "GET_MONTHLY_ERROR";


// invoice_creation_monthly_data

export const GET_MONTHLY_DATA_REQUEST = "GET_MONTHLY_DATA_REQUEST";
export const GET_MONTHLY_DATA_SUCCESS = "GET_MONTHLY_DATA_SUCCESS";
export const GET_MONTHLY_DATA_ERROR = "GET_MONTHLY_DATA_ERROR";


// get user name on department id

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";


// get client name on department id

export const GET_CLIENT_LIST_REQUEST = "GET_CLIENT_LIST_REQUEST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_ERROR = "GET_CLIENT_LIST_ERROR";


// get Invoice_s6

export const GET_INVOICE_S6_REQUEST = "GET_INVOICE_S6_REQUEST";
export const GET_INVOICE_S6_SUCCESS = "GET_INVOICE_S6_SUCCESS";
export const GET_INVOICE_S6_ERROR = "GET_INVOICE_S6_ERROR";


// get monthly sales analysis


export const GET_MONTHLY_SALES_REQUEST = "GET_MONTHLY_SALES_REQUEST";
export const GET_MONTHLY_SALES_SUCCESS = "GET_MONTHLY_SALES_SUCCESS";
export const GET_MONTHLY_SALES_ERROR = "GET_MONTHLY_SALES_ERROR";


// get quaterly sales analysis


export const GET_QUATERLY_SALES_REQUEST = "GET_QUATERLY_SALES_REQUEST";
export const GET_QUATERLY_SALES_SUCCESS = "GET_QUATERLY_SALES_SUCCESS";
export const GET_QUATERLY_SALES_ERROR = "GET_QUATERLY_SALES_ERROR";


// get yearly sales analysis


export const GET_YEARLY_SALES_REQUEST = "GET_YEARLY_SALES_REQUEST";
export const GET_YEARLY_SALES_SUCCESS = "GET_YEARLY_SALES_SUCCESS";
export const GET_YEARLY_SALES_ERROR = "GET_YEARLY_SALES_ERROR";


// get yearly sales testing


export const GET_YEARLY_TESTING_REQUEST = "GET_YEARLY_TESTING_REQUEST";
export const GET_YEARLY_TESTING_SUCCESS = "GET_YEARLY_TESTING_SUCCESS";
export const GET_YEARLY_TESTING_ERROR = "GET_YEARLY_TESTING_ERROR";


// get Quaterly sales testing


export const GET_QUATERLY_TESTING_REQUEST = "GET_QUATERLY_TESTING_REQUEST";
export const GET_QUATERLY_TESTING_SUCCESS = "GET_QUATERLY_TESTING_SUCCESS";
export const GET_QUATERLY_TESTING_ERROR = "GET_QUATERLY_TESTING_ERROR";


// get Monthly sales testing


export const GET_MONTHLY_TESTING_REQUEST = "GET_MONTHLY_TESTING_REQUEST";
export const GET_MONTHLY_TESTING_SUCCESS = "GET_MONTHLY_TESTING_SUCCESS";
export const GET_MONTHLY_TESTING_ERROR = "GET_MONTHLY_TESTING_ERROR";


// get yearly sales non_testing


export const GET_YEARLY_NON_TESTING_REQUEST = "GET_YEARLY_NON_TESTING_REQUEST";
export const GET_YEARLY_NON_TESTING_SUCCESS = "GET_YEARLY_NON_TESTING_SUCCESS";
export const GET_YEARLY_NON_TESTING_ERROR = "GET_YEARLY_NON_TESTING_ERROR";


// get Quaterly sales non_testing


export const GET_QUATERLY_NON_TESTING_REQUEST = "GET_QUATERLY_NON_TESTING_REQUEST";
export const GET_QUATERLY_NON_TESTING_SUCCESS = "GET_QUATERLY_NON_TESTING_SUCCESS";
export const GET_QUATERLY_NON_TESTING_ERROR = "GET_QUATERLY_NON_TESTING_ERROR";


// get Monthly sales non_testing


export const GET_MONTHLY_NON_TESTING_REQUEST = "GET_MONTHLY_NON_TESTING_REQUEST";
export const GET_MONTHLY_NON_TESTING_SUCCESS = "GET_MONTHLY_NON_TESTING_SUCCESS";
export const GET_MONTHLY_NON_TESTING_ERROR = "GET_MONTHLY_NON_TESTING_ERROR";


// get Monthly sales Piechart


export const GET_MONTHLY_PIECHART_REQUEST = "GET_MONTHLY_PIECHART_REQUEST";
export const GET_MONTHLY_PIECHART_SUCCESS = "GET_MONTHLY_PIECHART_SUCCESS";
export const GET_MONTHLY_PIECHART_ERROR = "GET_MONTHLY_PIECHART_ERROR";


// get Quaterly sales Piechart


export const GET_QUATERLY_PIECHART_REQUEST = "GET_QUATERLY_PIECHART_REQUEST";
export const GET_QUATERLY_PIECHART_SUCCESS = "GET_QUATERLY_PIECHART_SUCCESS";
export const GET_QUATERLY_PIECHART_ERROR = "GET_QUATERLY_PIECHART_ERROR";


// get Yearly sales Piechart


export const GET_YEARLY_PIECHART_REQUEST = "GET_YEARLY_PIECHART_REQUEST";
export const GET_YEARLY_PIECHART_SUCCESS = "GET_YEARLY_PIECHART_SUCCESS";
export const GET_YEARLY_PIECHART_ERROR = "GET_YEARLY_PIECHART_ERROR";


// get Testing Location


export const GET_TESTING_LOCATION_REQUEST = "GET_TESTING_LOCATION_REQUEST";
export const GET_TESTING_LOCATION_SUCCESS = "GET_TESTING_LOCATION_SUCCESS";
export const GET_TESTING_LOCATION_ERROR = "GET_TESTING_LOCATION_ERROR";


// get Testing Location Graph


export const GET_TESTING_LOCATION_GRAPH_REQUEST = "GET_TESTING_LOCATION_GRAPH_REQUEST";
export const GET_TESTING_LOCATION_GRAPH_SUCCESS = "GET_TESTING_LOCATION_GRAPH_SUCCESS";
export const GET_TESTING_LOCATION_GRAPH_ERROR = "GET_TESTING_LOCATION_GRAPH_ERROR";


// Get Client Type Revenue


export const GET_CLIENT_TYPE_REVENUE_REQUEST = "GET_CLIENT_TYPE_REVENUE_REQUEST";
export const GET_CLIENT_TYPE_REVENUE_SUCCESS = "GET_CLIENT_TYPE_REVENUE_SUCCESS";
export const GET_CLIENT_TYPE_REVENUE_ERROR = "GET_CLIENT_TYPE_REVENUE_ERROR";


// Get Client Type Sample


export const GET_CLIENT_TYPE_SAMPLE_REQUEST = "GET_CLIENT_TYPE_SAMPLE_REQUEST";
export const GET_CLIENT_TYPE_SAMPLE_SUCCESS = "GET_CLIENT_TYPE_SAMPLE_SUCCESS";
export const GET_CLIENT_TYPE_SAMPLE_ERROR = "GET_CLIENT_TYPE_SAMPLE_ERROR";



// Get Client Type Sample Revenue


export const GET_CLIENT_TYPE_SAMPLE_REVENUE_REQUEST = "GET_CLIENT_TYPE_SAMPLE_REVENUE_REQUEST";
export const GET_CLIENT_TYPE_SAMPLE_REVENUE_SUCCESS = "GET_CLIENT_TYPE_SAMPLE_REVENUE_SUCCESS";
export const GET_CLIENT_TYPE_SAMPLE_REVENUE_ERROR = "GET_CLIENT_TYPE_SAMPLE_REVENUE_ERROR";



// Get Service Penetration 


export const GET_SERVICE_PENETRATION_REQUEST = "GET_SERVICE_PENETRATION_REQUEST";
export const GET_SERVICE_PENETRATION_SUCCESS = "GET_SERVICE_PENETRATION_SUCCESS";
export const GET_SERVICE_PENETRATION_ERROR = "GET_SERVICE_PENETRATION_ERROR";



// Get BDE Calls Done


export const GET_BDE_CALLS_REQUEST = "GET_BDE_CALLS_REQUEST";
export const GET_BDE_CALLS_SUCCESS = "GET_BDE_CALLS_SUCCESS";
export const GET_BDE_CALLS_ERROR = "GET_BDE_CALLS_ERROR";




// Get BDE Meetings Done 


export const GET_BDE_MEETINGS_REQUEST = "GET_BDE_MEETINGS_REQUEST";
export const GET_BDE_MEETINGS_SUCCESS = "GET_BDE_MEETINGS_SUCCESS";
export const GET_BDE_MEETINGS_ERROR = "GET_BDE_MEETINGS_ERROR";



// Get BDE Leads Added


export const GET_BDE_LEADS_REQUEST = "GET_BDE_LEADS_REQUEST";
export const GET_BDE_LEADS_SUCCESS = "GET_BDE_LEADS_SUCCESS";
export const GET_BDE_LEADS_ERROR = "GET_BDE_LEADS_ERROR";



// Get  CHECK BDE 


export const GET_CHECK_BDE_REQUEST = "GET_CHECK_BDE_REQUEST";
export const GET_CHECK_BDE_SUCCESS = "GET_CHECK_BDE_SUCCESS";
export const GET_CHECK_BDE_ERROR = "GET_CHECK_BDE_ERROR";


// Get BDE Reporting Person


export const GET_REPORTING_PERSON_REQUEST = "GET_REPORTING_PERSON_REQUEST";
export const GET_REPORTING_PERSON_SUCCESS = "GET_REPORTING_PERSON_SUCCESS";
export const GET_REPORTING_PERSON_ERROR = "GET_REPORTING_PERSON_ERROR";



// Get BDE Reporting Person For Hub

export const GET_REPORTING_PERSON_HUB_REQUEST = "GET_REPORTING_PERSON_HUB_REQUEST";
export const GET_REPORTING_PERSON_HUB_SUCCESS = "GET_REPORTING_PERSON_HUB_SUCCESS";
export const GET_REPORTING_PERSON_HUB_ERROR = "GET_REPORTING_PERSON_HUB_ERROR";


// Get Nps Percentage data yearly

export const GET_NPS_YEARLY_REQUEST = "GET_NPS_YEARLY_REQUEST";
export const GET_NPS_YEARLY_SUCCESS = "GET_NPS_YEARLY_SUCCESS";
export const GET_NPS_YEARLY_ERROR = "GET_NPS_YEARLY_ERROR";




// For Count 


// Get Monthly Sales Data

export const GET_MONTHLY_SALES_COUNT_REQUEST = "GET_MONTHLY_SALES_COUNT_REQUEST";
export const GET_MONTHLY_SALES_COUNT_SUCCESS = "GET_MONTHLY_SALES_COUNT_SUCCESS";
export const GET_MONTHLY_SALES_COUNT_ERROR = "GET_MONTHLY_SALES_COUNT_ERROR";


// Get Monthly Sales Piechart

export const GET_MONTHLY_PIECHART_COUNT_REQUEST = "GET_MONTHLY_PIECHART_COUNT_REQUEST";
export const GET_MONTHLY_PIECHART_COUNT_SUCCESS = "GET_MONTHLY_PIECHART_COUNT_SUCCESS";
export const GET_MONTHLY_PIECHART_COUNT_ERROR = "GET_MONTHLY_PIECHART_COUNT_ERROR";


// Get Quaterly Sales Data

export const GET_QUATERLY_SALES_COUNT_REQUEST = "GET_QUATERLY_SALES_COUNT_REQUEST";
export const GET_QUATERLY_SALES_COUNT_SUCCESS = "GET_QUATERLY_SALES_COUNT_SUCCESS";
export const GET_QUATERLY_SALES_COUNT_ERROR = "GET_QUATERLY_SALES_COUNT_ERROR";


// Get Quaterly Sales Piechart

export const GET_QUATERLY_PIECHART_COUNT_REQUEST = "GET_QUATERLY_PIECHART_COUNT_REQUEST";
export const GET_QUATERLY_PIECHART_COUNT_SUCCESS = "GET_QUATERLY_PIECHART_COUNT_SUCCESS";
export const GET_QUATERLY_PIECHART_COUNT_ERROR = "GET_QUATERLY_PIECHART_COUNT_ERROR";


// Get Yearly Sales Data

export const GET_YEARLY_SALES_COUNT_REQUEST = "GET_YEARLY_SALES_COUNT_REQUEST";
export const GET_YEARLY_SALES_COUNT_SUCCESS = "GET_YEARLY_SALES_COUNT_SUCCESS";
export const GET_YEARLY_SALES_COUNT_ERROR = "GET_YEARLY_SALES_COUNT_ERROR";


// Get Yearly Sales Piechart

export const GET_YEARLY_PIECHART_COUNT_REQUEST = "GET_YEARLY_PIECHART_COUNT_REQUEST";
export const GET_YEARLY_PIECHART_COUNT_SUCCESS = "GET_YEARLY_PIECHART_COUNT_SUCCESS";
export const GET_YEARLY_PIECHART_COUNT_ERROR = "GET_YEARLY_PIECHART_COUNT_ERROR";


// Get Monthly Testing Sales Data

export const GET_MONTHLY_TESTING_SALES_COUNT_REQUEST = "GET_MONTHLY_TESTING_SALES_COUNT_REQUEST";
export const GET_MONTHLY_TESTING_SALES_COUNT_SUCCESS = "GET_MONTHLY_TESTING_SALES_COUNT_SUCCESS";
export const GET_MONTHLY_TESTING_SALES_COUNT_ERROR = "GET_MONTHLY_TESTING_SALES_COUNT_ERROR";


// Get Quaterly Testing Sales Data

export const GET_QUATERLY_TESTING_SALES_COUNT_REQUEST = "GET_QUATERLY_TESTING_SALES_COUNT_REQUEST";
export const GET_QUATERLY_TESTING_SALES_COUNT_SUCCESS = "GET_QUATERLY_TESTING_SALES_COUNT_SUCCESS";
export const GET_QUATERLY_TESTING_SALES_COUNT_ERROR = "GET_QUATERLY_TESTING_SALES_COUNT_ERROR";


// Get Yearly Testing Sales Data

export const GET_YEARLY_TESTING_SALES_COUNT_REQUEST = "GET_YEARLY_TESTING_SALES_COUNT_REQUEST";
export const GET_YEARLY_TESTING_SALES_COUNT_SUCCESS = "GET_YEARLY_TESTING_SALES_COUNT_SUCCESS";
export const GET_YEARLY_TESTING_SALES_COUNT_ERROR = "GET_YEARLY_TESTING_SALES_COUNT_ERROR";



// Get Monthly Non Testing Sales Data

export const GET_MONTHLY_NON_TESTING_SALES_COUNT_REQUEST = "GET_MONTHLY_NON_TESTING_SALES_COUNT_REQUEST";
export const GET_MONTHLY_NON_TESTING_SALES_COUNT_SUCCESS = "GET_MONTHLY_NON_TESTING_SALES_COUNT_SUCCESS";
export const GET_MONTHLY_NON_TESTING_SALES_COUNT_ERROR = "GET_MONTHLY_NON_TESTING_SALES_COUNT_ERROR";


// Get Quaterly Non Testing Sales Data

export const GET_QUATERLY_NON_TESTING_SALES_COUNT_REQUEST = "GET_QUATERLY_NON_TESTING_SALES_COUNT_REQUEST";
export const GET_QUATERLY_NON_TESTING_SALES_COUNT_SUCCESS = "GET_QUATERLY_NON_TESTING_SALES_COUNT_SUCCESS";
export const GET_QUATERLY_NON_TESTING_SALES_COUNT_ERROR = "GET_QUATERLY_NON_TESTING_SALES_COUNT_ERROR";


// Get Yearly Non Testing Sales Data

export const GET_YEARLY_NON_TESTING_SALES_COUNT_REQUEST = "GET_YEARLY_NON_TESTING_SALES_COUNT_REQUEST";
export const GET_YEARLY_NON_TESTING_SALES_COUNT_SUCCESS = "GET_YEARLY_NON_TESTING_SALES_COUNT_SUCCESS";
export const GET_YEARLY_NON_TESTING_SALES_COUNT_ERROR = "GET_YEARLY_NON_TESTING_SALES_COUNT_ERROR";


// Send Mail Graph

export const SEND_GRAPH_MAIL_REQUEST = "SEND_GRAPH_MAIL_REQUEST";
export const SEND_GRAPH_MAIL_SUCCESS = "SEND_GRAPH_MAIL_SUCCESS";
export const SEND_GRAPH_MAIL_ERROR = "SEND_GRAPH_MAIL_ERROR";


// Get Client Type Sample COUNT FOR SALESPERSON


export const GET_SALESPERSON_CLIENT_TYPE_COUNT_REQUEST = "GET_SALESPERSON_CLIENT_TYPE_COUNT_REQUEST";
export const GET_SALESPERSON_CLIENT_TYPE_COUNT_SUCCESS = "GET_SALESPERSON_CLIENT_TYPE_COUNT_SUCCESS";
export const GET_SALESPERSON_CLIENT_TYPE_COUNT_ERROR = "GET_SALESPERSON_CLIENT_TYPE_COUNT_ERROR";



// Get Client Type Sample Revenue


export const GET_SALESPERSON_CLIENT_TYPE_REVENUE_REQUEST = "GET_SALESPERSON_CLIENT_TYPE_REVENUE_REQUEST";
export const GET_SALESPERSON_CLIENT_TYPE_REVENUE_SUCCESS = "GET_SALESPERSON_CLIENT_TYPE_REVENUE_SUCCESS";
export const GET_SALESPERSON_CLIENT_TYPE_REVENUE_ERROR = "GET_SALESPERSON_CLIENT_TYPE_REVENUE_ERROR";


// Category Leadsource (Count)

export const CATEGORY_COUNT_REQUEST = "CATEGORY_COUNT_REQUEST";
export const CATEGORY_COUNT_SUCCESS = "CATEGORY_COUNT_SUCCESS";
export const CATEGORY_COUNT_ERROR = "CATEGORY_COUNT_ERROR";


// Marketing Section For Lead Source


export const CATEGORY_DATA_MARKETING_REQUEST = "CATEGORY_DATA_MARKETING_REQUEST";
export const CATEGORY_DATA_MARKETING_SUCCESS = "CATEGORY_DATA_MARKETING_SUCCESS";
export const CATEGORY_DATA_MARKETING_ERROR = "CATEGORY_DATA_MARKETING_ERROR";


export const CATEGORY_UPDATED_ON_MARKETING_REQUEST = "CATEGORY_UPDATED_ON_MARKETING_REQUEST";
export const CATEGORY_UPDATED_ON_MARKETING_SUCCESS = "CATEGORY_UPDATED_ON_MARKETING_SUCCESS";
export const CATEGORY_UPDATED_ON_MARKETING_ERROR = "CATEGORY_UPDATED_ON_MARKETING_ERROR";


export const CATEGORY_REVENUE_MARKETING_REQUEST = "CATEGORY_REVENUE_MARKETING_REQUEST";
export const CATEGORY_REVENUE_MARKETING_SUCCESS = "CATEGORY_REVENUE_MARKETING_SUCCESS";
export const CATEGORY_REVENUE_MARKETING_ERROR = "CATEGORY_REVENUE_MARKETING_ERROR";


// MARKETING_PERFORMANCE


export const MARKETING_PERFORMANCE_REQUEST = "MARKETING_PERFORMANCE_REQUEST";
export const MARKETING_PERFORMANCE_SUCCESS = "MARKETING_PERFORMANCE_SUCCESS";
export const MARKETING_PERFORMANCE_ERROR = "MARKETING_PERFORMANCE_ERROR";



// Weekly client data

export const GET_WEEKLY_CLIENT_REQUEST = "GET_WEEKLY_CLIENT_REQUEST";
export const GET_WEEKLY_CLIENT_SUCCESS = "GET_WEEKLY_CLIENT_SUCCESS";
export const GET_WEEKLY_CLIENT_ERROR = "GET_WEEKLY_CLIENT_ERROR";



// QC Utilization data

export const GET_QC_DATA_REQUEST = "GET_QC_DATA_REQUEST";
export const GET_QC_DATA_SUCCESS = "GET_QC_DATA_SUCCESS";
export const GET_QC_DATA_ERROR = "GET_QC_DATA_ERROR";



// category leadsource analysis created on

export const GET_LEADSOURCE_CREATED_ON_REQUEST = "GET_LEADSOURCE_CREATED_ON_REQUEST";
export const GET_LEADSOURCE_CREATED_ON_SUCCESS = "GET_LEADSOURCE_CREATED_ON_SUCCESS";
export const GET_LEADSOURCE_CREATED_ON_ERROR = "GET_LEADSOURCE_CREATED_ON_ERROR";


// category leadsource analysis updated on

export const GET_LEADSOURCE_UPDATED_ON_REQUEST = "GET_LEADSOURCE_UPDATED_ON_REQUEST";
export const GET_LEADSOURCE_UPDATED_ON_SUCCESS = "GET_LEADSOURCE_UPDATED_ON_SUCCESS";
export const GET_LEADSOURCE_UPDATED_ON_ERROR = "GET_LEADSOURCE_UPDATED_ON_ERROR";