import React from 'react'
import Sample_type_report_bde from '../pages/Sample_type_report_bde'

function Sample_report_bde_fnb() {
  return (
    <>
      <Sample_type_report_bde/>
    </>
  )
}

export default Sample_report_bde_fnb
