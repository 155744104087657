import React, { useRef } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import styles from "../Styles/Sales_analysis.module.css";
import no_data_icon from "../Media/no_data_icon.gif";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { ChartsXAxis, ChartsYAxis } from "@mui/x-charts";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import moment from "moment";
import dayjs from "dayjs";

function Yearly_sales({
  fnb_sales_data,
  setFnb_Sales_Data,
  corporate_sales_data,
  setCorporate_Sales_Data,
  load_data,
  years_data,
  width_flag
  // years_list
}) {
  const formattedMonth = dayjs(new Date());
  // let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY")?.toString();
  let xLabels = years_data
  // let xLabels =[
  //   null,
  //   Number(new_year)-1+"-"+new_year,
  //   Number(new_year)-2+"-"+Number(new_year)-1,
  //   Number(new_year)-3+"-"+Number(new_year)-2,
  //   Number(new_year)-4+"-"+Number(new_year)-3,
  //   Number(new_year)-5+"-"+Number(new_year)-4,
  //   Number(new_year)-6+"-"+Number(new_year)-5,
  //   Number(new_year)-7+"-"+Number(new_year)-6,
    
  // ]

  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format;
  const windowHeight = useRef(window.innerHeight);

  const storage = window.sessionStorage;
  let department = storage.getItem("DEPT");

  return (

    <>
      {load_data ? (
        <img src={report_loader} id={styles.loader_operation} />
      ) : (
        <div
          id={styles.graph_contain}
          style={{ paddingLeft: width_flag == "overall" ? 0 : "15%" }}
        >
          {fnb_sales_data.length > 0 && corporate_sales_data?.length > 0 ? (
            <div>
              {width_flag == "testing" ? (
                <>
                  {" "}
                  <p style={{fontWeight:"bold",textAlign:"center"}}>Testing Service Revenue Yearly</p>
                </>
              ) : (
                <></>
              )}
              {width_flag == "non_testing" ? (
                <>
                  {" "}
                  <p style={{fontWeight:"bold",textAlign:"center"}}>Non Testing Service Revenue Yearly</p>
                </>
              ) : (
                <></>
              )}
              {width_flag == "overall" ? (
                <>
                  {" "}
                  <p style={{fontWeight:"bold",textAlign:"center"}}>Overall Revenue Yearly</p>
                </>
              ) : (
                <></>
              )}
              <LineChart
                xAxis={[
                  {
                    scaleType: "point",
                    data: xLabels,
                    label: "Years",
                    valueFormatter: (value) =>
                      value == null ? "" : value.toString(),
                      id: "money" ,  id: "quantities2"
                  },
                ]}
                yAxis={[
                  {
                    id: "linearAxis",
                    scaleType: "linear",
                    label: "Revenue",
                    valueFormatter: (value) => currencyFormatter(value),
                  },
                ]}
                series={[
                  {
                    curve: "linear",
                    data: (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "FnB Sales")? fnb_sales_data:[null],
                    label: (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "FnB Sales")? "Fnb-Sales":"",
                    color: (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "FnB Sales")?  "#C70039":"white",
                    valueFormatter: (value) =>
                      value == null
                        ? null
                        : currencyFormatter(value),
                  },
                  {
                    curve: "linear",
                    data:(department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "Corporate Sales")? corporate_sales_data:[null],
                    label: (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "Corporate Sales")? "Corporate-Sales":"",
                    color: (department == "Tech" ||
                    department == "Admin" ||
                    department == "Management" || department == "Corporate Sales")? "#C683D7":"white",
                    valueFormatter: (value) =>
                      value == null 
                        ? null
                        : currencyFormatter(value),
                  },
                ]}
                // width={width_flag == "overall" ? 800 : 1000}
                // width={
                //   (windowHeight?.current>1070&&width_flag == "overall")?1070:
                //   (windowHeight?.current>1070&&width_flag != "overall")?1250:
                //   (width_flag == "overall" && windowHeight?.current<800) ? 800 : 1000}
                width={(windowHeight?.current>900&&width_flag == "overall")?950:
                (windowHeight?.current>900&&width_flag != "overall")?1250:
                width_flag == "overall"?750:900}
                height={windowHeight?.current>900?500:380}
                margin={{ left: width_flag == "overall" ? 140 : 150 }}
                label="Month"
                sx={{
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "translate(-90px, 0)",
                    fontWeight:"bold"
                  },
                  [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                    fontWeight:"bold",
                    transform: "translate(0, 5px)",
                    marginTop:"20px"
                  },
                }}
              >
       
              </LineChart>
            </div>
          ) : (
            <>
              {" "}
              <div
                className={styles.noData}
                style={{ marginLeft: "-20%", marginTop: "0%" }}
              >
                <img
                  src={no_data_icon}
                  alt=""
                  style={{ height: "200px", width: "200px" }}
                />
                <h4>No Data Added</h4>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Yearly_sales;
