import React, { useState } from "react";
import styles from "../Styles/Table.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MiniDrawer from "./Minidrawer";



function Order_history_tbl_Count({
    data,
    year,
    setDepartment,
    handleDate,
    department,
    isChangeView,
    setIsChangeView,
    theme
}) {

//   const [isChangeView, setIsChangeView] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");

  const get_total = (data_val) => {
    let new_total = data_val?.reduce((acc, ele) => {
      // return acc + Number(ele?.Total_Value);
      return acc + Number(ele?.order_count);
    }, 0);
    return new_total
    // return new Intl.NumberFormat("en-IN").format(new_total);
  };
  const get_total_val = (data_val, flag) => {
    let final_total = 0;
    if (flag == "New") {
      let new_total1 = data_val?.Q1?.New?.reduce((acc, ele) => {
        // return acc + Number(ele?.Total_Value);
        return acc + Number(ele?.order_count);
      }, 0);
      let new_total2 = data_val?.Q2?.New?.reduce((acc, ele) => {
        // return acc + Number(ele?.Total_Value);
        return acc + Number(ele?.order_count);
      }, 0);
      let new_total3 = data_val?.Q3?.New?.reduce((acc, ele) => {
        // return acc + Number(ele?.Total_Value);
        return acc + Number(ele?.order_count);
      }, 0);
      let new_total4 = data_val?.Q4?.New?.reduce((acc, ele) => {
        // return acc + Number(ele?.Total_Value);
        return acc + Number(ele?.order_count);
      }, 0);
      return final_total = new_total1 + new_total2 + new_total3 + new_total4;
      // return new Intl.NumberFormat("en-IN").format(final_total);
    } else {
      let new_total1 = data_val?.Q1?.Repeat?.reduce((acc, ele) => {
        // return acc + Number(ele?.Total_Value);
        return acc + Number(ele?.order_count);
      }, 0);
      let new_total2 = data_val?.Q2?.Repeat?.reduce((acc, ele) => {
        // return acc + Number(ele?.Total_Value);
        return acc + Number(ele?.order_count);
      }, 0);
      let new_total3 = data_val?.Q3?.Repeat?.reduce((acc, ele) => {
        // return acc + Number(ele?.Total_Value);
        return acc + Number(ele?.order_count);
      }, 0);
      let new_total4 = data_val?.Q4?.Repeat?.reduce((acc, ele) => {
        // return acc + Number(ele?.Total_Value);
        return acc + Number(ele?.order_count);
      }, 0);
      final_total = new_total1 + new_total2 + new_total3 + new_total4;
      return final_total
      // return new Intl.NumberFormat("en-IN").format(final_total);

    }
  };

  const handleDepartment = (e)=>{
    if(e.target.value != ""){
      setDepartment(e.target.value)
    }
 
  }

  const handleViewRevenue = (e) => {
    setIsChangeView(false)
  }

//   const handleViewCount = (e) => {
//     setIsChangeView(false)
//   }

  return (
    <>
      <Paper
        sx={{
          // width: index == 0 ? "40%" : "20%",
          // width: "94%",
          mb: 2,
          padding: "0px",
          // marginLeft:"5%"
          // height: "500px",
        }}
      >
        <TableContainer id="order_tbl">
          <Table id={theme =="light"? "light_tbl":"dark_tbl"} stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                backgroundColor: "#007cc3",
                color: "#444a4f !important",
                position: "sticky",
                top: 0,
                zIndex: "999",
              }}
            >
              <TableRow>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                  colspan={6}
                >
                  <h5
                    className="ml-2 mt-2"
                    style={{
                      color: "white",
                      textAlign: "left",
                      marginLeft: "1%",
                    }}
                  >
                    {" "}
                    New vs Repeated - Business
                  </h5>
                </TableCell>
                <TableCell>
                    <button 
                      style={{backgroundColor:"white", color:"#007cc3", borderRadius:"4px", display:"flex", margin:"auto"}}
                      onClick={handleViewRevenue}
                      >
                    View Revenue
                  </button>
                  
                </TableCell>

                {(user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" ||
                user_id == "a07b4768-cbab-4269-829b-bfbb17722385" || user_id == "dd95dec7-893c-45ec-a30f-7aacc506400c" )? (
                  <TableCell
                    id={styles.th_unit1}
                    className="dept_head"
                    style={{ borderBottom: "1px solid #89BBE0" ,padding:"0px"}}
                    colSpan={2}
                  >
                    <select
                      name=""
                      id=""
                      className="form-select"
                      onChange={handleDepartment}
                      style={{ width: "182px", margin: "0px auto" }}
                      value={department}
                    >
                      <option value="" selected>Select Department</option>
                      <option value="Corporate Sales">Sales - Corporate</option>
                      <option value="FnB Sales">Sales - Fnb</option>
                    </select>
                  </TableCell>
                ) : (
                  <></>
                )}
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                  colSpan={
                   ( user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" ||
                    user_id == "a07b4768-cbab-4269-829b-bfbb17722385" ||
                    user_id == "dd95dec7-893c-45ec-a30f-7aacc506400c")
                      ? 2
                      : 4
                  }
                >

                  <select
                    name=""
                    id=""
                    className="form-select"
                    onChange={handleDate}
                    value={year}
                    style={{ width: "150px", margin: "auto" }}
                  >
                    <option value="" disabled selected>Select Year</option>
                    <option value="2015-2016">2015-16</option>
                    <option value="2016-2017">2016-17</option>
                    <option value="2017-2018">2017-18</option>
                    <option value="2018-2019">2018-19</option>
                    <option value="2019-2020">2019-20</option>
                    <option value="2020-2021">2020-21</option>
                    <option value="2021-2022">2021-22</option>
                    <option value="2022-2023">2022-23</option>
                    <option value="2023-2024">2023-24</option>
                    <option value="2024-2025">2024-25</option>
                  </select>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                  rowSpan={3}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "left" }}
              >
                Sales Person
              </h6></TableCell>
                {/*     
                  <TableCell id={styles.th_unit1}>
                    <h6 style={{ color: "white" }}>New/Repeat</h6>
                  </TableCell> */}

                <TableCell
                  id={styles.th_unit1}
                  style={{ textAlign: "center" }}
                  colSpan={10}
                >
                  <h6
                    className="h6 mt-2"
                    style={{ color: "white", textAlign: "center" }}
                  >
                    Financial - year: {year}
                  </h6>
                </TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell
                  id={styles.th_unit1}
                  style={{
                    borderBottom: "1px solid #89BBE0",
                    borderTop: "1px solid #89BBE0",
                  }}
                >
                  <h6
                    className="h6 mt-2"
                    style={{ color: "white", textAlign: "left" }}
                  >
                    Sales Person
                  </h6>
                </TableCell> */}

                {/* <TableCell id={styles.th_unit1}></TableCell> */}

                <TableCell id={styles.th_unit1} colspan={2}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Q1
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1} colspan={2}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Q2
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1} colspan={2}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Q3
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1} colspan={2}>
                  <h6
                    className="h6 mt-2"
                    style={{ color: "white", width: "150px" }}
                  >
                    Q4
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1} colspan={2}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Total
                  </h6>
                </TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell
                  id={styles.th_unit1}
                  style={{ borderTop: "1px solid #89BBE0" }}
                >
                  {" "}
                </TableCell> */}
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    New
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Repeat
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    New
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Repeat
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    New
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Repeat
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    New
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Repeat
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    New
                  </h6>
                </TableCell>
                <TableCell id={styles.th_unit1}>
                  <h6 className="h6 mt-2" style={{ color: "white" }}>
                    Repeat
                  </h6>
                </TableCell>
              </TableRow>
            </TableHead>
            {data?.length == "0" ? (
              <TableBody>
                {" "}
                <TableRow>
                  <TableCell colSpan={11} className={styles.th_unit}>
                    {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                    <p style={{ textAlign: "center" }}>No Data Available</p>
                  </TableCell>
                  {/* </div> */}
                </TableRow>
              </TableBody>
            ) : (
              <TableBody id={styles.body_content}>
                {(rowsPerPage > 0
                  ? data?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                )?.map((ele) => (
                  <TableRow>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2" style={{ textAlign: "left" }}>
                        {ele.sales_person}
                      </p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q1?.New)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q1?.Repeat)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q2?.New)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q2?.Repeat)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q3?.New)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q3?.Repeat)}</p>
                    </TableCell>
                    <TableCell
                      className={styles.th_unit}
                      sx={{ width: "200px" }}
                    >
                      <p className="p mt-2">{get_total(ele?.Q4?.New)}</p>
                    </TableCell>
                    <TableCell
                      className={styles.th_unit}
                      sx={{ width: "200px" }}
                    >
                      <p className="p mt-2">{get_total(ele?.Q4?.Repeat)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total_val(ele, "New")}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total_val(ele, "Repeat")}</p>
                    </TableCell>
                    {/* <TableCell className={styles.th_unit}>
                        <p>{ele.sales_person}</p>
                      </TableCell>
                    
                 <TableCell className={styles.th_unit}>
                        <p>{ele.order_type}</p>
                      </TableCell>
                    {ele.Q == "Q1" ? (
                      <TableCell className={styles.th_unit}>
                        <p>{ele.order_value}</p>
                        <p>{ele.order_type}</p>
                      </TableCell>
                    ) : (
                      <TableCell className={styles.th_unit_null}>
                        <p>---</p>
                      </TableCell>
                    )}
                    {ele.Q == "Q2" ? (
                      <TableCell className={styles.th_unit}>
                        <p>{ele.order_value}</p>
                        <p>{ele.order_type}</p>
                      </TableCell>
                    ) : (
                      <TableCell className={styles.th_unit_null}>
                        <p>---</p>
                      </TableCell>
                    )}
                    {ele.Q == "Q3" ? (
                      <TableCell className={styles.th_unit}>
                        <p>{ele.order_value}</p>
                        <p>{ele.order_type}</p>
                      </TableCell>
                    ) : (
                      <TableCell className={styles.th_unit_null}>
                        <p>---</p>
                      </TableCell>
                    )}
                    {ele.Q == "Q4" ? (
                      <TableCell className={styles.th_unit}>
                        <p>{ele.order_value}</p>
                          <p>{ele.order_type}</p>
                      </TableCell>
                    ) : (
                      <TableCell className={styles.th_unit_null}>
                        <p>---</p>
                      </TableCell>
                    )}
                    <TableCell className={styles.th_unit}>
                      <p>{ele.Total_Value}</p>
                    </TableCell> */}
                    
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          id={styles.footer_div}
          class="footer_div"
          sx={{ backgroundColor: "#007cc3", color: "white" }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default Order_history_tbl_Count;
