import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import styles from "../Styles/Lead.module.css";
import MiniDrawer from "../components/Minidrawer";
import D3Funnel from "d3-funnel";
import no_data_icon from "../Media/no_data_icon.gif";
import report_loader from "../Media/ezgif.com-optimize.gif";
import moment from "moment";
import dayjs from "dayjs";
import { DateRangePicker, Stack } from "rsuite";
import InfoIcon from "@mui/icons-material/Info";
import { Menu, MenuItem } from "@mui/material";
import { format } from "echarts";

function Lead_funnel() {

  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id")

  const [cat_drop, setCat_Drop] = useState([]);
  const [source_drop, setSource_Drop] = useState([]);
  const [selected_cat, setSelected_Cat] = useState("PPC");
  const [selected_source, setSelected_Source] = useState("ALL");
  const [leads_data, setLeads_Data] = useState([]);
  const [funnel_load, setFunnel_Load] = useState(false);

  const [funnel_data, setFunnel_Data] = useState([]);

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [range_flag, setRange_Flag] = useState(false);
  const [funnel_params, setFunnel_Params] = useState("MTD");
  const lead_ref = useRef();

  const windowHeight = useRef(window.innerHeight);
  const [anchorEl, setAnchorEl] = useState(null);

  const [all_view, setAll_View] = useState(false);
  const [all_data, setAll_Data] = useState([]);
  const [all_lead_load, setAll_Lead_Load] = useState(false);

  function handleClose() {
    setAnchorEl(null);
  }

  const handleView = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const get_category = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_category");
      let data = await res.json();
      if (data.response?.code == "200") {
        setCat_Drop(data?.Data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {}
  };

  const get_source = async (val) => {
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/get_leadsource_by_category",
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            CATEGORY: val,
          }),
        }
      );
      let data = await res.json();
      if (data.response?.code == "200") {
        setSource_Drop(data?.Data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {}
  };

  const get_funnel_data = async (val1, val2, params, start_date, end_date) => {
    setLeads_Data([]);
    setFunnel_Data([]);
    setFunnel_Load(true);

    let newStartDate = moment(start_date).format("YYYY-MM-DD");
    let newEndDate = moment(end_date).format("YYYY-MM-DD");
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/lead_funnel_filter",
        {
          // let res = await fetch("https://reportapi.equinoxlab.com/lead_funnel",{
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            CATEGORY: val1,
            LEADSOURCE: val2,
            GET_DATE: params == "Custom Date" ? "" : params,
            START_DATE: params != "Custom Date" ? "" : newStartDate,
            END_DATE: params != "Custom Date" ? "" : newEndDate,
          }),
        }
      );
      let data = await res.json();
      setFunnel_Load(false);
      setLeads_Data(data?.Data);

      let funnel_info = [];
      let lead_count = 0;
      let prospect_count = 0;
      let customer_count = 0;
      if (data?.Data?.length > 0) {
        for (let i = 0; i < data?.Data?.length; i++) {
          if (data?.Data[i]?.label == "Lead Count") {
            lead_count = lead_count + data?.Data[i]?.value;
          }
          if (data?.Data[i]?.label == "Prospect Count") {
            prospect_count = prospect_count + data?.Data[i]?.value;
          }
          if (data?.Data[i]?.label == "Customer Count") {
            customer_count = customer_count + data?.Data[i]?.value;
          }
        }
        funnel_info?.push({
          label: "Lead",
          value: lead_count,
        });
        funnel_info?.push({
          label: "Prospect",
          value: prospect_count,
        });
        funnel_info?.push({
          label: "Customer",
          value: customer_count,
        });
      }

      if (data?.Data?.length == 0) {
        funnel_info?.push({
          label: "Lead",
          value: 0,
        });
        funnel_info?.push({
          label: "Prospect",
          value: 0,
        });
        funnel_info?.push({
          label: "Customer",
          value: 0,
        });
      }
      setFunnel_Data(funnel_info);
      const chart = new D3Funnel("#lead_contain");
      chart.draw(funnel_info, options);
    } catch (error) {
      setFunnel_Load(false);
    }
  };

  const get_leads_data = async (signal) => {
    setAll_Data([]);
    try {
      setAll_Lead_Load(true);
      let res = await fetch(
        "https://reportapi.equinoxlab.com/get_raw_to_customer_count",
        { signal: signal }
      );
      let data = await res.json();
      let funnel_info = [];
      setAll_Lead_Load(false);
      if (data?.Data?.length == 0) {
        funnel_info?.push({
          label: "Total",
          value: 0,
        });
        funnel_info?.push({
          label: "Raw",
          value: 0,
        });
        funnel_info?.push({
          label: "Prospect",
          value: 0,
        });
        funnel_info?.push({
          label: "Customer",
          value: 0,
        });
      } else {
        funnel_info?.push({
          label: "Total",
          value: data?.Data[0]?.total_leads,
        });
        funnel_info?.push({
          label: "Raw",
          value: data?.Data[0]?.raw_leads,
        });
        funnel_info?.push({
          label: "Prospect",
          value: data?.Data[0]?.prospect,
        });
        funnel_info?.push({
          label: "Customer",
          value: data?.Data[0]?.customers,
        });
      }
      setAll_Data(funnel_info);
      const chart = new D3Funnel("#all_lead_contain");
      chart.draw(funnel_info, all_options);
    } catch (error) {
      setAll_Lead_Load(false);
    }
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_category();
    get_source("PPC");
    get_funnel_data("PPC", "ALL", funnel_params, "", "");
    }
  }, []);

  const handleCategory = (e) => {
    setSelected_Cat(e.target.value);
    setSelected_Source("ALL");
    get_source(e.target.value);
    get_funnel_data(
      e.target.value,
      "ALL",
      funnel_params,
      selectedFromDate,
      selectedToDate
    );
    // if(e.target.value == "ALL"){
    //   setSource_Drop([])
    // setSelected_Cat("ALL")
    // setSelected_Source("ALL")
    // }else{

    // }
  };

  const handleSource = (e) => {
    // if(e.target.value != "ALL"){
    setSelected_Source(e.target.value);
    get_funnel_data(
      selected_cat,
      e.target.value,
      funnel_params,
      selectedFromDate,
      selectedToDate
    );
    // }
  };

  function indianNumberFormat(number) {
    // return new Intl.NumberFormat("en-IN").format(number);
    return number.toString();
}

  const handleParams = (e) => {
    setFunnel_Params(e.target.value);
    if (e.target.value == "Custom Date") {
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      get_funnel_data(
        selected_cat,
        selected_source,
        e.target.value,
        selectedFromDate,
        selectedToDate
      );
    }
  };

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);

      // let newStartDate = moment(new_val[0]).format("YYYY-MM-DD");
      // let newEndDate = moment(new_val[1]).format("YYYY-MM-DD");
      get_funnel_data(
        selected_cat,
        selected_source,
        funnel_params,
        new_val[0],
        new_val[1]
      );
    } else {
      setFunnel_Params("MTD");
      setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
      get_funnel_data(selected_cat, selected_source, "MTD", new_date, new_date);
      // dispatch(
      //   get_client_type_sample_revenue_data(
      //     setRevenue_Load,
      //     department,
      //     "MTD",
      //     new_date,
      //     new_date,
      //     setSample_Revenue_Data
      //   )
      // );
      // setDisplay2(false)
    }
  };

  const handleAllView = () => {
    setAll_View(!all_view);
  };

  const options = {
    label: {
      enabled: true,
      fontFamily: null,
      // fontWeight: 500,
      fontSize: windowHeight?.current > 900 ? "25px" : "15px",
      fill: "#000",
      // format: "{f}",
      format:function(label, value){
        return indianNumberFormat(value)
      }
    //   format: function(label, value) {
    //     return indianNumberFormat(value);
    // }
      // format: "{l}: {f}",
    },

    block: {
      barOverlay: false,
      highlight: false,
      dynamicHeight: false,
      minHeight: 15,
      fill: {
        type: "solid",
        scale: [
          "#87C4FF",
          "#FFD099",
          "#9ADE7B",

          // "#007cc3",
          // "#7ED7C1",
          // " #9ADE7B",
          // "#FFC7C7",
          // "#FFE3BB",
          // "#B0A695",
          // "#DCBFFF",
          // "#87C4FF",
          // "#FFD099",
        ],
      },
    },
    tooltip: {
      enabled: false,
      format: "{v}",
      // format: "{l}: {v}",
    },
    chart: {
      width: 500,
      height: windowHeight?.current > 900 ? 600 : 400,
      // bottomWidth: 1 / 3,
      bottomPinch: 1,
      inverted: false,
      horizontal: false,
      animate: 170,
      curve: {
        enabled: true,
        height: 40,
      },
      totalCount: null,
    },
  };

  const all_options = {
    label: {
      enabled: true,
      fontFamily: null,
      // fontWeight: 500,
      fontSize: windowHeight?.current > 900 ? "25px" : "15px",
      fill: "#000",
      // format: "{f}",
      format: function(label, value) {
        return indianNumberFormat(value)
    },

      // format: "{l}: {f}",
    },
    block: {
      barOverlay: false,
      highlight: false,
      dynamicHeight: false,
      minHeight: 15,
      fill: {
        type: "solid",
        scale: [
          "#87C4FF",
          "#FFD099",
          "#9ADE7B",
          "#7ED7C1",
          // "#007cc3",
          // "#7ED7C1",
          // " #9ADE7B",
          // "#FFC7C7",
          // "#FFE3BB",
          // "#B0A695",
          // "#DCBFFF",
          // "#87C4FF",
          // "#FFD099",
        ],
      },
    },
    tooltip: {
      enabled: false,
      format: "{v}",
      // format: "{l}: {v}",
    },
    chart: {
      width: 500,
      height: windowHeight?.current > 900 ? 600 : 400,
      // bottomWidth: 1 / 3,
      bottomPinch: 1,
      inverted: false,
      horizontal: false,
      animate: 170,
      curve: {
        enabled: true,
        height: 40,
      },
      totalCount: null,
    },
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    const controller = new AbortController();
    const signal = controller.signal;
    if (all_view) {
      get_leads_data(signal);
    }
    return () => {
      controller.abort();
    };
  }
  }, [all_view]);

  // if(funnel_load) return<img src={report_loader} id={styles.loader} />

  return (
    <>
      <MiniDrawer />

      <div
        style={{ display: all_view ? "none" : "block" }}
        className="container-fluid pt-3"
      >
        <div className="row" id={styles.dropdown_container}>
          <div
            className={range_flag ? "col-sm-12 col-md-3" : "col-sm-12 col-md-6"}
          >
            <button
              id={styles.change_btn}
              className="btn btn-primary"
              onClick={handleAllView}
            >
              Change View
            </button>
          </div>

          <div className="col-sm-12 col-md-2">
            <label>Please Select Category</label>
            <select
              className="form-select mt-2"
              value={selected_cat}
              onChange={handleCategory}
            >
              {cat_drop?.map((ele) => (
                <>
                  {ele?.Leadcat == "PPC" ? (
                    <option value={ele?.Leadcat} selected>
                      {ele?.Leadcat}
                    </option>
                  ) : (
                    <option value={ele?.Leadcat}>{ele?.Leadcat}</option>
                  )}
                </>
              ))}
            </select>
          </div>
          <div className="col-sm-12 col-md-2">
            <label>Please Select Source</label>
            <select
              className="form-select mt-2"
              value={selected_source}
              onChange={handleSource}
            >
              <option value="ALL">All</option>
              {source_drop?.map((ele) => (
                <option value={ele?.Leadsource}>{ele?.Leadsource}</option>
              ))}
            </select>
          </div>
          <div className="col-sm-12 col-md-2">
            <label>Please Select</label>
            <select
              onChange={handleParams}
              value={funnel_params}
              className="form-select mt-2"
              style={{ marginRight: "20px" }}
            >
              <option value="Today">Today</option>
              <option value="MTD">MTD</option>
              <option value="QTD">QTD</option>
              <option value="YTD">YTD</option>
              <option value="FYTD">FYTD</option>
              <option value="Custom Date">Custom Date</option>
            </select>
          </div>
          {range_flag ? (
            <div className="col-sm-12 col-md-3">
              <DateRangePicker
                style={{ marginTop: "28px" }}
                defaultValue={[new Date(), new Date()]}
                format="dd MMM yyyy"
                value={[selectedFromDate, selectedToDate]}
                onChange={handleDateChange}
                size="md"
                placement="bottomEnd"
                preventOverflow
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div id={styles.status_container}>
          <div style={{ marginLeft: "14vw" }}>
            <div style={{ backgroundColor: "#87C4FF" }}></div>
            <span>Lead</span>
          </div>
          <div>
            <div style={{ backgroundColor: "#FFD099" }}></div>
            <span>Prospect</span>
          </div>
          <div>
            <div style={{ backgroundColor: "#9ADE7B" }}></div>
            <span>Customer</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div
              id="lead_contain"
              ref={lead_ref}
              style={{
                display: funnel_data?.length > 0 ? "block" : "none",
                marginTop: "2.7vh",
              }}
            ></div>

            {/* {(leads_data?.length == 0 && !funnel_load)?<div
                className={styles.noData}
                style={{ marginTop: "5%" }}
              >
                <img
                  src={no_data_icon}
                  alt=""
                  style={{ height: "200px", width: "200px" }}
                />
                <h4>No Data Added</h4>
              </div>:<></>} */}

            <div style={{ display: funnel_load ? "flex" : "none" }}>
              <img id={styles.loader_funnel} src={report_loader} alt="" />
            </div>
          </div>
        </div>
        {/* <div onMouseEnter={handleView} id={styles.info_icon_contain}>
          <InfoIcon sx={{ height: "3vh", color: "white" }} />
        </div>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          MenuListProps={{ onMouseLeave: handleClose }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <div id={styles.process_contain}>
            This lead funnel chart illustrates the number of leads generated on
            a specific date, how many of these leads are moved to prospects, and
            how many are ultimately converted into customers. The data is shown
            on created on date range selected in the date filter.
          </div>

        </Menu> */}
      </div>

      <div
        style={{ display: !all_view ? "none" : "block" }}
        className="container-fluid pt-3"
      >
        <div className="row">
          <div className="col-sm-12 col-md-2">
            <button
              id={styles.change_btn}
              className="btn btn-primary"
              onClick={() => setAll_View(!all_view)}
            >
              Change View
            </button>
          </div>
          <div className="col-sm-12 col-md-10">
            <p id={styles.leades_header}>Leads Data</p>
          </div>
        </div>
        <div id={styles.all_lead_status}>
          <div style={{ marginLeft: "37%" }}>
            <div style={{ backgroundColor: "#87C4FF" }}></div>
            <span>Total</span>
          </div>
          <div>
            <div style={{ backgroundColor: "#FFD099" }}></div>
            <span>Raw</span>
          </div>
          <div>
            <div style={{ backgroundColor: "#9ADE7B" }}></div>
            <span>Prospect</span>
          </div>
          <div>
            <div style={{ backgroundColor: "#7ED7C1" }}></div>
            <span>Customer</span>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6" style={{ margin: "auto" }}>
            <div
              id="all_lead_contain"
              style={{
                display: all_data?.length > 0 ? "block" : "none",
                marginTop: "2.7vh",
              }}
            ></div>

            {/* {(leads_data?.length == 0 && !funnel_load)?<div
                className={styles.noData}
                style={{ marginTop: "5%" }}
              >
                <img
                  src={no_data_icon}
                  alt=""
                  style={{ height: "200px", width: "200px" }}
                />
                <h4>No Data Added</h4>
              </div>:<></>} */}

            <div style={{ display: all_lead_load ? "flex" : "none" }}>
              <img id={styles.loader_funnel} src={report_loader} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div onMouseEnter={handleView} id={styles.info_icon_contain}>
        <InfoIcon sx={{ height: "3vh", color: "white" }} />
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem > */}
        <div id={styles.process_contain}>
          {all_view
            ? "This lead funnel chart illustrates the number of leads, raw leads, prospect and customers available in the system till date."
            : "This lead funnel chart illustrates the number of leads generated on a specific date, how many of these leads are moved to prospects, and how many are ultimately converted into customers. The data is shown on created on date range selected in the date filter."}
        </div>
        {/* </MenuItem> */}
      </Menu>
    </>
  );
}

export default Lead_funnel;
