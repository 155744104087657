import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import styles from "../Styles/Table.module.css";
import { leads_data } from "../Redux/actions";
import Minidrawer from "../components/Minidrawer";
import { useNavigate } from "react-router-dom";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


function Salesperson_Wise() {

  const [sales_data,setSales_Data] = useState([])
  // const data = useSelector((state) => state.data.data);
  const dispatch = useDispatch();

  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state)=>state.data.error)
  const [show, setShow] = useState(false);

  const storage = window.sessionStorage;
  let img = storage.getItem("profile_img");
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");
  let DEPT = storage.getItem("DEPT")

  const navigate = useNavigate();


  const get_total =(val)=>{


  //   if(val == "D"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.D)
  //   },0)
  //   return total_count;
  // }
  //   else if(val == "D1"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.D1)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "D2"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.D2)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "D3"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.D3)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "D4"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.D4)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "D5"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.D5)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "D6"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.D6)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "W"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.W)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "W1"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.W1)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "W2"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.W2)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "W3"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.W3)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "W4"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.W4)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "W5"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.W5)
  //   },0)
  //   return total_count;
  // }
  // else if(val == "W6"){
  //    let total_count = sales_data?.reduce((acc,ele)=>{
  //      return acc+Number(ele?.W6)
  //   },0)
  //   return total_count;
  // }
   if(val == "M"){
    let total_count = sales_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.M)
   },0)
   return total_count;
 }
 else if(val == "M1"){
    let total_count = sales_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.M1)
   },0)
   return total_count;
 }
 else if(val == "M2"){
    let total_count = sales_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.M2)
   },0)
   return total_count;
 }
 else if(val == "M3"){
    let total_count = sales_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.M3)
   },0)
   return total_count;
 }
 else if(val == "M4"){
    let total_count = sales_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.M4)
   },0)
   return total_count;
 }
 else  if(val == "M5"){
    let total_count = sales_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.M5)
   },0)
   return total_count;
 }
 else if(val == "M6"){
    let total_count = sales_data?.reduce((acc,ele)=>{
      return acc+Number(ele?.M6)
   },0)
   return total_count;
 }
 else if(val == "Q"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Q)
 },0)
 return total_count;
}
else if(val == "Q1"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Q1)
 },0)
 return total_count;
}
else if(val == "Q2"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Q2)
 },0)
 return total_count;
}
else if(val == "Q3"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Q3)
 },0)
 return total_count;
}
else if(val == "Q4"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Q4)
 },0)
 return total_count;
}
else if(val == "Q5"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Q5)
 },0)
 return total_count;
}
else if(val == "Q6"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Q6)
 },0)
 return total_count;
}
else if(val == "Y"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Y)
 },0)
 return total_count;
}
else if(val == "Y1"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Y1)
 },0)
 return total_count;
}
else if(val == "Y2"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Y2)
 },0)
 return total_count;
}
else if(val == "Y3"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Y3)
 },0)
 return total_count;
}
else if(val == "Y4"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Y4)
 },0)
 return total_count;
}
else if(val == "Y5"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Y5)
 },0)
 return total_count;
}
else if(val == "Y6"){
  let total_count = sales_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Y6)
 },0)
 return total_count;
}

else{
  let x= 1;
}

  }

//   React.useEffect(()=>{
//     if(USER_GUID == "" || USER_GUID == null){
//       navigate("/")
//     }
//  },[storage])

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    dispatch(leads_data(setSales_Data,DEPT));
    }
  }, []);

  const options = {
    filterType: "checkbox",
  };

  const columns = [
    {
      name: "Sales",
      label: "Sales",
      options: {
        filter: false,
        sort: false,
      setCellProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          left: "0",
          background: "white",
          zIndex: 0,
          // minWidth:"250px",
          // maxWidth:"250px"
        }
      }),
      setCellHeaderProps: () => ({
        style: {
          whiteSpace: "nowrap",
          position: "sticky",
          left: 0,
          background: "white",
          zIndex: 101
        }
      }),

        customBodyRender: (row) => {
          return <div style={{textAlign:"left",paddingRight:"20px"}}><p style={{marginLeft:"30%"}}>{row}</p></div>
        },
      },
    },
    (USER_GUID == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" || USER_GUID == "a07b4768-cbab-4269-829b-bfbb17722385" || USER_GUID == "dd95dec7-893c-45ec-a30f-7aacc506400c") ?{
      name: "Department",
      label: "Department",
      options: {
        filter: true,
        sort: false,
        // setCellProps: () => ({
        //   style: {
        //     whiteSpace: "nowrap",
        //     position: "sticky",
        //     left: "0",
        //     background: "white",
        //     zIndex: 100,
        //     minWidth:"200px",
        //     maxWidth:"200px"
        //   }
        // }),
        // setCellHeaderProps: () => ({
        //   style: {
        //     whiteSpace: "nowrap",
        //     position: "sticky",
        //     left: 0,
        //     background: "white",
        //     zIndex: 101
        //   }
        // }),


        // setCellProps: () => ({
        //   style: { minWidth: "140px", maxWidth: "auto" },
        // }),
        customBodyRender: (row) => {
          return <div style={{textAlign:"left",paddingRight:"20px"}}><p style={{marginLeft:"30%"}}>{row}</p></div>
        },
      },
    }:{
      name: "Department",
      label: "Department",
      options: {
        filter: false,
        sort: false,
        display:"none"
      }
    },
    {
      name: "ROLE",
      label: "Role",
      options: {
        filter: true,
        sort: false,
        // setCellProps: () => ({
        //   style: { minWidth: "150px", maxWidth: "180px" },
        // }),
        customBodyRender: (row) => {
          return <div style={{textAlign:"left",paddingRight:"20px"}}><p style={{marginLeft:"30%"}}>{row}</p></div>
        },
        // setCellProps: () => ({
        //   style: { minWidth: "200px", maxWidth: "200px" },
        // }),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        // setCellProps: () => ({
        //   style: { minWidth: "80px", maxWidth: "80px",textAlign:"left" },
        // }),
      },
    },
    // {
    //   name: "D",
    //   label: "D",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //         padding: "20px",
    //              color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),

    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "D1",
    //   label: "D1",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "D2",
    //   label: "D2",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "D3",
    //   label: "D3",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "D4",
    //   label: "D4",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "D5",
    //   label: "D5",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "D6",
    //   label: "D6",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "W",
    //   label: "W",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "W1",
    //   label: "W1",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "W2",
    //   label: "W2",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "W3",
    //   label: "W3",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "W4",
    //   label: "W4",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "W5",
    //   label: "W5",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    // {
    //   name: "W6",
    //   label: "W6",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:
    //           Number(cellValue?.props?.children) >= 0 &&
    //           Number(cellValue?.props?.children) < 20
    //             ? "#dff3ff"
    //             : Number(cellValue?.props?.children) >= 20 &&
    //               Number(cellValue?.props?.children) < 40
    //             ? "#9fdcff"
    //             : Number(cellValue?.props?.children) >= 40 &&
    //               Number(cellValue?.props?.children) < 60
    //             ? "#60c5ff"
    //             : Number(cellValue?.props?.children) >= 60 &&
    //               Number(cellValue?.props?.children) < 80
    //             ? "#20aeff"
    //             : Number(cellValue?.props?.children) >= 80 &&
    //               Number(cellValue?.props?.children) <= 600
    //             ? "#008edf"
    //             : Number(cellValue?.props?.children) >= 600
    //             ? "#1D5D9B"
    //             : "white",
    //                  color:((Number(cellValue?.props?.children) >= 80 &&
    //             Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
    //       },
    //     }),
    //     customBodyRender: (row) => {
    //       return <>{row}</>;
    //     },
    //   },
    // },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        // customHeadLabelRender: () => {
        //   return (
        //     <>
        //       {!show ? (
        //         <div style={{ display: "flex" }} onClick={() => setShow(true)}>
        //           <p
        //             style={{display: "flex",
        //             alignItems: "center" ,fontSize: "15px",
        //             marginTop: "8px",
        //             marginBottom: "0.4rem"}}
        //           >
        //             Q{" "}
        //             <ArrowDownwardIcon sx={{height: "15px"}} />
        //           </p>{" "}
        //         </div>
        //       ) : (
        //         <p style={{ verticalAlign: "middle",   marginTop: "0",
        //         marginBottom: "0.1rem" }}>Q</p>
        //       )}
        //     </>
        //   );
        // },
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),

        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),

        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 20
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 20 &&
                  Number(cellValue?.props?.children) < 40
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 40 &&
                  Number(cellValue?.props?.children) < 60
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 60 &&
                  Number(cellValue?.props?.children) < 80
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 80 &&
                  Number(cellValue?.props?.children) <= 600
                ? "#008edf"
                : Number(cellValue?.props?.children) >= 600
                ? "#1D5D9B"
                : "white",
                     color:((Number(cellValue?.props?.children) >= 80 &&
                Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    // {
    //   name: "",
    //   label: "Total",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     setCellProps: (cellValue) => ({
    //       style: {
    //         backgroundColor:"#141E46",color: "white"
    //       },
    //     }),
    //     customBodyRender: (row,data) => {
    //       return <>{get_total(data)}</>;
    //     },
    //   },
    // }
  ];
  


  return (
    <>
  <Minidrawer/>
  { check_loading ? <img src={report_loader} id={styles.loader} />: 
  <div className="container-fluid pt-3"  id={sales_data?.length == 0?"":"table_s6"}>

          <MUIDataTable
            title={
              <h5 style={{ textAlign: "left" }}>
                Leads Added By Sales
              </h5>
            }
            data={sales_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: true,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              // tableBodyMaxHeight: "60vh",
              rowsPerPageOptions:[10,25,50,100],
              sortOrder: {
                name: 'Q',
                direction: 'desc'
              },
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
              customTableBodyFooterRender: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                data
              ) => {
                const startIndex = page * rowsPerPage;
                const endIndex = (page + 1) * rowsPerPage;
                return (
                  <TableFooter>
                    <TableRow id={styles.table_footer}>
                    <TableCell  
                     id={styles.first_col}>
                     <p >Total</p>
                      </TableCell>
                      <TableCell colspan={(DEPT == "Tech" || DEPT == "Admin" || DEPT == "Management" )?3:2}>
                        {/* <p >Total</p> */}
                      </TableCell>
                      {/* <TableCell colspan={1}>
                       <p></p>
                      </TableCell>
                      <TableCell colspan={2}>
                        <p >Total</p> 
                      </TableCell> */}
                      {/* <TableCell>
                        <p >{get_total("D")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("D1")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("D2")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("D3")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("D4")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("D5")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("D6")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("W")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("W1")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("W2")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("W3")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("W4")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("W5")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("W6")}</p> 
                      </TableCell> */}
                      <TableCell>
                        <p >{get_total("M")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M1")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M2")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M3")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M4")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M5")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("M6")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q1")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q2")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q3")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q4")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q5")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Q6")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y1")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y2")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y3")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y4")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y5")}</p> 
                      </TableCell>
                      <TableCell>
                        <p >{get_total("Y6")}</p> 
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              }

            }}
          />
        
      </div>
   } 
    </>
  );
}

export default Salesperson_Wise;
