import React from 'react'
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";


function Total_lead_assigned_tbl({data}) {
    const get_total = (val)=>{
        if(val == "added"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.added)
         },0)
         return total_count;
        }
        else if(val == "in-verfication"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.in_verification)
         },0)
         return total_count;
        }
        else if(val == "Not Relevant"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.not_relevant)
         },0)
         return total_count;
        }
        else if(val == "in-verfication"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.total_order)
         },0)
         return total_count;
        }
        else if(val == "New"){
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.new)
         },0)
         return total_count;
        }else{
          let total_count = data?.reduce((acc,ele)=>{
            return acc+Number(ele?.relevant)
         },0)
         return total_count;
        }
      }
    
        const options = {
            filterType: "checkbox",
          };
          const columns = [
        
            {
              name: "category",
              label: `Category`,
              options: {
                filter: false,
                sort: false,
                         customHeadLabelRender: () => {
                return (
                  <>
                      <div style={{ display: "flex",justifyContent:"left",paddingLeft:"10px" }} >
                        <span id="name_bde">Category</span>
                      </div>

                  </>
                );
              },
                customBodyRender:(row)=>{
                  return(
                     <>
                     <div style={{display:"flex",justifyContent:"left",paddingLeft:"15px"}}>
                      <span>{row}</span>
                     </div>
                     </>
                  )
                }
              }
            },
          
            {
              name: "added",
              label: `Added`,
              options: {
                filter: false,
                sort: true,
              
              },
            },
            {
              name: "new",
              label: `New`,
              options: {
                filter: false,
                sort: true,
               
              },
            },
            {
              name: "in_verification",
              label: `In-Verification`,
              options: {
                filter: false,
                sort: true,
               
              },
            },
 
            {
              name: "relevant",
              label: `Relevant`,
              options: {
                filter: false,
                sort: true,
               
              },
            },
            {
              name: "not_relevant",
              label: `Not Relevant`,
              options: {
                filter: false,
                sort: true,
               
              },
            },
       
      
          ];
  return (
    <div>
        <MUIDataTable
                title={<h5 style={{ textAlign: "left" }}>Total Leads</h5>}
                data={data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  filter: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  // tableBodyMaxHeight: "50vh",
                  rowsPerPageOptions:[10,30,50,100],
                  // rowsPerPage: "",
                  sortOrder: {
                    name: 'InvoiceAmount',
                    direction: 'desc'
                  },
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available"
                    },
                  },
                  customTableBodyFooterRender: (
                      count,
                      page,
                      rowsPerPage,
                      changeRowsPerPage,
                      changePage,
                      data
                    ) => {
                      const startIndex = page * rowsPerPage;
                      const endIndex = (page + 1) * rowsPerPage;
                      return (
                        <TableFooter id={styles.table_footer}>
                          <TableRow>
                        
                            <TableCell ><p style={{textAlign:"left",marginLeft:"10px"}}>Grand Total</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("added")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("in-verfication")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("Not Relevant")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("New")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("Relevant")}</p></TableCell>
                          
                          </TableRow>
                        </TableFooter>
                      );
                    },
                 
                }}
              />
    </div>
  )
}

export default Total_lead_assigned_tbl
