import React from 'react'
import Invoice_monthly_data from '../pages/Invoice_monthly_data'

function Invoice_monthly_data_fnb() {
  return (
    <>
      <Invoice_monthly_data/>
    </>
  )
}

export default Invoice_monthly_data_fnb
