import React from "react";
import MUIDataTable from "mui-datatables";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";

function Salesperson_order_tbl({ data }) {
  const get_total = (param) => {
    if (param == "amount") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Total_Amount);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Total_Order);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }
  };

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "Sales_Person",
      label: `Sales Person`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "Total_Amount",
      label: "Total Amount (₹)",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Total_Order",
      label: "Total Order",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ];
  return (
    <div id="corporate_tbl">
      <MUIDataTable
        title={
          <h5 style={{ textAlign: "left" }}>Salesperson Order Details</h5>
        }
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          viewColumns: false,
          print: false,
          responsive: "standard",
          download: false,
          fixedHeader: true,
          // tableBodyMaxHeight: "50vh",
          rowsPerPageOptions: [10, 25, 50, 100],
          filter: false,
          setTableProps: () => {
            return {
              padding: 0,
            };
          },
          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
          customTableBodyFooterRender: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage,
            data
          ) => {
            const startIndex = page * rowsPerPage;
            const endIndex = (page + 1) * rowsPerPage;
            return (
              <TableFooter id={styles.table_footer}>
                <TableRow>
                  <TableCell id={styles.first_col}>
                    <p style={{ textAlign: "left" }}>Grand Total (₹)</p>
                  </TableCell>
                  <TableCell>
                    <p style={{ textAlign: "center" }}>{get_total("amount")}</p>
                  </TableCell>
                  <TableCell>
                    <p style={{ textAlign: "center" }}>{get_total("order")}</p>
                  </TableCell>
                </TableRow>
              </TableFooter>
            );
          },
        }}
      />
    </div>
  );
}

export default Salesperson_order_tbl;
