import React, { useState } from 'react'
import styles from "../Styles/Corporate_oh.module.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Select2 from "react-select";

const customStyles = {
  option: (provided) => ({
    ...provided,
    textAlign: "left",
    fontSize: "14px",
  }),
};


function Client_oh_expire({data,sales_person_data,
  selectedFromDate,selectedToDate,
  setSelectedFromDate,setSelectedToDate,      
  show_OhSalesperson,
  setShow_OhSalesperson,
  selected_Ohbusiness,
  setSelected_OhBusiness,
  selected_Ohcompany,
  setSelected_OhCompany,
  company_list,
  business_dropdown
}) {

const [total_order_value,setTotal_Order_Value] = useState(0);
const [total_order_count,setTotal_Order_count] = useState(0)

  let date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();

  const handleDateChange = (new_val)=>{
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date());
    }
  }


  const handleSalesperson = (e) => {
    setShow_OhSalesperson(e);
  };

  const handleCompany = (e) => {
    setSelected_OhCompany(e);
  };


  const get_total = (val)=>{
    if(val == "value"){
      let total_count = data?.reduce((acc,ele)=>{ 
        return acc+Number(ele?.total_value)
     },0)
     setTotal_Order_Value(total_count)
     return new Intl.NumberFormat("en-IN").format(total_count);
    }else{
      let total_count = data?.reduce((acc,ele)=>{
        return acc+Number(ele?.oh)
     },0)
     setTotal_Order_count(total_count)
     return total_count;
    }
  }

  const options = {
    filterType: "checkbox",
  };
  const columns = [

    {
      name: "salesperson",
      label: `Sales Person`,
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"left"}}>
              <p>{row}</p>
            </div>
          )
        }
      },
    },
  
    {
      name: "company_name",
      label: `Company Name`,
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"left"}}>
              <>{row}</>
            </div>
          )
        }
      },
    },
  
    {
      name: "po_end_date",
      label: `Po Expiry Date`,
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"left"}}>
              <p>{row}</p>
            </div>
          )
        }
      },
    },
    {
      name: "oh_number",
      label: `OH`,
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"left"}}>
              <p>{row}</p>
            </div>
          )
        }
      },
    },
    {
      name: "business_type",
      label: `Business Type`,
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => {
          return (
            <>
             <p style={{textAlign:"center"}}>Business Type</p>
            </>
          );
        },
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"center"}}>
              <p>{row}</p>
            </div>
          )
        }
      },
    },
    {
      name: "oh",
      label: `OH Count`,
      options: {
        filter: false,
        sort: true,
        
      },
    },
    {
      name: "total_value",
      label: `Order Value`,
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"center"}}>
              <p> {row == undefined ||
                  row == null
                    ? 0
                    : new Intl.NumberFormat("en-IN").format(
                        row
                      )}</p>
            </div>
          )
        }
      },
    },
  

  ];
  
  return (
    <>
       <div className='row mt-2'>
          <h2 style={{textAlign:"left",fontWeight:"500",fontSize:"27px" }}>Client OH - Expire Dashboard</h2>
       </div>
       <div className='row'>
         <div className='col-sm-12 col-md-4'>
         <div className="row mt-2" id={styles.metrics_info_contain}>
              <div className="col-1"></div>
                <div className="col-5">
                    <div><h6>Expiring Order Value</h6></div>
                    <p>       {total_order_value == undefined ||
                        total_order_value == null
                          ? 0
                          : new Intl.NumberFormat("en-IN").format(
                              total_order_value
                            )}</p>
                </div>
                <div className="col-5">
                <div><h6>Expiring OH count</h6></div>
                    <p>{total_order_count}</p>
                </div>
                <div className="col-1"></div>
            </div>
         </div>
         <div className='col-3'></div>
         <div className='col-sm-12 col-md-5'>
            <div className='row' id={styles.filter_container}>
            <div className="col-sm-12 col-md-6">
            <p>Business Type</p>
                              <Box sx={{ width: "100%" }}>
                <FormControl size="small" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_Ohbusiness}
                    onChange={(e) => setSelected_OhBusiness(e.target.value)}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {business_dropdown?.map((ele)=>(
                      <MenuItem value={ele?.business_type}>{ele?.business_type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
             </div>
             <div className="col-sm-12 col-md-6">
             <p>Po Expiry Datee</p>
             <DateRangePicker
                            // defaultValue={[new Date(), new Date()]}
                            format = "dd MMM yyyy"
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateChange}
                            style={{width:"100%"}}
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          />
             </div>
            </div>
            <div className='row mt-1'  id={styles.filter_container}>
              <div className="col-sm-12 col-md-6">
              <p>Sales Person</p>
              <Select2
                            isMulti
                            name="label"
                            options={sales_person_data}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={show_OhSalesperson}
                            onChange={handleSalesperson}
                            styles={customStyles}
                            maxMenuHeight="180px"
                          />
             </div>
             <div className="col-sm-12 col-md-6">
             <p>Company Name</p>
             <Select2
                            isMulti
                            name="label"
                            options={company_list}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={selected_Ohcompany}
                            onChange={handleCompany}
                            styles={customStyles}
                            maxMenuHeight="180px"
                          />
             </div>
            </div>
         </div>
       </div>
       <div className='row mt-3 mb-5' id="corporate_tbl">
       <MUIDataTable
                // title={<h5 style={{ textAlign: "left" }}>Team Leader / OH / Order value </h5>}
                data={data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  filter: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  // tableBodyMaxHeight: "50vh",
                  rowsPerPageOptions:[10,30,50,100],
                  // rowsPerPage: "",
                  sortOrder: {
                    name: 'InvoiceAmount',
                    direction: 'desc'
                  },
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available"
                    },
                  },
                  customTableBodyFooterRender: (
                      count,
                      page,
                      rowsPerPage,
                      changeRowsPerPage,
                      changePage,
                      data
                    ) => {
                      const startIndex = page * rowsPerPage;
                      const endIndex = (page + 1) * rowsPerPage;
                      return (
                        <TableFooter id={styles.table_footer}>
                          <TableRow>
                            <TableCell colSpan={4}></TableCell>
                            <TableCell id={styles.first_col}>
                              <p style={{textAlign:"center"}}>Grand Total (₹)</p>
                            </TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("count")}</p></TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total("value")}</p></TableCell>
                          
                          </TableRow>
                        </TableFooter>
                      );
                    },
                 
                }}
              />
       </div>
    </>
  )
}

export default Client_oh_expire
