import React from 'react'
import styles from "../Styles/Table.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";

function Tags_level_report_tbl({data}) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const get_total_sample = (param_val) => {
      if(param_val == "relevant"){
        let total_count = data?.reduce((acc, ele) => {
          return acc + Number(ele?.relevant);
        }, 0);
        return total_count;
      }
      else if(param_val == "proposal_sent"){
        let total_count = data?.reduce((acc, ele) => {
          return acc + Number(ele?.proposal_sent);
        }, 0);
        return total_count;
      }
      else if(param_val == "not_relevant"){
        let total_count = data?.reduce((acc, ele) => {
          return acc + Number(ele?.not_relevant);
        }, 0);
        return total_count;
      }
      else if(param_val == "interested"){
        let total_count = data?.reduce((acc, ele) => {
          return acc + Number(ele?.interested);
        }, 0);
        return total_count;
      }
      else if(param_val == "not_interested"){
        let total_count = data?.reduce((acc, ele) => {
          return acc + Number(ele?.not_interested);
        }, 0);
        return total_count;
      }
      else if(param_val == "converted"){
        let total_count = data?.reduce((acc, ele) => {
          return acc + Number(ele?.converted);
        }, 0);
        return total_count;
      }
      else{
        let total_count = data?.reduce((acc, ele) => {
          return acc + Number(ele?.grand_total);
        }, 0);
        return total_count;
      }

    };


  return (
    <>
          <Paper
        sx={{
          // width: index == 0 ? "40%" : "20%",
          width: "98%",
          padding: "0px",
          margin:"auto",
          mb:5
          // marginLeft:"5%"
          // height: "500px",
        }}
      >
        <TableContainer id="order_tbl">
            <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                backgroundColor: "#007cc3",
                color: "#444a4f !important",
                position: "sticky",
                top: 0,
                zIndex: "999",
              }}
            >
              <TableRow>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                  colspan={9}
                >
                  <h5
                    className="ml-2 mt-2"
                    style={{
                      color: "white",
                      textAlign: "right",
                      marginLeft: "1%",
                    }}
                  >
                    {" "}
                    Stages / Tags
                  </h5>
                </TableCell>
              
     
              </TableRow>

              <TableRow>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                Tags
              </h6></TableCell>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                SalesPerson
              </h6></TableCell>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                Relevant
              </h6></TableCell>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                Proposal Sent
              </h6></TableCell>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                Not Relevant
              </h6></TableCell>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                Interested
              </h6></TableCell>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                Not Interested
              </h6></TableCell>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                Converted
              </h6></TableCell>
                <TableCell
                  id={styles.th_unit1}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                >                <h6
                className="h6 mt-2"
                style={{ color: "white", textAlign: "center" }}
              >
                Grand Total
              </h6></TableCell>
              
              </TableRow>
            </TableHead>
            {data?.length == "0" ? (
              <TableBody>
                {" "}
                <TableRow>
                  <TableCell colSpan={11} className={styles.th_unit}>
                    {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                    <p style={{ textAlign: "center" }}>No Data Available</p>
                  </TableCell>
                  {/* </div> */}
                </TableRow>
              </TableBody>
            ) : (
              <TableBody id={styles.body_content}>
              {(rowsPerPage > 0
                ? data?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              )?.map((ele) => (
                <TableRow>
                  <TableCell
                    sx={{
                      border:
                        ele?.flag != "1" ? "1px solid white" : "",
                      borderLeft:
                        ele?.flag != "1"
                          ? "1px solid rgb(201, 201, 201);"
                          : "",
                      borderBottom:
                        ele?.flag == "1" ? "1px solid white" : "",
                      borderRight: ele?.flag == "1" ? "0px" : "",
                    }}
                    className={styles.th_unit}
                  >
                    <p
                      className="p mt-2"
                      style={{
                        textAlign: "left",
                        display: ele?.flag == "1" ? "block" : "none",
                      }}
                    >
                      {ele.tag}
                    </p>
                  </TableCell>
                  <TableCell className={styles.th_unit}>
                    <p
                      className="p mt-2"
                      style={{ textAlign: "center" }}
                    >
                      {ele.salesperson}
                    </p>
                  </TableCell>
                  <TableCell className={styles.th_unit}>
                    <p
                      className="p mt-2"
                      style={{ textAlign: "center" }}
                    >
                      {ele.relevant}
                    </p>
                  </TableCell>
                  <TableCell className={styles.th_unit}>
                    <p
                      className="p mt-2"
                      style={{ textAlign: "center" }}
                    >
                      {ele.proposal_sent}
                    </p>
                  </TableCell>
                  <TableCell className={styles.th_unit}>
                    <p
                      className="p mt-2"
                      style={{ textAlign: "center" }}
                    >
                      {ele.not_relevant}
                    </p>
                  </TableCell>
                  <TableCell className={styles.th_unit}>
                    <p
                      className="p mt-2"
                      style={{ textAlign: "center" }}
                    >
                      {ele.interested}
                    </p>
                  </TableCell>
                  <TableCell className={styles.th_unit}>
                    <p
                      className="p mt-2"
                      style={{ textAlign: "center" }}
                    >
                      {ele.not_interested}
                    </p>
                  </TableCell>
                  <TableCell className={styles.th_unit}>
                    <p
                      className="p mt-2"
                      style={{ textAlign: "center" }}
                    >
                      {ele.converted}
                    </p>
                  </TableCell>
                  <TableCell className={styles.th_unit}>
                    <p
                      className="p mt-2"
                      style={{ textAlign: "center" }}
                    >
                      {ele.grand_total}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            )}
            <TableFooter id={styles.table_footer} >
                      <TableRow sx={{height:"40px"}}>
                        <TableCell  colSpan={2} className={styles.th_unit}>
                          <h5 style={{ textAlign: "left", paddingLeft:"10px" }}>Grand Total (₹)</h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "center", paddingLeft:"10px" }}>
                            {get_total_sample("relevant")}
                          </h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "center", paddingLeft:"10px" }}>
                            {get_total_sample("proposal_sent")}
                          </h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "center", paddingLeft:"10px" }}>
                            {get_total_sample("not_relevant")}
                          </h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "center", paddingLeft:"10px" }}>
                            {get_total_sample("interested")}
                          </h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "center", paddingLeft:"10px" }}>
                            {get_total_sample("not_interested")}
                          </h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "center", paddingLeft:"10px" }}>
                            {get_total_sample("converted")}
                          </h5>
                        </TableCell>
                        <TableCell className={styles.th_unit}>
                          <h5 style={{ textAlign: "center", paddingLeft:"10px" }}>
                            {get_total_sample("grand_total")}
                          </h5>
                        </TableCell>
                      
                      </TableRow>
                    </TableFooter>
          </Table>
          

        </TableContainer>
        <TablePagination
          id={styles.footer_div}
          class="footer_div"
          sx={{ backgroundColor: "#007cc3", color: "white" }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

export default Tags_level_report_tbl