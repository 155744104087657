import React, { useEffect, useState } from "react";
import styles from "../Styles/Table.module.css";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import MiniDrawer from "../components/Minidrawer";
import Tags_leads_pieChart from "../components/Tags_leads_pieChart";
import Last_seven_leads_graphs from "../components/Last_seven_leads_graphs";
import Tags_level_report_tbl from "../components/Tags_level_report_tbl";
import report_loader from "../Media/ezgif.com-optimize.gif";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function Tags_level_report() {

  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id")

  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [selectedFromDate, setSelectedFromDate] = useState(new Date(y, m, 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date(y, m + 1, 0));

  const [leads_piechart, setLeads_Piechart] = useState([]);
  const [tags_tbl_data, setTags_Tbl_Data] = useState([]);
  const [leads_graph_data, setLeads_Graph_Data] = useState([]);
  const [graph_labels, setGraph_Labels] = useState([]);
  const [tags_list, setTags_List] = useState([]);

  const [selected_tag, setSelected_Tag] = useState("all");

  const [pie_load, setPie_Load] = useState(false);
  const [graph_load, setGraph_Load] = useState(false);
  const [tbl_load, setTbl_Load] = useState(false);
  const [tags_load, setTags_Load] = useState(false);

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      //   setParams("MTD");
      //   setRange_Flag(false);
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date(y, m + 1, 0));
    }
  };

  const get_tag_wise_leads = async () => {
    setPie_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/tag_wise_total_count`,
        {
          method: "POST",
          headers: { "Content-type": "Application/Json" },
          body: JSON.stringify({
            START_DATE: moment(selectedFromDate).format("YYYY-MM-DD"),
            END_DATE: moment(selectedToDate).format("YYYY-MM-DD"),
            TAG: selected_tag,
          }),
        }
      );
      let data = await res.json();
      setPie_Load(false);
      setLeads_Piechart(data?.Data);
    } catch (error) {
      setPie_Load(false);
    }
  };

  const get_leads_seven_days = async () => {
    setGraph_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/date_wise_total_count`,
        {
          method: "POST",
          headers: { "Content-type": "Application/Json" },
          body: JSON.stringify({
            TAG: selected_tag,
          }),
        }
      );
      let data = await res.json();
      setGraph_Load(false);
      setLeads_Graph_Data(data?.Data);
      setGraph_Labels(data?.name_of_tag);
    } catch (error) {
      setGraph_Load(false);
    }
  };

  const get_leads_tbl_data = async () => {
    setTbl_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/tag_stage_salesperson_wise_count`,
        {
          method: "POST",
          headers: { "Content-type": "Application/Json" },
          body: JSON.stringify({
            START_DATE: moment(selectedFromDate).format("YYYY-MM-DD"),
            END_DATE: moment(selectedToDate).format("YYYY-MM-DD"),
            TAG: selected_tag,
          }),
        }
      );
      let data = await res.json();
      setTbl_Load(false);
      setTags_Tbl_Data(data?.Data);
    } catch (error) {
      setTbl_Load(false);
    }
  };

  const get_tags = async () => {
    setTags_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/corporate_tag_list`,
        {
          method: "POST",
          headers: { "Content-type": "Application/Json" },
          body: JSON.stringify({
            START_DATE: moment(selectedFromDate).format("YYYY-MM-DD"),
            END_DATE: moment(selectedToDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setTags_Load(false);
      setTags_List(data?.Data);
    } catch (error) {
      setTags_Load(false);
    }
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_tag_wise_leads();
    get_leads_tbl_data();
    }
  }, [selectedFromDate, selected_tag]);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_leads_seven_days();
    }
  }, [selected_tag]);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_tags();
    }
  }, []);

  const customStyle = {
    height: "60px", // Adjust this value
    lineHeight: "50px", // Adjust this value
    marginTop: "10px",
  };

  return (
    <>
      <MiniDrawer />
      {tags_load ? (
        <div className="container-fluid pt-3">
          <img
            style={{ marginTop: "50px" }}
            src={report_loader}
            id={styles.graph_loader}
          />
        </div>
      ) : (
        <div className="container-fluid pt-3">
          <div className="row">
            <div className="col-8">
              <h4 style={{ textAlign: "left" }}>
                Tags Level Report (Monthly Digest){" "}
              </h4>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-5">
                  <Box sx={{ width: "170px", marginTop: "10px" }}>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tags
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tags"
                        value={selected_tag}
                        onChange={(e) => setSelected_Tag(e.target.value)}
                      >
                        <MenuItem value="all">All</MenuItem>
                        {tags_list?.map((ele) => (
                          <MenuItem value={ele.tag}>{ele.tag}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="col-7">
                  <DateRangePicker
                    // defaultValue={[new Date(), new Date()]}
                    format="dd MMM yyyy"
                    // style={{marginTop:"10px"}}
                    style={customStyle}
                    value={[selectedFromDate, selectedToDate]}
                    onChange={handleDateChange}
                    size="md"
                    placement="bottomEnd"
                    preventOverflow
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <div
                style={{
                  backgroundColor: "#007cc3",
                  padding: "7px",
                  color: "white",
                  margin: "0px 1vw 0px 0px",
                }}
              >
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "10vw",
                    fontWeight: "500",
                  }}
                >
                  Tags-wise Leads
                </h5>
              </div>
              <Tags_leads_pieChart
                pie_load={pie_load}
                pieData={leads_piechart}
              />
            </div>
            <div className="col-7">
              <div
                style={{
                  backgroundColor: "#007cc3",
                  padding: "7px",
                  color: "white",
                }}
              >
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "10vw",
                    fontWeight: "500",
                  }}
                >
                  Last 7 Days
                </h5>
              </div>
              {graph_load ? (
                <img src={report_loader} id={styles.graph_loader} />
              ) : (
                <Last_seven_leads_graphs
                  data={leads_graph_data}
                  labels={graph_labels}
                />
              )}
            </div>
          </div>
          <div
            className="row"
            style={{ marginTop: leads_piechart?.length > 0 ? "" : "70px" }}
          >
            {tbl_load ? (
              <img src={report_loader} id={styles.graph_loader} />
            ) : (
              <Tags_level_report_tbl data={tags_tbl_data} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Tags_level_report;
