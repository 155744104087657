import MUIDataTable from "mui-datatables";
import React from "react";
import styles from "../Styles/Table.module.css";
import { DatePicker, Space } from "antd";

function Weekly_client_tbl({
  data,
  dept,
  setDept,
  weekly_dates,
  handleMonth,
  selectedMonth,
}) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");

  const handleDepartment = (e) => {
    if (e.target.value != "") {
      setDept(e.target.value);
    }
  };

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "W1",
      label: ``,
      options: {
        filter: false,
        sort: false,
        setCellProps: (cellValue) => ({
          style: {
            fontSize: "16px",
          },
        }),
        customHeadLabelRender: () => {
          return (
            <div style={{ textAlign: "center" }}>
              <p>W1</p>
              <span>({weekly_dates[0] + " - " + weekly_dates[1]})</span>
            </div>
          );
        },
      },
    },
    {
      name: "W2",
      label: ``,
      options: {
        filter: false,
        sort: false,
        setCellProps: (cellValue) => ({
          style: {
            fontSize: "16px",
          },
        }),
        customHeadLabelRender: () => {
          return (
            <div style={{ textAlign: "center" }}>
              <p>W2</p>
              <span>({weekly_dates[2] + " - " + weekly_dates[3]})</span>
            </div>
          );
        },
      },
    },
    {
      name: "W3",
      label: ``,
      options: {
        filter: false,
        sort: false,
        setCellProps: (cellValue) => ({
          style: {
            fontSize: "16px",
          },
        }),
        customHeadLabelRender: () => {
          return (
            <div style={{ textAlign: "center" }}>
              <p>W3</p>
              <span>({weekly_dates[4] + " - " + weekly_dates[5]})</span>
            </div>
          );
        },
      },
    },
    {
      name: "W4",
      label: ``,
      options: {
        filter: false,
        sort: false,
        setCellProps: (cellValue) => ({
          style: {
            fontSize: "16px",
          },
        }),
        customHeadLabelRender: () => {
          return (
            <div style={{ textAlign: "center" }}>
              <p>W4</p>
              <span>({weekly_dates[6] + " - " + weekly_dates[7]})</span>
            </div>
          );
        },
      },
    },
  ];
  return (
    <div
    // id={theme =="light"? "light_tbl":"dark_tbl"}
    >
      <MUIDataTable
        title={<h5 style={{ textAlign: "left" }}>Weekly New Client Added</h5>}
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          viewColumns: false,
          filter: false,
          print: false,
          responsive: "standard",
          download: false,
          fixedHeader: true,
          tableBodyMaxHeight: "60vh",
          // rowsPerPageOptions:[28,50],
          rowsPerPage: "28",
          pagination: false,
          sortOrder: {
            name: "Q",
            direction: "desc",
          },
          setTableProps: () => {
            return {
              padding: 0,
            };
          },
          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
          customTableBodyFooterRender: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage,
            data
          ) => {
            const startIndex = page * rowsPerPage;
            const endIndex = (page + 1) * rowsPerPage;
            return (
              <></>
              // <TableFooter>
              //   <TableRow id={styles.table_footer}>
              //     <TableCell  id={styles.first_col}>
              //       <p >Total</p>
              //     </TableCell>

              //     <TableCell>
              //       <p >{get_total("M")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("M1")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("M2")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("M3")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("M4")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("M5")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("M6")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Q")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Q1")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Q2")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Q3")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Q4")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Q5")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Q6")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Y")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Y1")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Y2")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Y3")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Y4")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Y5")}</p>
              //     </TableCell>
              //     <TableCell>
              //       <p >{get_total("Y6")}</p>
              //     </TableCell>
              //   </TableRow>
              // </TableFooter>
            );
          },
          customToolbar: () => {
            return (
              <>
                {user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" ||
                user_id == "a07b4768-cbab-4269-829b-bfbb17722385" ||
                user_id == "dd95dec7-893c-45ec-a30f-7aacc506400c" ? (
                  <select
                    name=""
                    id={styles.dept_drop}
                    onChange={handleDepartment}
                    value={dept}
                    style={{borderRadius:"5px"}}
                  >
                    <option value="" selected>
                      Select Department
                    </option>
                    <option value="Corporate Sales">Sales - Corporate</option>
                    <option value="FnB Sales">Sales - Fnb</option>
                  </select>
                ) : (
                  <></>
                )}
                <Space direction="vertical">
                  <DatePicker
                    style={{
                      height: "38px",
                      marginLeft:
                        user_id == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" ||
                        user_id == "a07b4768-cbab-4269-829b-bfbb17722385" ||
                        user_id == "dd95dec7-893c-45ec-a30f-7aacc506400c"
                          ? "10px"
                          : "",
                    }}
                    onChange={handleMonth}
                    picker="month"
                    value={selectedMonth}
                    format="MMM-YYYY"
                  />
                </Space>
              </>
            );
          },
        }}
      />
    </div>
  );
}

export default Weekly_client_tbl;
