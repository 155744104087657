let initState ={
    loading:false,
    error: false,
    data:[]
}

export const dataStudioReducer = (state=initState,action)=>{

    switch(action.type){        default:{
        {return state}
    }

}
}