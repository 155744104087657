import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useState } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Select from 'react-select'
import { DatePicker, Space } from "antd";
import { Chip } from "@mui/material";
import { daily_invoice_report, get_client_list, get_invoice_monthly_data, get_user_list } from "../Latest_addition_redux/actions";
import Oh_pop_up from "../Modals/Oh_pop_up";

import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const { RangePicker } = DatePicker;

function Invoice_monthly_data() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id")
  let dept = storage.getItem("DEPT");
  let admin_param = storage.getItem("admin_param")

const [invoice_monthly_data,setInvoice_Monthly_Data] = useState([])
const [total_amt,setTotal_Amt] = useState(0)

const [client,setClient] = useState("all")
const [client_data,setClient_Data] = useState([]);
const [client_load,setClient_Load] = useState(false)
const dispatch = useDispatch();

const [salesperson_list,setSalesperson_List] = useState([]);
const [selected_salesperson,setSelected_salesperson] = useState("all")
const [sales_load,setSales_Load] = useState(false);

const formattedMonth = dayjs(new Date());
let new_month = moment(formattedMonth.$d).format("MM");
let new_year = moment(formattedMonth.$d).format("YYYY");
const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
const [send_month, setSend_month] = useState(new_month);
const [send_year, setSend_year] = useState(new_year);
  
const [oh_data,setOh_Data] = useState([]);

const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);


const [invoice_load,setInvoice_Load] = useState(false)

const get_salesperson_list = async()=>{
  setSales_Load(true)
  try {
    let res = await fetch(`https://reportapi.equinoxlab.com/get_unique_salesperson`,{
      method:"POST",
      headers:{"Content-Type":"Application/Json"},
      body: JSON.stringify({
       "dept_id": (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
      })
    });
    // let res = await fetch(`https://reportapi.equinoxlab.com/corporate_salesperson`);
    let data = await res.json();
    setSales_Load(false)
    setSalesperson_List(data?.Data)
  } catch (error) {
    setSales_Load(false)
  }
}

const get_client_list = async()=>{
  setClient_Load(true)
  try {
    let res = await fetch(`https://reportapi.equinoxlab.com/get_client_list_by_dept`,{
      method:"POST",
      headers:{"Content-Type":"Application/Json"},
      body:JSON.stringify({
           month_input: send_month,
           year_input: send_year,
       "dept_id": (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
      })
    });
    let data = await res.json();
    setClient_Load(false)
    setClient_Data(data?.data)
  } catch (error) {
    setClient_Data([])
    setClient_Load(false)
  }
}

const handleMonth = (date)=>{
  if(date){
    setSelectedMonth(date);
    let formattedMon = moment(date?.$d)?.format("MM");
    let formattedYear = moment(date?.$d)?.format("YYYY");
    setSend_year(formattedYear);
    setSend_month(formattedMon);
  }else{
    const formattedMonth = dayjs(new Date());
    let new_month = moment(formattedMonth.$d).format("MM");
    let new_year = moment(formattedMonth.$d).format("YYYY");
    setSelectedMonth(formattedMonth)
    setSend_month(new_month)
    setSend_year(new_year)
  }
}

// const handleDept = (e)=>{
//   setDepartment(e.target.value)
//    dispatch(get_user_list(setUser_Data,e.target.value))
//    dispatch(get_client_list(setClient_Data,e.target.value))
// }

// const handleSubmit = (e)=>{
//   e.preventDefault();

//     dispatch(get_invoice_monthly_data(setInvoice_Monthly_Data,invoice_start_date,invoice_end_date,user,department,client))
  
// }


function getText(data) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = data;
  return divContainer.textContent || divContainer.innerText || "";
}

useEffect(()=>{
 if(Role_id != "" && Role_id != null && Role_id != "null"){
  get_salesperson_list();
  get_client_list();
  }
},[])

useEffect(()=>{
 if(Role_id != "" && Role_id != null && Role_id != "null"){
  dispatch(get_invoice_monthly_data(setInvoice_Monthly_Data,send_month,send_year,selected_salesperson,client,setInvoice_Load,setTotal_Amt,dept))
  }
},[send_month,selected_salesperson,client,send_year])

const handleOH = (new_data)=>{
  setOh_Data(new_data);
  handleOpen();
}

  const columns = [
    {
      name: "invoice_date",
      label: "invoice date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "invoice_no",
      label: "invoice no",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "180px", maxWidth: "180px" },
        }),
      },
    },
    {
      name: "client_name",
      label: "client name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return<div style={{width:"250px",textAlign:"left",paddingLeft:"20px"}}>
            <p>{row}</p>
          </div>
        }
      },
    },
    {
      name: "sitename",
      label: "sitename",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return<div style={{width:"200px",textAlign:"left",paddingLeft:"20px"}}>
            <p>{row}</p>
          </div>
        }
      },
    },
    {
      name: "invoive_total",
      label: "invoice total (₹)",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "200px", maxWidth: "auto" },
        }),
         customBodyRender:(row)=>{
          return(
            <>{new Intl.NumberFormat("en-IN").format(row)}</>
          )
        }
      },
    },
    {
      name: "oh_number",
      label: "OH Number",
      options: {
        filter: false,
        sort: false,
        download: false,
        customHeadLabelRender: () => {
          return (
            <>
             <p style={{textAlign:"center"}}>OH Number</p>
            </>
          );
        },

        setCellProps: () => ({
          style: { width: "200px", maxWidth: "200px" },
        }),
        customBodyRender: (row, data) => {
          if (row == ""|| row == null) {
            return <>--</>;
          }
          let new_data = row?.split(",");

        if(new_data?.length>2){

          return <>
          <p onClick={()=>handleOH(new_data)} style={{color:"#007cc3",cursor:"pointer"}}>View</p>
          </>
        }

          return (
            <>
              {new_data?.map((ele) => (
                <>
                  <Chip
                    label={ele}
                    color="primary"
                    sx={{
                      marginLeft: "4px",
                      marginTop: "5px",
                      borderRadius: "7px",
                      height: "25px",
                    }}
                    variant="outlined"
                  />
                </>
              ))}
            </>
          );
        },
      },
    },
 
    {
      name: "due_date",
      label: "due date",
      options: {
        filter: false,
        sort: false,
      },
    },

  
    {
      name: "salesperson",
      label: "salesperson",
      options: {
        filter: false,
        sort: true,
      },
    },
  
    {
      name: "planner_name",
      label: "planner name",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "status",
      label: "invoice status",
      options: {
        filter: false,
        sort: false,
      },
    },
  


  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />
      {sales_load || invoice_load ? (
        <img src={report_loader} id={styles.loader} />
      ) : (
        <div
          style={{
            marginLeft: "2%",
            marginTop: "2%",
            marginBottom: "3%",
            width: "96%",
          }}
         id="corporate_tbl"
        >
          <MUIDataTable
          title={
            dept == "Tech" || dept == "Admin" || dept == "Management" ? (
              <h4 style={{ textAlign: "left" }}>
                Invoice Monthly Data ({" "}
                {admin_param == "1" ? "FnB" : "Corporate"} )
              </h4>
            ) : (
              <h4 style={{ textAlign: "left" }}>Invoice Monthly Data</h4>
            )
          }
            data={invoice_monthly_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              print: false,
              search: false,
              filter: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              tableBodyMaxHeight: "600px",
              rowsPerPageOptions: [10, 25, 50, 100],
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customTableBodyFooterRender: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                data
              ) => {
                const startIndex = page * rowsPerPage;
                const endIndex = (page + 1) * rowsPerPage;
                return (
                  <TableFooter id={styles.table_footer}>
                    <TableRow>
                    {/* <TableCell colSpan={4}></TableCell> */}
                      <TableCell colSpan={4} id={styles.first_col}>
                      </TableCell>
                      <TableCell><p style={{textAlign:"center"}}>Total (₹) : {new Intl.NumberFormat("en-IN").format(total_amt)}</p></TableCell>
                      <TableCell colSpan={5} id={styles.first_col}>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },
              customToolbar: () => {
                return (
                  <>
                    <div style={{ display: "inline-flex" }}>
                      <select
                        className="form-select"
                        id={styles.user_drop_invoice}
                        style={{fontSize:"14px",width:"250px",maxWidth:"auto",marginLeft:"0px"}}
                        onChange={(e)=>setSelected_salesperson(e.target.value)}
                        value={selected_salesperson}
                        required
                      >
                        <option value="all" selected>All salesperson</option>
                        {salesperson_list?.map((ele) => (
                          <option value={ele.salesperson}>{ele.salesperson}</option>
                        ))}
                      </select>

                        <select
                       className="form-select"
                        id={styles.user_drop_invoice}
                        style={{fontSize:"14px",width:"250px",maxWidth:"auto"}}
                        onChange={(e)=>setClient(e.target.value)}
                        value={client}
                        // style={{width:"20%"}}
                        required
                      >
                        <option value="all" style={{maxWidth:"20%"}} >All Client</option>
                        {client_data?.map((ele) => (
                          <option style={{maxWidth:"20%"}} value={ele.client_name}>{ele.client_name}</option>
                        ))}
                      </select>
                        {/* <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={client_data[0]?.client_name}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="client_name"
        options={client_data}
      /> */}
                       <Space direction="vertical">
                  <DatePicker
                    onChange={handleMonth}
                    picker="month"
                    value={selectedMonth}
                    format="MMM-YYYY"
                  />
                </Space>

                    </div>

                  </>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}
           <Oh_pop_up
             open={open}
             handleOpen={handleOpen}
             handleClose={handleClose}
             data={oh_data}
       />
    </>
  );
}


export default Invoice_monthly_data