import MUIDataTable from 'mui-datatables';
import React from 'react'
import moment from "moment";

function MainTable({data,date,setDate}) {
 
    const handleDate = (e)=>{
  //   let date_arr = e.target.value.split("-")
  //   setYear(date_arr[0])
  //  let new_val = moment(date_arr[1]).format("MMMM");
  //  setMonth(new_val)
  setDate(e.target.value)
    }

    const options = {
        filterType: "checkbox",
      };
      const columns = [
        {
          name: "Collected_Year",
          label: "Collected Year",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Collected_Month",
          label: "Collected Month",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Bangalore",
          label: "Bangalore",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Bangalore-Revenue",
          label: "Bangalore-Revenue",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Chennai",
          label: "Chennai",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Chennai-Revenue",
          label: "Chennai-Revenue",
          options: {
            filter: false,
            sort: false,
          },
        },
      
        {
          name: "Hyderabad",
          label: "Hyderabad",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Hyderabad - Revenue",
          label: "Hyderabad - Revenue",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Kolkata",
          label: "Kolkata",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Kolkata-Revenue",
          label: "Kolkata-Revenue",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Mumbai",
          label: "Mumbai",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Mumbai - Revenue",
          label: "Mumbai - Revenue",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Noida",
          label: "Noida",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "Noida-Revenue",
          label: "Noida-Revenue",
          options: {
            filter: false,
            sort: false,
          },
        }
      
      ];
  return (
    <>
    <div >
        
        <MUIDataTable
          title={<h4 style={{ textAlign: "left" }}>MRIS</h4>}
          data={data}
          columns={columns}
          options={{
            options: options,
            selectableRows: "none",
            viewColumns: false,
            print: false,
            responsive: "standard",
            download: false,
            fixedHeader: true,
            tableBodyMaxHeight: "620px",
            setTableProps: () => {
              return {
                padding: "default",
              };
            },
            customToolbar: () => {
              return (
              <input type='month' onChange={handleDate} value={date}/>
              );
            },
            textLabels: {
              body: {
                noMatch: "Data Not Available",
              },
            },
          }}
        />
    </div>
    </>
  )
}

export default MainTable