import React from 'react'
import Sales_leaderboard from '../pages/Sales_leaderboard'

function Sales_leaderboard_fnb() {
  return (
    <>
      <Sales_leaderboard/>
    </>
  )
}

export default Sales_leaderboard_fnb
