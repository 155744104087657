import React from 'react'
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";


function Total_leads_tbl({header_name,data}) {
    const get_total =(param)=>{
      if(param == "total"){
        let total_count = data?.reduce((acc,ele)=>{
           return acc+Number(ele?.total_lead)
        },0)
        return total_count;

      }
     else if(param == "converted"){
        let total_count = data?.reduce((acc,ele)=>{
           return acc+Number(ele?.converted)
        },0)
        return total_count;
      }
      else{
        let total_count = data?.reduce((acc,ele)=>{
           return acc+Number(ele?.cr)
        },0)
        if(total_count == 0){
          return total_count;
        }else{
          return total_count+"%";
        }
      }
  }

    const options = {
        filterType: "checkbox",
      };
      const columns = [
    
        {
          name: "lead_owner",
          label: `Lead Owner`,
          options: {
            filter: false,
            sort: false,
            customHeadLabelRender: () => {
              return (
                  <p style={{marginLeft:"10px"}}>Lead Owner</p>
              );
            },
            customBodyRender:(row)=>{
              return(
                <div style={{textAlign:"left"}}>
                  <p>{row}</p>
                </div>
              )
            }
          },
        },
        {
          name: "total_lead",
          label: "Total",
          options: {
            filter: false,
            sort: true,
          }
        },
        {
          name: "converted",
          label: "Converted",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "cr",
          label: "CR",
          options: {
            filter: false,
            sort: true,
           customBodyRender:(row)=>{
            if(row == 0 || row == null || row == undefined){
              return(
                <>
                {row}
                </>
              )
            }else{
              return(
                <>
                {row}%
                </>
              )
            }
    
           }
          },
        },
       
      
  
      ];
  return (
    <div id="corporate_tbl">
    <MUIDataTable
         title={<h5 style={{ textAlign: "left" }}>{header_name}</h5>}
         data={data}
         columns={columns}
         options={{
           options: options,
           selectableRows: "none",
           viewColumns: false,
           print: false,
           responsive: "standard",
           download: false,
           fixedHeader: true,
           // tableBodyMaxHeight: "50vh",
           rowsPerPageOptions:[10,25,50,100],
           filter:false,
           setTableProps: () => {
             return {
               padding: 0,
             };
           },
           textLabels: {
             body: {
               noMatch: "Data Not Available"
             },
           },
           customTableBodyFooterRender: (
               count,
               page,
               rowsPerPage,
               changeRowsPerPage,
               changePage,
               data
             ) => {
               const startIndex = page * rowsPerPage;
               const endIndex = (page + 1) * rowsPerPage;
               return (
                 <TableFooter id={styles.table_footer}>
                   <TableRow>
                     <TableCell id={styles.first_col}>
                       <p style={{textAlign:"left",marginLeft:"10px"}}>Grand Total</p>
                     </TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("total")}</p></TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("converted")}</p></TableCell>
                     <TableCell><p style={{textAlign:"center"}}>{get_total("cr")}</p></TableCell>
                   
                   </TableRow>
                 </TableFooter>
               );
             },
          
         }}
       />
</div>
  )
}

export default Total_leads_tbl