import { TRANSECTION_DATA_ERROR, TRANSECTION_DATA_REQUEST, TRANSECTION_DATA_SUCCESS } from "../Redux/actionTypes"
import { GET_DAILY_ERROR, GET_DAILY_REQUEST, GET_DAILY_SUCCESS, GET_INVOICE_S6_ERROR, GET_INVOICE_S6_REQUEST, GET_INVOICE_S6_SUCCESS, GET_MONTHLY_DATA_ERROR, GET_MONTHLY_DATA_REQUEST, GET_MONTHLY_DATA_SUCCESS, GET_MONTHLY_ERROR, GET_MONTHLY_REQUEST, GET_MONTHLY_SUCCESS, GET_USER_LIST_ERROR, GET_USER_LIST_REQUEST, GET_USER_LIST_SUCCESS, MARKETING_PERFORMANCE_ERROR, MARKETING_PERFORMANCE_REQUEST, MARKETING_PERFORMANCE_SUCCESS } from "./actionTypes"

let initState ={
    loading:false,
    error: false,
    data:[]
}

export const latest_dataReducer = (state=initState,action)=>{

    switch(action.type){
        case TRANSECTION_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case TRANSECTION_DATA_ERROR:{ 
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case TRANSECTION_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case GET_DAILY_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case GET_DAILY_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case GET_DAILY_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case GET_MONTHLY_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case GET_MONTHLY_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case GET_MONTHLY_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case GET_MONTHLY_DATA_REQUEST:{
            return{
                ...state,
                loading:true,
                error:false,
                data:[]
            }
        }
        case GET_MONTHLY_DATA_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case GET_MONTHLY_DATA_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        case GET_USER_LIST_REQUEST:{
            return{
                ...state,
                loading:false,
                error:false,
                data:[]
            }
        }
        case GET_USER_LIST_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case GET_USER_LIST_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        
        case GET_INVOICE_S6_REQUEST:{
            return{
                ...state,
                loading:false,
                error:false,
                data:[]
            }
        }
        case GET_INVOICE_S6_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case GET_INVOICE_S6_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        
        case MARKETING_PERFORMANCE_REQUEST:{
            return{
                ...state,
                loading:false,
                error:false,
                data:[]
            }
        }
        case MARKETING_PERFORMANCE_ERROR:{
            return{
                ...state,
                loading: false,
                error: true,
                data:[]
            }
        }
        case MARKETING_PERFORMANCE_SUCCESS: {
            return{
                ...state,
                loading:false,
                error:false,
                data: action.payload
            }
        }
        default:{
            {return state}
        }

    }
}