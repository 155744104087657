import logo from './logo.svg';
import './App.css';
import Allroutes from './Routes/Allroutes';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useTheme } from './ThemeContext';
import ThemeSwitcher from '../src/components/ThemeSwitcher';

function App() {
  const storage = window.sessionStorage;
  const navigate = useNavigate();
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");

    useEffect(() => {
    if (USER_GUID == "" || Role_id == "" || DEPT_ID == "") {
      navigate("/");
    }
  }, [storage]);

  const { theme } = useTheme();

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div className="App" style={{marginTop:"4em",marginLeft:"4em"}}>
            {/* <ThemeSwitcher /> */}
      <Allroutes/>
    </div>
  );
}

export default App;


