import React from "react";
import styles from "../Styles/Table.module.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Chip } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  zIndex: "9999",
};

function Oh_pop_up({   open,
  handleOpen,
  handleClose,
data
}) {



  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <div className="modal-content">
            <div className="modal-header" style={{ marginBottom: "20px" }}>
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                style={{ color: "black" }}
              >
                OH Numbers
              </h1>
              <CloseIcon id={styles.close_icon} onClick={handleClose} />
            </div>

            <div className="modal-body" id={styles.oh_pop_up}>
          {data?.map((ele,index)=>( 
               <Chip
               label={ele}
               color="primary"
               sx={{
                 marginLeft: "10px",
                 marginTop: "10px",
                 borderRadius: "7px",
                 height: "25px",
               }}
               variant="outlined"
             />
            ))}
           
            </div>
       
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Oh_pop_up;
