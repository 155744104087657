import React, { useState, useEffect, useRef, useCallback } from "react";
// import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import moment from "moment";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import styles from "../Styles/Sales_analysis.module.css";
import no_data_icon from "../Media/no_data_icon.gif";
import report_loader from "../Media/ezgif.com-optimize.gif";

// import { PieChart } from "@mui/x-charts/PieChart";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";

function Sales_pieChart_year({
    pie_load,
    pieData,
    pie_dept,
    setPie_Dept,
    quater_year,
    setQuater_Year,
    department,
    count_flag
}) {

  const windowHeight = useRef(window.innerHeight);
  const [activeIndex, setActiveIndex] = useState(1);

  let dataArray = []
  pieData.forEach((ele, ind) => {
    dataArray.push({
      id: ind,
      value: Number(ele.non_testing),
      name: "Non-Testing",
    });
    dataArray.push({
      id: ind,
      value: Number(ele.testing),
      name: "Testing",
    });
  });

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        {count_flag?<text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{value}</text>: <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >₹ {`${new Intl.NumberFormat("en-IN").format(value)}`}</text>}
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const COLORS = [
    "#776B5D",
    "#007cc3",       
    // "#776B5D",     
  ]





  return (
    <>
    {pie_load ? (
      <img src={report_loader} id={styles.loader_pie} />
    ) : (
      <div className="container-fluid">
        {" "}
 
        <div className="row">
        <div className="col-md-3"></div>
        {department == "Tech" ||
            department == "Admin" ||
            department == "Management" ? <></>:  <div className="col-md-4"></div>}
           <div className="col-md-4" style={{textAlign:"left"}}>
            <span id={styles.dropdown_span}>Select Year</span>
           <select
            className="form-select"
            name=""
            id=""
            value={quater_year}
           onChange={(e)=>setQuater_Year(e.target.value)}
          >
            <option value="" disabled>
              Select Year
            </option>
            <option value="2015-2016">2015-16</option>
            <option value="2016-2017">2016-17</option>
            <option value="2017-2018">2017-18</option>
            <option value="2018-2019">2018-19</option>
            <option value="2019-2020">2019-20</option>
            <option value="2020-2021">2020-21</option>
            <option value="2021-2022">2021-22</option>
            <option value="2022-2023">2022-23</option>
            <option value="2023-2024">2023-24</option>
            <option value="2024-2025">2024-25</option>
          </select>

           </div>
           {department == "Tech" ||
            department == "Admin" ||
            department == "Management" ?   <div className="col-md-5" style={{textAlign:"left"}}>
           <span id={styles.dropdown_span}>Select Department</span>
           <select
            name=""
            id=""
            value={pie_dept}
            onChange={(e) => setPie_Dept(e.target.value)}
            className="form-select"
            // style={{ width: "39%" }}
          >
                 <option value="" disabled selected>Select Department</option>
            <option value="ALL">All</option>
            <option value="1">Fnb-Sales</option>
              <option value="2">Corporate-Sales</option>
          </select>
           </div>:<></>}
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "50px",
          }}
        >

                  <select
            style={{ width: "27%",marginRight:"5%" }}
            className="form-select"
            name=""
            id=""
            value={quater_year}
           onChange={(e)=>setQuater_Year(e.target.value)}
          >
            <option value="" disabled>
              Select Year
            </option>
            <option value="2015-2016">2015-16</option>
            <option value="2016-2017">2016-17</option>
            <option value="2017-2018">2017-18</option>
            <option value="2018-2019">2018-19</option>
            <option value="2019-2020">2019-20</option>
            <option value="2020-2021">2020-21</option>
            <option value="2021-2022">2021-22</option>
            <option value="2022-2023">2022-23</option>
            <option value="2023-2024">2023-24</option>
          </select>

    

          <select
            name=""
            id=""
            value={pie_dept}
            onChange={(e) => setPie_Dept(e.target.value)}
            className="form-select"
            style={{ width: "39%" }}
          >
                 <option value="" disabled selected>Select Department</option>
            <option value="ALL">All</option>
            <option value="1">Fnb-Sales</option>
              <option value="2">Corporate-Sales</option>
          </select>
        </div> */}
        {(pieData?.length == 0 || (pieData[0]?.non_testing ==0  && pieData[0]?.testing == 0)) ? (
       <>
       {" "}
       <div className={styles.noData}>
         <img
           src={no_data_icon}
           alt=""
           style={{ height: "200px", width: "200px" }}
         />
         <h4>No Data Added</h4>
       </div>
     </>
          // <PieChart
          //   series={[
          //     {
          //       data: [
          //         {
          //           id: 0,
          //           value: Number(pieData[0]?.testing),
          //           label: "Testing",
          //           color: "#C70039",
          //         },
          //         {
          //           id: 1,
          //           value: Number(pieData[0]?.non_testing),
          //           label: "Non-testing",
          //           color: "#C683D7",
          //         },
          //       ],
          //       innerRadius: 55,
          //       outerRadius: 100,
          //       cornerRadius: 5,
          //       cx: 150,
          //       cy: 150,
          //     },
          //   ]}
          //   width={400}
          //   height={400}
          // />
        ) : (
          <>
          <div 
          // style={{marginLeft:windowHeight?.current<800?"7vw":"12vw" }}
           >
           <PieChart width={windowHeight?.current>950?750:510} height={windowHeight?.current>950?580:380} >
           {/* <PieChart width={510} height={380} > */}
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={dataArray}
            // cx={250}
            // cy={120}
            // innerRadius={50}
            // outerRadius={90}
            cx={250}
            cy={windowHeight?.current>950?350:150}
            innerRadius={windowHeight?.current>950?120:50}
            outerRadius={windowHeight?.current>950?200:90}
            fill="#007cc3"
            dataKey="value"
            onMouseEnter={onPieEnter}
            paddingAngle={1}
          >
              {pieData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
          </Pie>
        </PieChart>
        </div>
        {/* <p className={styles.pieCaption}>Service Type Amount for year {}</p> */}
          </>
         
        )}
      </div>
    )}
  </>
  )
}

export default Sales_pieChart_year