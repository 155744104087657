import React, { useEffect, useState } from "react";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import styles from "../Styles/Table.module.css";
import MiniDrawer from "../components/Minidrawer";
import MUIDataTable from "mui-datatables";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import report_loader from "../Media/ezgif.com-optimize.gif";

function Overdue_leads_sales() {

  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id")

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [selected_salesperson, setSelected_salesperson] = useState("all");

  const [salesperson_list, setSalesperson_List] = useState([]);
  const [overdue_count_data, setOverdue_Count_Data] = useState([]);
  const [overall_count_data, setOverall_Count_Data] = useState([]);

  const [count_load, setCount_Load] = useState(false);
  const [tbl_load, setTbl_Load] = useState(false);
  const [salesperson_load, setSalesperson_Load] = useState(false);

  let data = [];

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      //   setParams("MTD");
      //   setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      const fromattedDate = dayjs(new Date());
      let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
    }
  };

  const get_total = (param_val) => {
    if (param_val == "overdue_4") {
      let total_count = overdue_count_data?.reduce((acc, ele) => {
        return acc + Number(ele?.over_due_more_than_4_day);
      }, 0);
      return total_count;
    } else if (param_val == "overdue_3") {
      let total_count = overdue_count_data?.reduce((acc, ele) => {
        return acc + Number(ele?.over_due_by_3_day);
      }, 0);
      return total_count;
    } else if (param_val == "overdue_2") {
      let total_count = overdue_count_data?.reduce((acc, ele) => {
        return acc + Number(ele?.over_due_by_2_day);
      }, 0);
      return total_count;
    } else if (param_val == "overdue_1") {
      let total_count = overdue_count_data?.reduce((acc, ele) => {
        return acc + Number(ele?.over_due_by_1_day);
      }, 0);
      return total_count;
    } else if (param_val == "due_today") {
      let total_count = overdue_count_data?.reduce((acc, ele) => {
        return acc + Number(ele?.due_today);
      }, 0);
      return total_count;
    } else {
      let total_count = overdue_count_data?.reduce((acc, ele) => {
        return acc + Number(ele?.grand_total);
      }, 0);
      return total_count;
    }
  };

  const get_salesperson = async () => {
    setSalesperson_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/atlas_corporate_salesperson`
      );
      let data = await res.json();
      setSalesperson_Load(false);
      setSalesperson_List(data?.Data);
    } catch (error) {
      setSalesperson_Load(false);
    }
  };

  const get_overdue_count = async () => {
    setTbl_Load(false);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/due_count_by_salesperson`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            salesperson: selected_salesperson,
          }),
        }
      );
      let data = await res.json();
      setTbl_Load(false);
      setOverdue_Count_Data(data?.Data);
    } catch (error) {
      setTbl_Load(false);
    }
  };

  const get_overall_count = async () => {
    setCount_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/overall_count_leads`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            salesperson: selected_salesperson,
          }),
        }
      );
      let data = await res.json();
      setCount_Load(false);
      setOverall_Count_Data(data?.Data);
    } catch (error) {
      setCount_Load(false);
    }
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_salesperson();
    }
  }, []);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_overdue_count();
    get_overall_count();
    }
  }, [selected_salesperson]);

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "salesperson",
      label: `Sales Person`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "over_due_more_than_4_day",
      label: "Overdue more than 4 days",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "over_due_by_3_day",
      label: "Overdue more than 3 days",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },

    {
      name: "over_due_by_2_day",
      label: "Overdue by 2 days",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "over_due_by_1_day",
      label: "Overdue by 1",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "due_today",
      label: "Due Today",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "grand_total",
      label: "Grand Total",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ];
  return (
    <>
      <MiniDrawer />
      {salesperson_load || count_load || tbl_load ? (
        <div className="container-fluid pt-3">
          <img src={report_loader} id={styles.purchase_loader} />
        </div>
      ) : (
        <div className="container-fluid pt-3">
          <div className="row">
            <div className="col-10">
              <h4 style={{ textAlign: "left" }}>
                Over Due Leads Sales Person - (Monthly Digest)
              </h4>
            </div>
            <div className="col-2">
              <Box sx={{ width: "100%", marginTop: "10px" }}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Sales Person
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Salesperson"
                    value={selected_salesperson}
                    onChange={(e) => setSelected_salesperson(e.target.value)}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {salesperson_list?.map((ele) => (
                      <MenuItem value={ele.salesperson}>
                        {ele.salesperson}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {/* <DateRangePicker
              // defaultValue={[new Date(), new Date()]}
              format="dd MMM yyyy"
              value={[selectedFromDate, selectedToDate]}
              onChange={handleDateChange}
              size="md"
              placement="bottomEnd"
              preventOverflow
            /> */}
            </div>
          </div>
          <div id={styles.overdue_tabsContainer}>
            <div>
              <p>Total Leads</p>
              {overall_count_data[0]?.total_leads == undefined ||
              overall_count_data[0]?.total_leads == null ? (
                <h6>0</h6>
              ) : overall_count_data[0]?.total_leads.toString()?.length > 5 ? (
                <marquee
                  id={styles.overdue_count_moving}
                  style={{ marginTop: "-7px" }}
                  scrollamount="2"
                >
                  {overall_count_data[0]?.total_leads}
                </marquee>
              ) : (
                <h6>{overall_count_data[0]?.total_leads}</h6>
              )}
            </div>
            <div>
              <p>Callback</p>
              {overall_count_data[0]?.callback == undefined ||
              overall_count_data[0]?.callback == null ? (
                <h6>0</h6>
              ) : overall_count_data[0]?.callback.toString()?.length > 5 ? (
                <marquee
                  id={styles.overdue_count_moving}
                  style={{ marginTop: "-7px" }}
                  scrollamount="2"
                >
                  {overall_count_data[0]?.callback}
                </marquee>
              ) : (
                <h6>{overall_count_data[0]?.callback}</h6>
              )}
            </div>
            <div>
              {" "}
              <p>Follow-up done</p>
              {overall_count_data[0]?.follow_up_done == undefined ||
              overall_count_data[0]?.follow_up_done == null ? (
                <h6>0</h6>
              ) : overall_count_data[0]?.follow_up_done.toString()?.length >
                5 ? (
                <marquee
                  id={styles.overdue_count_moving}
                  style={{ marginTop: "-7px" }}
                  scrollamount="2"
                >
                  {overall_count_data[0]?.follow_up_done}
                </marquee>
              ) : (
                <h6>{overall_count_data[0]?.follow_up_done}</h6>
              )}
            </div>
            <div>
              {" "}
              <p>Meeting Scheduled</p>
              {overall_count_data[0]?.meeting_scheduled == undefined ||
              overall_count_data[0]?.meeting_scheduled == null ? (
                <h6>0</h6>
              ) : overall_count_data[0]?.meeting_scheduled.toString()?.length >
                5 ? (
                <marquee
                  id={styles.overdue_count_moving}
                  style={{ marginTop: "-7px" }}
                  scrollamount="2"
                >
                  {overall_count_data[0]?.meeting_scheduled}
                </marquee>
              ) : (
                <h6>{overall_count_data[0]?.meeting_scheduled}</h6>
              )}
            </div>
            <div>
              {" "}
              <p>Ringing</p>
              {overall_count_data[0]?.ringing == undefined ||
              overall_count_data[0]?.ringing == null ? (
                <h6>0</h6>
              ) : overall_count_data[0]?.ringing.toString()?.length > 5 ? (
                <marquee
                  id={styles.overdue_count_moving}
                  style={{ marginTop: "-7px" }}
                  scrollamount="2"
                >
                  {overall_count_data[0]?.ringing}
                </marquee>
              ) : (
                <h6>{overall_count_data[0]?.ringing}</h6>
              )}
            </div>
            <div>
              {" "}
              <p>Discussion Done</p>
              {overall_count_data[0]?.discussion_done == undefined ||
              overall_count_data[0]?.discussion_done == null ? (
                <h6>0</h6>
              ) : overall_count_data[0]?.discussion_done.toString()?.length >
                5 ? (
                <marquee
                  id={styles.overdue_count_moving}
                  style={{ marginTop: "-7px" }}
                  scrollamount="2"
                >
                  {overall_count_data[0]?.discussion_done}
                </marquee>
              ) : (
                <h6>{overall_count_data[0]?.discussion_done}</h6>
              )}
            </div>
            <div>
              {" "}
              <p>Proposal Sent</p>
              {overall_count_data[0]?.proposal_sent == undefined ||
              overall_count_data[0]?.proposal_sent == null ? (
                <h6>0</h6>
              ) : overall_count_data[0]?.proposal_sent.toString()?.length >
                5 ? (
                <marquee
                  id={styles.overdue_count_moving}
                  style={{ marginTop: "-7px" }}
                  scrollamount="2"
                >
                  {overall_count_data[0]?.proposal_sent}
                </marquee>
              ) : (
                <h6>{overall_count_data[0]?.proposal_sent}</h6>
              )}
            </div>
            <div>
              {" "}
              <p>Proposal Converted</p>
              {overall_count_data[0]?.proposal_converted == undefined ||
              overall_count_data[0]?.proposal_converted == null ? (
                <h6>0</h6>
              ) : overall_count_data[0]?.proposal_converted.toString()?.length >
                5 ? (
                <marquee
                  id={styles.overdue_count_moving}
                  style={{ marginTop: "-7px" }}
                  scrollamount="2"
                >
                  {overall_count_data[0]?.proposal_converted}
                </marquee>
              ) : (
                <h6>{overall_count_data[0]?.proposal_converted}</h6>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div id="corporate_tbl" style={{ marginBottom: "40px" }}>
              <MUIDataTable
                title={
                  <h5 style={{ textAlign: "left" }}>
                    Overdue by / Record Count
                  </h5>
                }
                data={overdue_count_data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  // tableBodyMaxHeight: "50vh",
                  rowsPerPageOptions: [10, 25, 50, 100],
                  filter: false,
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available",
                    },
                  },
                  customTableBodyFooterRender: (
                    count,
                    page,
                    rowsPerPage,
                    changeRowsPerPage,
                    changePage,
                    data
                  ) => {
                    const startIndex = page * rowsPerPage;
                    const endIndex = (page + 1) * rowsPerPage;
                    return (
                      <TableFooter id={styles.table_footer}>
                        <TableRow>
                          <TableCell id={styles.first_col}>
                            <p style={{ textAlign: "left" }}>Grand Total (₹)</p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("overdue_4")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("overdue_3")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("overdue_2")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("overdue_1")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("due_today")}
                            </p>
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: "center" }}>
                              {get_total("grand_total")}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Overdue_leads_sales;
