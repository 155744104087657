import React from 'react'
import Corporate_client_oh_dashboard from '../pages/Corporate_client_oh_dashboard'

function Fnb_client_oh_dashboard() {
  return (
    <>
      <Corporate_client_oh_dashboard/>
    </>
  )
}

export default Fnb_client_oh_dashboard
