import Swal from "sweetalert2";
import moment from "moment";
import {
  GET_ASSIGNED_MARKETING_DATA_ERROR,
  GET_ASSIGNED_MARKETING_DATA_REQUEST,
  GET_ASSIGNED_MARKETING_DATA_SUCCESS,
  GET_CORPORATE_EXP_DETAILS_ERROR,
  GET_CORPORATE_EXP_DETAILS_REQUEST,
  GET_CORPORATE_EXP_DETAILS_SUCCESS,
  GET_MARKETING_LEADS_CREATED_ERROR,
  GET_MARKETING_LEADS_CREATED_REQUEST,
  GET_MARKETING_LEADS_CREATED_SUCCESS,
  GET_MARKETING_LEADS_UPDATED_ERROR,
  GET_MARKETING_LEADS_UPDATED_REQUEST,
  GET_MARKETING_LEADS_UPDATED_SUCCESS,
  GET_OH_COUNT_GRAPH_DATA_ERROR,
  GET_OH_COUNT_GRAPH_DATA_REQUEST,
  GET_OH_COUNT_GRAPH_DATA_SUCCESS,
  GET_OH_REPORT_DATA_ERROR,
  GET_OH_REPORT_DATA_REQUEST,
  GET_OH_REPORT_DATA_SUCCESS,
  GET_OH_REPORT_LIST_ERROR,
  GET_OH_REPORT_LIST_REQUEST,
  GET_OH_REPORT_LIST_SUCCESS,
  GET_RELEVANT_MARKETING_DATA_ERROR,
  GET_RELEVANT_MARKETING_DATA_REQUEST,
  GET_RELEVANT_MARKETING_DATA_SUCCESS,
  GET_SALESPERSON_OH_ERROR,
  GET_SALESPERSON_OH_REQUEST,
  GET_SALESPERSON_OH_SUCCESS,
} from "./actionTypes";

let base_url = "https://reportapi.equinoxlab.com";

//  get salesperson oh report

export const get_salesperson_oh_req = () => ({
  type: GET_SALESPERSON_OH_REQUEST,
});
export const get_salesperson_oh_succ = (data) => ({
  type: GET_SALESPERSON_OH_SUCCESS,
  payload: data,
});
export const get_salesperson_oh_err = () => ({
  type: GET_SALESPERSON_OH_ERROR,
});

export const get_salesperson_oh_data =
  (
    setLoad,
    setData,
    ohFromDate,
    ohToDate,
    show_Salesperson,
    selected_company,
    selected_business,
    dept,
    admin_param
  ) =>
  async (dispatch) => {
    let send_salesperson = [];
    for (let i = 0; i < show_Salesperson?.length; i++) {
      send_salesperson.push(show_Salesperson[i]?.value);
    }

    let send_company = [];
    for (let i = 0; i < selected_company?.length; i++) {
      send_company.push(selected_company[i]?.value);
    }

    setLoad(true);
    try {
      dispatch(get_salesperson_oh_req());
      let res = await fetch(
        `${base_url}/client_report_by_salesperson`,
        // `${base_url}/corporate_client_report_by_salesperson`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            start_date: moment(ohFromDate).format("YYYY-MM-DD"),
            end_date: moment(ohToDate).format("YYYY-MM-DD"),
            salesperson:
              show_Salesperson?.length == 0
                ? "all"
                : send_salesperson?.toString(),
            company_name:
              selected_company?.length == 0 ? "all" : send_company?.toString(),
            business_type: selected_business,
             dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_salesperson_oh_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_salesperson_oh_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_salesperson_oh_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  get client report by company

export const get_client_report_company_req = () => ({
  type: GET_SALESPERSON_OH_REQUEST,
});
export const get_client_report_company_succ = (data) => ({
  type: GET_SALESPERSON_OH_SUCCESS,
  payload: data,
});
export const get_client_report_company_err = () => ({
  type: GET_SALESPERSON_OH_ERROR,
});

export const get_client_report_company_data =
  (
    setLoad,
    setData,
    ohFromDate,
    ohToDate,
    show_Salesperson,
    selected_company,
    selected_business,
    dept,
    admin_param
  ) =>
  async (dispatch) => {
    let send_salesperson = [];
    for (let i = 0; i < show_Salesperson?.length; i++) {
      send_salesperson.push(show_Salesperson[i]?.value);
    }

    let send_company = [];
    for (let i = 0; i < selected_company?.length; i++) {
      send_company.push(selected_company[i]?.value);
    }

    setLoad(true);
    try {
      dispatch(get_client_report_company_req());
      let res = await fetch(`${base_url}/client_report_by_company_deptwise`, {
      // let res = await fetch(`${base_url}/corporate_client_report_by_company`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          start_date: moment(ohFromDate).format("YYYY-MM-DD"),
          end_date: moment(ohToDate).format("YYYY-MM-DD"),
          salesperson:
            show_Salesperson?.length == 0
              ? "all"
              : send_salesperson?.toString(),
          company_name:
            selected_company?.length == 0 ? "all" : send_company?.toString(),
          business_type: selected_business,
           dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_client_report_company_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_client_report_company_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_client_report_company_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  get client report by Team Lead

export const get_client_report_teamLead_req = () => ({
  type: GET_SALESPERSON_OH_REQUEST,
});
export const get_client_report_teamLead_succ = (data) => ({
  type: GET_SALESPERSON_OH_SUCCESS,
  payload: data,
});
export const get_client_report_teamLead_err = () => ({
  type: GET_SALESPERSON_OH_ERROR,
});

export const get_client_report_teamLead_data =
  (
    setLoad,
    setData,
    ohFromDate,
    ohToDate,
    show_Salesperson,
    selected_company,
    selected_business,
    dept,
    admin_param
  ) =>
  async (dispatch) => {
    let send_salesperson = [];
    for (let i = 0; i < show_Salesperson?.length; i++) {
      send_salesperson.push(show_Salesperson[i]?.value);
    }

    let send_company = [];
    for (let i = 0; i < selected_company?.length; i++) {
      send_company.push(selected_company[i]?.value);
    }

    setLoad(true);
    try {
      dispatch(get_client_report_teamLead_req());
      let res = await fetch(`${base_url}/client_report_by_dept_teamlead`, {
      // let res = await fetch(`${base_url}/corporate_client_report_by_teamlead`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          start_date: moment(ohFromDate).format("YYYY-MM-DD"),
          end_date: moment(ohToDate).format("YYYY-MM-DD"),
          salesperson:
            show_Salesperson?.length == 0
              ? "all"
              : send_salesperson?.toString(),
          company_name:
            selected_company?.length == 0 ? "all" : send_company?.toString(),
          business_type: selected_business,
          dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_client_report_teamLead_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_client_report_teamLead_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_client_report_teamLead_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  get client report by Business Type

export const get_client_report_businessType_req = () => ({
  type: GET_SALESPERSON_OH_REQUEST,
});
export const get_client_report_businessType_succ = (data) => ({
  type: GET_SALESPERSON_OH_SUCCESS,
  payload: data,
});
export const get_client_report_businessType_err = () => ({
  type: GET_SALESPERSON_OH_ERROR,
});

export const get_client_report_businessType_data =
  (
    setLoad,
    setData,
    ohFromDate,
    ohToDate,
    show_Salesperson,
    selected_company,
    selected_business,
    dept,
    admin_param
  ) =>
  async (dispatch) => {
    setLoad(true);

    let send_salesperson = [];
    for (let i = 0; i < show_Salesperson?.length; i++) {
      send_salesperson.push(show_Salesperson[i]?.value);
    }

    let send_company = [];
    for (let i = 0; i < selected_company?.length; i++) {
      send_company.push(selected_company[i]?.value);
    }

    try {
      dispatch(get_client_report_businessType_req());
      let res = await fetch(
        `${base_url}/oh_report_by_business_type_deptwise`,
        // `${base_url}/corporate_oh_report_by_business_type`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            start_date: moment(ohFromDate).format("YYYY-MM-DD"),
            end_date: moment(ohToDate).format("YYYY-MM-DD"),
            salesperson:
              show_Salesperson?.length == 0
                ? "all"
                : send_salesperson?.toString(),
            company_name:
              selected_company?.length == 0 ? "all" : send_company?.toString(),
            business_type: selected_business,
            dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_client_report_businessType_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_client_report_businessType_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_client_report_businessType_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  get client report by Business Type

export const get_corporate_exp_details_req = () => ({
  type: GET_CORPORATE_EXP_DETAILS_REQUEST,
});
export const get_corporate_exp_details_succ = (data) => ({
  type: GET_CORPORATE_EXP_DETAILS_SUCCESS,
  payload: data,
});
export const get_corporate_exp_details_err = () => ({
  type: GET_CORPORATE_EXP_DETAILS_ERROR,
});

export const get_corporate_exp_details_data =
  (
    setLoad,
    setData,
    ohFromDate,
    ohToDate,
    show_Salesperson,
    selected_company,
    selected_business,
    dept,
    admin_param
  ) =>
  async (dispatch) => {
    setLoad(true);

    let send_salesperson = [];
    for (let i = 0; i < show_Salesperson?.length; i++) {
      send_salesperson.push(show_Salesperson[i]?.value);
    }

    let send_company = [];
    for (let i = 0; i < selected_company?.length; i++) {
      send_company.push(selected_company[i]?.value);
    }

    try {
      dispatch(get_corporate_exp_details_req());
      let res = await fetch(`${base_url}/exp_order_details_by_dept`, {
      // let res = await fetch(`${base_url}/corporate_exp_order_details`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          start_date: moment(ohFromDate).format("YYYY-MM-DD"),
          end_date: moment(ohToDate).format("YYYY-MM-DD"),
          salesperson:
            show_Salesperson?.length == 0
              ? "all"
              : send_salesperson?.toString(),
          company_name:
            selected_company?.length == 0 ? "all" : send_company?.toString(),
          business_type: selected_business,
           dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_corporate_exp_details_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_corporate_exp_details_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_corporate_exp_details_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  get oh count graph data

export const get_oh_count_graph_req = () => ({
  type: GET_OH_COUNT_GRAPH_DATA_REQUEST,
});
export const get_oh_count_graph_succ = (data) => ({
  type: GET_OH_COUNT_GRAPH_DATA_SUCCESS,
  payload: data,
});
export const get_oh_count_graph_err = () => ({
  type: GET_OH_COUNT_GRAPH_DATA_ERROR,
});

export const get_oh_count_graph_data =
  (setLoad, setData, ohFromDate, ohToDate,dept,admin_param) => async (dispatch) => {
    setLoad(true);
    setData([]);
    try {
      dispatch(get_oh_count_graph_req());
      let res = await fetch(`${base_url}/oh_count_value_by_dept`, {
      // let res = await fetch(`${base_url}/corporate_oh_count_value`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          start_date: moment(ohFromDate).format("YYYY-MM-DD"),
          end_date: moment(ohToDate).format("YYYY-MM-DD"),
          dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_oh_count_graph_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_oh_count_graph_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_oh_count_graph_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  get oh corporate list

export const get_oh_corporate_list_req = () => ({
  type: GET_OH_REPORT_LIST_REQUEST,
});
export const get_oh_corporate_list_succ = (data) => ({
  type: GET_OH_REPORT_LIST_SUCCESS,
  payload: data,
});
export const get_oh_corporate_list_err = () => ({
  type: GET_OH_REPORT_LIST_ERROR,
});

export const get_oh_corporate_list_data =
  (setLoad, setData, FromDate, ToDate, params) => async (dispatch) => {
    setLoad(true);
    setData([]);
    try {
      dispatch(get_oh_corporate_list_req());
      let res = await fetch(`${base_url}/corporate_oh_report_list`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          month_type: params,
          start_date:
            params != "current_month"
              ? ""
              : moment(FromDate).format("YYYY-MM-DD"),
          end_date:
            params != "current_month"
              ? ""
              : moment(ToDate).format("YYYY-MM-DD"),
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_oh_corporate_list_succ(data?.Data));

        let dropdown_data = [];
        if (data?.Data?.length > 0) {
          for (let i = 0; i < data?.Data?.length; i++) {
            dropdown_data?.push({
              label: data?.Data[i]?.OH,
              value: data?.Data[i]?.OH,
            });
          }
          setData(dropdown_data);
        }
      } else {
        setData([]);
        dispatch(get_oh_corporate_list_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_oh_corporate_list_err());
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Something Went Wrong",
      });
    }
  };

//  get oh corporate report data

export const get_oh_report_data_req = () => ({
  type: GET_OH_REPORT_DATA_REQUEST,
});
export const get_oh_report_data_succ = (data) => ({
  type: GET_OH_REPORT_DATA_SUCCESS,
  payload: data,
});
export const get_oh_report_data_err = () => ({
  type: GET_OH_REPORT_DATA_ERROR,
});

export const get_oh_report_data =
  (
    setLoad,
    setData,
    FromDate,
    ToDate,
    params,
    selected_oh,
    selected_salesperson,
    signal
  ) =>
  async (dispatch) => {
    setLoad(true);
    setData([]);

    let send_oh = [];
    for (let i = 0; i < selected_oh?.length; i++) {
      send_oh.push(selected_oh[i]?.value);
    }

    let send_salesperson = [];
    for (let i = 0; i < selected_salesperson?.length; i++) {
      send_salesperson.push(selected_salesperson[i]?.value);
    }

    try {
      dispatch(get_oh_report_data_req());
      let res = await fetch(`${base_url}/corporate_oh_report`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        signal: signal,
        body: JSON.stringify({
          month_type: params,
          start_date:
            params != "current_month"
              ? ""
              : moment(FromDate).format("YYYY-MM-DD"),
          end_date:
            params != "current_month"
              ? ""
              : moment(ToDate).format("YYYY-MM-DD"),
          salesperson:
            selected_salesperson?.length == 0
              ? "all"
              : send_salesperson?.toString(),
          oh_number: selected_oh?.length == 0 ? "all" : send_oh?.toString(),
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_oh_report_data_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_oh_report_data_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_oh_report_data_err());
      if (error.name === "AbortError") {
        setLoad(true);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: "Something Went Wrong",
        });
      }
    }
  };

//  get relevant marketing performance

export const get_relevant_marketing_data_req = () => ({
  type: GET_RELEVANT_MARKETING_DATA_REQUEST,
});
export const get_relevant_marketing_data_succ = (data) => ({
  type: GET_RELEVANT_MARKETING_DATA_SUCCESS,
  payload: data,
});
export const get_relevant_marketing_data_err = () => ({
  type: GET_RELEVANT_MARKETING_DATA_ERROR,
});

export const get_relevant_marketing_data =
  (
    setLoad,
    setData,
    start_date,
    end_date,
    params,

  ) =>
  async (dispatch) => {
    setLoad(true);
    setData([]);
    try {
      dispatch(get_relevant_marketing_data_req());
      let res = await fetch(`${base_url}/marketing_relevant_performance`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          "GET_DATE":params == "Custom Date"?"":params,
          "START_DATE":params == "Custom Date"?moment(start_date).format("YYYY-MM-DD"):"",
          "END_DATE":params == "Custom Date"?moment(end_date).format("YYYY-MM-DD"):""
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_relevant_marketing_data_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_relevant_marketing_data_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_relevant_marketing_data_err());
 
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: "Something Went Wrong",
        });
      
    }
  };

  
//  get assigned marketing performance

export const get_assigned_marketing_data_req = () => ({
  type: GET_ASSIGNED_MARKETING_DATA_REQUEST,
});
export const get_assigned_marketing_data_succ = (data) => ({
  type: GET_ASSIGNED_MARKETING_DATA_SUCCESS,
  payload: data,
});
export const get_assigned_marketing_data_err = () => ({
  type: GET_ASSIGNED_MARKETING_DATA_ERROR,
});

export const get_assigned_marketing_data =
  (
    setLoad,
    setData,
    start_date,
    end_date,
    params,

  ) =>
  async (dispatch) => {
    setLoad(true);
    setData([]);
    try {
      dispatch(get_assigned_marketing_data_req());
      let res = await fetch(`${base_url}/marketing_assigned_performance`, {
        method: "POST",
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          "GET_DATE":params == "Custom Date"?"":params,
          "START_DATE":params == "Custom Date"?moment(start_date).format("YYYY-MM-DD"):"",
          "END_DATE":params == "Custom Date"?moment(end_date).format("YYYY-MM-DD"):""
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_assigned_marketing_data_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_assigned_marketing_data_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      dispatch(get_assigned_marketing_data_err());
 
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: "Something Went Wrong",
        });
      
    }
  };


  //  get_marketing_leads_created

export const get_marketing_leads_created_req = () => ({
  type: GET_MARKETING_LEADS_CREATED_REQUEST,
});
export const get_marketing_leads_created_succ = (data) => ({
  type: GET_MARKETING_LEADS_CREATED_SUCCESS,
  payload: data,
});
export const get_marketing_leads_created_err = () => ({
  type: GET_MARKETING_LEADS_CREATED_ERROR,
});

export const get_marketing_leads_created =
  (
    setLoad,
    setData,
    start_date,
    end_date,
    params,
    type,
    signal
  ) =>
  async (dispatch) => {
    setLoad(true);
    setData([]);
    try {
      dispatch(get_marketing_leads_created_req());
      let res = await fetch(`${base_url}/marketing_assigned_leads_created_on`, {
        method: "POST",
        signal:signal,
        headers: { "Content-Type": "Application/Json" },
        body: JSON.stringify({
          "GET_DATE":params == "Custom Date"?"":params,
          "START_DATE":params == "Custom Date"?moment(start_date).format("YYYY-MM-DD"):"",
          "END_DATE":params == "Custom Date"?moment(end_date).format("YYYY-MM-DD"):"",
          "TYPE":type,
        }),
      });
      let data = await res.json();
      setLoad(false);
      if (data.response.code == 200) {
        dispatch(get_marketing_leads_created_succ(data?.Data));
        setData(data?.Data);
      } else {
        setData([]);
        dispatch(get_marketing_leads_created_err());
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: data.response?.message,
        });
      }
    } catch (error) {
      setLoad(false);
      setData([]);
      if (error.name === "AbortError") {
        setLoad(true);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: "Something Went Wrong",
        });
      }
      
    }
  };




  export const get_marketing_leads_updated_req = () => ({
    type: GET_MARKETING_LEADS_UPDATED_REQUEST,
  });
  export const get_marketing_leads_updated_succ = (data) => ({
    type: GET_MARKETING_LEADS_UPDATED_SUCCESS,
    payload: data,
  });
  export const get_marketing_leads_updated_err = () => ({
    type: GET_MARKETING_LEADS_UPDATED_ERROR,
  });
  
  export const get_marketing_leads_updated =
    (
      setLoad,
      setData,
      start_date,
      end_date,
      params,
      type,
      signal
    ) =>
    async (dispatch) => {
      setLoad(true);
      setData([]);
      try {
        dispatch(get_marketing_leads_updated_req());
        let res = await fetch(`${base_url}/marketing_assigned_leads_updated_on`, {
          method: "POST",
          signal:signal,
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            "GET_DATE":params == "Custom Date"?"":params,
            "START_DATE":params == "Custom Date"?moment(start_date).format("YYYY-MM-DD"):"",
            "END_DATE":params == "Custom Date"?moment(end_date).format("YYYY-MM-DD"):"",
            "TYPE":type,
          }),
        });
        let data = await res.json();
        setLoad(false);
        if (data.response.code == 200) {
          dispatch(get_marketing_leads_updated_succ(data?.Data));
          setData(data?.Data);
        } else {
          setData([]);
          dispatch(get_marketing_leads_updated_err());
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: data.response?.message,
          });
        }
      } catch (error) {
        setLoad(false);
        setData([]);
        dispatch(get_marketing_leads_updated_err());
   
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "Something Went Wrong",
          });
        
      }
    };