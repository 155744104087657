import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import MiniDrawer from "../components/Minidrawer";
import report_loader from "../Media/ezgif.com-optimize.gif";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Select2 from "react-select";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const customStyles = {
  option: (provided) => ({
    ...provided,
    textAlign: "left",
    fontSize: "13px",
    color: "black",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
    textAlign: "left",
  }),
  valueContainer: (base) => ({
    ...base,
     fontSize:"13px",
     textAlign: "left"
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Set this to the desired z-index
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

function Sample_type_report_bde() {
  const storage = window.sessionStorage
  const admin_param = storage.getItem("admin_param")
  const dept = storage.getItem("DEPT")
  const [sample_report_data, setSample_Report_Data] = useState([]);
  const [client_list, setClient_List] = useState([]);
  const [salesperson_list, setSalesperson_List] = useState([]);

  const [selected_salesperson, setSelected_salesperson] = useState([]);
  const [selected_client, setSelected_Client] = useState([]);

  const [params, setParams] = useState("MTD");
  const [range_flag, setRange_Flag] = useState(false);
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const [selectedFromDate, setSelectedFromDate] = useState(new Date(y, m, 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [date_change_flag, setDate_change_Flag] = useState(false);

  const [sample_load, setSample_Load] = useState(false);
  const [client_load, setClient_Load] = useState(false);
  const [sales_load, setSales_Load] = useState(false);

  const get_sample_types = async () => {
    setSample_Load(true);
    try {
      let res = await fetch(
        // `https://reportapi.equinoxlab.com/corporate_sample_report`,
        `https://reportapi.equinoxlab.com/sample_report_by_dept`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            start_date:
              params == "Custom Date"
                ? moment(selectedFromDate)?.format("YYYY-MM-DD")  
                : "",
            end_date:
              params == "Custom Date"
                ? moment(selectedToDate)?.format("YYYY-MM-DD")
                : "",
            get_date: params == "Custom Date" ? "" : params,
            client_type: selected_client?.length == 0 || selected_client == null ?"all":selected_client?.value,
            salesperson: selected_salesperson?.length == 0 || selected_salesperson == null?"all":selected_salesperson?.value,
            dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"

          }),
        }
      );
      let data = await res.json();
      setSample_Load(false);
      if (data?.response?.code == "200") {
        setSample_Report_Data(data?.Data);
      }
    } catch (error) {
      setSample_Load(false);
    }
  };

  const get_client_type = async () => {
    try{
      let res = await fetch(
        `https://reportapi.equinoxlab.com/get_client_type`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
                    dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setClient_Load(false);
      if (data?.response?.code == "200") {
        let dropdown_data = [];
        for (let i = 0; i < data?.Data?.length; i++) {
          dropdown_data.push({
            label: data?.Data[i]?.client_type,
            value: data?.Data[i]?.client_type,
          });
        }
        setClient_List(dropdown_data);
      }
    }
    catch(error){
      setClient_Load(false);
    }
  } 



  const get_salesperson = async () => {
    try {
      setSales_Load(true);
      let res = await fetch(
        "https://reportapi.equinoxlab.com/get_unique_salesperson",
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
               dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setSales_Load(false);
      if (data?.response?.code == "200") {
        let dropdown_data = [];
        for (let i = 0; i < data?.Data?.length; i++) {
          dropdown_data.push({
            label: data?.Data[i]?.salesperson,
            value: data?.Data[i]?.salesperson,
          });
        }
        setSalesperson_List(dropdown_data);
      }
    } catch (error) {
      setSales_Load(false);
    }
  };

  // const get_total = (param)=>{
  //   if (param == "mumbai") {
  //     let total_count = data?.reduce((acc, ele) => {
  //       return acc + Number(ele?.mumbai);
  //     }, 0);
  //     return total_count;
  //   } 
  //   else if (param == "delhi") {
  //     let total_count = data?.reduce((acc, ele) => {
  //       return acc + Number(ele?.delhi);
  //     }, 0);
  //     return total_count;
  //   } 
  //   else if (param == "hyderabad") {
  //     let total_count = data?.reduce((acc, ele) => {
  //       return acc + Number(ele?.hyderabad);
  //     }, 0);
  //     return total_count;
  //   } 
  //   else if (param == "kolkata") {
  //     let total_count = data?.reduce((acc, ele) => {
  //       return acc + Number(ele?.kolkata);
  //     }, 0);
  //     return total_count;
  //   } 
  //   else if (param == "delhi") {
  //     let total_count = data?.reduce((acc, ele) => {
  //       return acc + Number(ele?.delhi);
  //     }, 0);
  //     return total_count;
  //   } 
  //   else if (param == "delhi") {
  //     let total_count = data?.reduce((acc, ele) => {
  //       return acc + Number(ele?.delhi);
  //     }, 0);
  //     return total_count;
  //   } 

  //   else {
  //     let total_count = data?.reduce((acc, ele) => {
  //       return acc + Number(ele?.Total_Order);
  //     }, 0);
  //     return total_count;
  //   }
  // }

  const handleDateRange = (e) => {
    if (e.target.value == "Custom Date") {
      setParams("Custom Date");
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setParams(e.target.value);
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date());
    }
  };

  const handleSelectDateRange = (new_val) => {
    if (new_val !== null) {
      setDate_change_Flag(true);
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      setParams("MTD");
      setRange_Flag(false);
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date());
      setDate_change_Flag(false);
    }
  };

  useEffect(() => {
    get_sample_types();
  }, [selectedFromDate, selectedToDate, selected_salesperson, selected_client]);

  useEffect(() => {
    get_client_type();
    get_salesperson();
  }, []);

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "sample_category",
      label: `Sample Category`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "mumbai",
      label: `Mumbai`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "chennai",
      label: `Chennai`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bangalore",
      label: `Bangalore`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "hyderabad",
      label: `Hyderabad`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "kolkata",
      label: `Kolkata`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "delhi",
      label: `Delhi`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "location_not_updated",
      label: `location not updated`,
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  return (
    <>
      <MiniDrawer />

      {sample_load || client_load || sales_load ? (
        <img src={report_loader} id={styles.loader_sample} />
      ) : (
        <div className="container-fluid pt-3">
          <div className="row">
            <MUIDataTable
              title={
                <h5 style={{ textAlign: "left" }}>
                  Sample Type Report ( BDE )
                </h5>
              }
              data={sample_report_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "60vh",
                rowsPerPageOptions: [10, 15, 25, 50, 100],

                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
                // customTableBodyFooterRender: (
                //   count,
                //   page,
                //   rowsPerPage,
                //   changeRowsPerPage,
                //   changePage,
                //   data
                // ) => {
                //   const startIndex = page * rowsPerPage;
                //   const endIndex = (page + 1) * rowsPerPage;
                //   return (
  
                //     <TableFooter>
                //       <TableRow id={styles.table_footer}>
                //         <TableCell  id={styles.first_col}>
                //           <p >Total</p>
                //         </TableCell>
      
                //         <TableCell>
                //           <p >{get_total("M")}</p>
                //         </TableCell>

                     
                //       </TableRow>
                //     </TableFooter>
                //   );
                // },
                customToolbar: () => {
                  return (
                    <>
                      <div style={{ display: "inline-flex", gap: "5px" }}>
                        <div style={{ width: "250px" }}>
                          <Select2
                            name="label"
                            options={salesperson_list}
                     className="basic-single"
                            classNamePrefix="select"
                            styles={customStyles}
                            style={{backgroundColor:"white",height:"34px",width: "100%",}}
                            value={selected_salesperson}
                            onChange={(e) => setSelected_salesperson(e)}
                            placeholder="Select Salesperson"
                            isClearable={true}
                            maxMenuHeight="180px"
                            required
                          />
                        </div>
                        <div style={{ width: "250px" }}>
                          <Select2
                            name="label"
                            options={client_list}
                     className="basic-single"
                            classNamePrefix="select"
                            styles={customStyles}
                            style={{backgroundColor:"white",height:"34px",width: "100%",}}
                            value={selected_client}
                            onChange={(e) => setSelected_Client(e)}
                            placeholder="Select Client Type"
                            isClearable={true}
                            maxMenuHeight="180px"
                            required
                          />
                        </div>
                        <Box
                          sx={{
                            width: "160px",
                            backgroundColor: "white",
                            height: "37px",
                            borderRadius: "5px",
                          }}
                        >
                          <FormControl size="small" fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{
                                width: "160px",
                                height: "37px",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                              onChange={handleDateRange}
                              value={params}
                            >
                              <MenuItem value="" disabled>
                                Please Select
                              </MenuItem>
                              <MenuItem value="Today">Today</MenuItem>
                              <MenuItem value="MTD">MTD</MenuItem>
                              <MenuItem value="QTD">QTD</MenuItem>
                              <MenuItem value="YTD">YTD</MenuItem>
                              <MenuItem value="FYTD">FYTD</MenuItem>
                              <MenuItem value="Custom Date">
                                Custom Date
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        {range_flag ? (
                          <>
                            <DateRangePicker
                              // defaultValue={[new Date(), new Date()]}
                              format="dd MMM yyyy"
                              value={
                                date_change_flag
                                  ? [selectedFromDate, selectedToDate]
                                  : []
                              }
                              onChange={handleSelectDateRange}
                              size="md"
                              placement="bottomEnd"
                              placeholder="Select Date Range"
                              preventOverflow
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  );
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Sample_type_report_bde;
