import { LineChart } from "@mui/x-charts/LineChart";
import React, { useRef } from "react";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { ChartsReferenceLine } from "@mui/x-charts";

function Nps_percentage_graph({ nps_yearly_data }) {
  let xLabels = [
    "",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];
  const windowHeight = useRef(window.innerHeight);

  return (
    <div>
      <LineChart
        xAxis={[{ scaleType: "point", data: xLabels, label: "Months" }]}
        yAxis={[
            {
              id: "linearAxis",
              scaleType: "linear",
              label: "Percentage",
            },
          ]}
        series={[
          {
            curve: "linear",
            data: nps_yearly_data,
            label:"Report Rating",
            valueFormatter: (value) =>(value == null) ? null :value +"%"
          },
        ]}
        sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translate(-10px, 0)",
              fontWeight: "bold",
            },
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              fontWeight: "bold",
              transform: "translate(0, 5px)",
              marginTop: "20px",
            },
          }}
          width={
          (windowHeight?.current>900)?1250:1200}
          height={windowHeight?.current>900?450:360}
          margin={{ left:  250 }}
      >
        <ChartsReferenceLine y={80}  labelAlign="end" labelStyle={{ fontSize: '15' }} lineStyle={{ strokeDasharray: '5 5',color:"green" }} />
      </LineChart>
    </div>
  );
}

export default Nps_percentage_graph;
