import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import styles from "../Styles/Table.module.css";
import { category_data, category_data_revenue, category_data_updated_on } from "../Redux/actions";
import MiniDrawer from "../components/Minidrawer";
import { useNavigate } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Swal from "sweetalert2";

function Category_leadSource_Revenue({
    revenue_flag,
    handleRevenue,
    selected_cat,
    cat_data,
    check_loading,
    get_total,
    handleCategory,
    cat_drop,
    selected_source,
    setSelected_Source,
    setSelected_Cat,
    source_drop,
}) {

    const storage = window.sessionStorage;
    let img = storage.getItem("profile_img");
    let USER_GUID = storage.getItem("USER_GUID");
    let Role_id = storage.getItem("Role_id");
    let DEPT_ID = storage.getItem("DEPT_ID");
    let DEPT = storage.getItem("DEPT");

    const navigate = useNavigate();

    const handleSource =(e)=>{
      if(e.target.value!= ""){
        setSelected_Source(e.target.value)
      }
    }

    // const get_total =(val)=>{


    //   if(val == "M"){
    //      let total_count = cat_data?.reduce((acc,ele)=>{
    //        return acc+Number(ele?.M)
    //     },0)
    //    //  return total_count;
    //    return total_count;
    //   }
    //   else if(val == "M1"){
    //      let total_count = cat_data?.reduce((acc,ele)=>{
    //        return acc+Number(ele?.M1)
    //     },0)
    //     return total_count;
    //   }
    //   else if(val == "M2"){
    //      let total_count = cat_data?.reduce((acc,ele)=>{
    //        return acc+Number(ele?.M2)
    //     },0)
    //     return total_count;
    //   }
    //   else if(val == "M3"){
    //      let total_count = cat_data?.reduce((acc,ele)=>{
    //        return acc+Number(ele?.M3)
    //     },0)
    //     return total_count;
    //   }
    //   else if(val == "M4"){
    //      let total_count = cat_data?.reduce((acc,ele)=>{
    //        return acc+Number(ele?.M4)
    //     },0)
    //     return total_count;
    //   }
    //   else  if(val == "M5"){
    //      let total_count = cat_data?.reduce((acc,ele)=>{
    //        return acc+Number(ele?.M5)
    //     },0)
    //     return total_count;
    //   }
    //   else if(val == "M6"){
    //      let total_count = cat_data?.reduce((acc,ele)=>{
    //        return acc+Number(ele?.M6)
    //     },0)
    //     return total_count;
    //   }
    //   else if(val == "Q"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Q)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Q1"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Q1)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Q2"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Q2)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Q3"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Q3)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Q4"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Q4)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Q5"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Q5)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Q6"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Q6)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Y"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Y)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Y1"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Y1)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Y2"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Y2)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Y3"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Y3)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Y4"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Y4)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Y5"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Y5)
    //   },0)
    //   return total_count;
    //  }
    //  else if(val == "Y6"){
    //    let total_count = cat_data?.reduce((acc,ele)=>{
    //      return acc+Number(ele?.Y6)
    //   },0)
    //   return total_count;
    //  }
     
    //  else{
    //    let x= 1;
    //  }
     
    //    }
    
  const options = {
    filterType: "checkbox",

  };
  const columns = [
    {
      name: "Leadcat",
      label: "Lead Category",
      options: {
        filter: true,
        sort: false,
 
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 0,
            // minWidth:"200px",
            // maxWidth:"200px"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 1000
          }
        }),
  
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left",paddingRight:"20px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "LeadSource",
      label: "Lead Source",
      options: {
        filter: true,
        sort: false,

        setCellProps: () => ({
          style: { minWidth: "250px", maxWidth: "auto" },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left",paddingRight:"10px" }}>
              <p style={{ marginLeft: "30%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          // return <>{row}</>;
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        tooltips:false,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        rowsPerPageOptions:[10,25,50,100],
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),

        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),

        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
            style: {
                backgroundColor:
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) >= 0 &&
                  Number(
                    cellValue?.props?.children?.replaceAll(",","")
                  ) < 500
                    ? "#dff3ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1000
                    ? "#9fdcff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 1500
                    ? "#60c5ff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 1500 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) < 2000
                    ? "#20aeff"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2000 &&
                      Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) <= 2500
                    ? "#008edf"
                    : Number(
                        cellValue?.props?.children?.replaceAll(",","")
                      ) >= 2500
                    ? "#1D5D9B"
                    : "white",
                       padding:"10px",
                 color: ((Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2000 &&
                Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) <= 2500)|| ( Number(
                  cellValue?.props?.children?.replaceAll(",","")
                ) >= 2500))?"white":"black"     
              },
        }),
        customBodyRender: (row) => {
          return <>{revenue_flag ? new Intl.NumberFormat("en-IN").format(row) : row}</>;
        },
      },
    },
  ];


  return (
    <>
    <MiniDrawer />
    {
        check_loading ? (
            <img src={report_loader} id={styles.loader} />
        ) : (
            <div className="container-fluid pt-3"  id={cat_data.length == 0?"":"table_s6_category_tbl"}>
           
            <MUIDataTable
              title={
                <h5 style={{ textAlign: "left" }}>Leadsource vs Revenue</h5>
              }
              data={cat_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: true,
                fixedHeader: true,
                rowsPerPageOptions:[10,25,50,100],
                tableBodyMaxHeight: "57vh",
                sortOrder: {
                  name: 'Q',
                  direction: 'desc'
                },
                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                customToolbar: () => {
                  return (
      
                    <>
                       <select  id={styles.payment_drop} name=""  value={selected_cat} onChange={handleCategory}>
                        {/* <option value="ALL" disabled selected>Select Category</option> */}
                        {/* <option value="">Select Cateogory</option> */}
                        {
                          revenue_flag ?
                          <option value="ALL" 
                            // style={{display:revenue_flag?"block":"none"}} 
                            // selected={revenue_flag?true:false}
                            >All Category</option>
                        :
                        <option value="ALL"
                      //  style={{display:revenue_flag?"block":"none"}}
                      //  selected={revenue_flag?true:false}
                       >All Category</option>
                        }
                        {cat_drop?.map((ele)=>(
                          <option value={ele.Leadcat}>{ele.Leadcat}</option>
                        ))}
                       </select>
                       <select id={styles.source_drop} name="" value={selected_source} onChange={handleSource} >
                        {/* <option  value="ALL" disabled selected >Select Lead Source</option> */}
                        <option value="" selected>Select Source</option>
                      <option value="ALL" selected>All Source</option>
                        {source_drop?.map((ele)=>(
                          <option value={ele.Leadsource}>{ele.Leadsource}</option>
                        ))}
                       </select>
                       
                    </>
                    )
                  },
  
                customTableBodyFooterRender: (
                  count,
                  page,
                  rowsPerPage,
                  changeRowsPerPage,
                  changePage,
                  data
                ) => {
                  const startIndex = page * rowsPerPage;
                  const endIndex = (page + 1) * rowsPerPage;
                  return (
                    <TableFooter id={styles.table_footer}>
                      <TableRow>
  
                      <TableCell  
                       id={styles.first_col}>
                            <p>Total</p> 
                        </TableCell>
                        <TableCell>
  
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y6")}</p> 
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
              }}
            />
          </div>
        )
    }
    <div>
      
    </div>
    </>
    
  )
}

export default Category_leadSource_Revenue
