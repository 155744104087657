import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from '../pages/Main'
import Order_history from '../pages/Order_history'
import Individual_sales_user_performance_dashboard from '../pages/Individual_sales_user_performance_dashboard'
import Login from '../pages/Login'
import Salesperson_Wise from '../pages/Salesperson_Wise'
import { useState } from 'react'
import styles from "../Styles/Login.module.css"
import Category_Leadsource_wise from '../pages/Category_Leadsource_wise'
import Hub_wise_Data from '../pages/Hub_wise_Data'
import Geography_data from '../pages/Geography_data'
import Lab_wise_data from '../pages/Lab_wise_data'
import Hub_wise_lab from '../pages/Hub_wise_lab'
import Operation_Report from '../pages/Operation_Report'
import Logistics_Report from '../pages/Logistics_Report'
import Registration_Report from '../pages/Registration_Report'
import NPS_Dashboard from '../pages/NPS_Dashboard'
import Payment_report from '../pages/Payment_report'
import Transaction_report from '../pages/Transaction_report'
import KAE_planning from './../pages/KAE_planning';
import Billing_report from '../pages/Billing_report'
import Invoice_daily_report from '../pages/Invoice_daily_report'
import Invoice_monthly_report from '../pages/Invoice_monthly_report'
import Transaction_report_invoice from '../pages/Transaction_report_invoice'
import Invoice_monthly_data from '../pages/Invoice_monthly_data'
import Invoice_s6 from '../pages/Invoice_s6'
import Sales_analysis from '../pages/Sales_analysis'
import Profile_Metrics from '../pages/Profile_Metrics'
import HR_Team_S6 from '../pages/HR_Team_S6'
import Testing_service from '../pages/Testing_service'
import Non_testing_service from '../pages/Non_testing_service'
import Testing_location from '../pages/Testing_location'
import Testing_location_graph from '../pages/Testing_location_graph'
import Client_Type_Revenue from '../pages/Client_Type_Revenue'
import Client_type_sample_revenue from '../pages/Client_type_sample_revenue'
import Service_penetration from '../pages/Service_penetration'
import BDE_dashboard from '../pages/BDE_dashboard'
import Average_ttf_dashboard from '../pages/Average_ttf_dashboard'
import Offer_sent_accept_dashboard from '../pages/Offer_sent_accept_dashboard'
import Client_Type_Sample_Type from '../pages/Client_Type_Sample_Type'
import Lead_funnel from '../pages/Lead_funnel'
import Marketing_leadsource from '../pages/Marketing_leadsource'
import LeadSource_vs_revenue from '../pages/LeadSource_vs_revenue'
import Marketing_Performance from '../pages/Marketing_Performance'
import Qc_utilization from '../pages/Qc_utilization'
import Invoice_corporate from '../pages/Invoice_corporate'
import Salespersonwise_invoice from '../pages/Salespersonwise_invoice'
import Billing_report_monthly_digest from '../pages/Billing_report_monthly_digest'
import Leads_leader from '../pages/Leads_leader'
import Sample_business_report from '../pages/Sample_business_report'
import Overdue_leads_sales from '../pages/Overdue_leads_sales'
import Purchase_order_report from '../pages/Purchase_order_report'
import Tags_level_report from '../pages/Tags_level_report'
import Interested_lead_report from '../pages/Interested_lead_report'
import Corporate_client_oh_dashboard from '../pages/Corporate_client_oh_dashboard'
import Corporate_oh_dashboard from '../pages/Corporate_oh_dashboard'
import Relevant_marketing from '../pages/Relevant_marketing'
import Invoice_daily_fnb from '../Fnb_Pages/Invoice_daily_fnb'
import Invoice_monthly_fnb from '../Fnb_Pages/Invoice_monthly_fnb'
import Invoice_monthly_data_fnb from '../Fnb_Pages/Invoice_monthly_data_fnb' 
import Invoice_dashboard_fnb from '../Fnb_Pages/Invoice_dashboard_fnb'
import Interested_lead_report_fnb from '../Fnb_Pages/Interested_lead_report_fnb'
import Fnb_client_oh_dashboard from '../Fnb_Pages/Fnb_client_oh_dashboard'
import Assigned_marketing_performance from '../pages/Assigned_marketing_performance'
import Category_leadsourcewise_analysis from '../pages/Category_leadsourcewise_analysis'
import Sales_leaderboard from '../pages/Sales_leaderboard'
import Sales_leaderboard_fnb from '../Fnb_Pages/Sales_leaderboard_fnb'
import Marketing_lead_assigned from '../pages/Marketing_lead_assigned'
import Salespersonwise_invoice_fnb from '../Fnb_Pages/Salespersonwise_invoice_fnb'
import Sample_business_report_fnb from '../Fnb_Pages/Sample_business_report_fnb'
import Fnb_order_details from '../Fnb_Pages/Fnb_order_details'
import Sample_type_report_bde from '../pages/Sample_type_report_bde'
import Sample_report_bde_fnb from '../Fnb_Pages/Sample_report_bde_fnb'



function Allroutes() {
  const [year,setYear] = useState(new Date())
  let info_arr = year?.toString()?.split(" ")

  // new Intl.NumberFormat("en-IN").format(row)
  return (
 <>
   <Routes>
     <Route path="/samples_tested_vs_revenue" element={<Main/>}/>
     <Route path="/new_vs_repeated_business" element={<Order_history />}/>
     <Route path="/Individual_sales_performance_leads" element={<Individual_sales_user_performance_dashboard />}/>
     <Route path="/leads_added_by_sales" element={<Salesperson_Wise />}/>
     <Route path="/Category_Leadsource_wise" element={<Category_Leadsource_wise />}/>
     <Route path="/leadsource_vs_revenue" element={<LeadSource_vs_revenue />}/>
     <Route path="/hub_wise_data" element={<Hub_wise_Data />}/>
     <Route path="/revenue_citywise" element={<Geography_data />}/>
     <Route path="/lab_wise_data" element={<Lab_wise_data />}/>
     <Route path="/hub_wise_report_released" element={<Hub_wise_lab />}/>
     <Route path="/logistics_report" element={<Operation_Report />}/>
     {/* <Route path="/logistics_report" element={<Logistics_Report />}/> */}
     <Route path="/registration_report" element={<Registration_Report />}/>
     <Route path="/nps" element={<NPS_Dashboard />}/>
     <Route path="/payment_report" element={<Payment_report />}/>
     <Route path="/transaction_report" element={<Transaction_report />}/>
     <Route path="/kae_planning" element={<KAE_planning />}/>
     <Route path="/billing_report" element={<Billing_report />}/>
     <Route path="/invoice_daily" element={<Invoice_daily_report />}/>
     <Route path="/invoice_monthly" element={<Invoice_monthly_report />}/>
     <Route path="/invoice_monthly_data" element={<Invoice_monthly_data />}/>
     <Route path="/transaction_report_invoice" element={<Transaction_report_invoice />}/>
     <Route path="/invoice_s6" element={<Invoice_s6 />}/>
     <Route path="/sales_analysis" element={<Sales_analysis />}/>
     <Route path="/profile_metrics" element={<Profile_Metrics />}/>
     <Route path="/hr_team_s6" element={<HR_Team_S6 />}/>
     <Route path="/testing_service" element={<Testing_service />}/>
     <Route path="/non_testing_service" element={<Non_testing_service />}/>
     <Route path="/testing_location" element={<Testing_location />}/>
     <Route path="/testing_location_graph" element={<Testing_location_graph />}/>
     <Route path="/client_type_revenue" element={<Client_Type_Revenue />}/>
     <Route path="/client_type_sample_revenue" element={<Client_type_sample_revenue />}/>
     <Route path="/service_penetration" element={<Service_penetration />}/>
     <Route path="/bde_dashboard" element={<BDE_dashboard />}/>
     <Route path="/average_ttf_dashboard" element={<Average_ttf_dashboard />}/>
     <Route path="/offer_sent_accept_dashboard" element={<Offer_sent_accept_dashboard />}/>
     <Route path="/salesperson_client_type_sample_type" element={<Client_Type_Sample_Type />}/>
     <Route path="/lead_funnel" element={<Lead_funnel />}/>
     <Route path="/marketing_leadsource" element={<Marketing_leadsource />}/>
     <Route path="/marketing_performance" element={<Marketing_Performance />}/>
     <Route path="/qc_utilization" element={<Qc_utilization />}/>
     <Route path="/invoice_corporate" element={<Invoice_corporate />}/>
     <Route path="/sales_leaderboard" element={<Sales_leaderboard />}/>
     <Route path="/salesperson_wise_invoice" element={<Salespersonwise_invoice />}/>
     <Route path="/salesperson_wise_invoice_fnb" element={<Salespersonwise_invoice_fnb />}/>
     <Route path="/sample_business_report" element={<Sample_business_report />}/>
     <Route path="/sample_business_report_fnb" element={<Sample_business_report_fnb />}/>
     <Route path="/billing_report_monthly_digest" element={<Billing_report_monthly_digest />}/>
     <Route path="/leads_leader" element={<Leads_leader />}/>
     <Route path="/overdue_leads_sales" element={<Overdue_leads_sales />}/>
     <Route path="/purchase_order_report" element={<Purchase_order_report />}/>
     <Route path="/tags_level_report" element={<Tags_level_report />}/>
     <Route path="/interested_lead_report" element={<Interested_lead_report />}/>
     <Route path="/corporate_client_oh_dashboard" element={<Corporate_client_oh_dashboard />}/>
     <Route path="/corporate_oh_dashboard" element={<Corporate_oh_dashboard />}/>
     <Route path="/relevant_marketing_performance" element={<Relevant_marketing />}/>
     <Route path="/invoice_daily_fnb" element={<Invoice_daily_fnb />}/>
     <Route path="/invoice_monthly_fnb" element={<Invoice_monthly_fnb />}/>
     <Route path="/invoice_monthly_data_fnb" element={<Invoice_monthly_data_fnb />}/>
     <Route path="/invoice_dashboard_fnb" element={<Invoice_dashboard_fnb />}/>
     <Route path="/interested_lead_report_fnb" element={<Interested_lead_report_fnb />}/>
     <Route path="/sales_leaderboard_fnb" element={<Sales_leaderboard_fnb />}/>
     <Route path="/fnb_client_oh_dashboard" element={<Fnb_client_oh_dashboard />}/>
     <Route path="/assigned_marketing_performance" element={<Assigned_marketing_performance />}/>
     <Route path="/category_leadsourcewise_analysis" element={<Category_leadsourcewise_analysis />}/>
     <Route path="/marketing_lead_assigned" element={<Marketing_lead_assigned />}/>
     <Route path="/fnb_order_details" element={<Fnb_order_details />}/>
     <Route path="/sample_type_report_bde" element={<Sample_type_report_bde />}/>
     <Route path="/sample_type_report_bde_fnb" element={<Sample_report_bde_fnb />}/>
     {/* <Route path="/Category_Leadsource_wise" element={<Category_Leadsource_wise />}/> */}

     <Route path="/" element={<Login />}/>
   </Routes>
   <div id={styles.footer}>
    <footer class="footer" role="contentinfo" >
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-md-7" style={{marginTop: "0.7%",fontSize:"14px"}}>
                <p class="copyright" style={{color: "grey"}}>
                  &copy; Copyright {info_arr[3]} Equinox Labs.
                </p>
              </div>
            </div>
          </div>
        </footer>
        </div>
 </>
  )
}

export default Allroutes