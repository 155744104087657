import React from 'react'
import MainTable from '../components/MainTable'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get_data } from '../Redux/actions'
import { useState } from 'react'
import MiniDrawer from '../components/Minidrawer'
import Hub_tbl from '../components/Hub_tbl'
import report_loader from "../Media/ezgif.com-optimize.gif";
import styles from "../Styles/Table.module.css";
import { useNavigate } from 'react-router-dom'
import no_data_icon from "../Media/no_data_icon.gif";

function Main() {
  const dispatch = useDispatch();
  // const data = useSelector((state)=> state.data.data)
  const check_loading = useSelector((state)=>state.data.loading)
  const check_error = useSelector((state)=>state.data.error)
  const [tested_data,setTested_Data] = useState([])

  const storage = window.sessionStorage;
  let img = storage.getItem("profile_img");
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");

  const navigate = useNavigate();

  React.useEffect(()=>{
    if(USER_GUID == "" || USER_GUID == null){
      navigate("/")
    }
 },[storage])

  // let new_data = new Date().split(" ")
 
  const [date,setDate] = useState(new Date().getFullYear())

  useEffect(()=>{
    dispatch(get_data(date,setTested_Data))
  },[date])
 
  const handleLoad = ()=>{
    window.location.reload()
  }

  return (
    <>
    <MiniDrawer/>
    <div className='container-fluid'>
      <div className='row m-2 mt-4'>
       {/* {data.length>0?<MainTable data={data} date={date} setDate={setDate} />:<>No Data Available</>} */}
       {check_loading ? (
          <img src={report_loader} id={styles.loader} />
        )  :  (tested_data?.length == 0 || tested_data == null)? <div className={styles.noData}>
        <img
          src={no_data_icon}
          alt=""
          style={{ height: "200px", width: "200px" }}
        />
        <h4>No Data Available</h4>
        <button onClick={handleLoad} className="btn btn-primary mt-3">Reset</button>
      </div>:<Hub_tbl data={tested_data} date={date} setDate={setDate} />}
        </div>
    </div>
    </>
  )
}

export default Main