import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_hub_data } from "../Redux/actions";
import MiniDrawer from "../components/Minidrawer";
import MUIDataTable from "mui-datatables";
import report_loader from "../Media/ezgif.com-optimize.gif";
import styles from "../Styles/Table.module.css";
import { useNavigate } from "react-router-dom";

function Hub_wise_Data() {
  const storage = window.sessionStorage;
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");

  const navigate = useNavigate();

  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  const [hub_data, setHub_Data] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      if (USER_GUID == "" || USER_GUID == null) {
        navigate("/");
      }
    }
  }, [storage]);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      dispatch(get_hub_data(setHub_Data));
    }
  }, []);

  const options = {
    filterType: "checkbox",
  };

  const columns = [
    {
      name: "hub_name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            minWidth:"150px",
            maxWidth:"150px"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101
          }
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "D",
      label: "D",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "D1",
      label: "D1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "D2",
      label: "D2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "D3",
      label: "D3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "D4",
      label: "D4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "D5",
      label: "D5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "D6",
      label: "D6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "W",
      label: "W",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "W1",
      label: "W1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "W2",
      label: "W2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "W3",
      label: "W3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "W4",
      label: "W4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "W5",
      label: "W5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "W6",
      label: "W6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) >= 0 &&
              Number(
                (cellValue?.props?.children?.props?.children)?.replaceAll(",", "")
              ) < 500
                ? "#dff3ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1000
                ? "#9fdcff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 1500
                ? "#60c5ff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 1500 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) < 2000
                ? "#20aeff"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2000 &&
                  Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) <= 2500
                ? "#008edf"
                : Number(
                    (cellValue?.props?.children?.props?.children)?.replaceAll(
                      ",",
                      ""
                    )
                  ) >= 2500
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "center", padding: "10px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
  ];
  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
        {check_loading ? (
          <img
            src={report_loader}
            id={styles.loader}
            style={{ marginTop: "6em" }}
          />
        ) : 
        <div  id={hub_data?.length == 0?"":"table_s6"}>
        
          <MUIDataTable
            title={<h5 style={{ textAlign: "left" }}>Report Insights</h5>}
            data={hub_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: true,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              tableBodyMaxHeight: "60vh",
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>}
      </div>
    </>
  );
}

export default Hub_wise_Data;
