import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import dayjs from "dayjs";
import moment from "moment";
import { DatePicker, Space } from "antd";
import { daily_billing_report } from "../Redux/actions";
import styles from "../Styles/Table.module.css"

function Billing_report() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");

  const formattedMonth = dayjs(new Date());
  let new_date = moment(formattedMonth.$d).format("YYYY-MM-DD");

  const [billing_data, setBilling_data] = useState([]);
  const [send_date, setSend_date] = useState(new_date);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      dispatch(daily_billing_report(setBilling_data, send_date));
    }
  }, [send_date]);

  const onChange = (date, dateString) => {
    let newDate = moment(date?.$d).format("YYYY-MM-DD");
    setSend_date(newDate);
    setSelectedDate(date);
  };

  const columns = [
    {
      name: "created_by",
      label: "Created By",
      options: {
        filter: false,
        sort: false,
        download: false,
      },
    },
    {
      name: "total_invoice_count",
      label: "Total Invoice Count",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "total_null_service_count",
      label: "Total Null Service Count",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "total_service_invoice_count",
      label: "Total Service Invoice Count",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />

      {check_loading ? (
        <img src={report_loader} />
      ) : (
        <>
          <div  className={styles.opertion_tbl} style={{ marginLeft: "2%", marginTop: "2%", width: "96%" }}>
            <MUIDataTable
              title={<h4 style={{ textAlign: "left" }}>Billing Report</h4>}
              data={billing_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                print: false,
                responsive: "standard",
                download: false,
                search: false,
                filter: false,
                fixedHeader: true,
                tableBodyMaxHeight: "600px",
                pagination:false,
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                customToolbar: () => {
                  return (
                    <>
                      <Space direction="vertical">
                        <DatePicker
                          onChange={onChange}
                          value={selectedDate}
                          format="DD-MM-YYYY"
                        />
                      </Space>
                    </>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
export default Billing_report;
