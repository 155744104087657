import React, { useRef } from 'react'
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import no_data_icon from "../Media/no_data_icon.gif";
import styles from "../Styles/Sales_analysis.module.css";

function Salesperson_invoice_graph({data,xLabels}) {

  const windowHeight = useRef(window.innerHeight);

  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format;
  return (
    <div id="invoice_graph">
    {data?.length > 0 ? (
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: xLabels,
            label: "Date",
            categoryGapRatio: data?.length == 1?0.8:0.4,
            // valueFormatter: (value) => value.split(" ").join("\n"),
          },
        ]}
        yAxis={[
          {
            id: "linearAxis",
            scaleType: "linear",
            label: "Invoice Amount",
            //   valueFormatter: (value) => currencyFormatter(value),
            //   valueFormatter: (value) => currencyFormatter(value/100000)+"L",
            valueFormatter: (value) =>
              value >= 100000
                ? currencyFormatter(value / 100000) + "L"
                : currencyFormatter(value),
          },
        ]}
        series={[
          {
            data: data,
            label: "Invoice Amount",
            valueFormatter: (value) =>
              // (value == null || value == 0) ? null : value.toString(),
              value == null || value == 0
                ? null
                : value >= 100000
                ? currencyFormatter(value / 100000) + "L"
                : currencyFormatter(value),
            color: "#007cc3",
          },
        ]}

        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-50px, 0)",
            fontWeight: "bold",
          },
          [`.${axisClasses.bottom} .${axisClasses.label}`]: {
            fontWeight: "bold",
            transform: "translate(0, 10px)",
            marginTop: "20px",
          },
        }}
        width={windowHeight?.current<650?820:950}
        // width={820}
        height={350}
        margin={{ left: 100 }}
      />
    ) : (
      <>
        {" "}
        <div
          className={styles.noData}
          style={{ marginLeft: "-1%", marginTop: "0%" }}
        >
          <img
            src={no_data_icon}
            alt=""
            style={{ height: "200px", width: "200px" }}
          />
          <h4>No Data Added</h4>
        </div>
      </>
    )}
  </div>
  )
}

export default Salesperson_invoice_graph