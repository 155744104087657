import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { DatePicker, Space } from "antd";
import report_loader from "../Media/ezgif.com-optimize.gif";
import dayjs from "dayjs";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";

function Candidate_source_chart() {
  const storage = window.sessionStorage;

  let dateFormat = "MM/YYYY";
  let format2 = moment().format("MM/YYYY");
  const [month_filter, setMonth_filter] = useState(new Date());
  const [send_month_filter, setSend_Month_filter] = useState(format2);
  const [pieData, setPieData] = useState([]);
  const [show_no_data, setShow_no_data] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleDateChange = (date, dateString) => {
    if(date){
      setMonth_filter(date);
      setSend_Month_filter(moment(date.$d).format("MM/YYYY"));
    }else{
      setMonth_filter(new Date())
      let format2 = moment().format("MM/YYYY");
      setSend_Month_filter(format2)
    }

  };


  let dataArray = [];
  const get_pie_data = async () => {
    setLoader2(true);
    try {
      let res = await fetch(
        `https://venus.equinoxlab.com/api/Dashboard.svc/Get_Pie_Chart_Data/${send_month_filter}`
      );
      let data = await res.json();
      data?.pie_data?.forEach((ele, ind) => {
        let sourceLable =
          ele.Source_of_Candidate == undefined
            ? "Not Defined"
            : ele.Source_of_Candidate;
        dataArray.push({
          id: ind,
          value: Number(ele.Count_of_Candidate),
          name: sourceLable,
        });
        setPieData(dataArray);
      });
      setLoader2(false);
      if (dataArray.length < 1) {
        setShow_no_data(true);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: "Data Not Available!",
        });
      } else {
        setShow_no_data(false);
      }
    } catch (error) {
      ;
    }
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const COLORS = [
  "#007cc3",       
  "#D2DE32",      
  " #9ADE7B",      
  "#776B5D",      
  "#557C55",       
  "#B0A695",       
  "#DCBFFF",       
  // "#E7BCDE",       
  "#994D1C",
  "#FF8F8F",       
  "#176B87",        
  "#00C49F",       
  "#435585",       
]

  useEffect(() => {
    get_pie_data();
  }, [month_filter, send_month_filter]);

  return (
    <div>
      <div className="row" style={{
        zIndex: "999", position:"absolute", width:"46%",
        top: loader2 ? "10%" : "",
        }}>
        <div
          className="col-md-6"
          style={{
            display: "flex",
            alignItems: "flex-end",
            paddingRight: "0px",

          }}
        >
          <h4 style={{margin:"0px", fontSize:"22px"}}>Source Chart</h4>
        </div>
        <div
          className="col-md-6"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            textAlign: "left",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                marginRight: "5px",
                marginTop: "7px",
                fontWeight: "bold",
                fontSize:"16px"
              }}
            >
              Select Month:
            </label>
            <Space direction="vertical">
              <DatePicker
                onChange={handleDateChange}
                picker="month"
                defaultValue={dayjs(format2, dateFormat)}
                value={dayjs(month_filter)}
                format="MMM-YYYY"
              />
            </Space>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", display: loader2 ? "none" : "flex" }}>
        <div style={{ display: show_no_data ? "none" : "block" }}>
          {/* ***********Rechart */}
          <PieChart width={610} height={380}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={pieData}
              cx={340}
              cy={250}
              innerRadius={70}
              outerRadius={120}
              fill="#007cc3"
              dataKey="value"
              onMouseEnter={onPieEnter}
              paddingAngle={1}
            >
                {pieData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
            </Pie>
          </PieChart>

          {!show_no_data ? 
            <div style={{zIndex:"999", marginTop:"10vh"}}>
            <p style={{color:"#999"}}>
              Note: Candidate source is not available in Not Defined section
            </p>
          </div>
          :
          <></>
          }

        </div>
      </div>

      <div
        style={{
          display: loader2 ? "flex" : "none",
          justifyContent: "center",
          // marginTop: loader2 ? "25vh" : "",
        }}
      >
        <img src={report_loader} alt="" />
      </div>
    </div>
  );
}

export default Candidate_source_chart;
