import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es";
import { registration_report } from "../Redux/actions";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { DatePicker, Space } from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const { RangePicker } = DatePicker;

function Registration_Report() {
  const [registartionData, setRegistrationData] = useState([]);

  const [registration_load, setRegistration_Load] = useState(false);

  const check_loading = useSelector((state) => state.data.loading);

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());

  const [start_date, setStart_Date] = useState(new Date());
  const [end_date, setEnd_Date] = useState(new Date());

  const [range_flag, setRange_Flag] = useState(false);
  const [params, setParams] = useState("MTD");

  const storage = window.sessionStorage;
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);

  const dispatch = useDispatch();

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      dispatch(
        registration_report(
          setRegistration_Load,
          params,
          start_date,
          end_date,
          setRegistrationData
        )
      );
    }
  }, []);

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);

      let newStartDate = moment(new_val[0]).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]).format("YYYY-MM-DD");

      // let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      // let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
      setStart_Date(newStartDate);
      setEnd_Date(newEndDate);
      dispatch(
        registration_report(
          setRegistration_Load,
          params,
          newStartDate,
          newEndDate,
          setRegistrationData
        )
      );
      // setShowBtn(true);
    } else {
      setParams("MTD");
      setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      setStart_Date(new Date());
      setEnd_Date(new Date());
      dispatch(
        registration_report(
          setRegistration_Load,
          "MTD",
          start_date,
          end_date,
          setRegistrationData
        )
      );
    }
  };

  const handleClick = () => {
    if (selectedFromDate == undefined && selectedToDate == undefined) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please Select Date",
      });
    } else {
      dispatch(
        registration_report(
          setRegistration_Load,
          params,
          start_date,
          end_date,
          setRegistrationData
        )
      );
    }
  };

  const handleDateRange = (e) => {
    setParams(e.target.value);
    if (e.target.value == "Custom Date") {
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      setStart_Date(new Date());
      setEnd_Date(new Date());
      dispatch(
        registration_report(
          setRegistration_Load,
          e.target.value,
          start_date,
          end_date,
          setRegistrationData
        )
      );
    }
  };

  return (
    <>
      <MiniDrawer />
      {registration_load ? (
        <img src={report_loader} id={styles.loader} />
      ) : (
        <Paper
          sx={{
            // width: index == 0 ? "40%" : "20%",
            mb: 2,
            padding: "0px",
            marginLeft: "2%",
            marginTop: "2%",
            width: "96%",
          }}
        >
          <TableContainer sx={{ maxHeight: "550px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead
                sx={{
                  backgroundColor: "#007cc3",
                  color: "#444a4f !important",
                  position: "sticky",
                  top: 0,
                  zIndex: "999",
                }}
              >
                <TableRow>
                  <TableCell id={styles.th_unit1} colspan={range_flag ? 4 : 6}>
                    {/* <TableCell id={styles.th_unit1} colspan={7}> */}
                    <h5
                      className="h5 mt-2"
                      style={{
                        // color: "white",
                        // textAlign: "center",
                        // marginLeft: "1%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        height: "50px",
                        paddingLeft: "20px",
                      }}
                    >
                      Sample Registration
                    </h5>
                  </TableCell>
                  <TableCell colspan={range_flag ? 3 : 1}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {range_flag ? (
                        <>
                          <DateRangePicker
                          format = "dd MMM yyyy"
                            defaultValue={[new Date(), new Date()]}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateChange}
                            size='md'
                          placement='bottomEnd'
                          preventOverflow
                          />
                          {/* <RangePicker
                            format={"DD-MM-YYYY"}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={(new_val) => handleDateChange(new_val)}
                          />
                          <button
                            className="btn btn-light btn-sm ml-2"
                            onClick={handleClick}
                            style={{

                              marginLeft: "5px",

                            }}
                          >
                            Apply
                          </button> */}
                        </>
                      ) : (
                        <></>
                      )}
                      <select
                        onChange={handleDateRange}
                        value={params}
                        className="form-select"
                        style={{ marginLeft: "20px", width: "160px" }}
                      >
                        <option value="" selected disabled>
                          Please Select
                        </option>
                        <option value="Today">Today</option>
                        <option value="MTD">MTD</option>
                        <option value="QTD">QTD</option>
                        <option value="YTD">YTD</option>
                        <option value="FYTD">FYTD</option>
                        <option value="Custom Date">Custom Date</option>
                      </select>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id={styles.th_unit1} style={{ textAlign: "left" }}>
                    <h6
                      className="h6 mt-2"
                      id={styles.hub_title}
                      // style={{ color: "white",textAlign:"left",marginLeft:"10px !important" }}
                    >
                      Hub
                    </h6>
                  </TableCell>
                  <TableCell
                    id={styles.th_unit1}
                    style={{ textAlign: "center" }}
                  >
                    <h6
                      className="h6 mt-2"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      Total Sample Count
                    </h6>
                  </TableCell>
                  <TableCell
                    id={styles.th_unit1}
                    style={{ textAlign: "center" }}
                  >
                    <h6
                      className="h6 mt-2"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      12 Hrs
                    </h6>
                  </TableCell>
                  <TableCell
                    id={styles.th_unit1}
                    style={{ textAlign: "center" }}
                  >
                    <h6
                      className="h6 mt-2"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      24 Hrs
                    </h6>
                  </TableCell>

                  <TableCell
                    id={styles.th_unit1}
                    style={{ textAlign: "center" }}
                  >
                    <h6
                      className="h6 mt-2"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      48 Hrs
                    </h6>
                  </TableCell>
                  <TableCell
                    id={styles.th_unit1}
                    style={{ textAlign: "center" }}
                  >
                    <h6
                      className="h6 mt-2"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      48+ Hrs
                    </h6>
                  </TableCell>
                  <TableCell
                    id={styles.th_unit1}
                    style={{ textAlign: "center" }}
                  >
                    <h6
                      className="h6 mt-2"
                      style={{ color: "white", textAlign: "center" }}
                    >
                      Samples Not Yet Received
                    </h6>
                  </TableCell>
                </TableRow>
              </TableHead>
              {registartionData?.length == "0" ? (
                <TableBody>
                  <TableRow>
                    <TableCell id={styles.td_unit} colSpan={11}>
                      {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                      <p style={{ textAlign: "center", padding: "0px" }}>
                        No Data Available
                      </p>
                    </TableCell>
                    {/* </div> */}
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody id={styles.body_content}>
                  {registartionData.map((ele) => (
                    <TableRow>
                      <TableCell id={styles.td_unit}>
                        <p
                          style={{ textAlign: "left" }}
                          className="p mt-2 ml-4"
                        >
                          {ele?.hub}
                        </p>
                      </TableCell>
                      <TableCell id={styles.td_unit}>
                        <p className="p mt-2">{ele?.total_sample_count}</p>
                      </TableCell>
                      {/* <TableCell id={styles.td_unit}>
                          <p className="p mt-2" >{ele?.Collected_Month}</p>
                          </TableCell> */}
                      <TableCell id={styles.td_unit}>
                        <p className="p mt-2">{ele?.less_than_or_12_hour}</p>
                      </TableCell>
                      <TableCell id={styles.td_unit}>
                        <p className="p mt-2">{ele?.less_than_or_24_hour}</p>
                      </TableCell>
                      <TableCell id={styles.td_unit}>
                        <p className="p mt-2">{ele?.less_than_or_48_hour}</p>
                      </TableCell>
                      <TableCell id={styles.td_unit}>
                        <p className="p mt-2">{ele?.more_than_48_hour}</p>
                      </TableCell>
                      <TableCell id={styles.td_unit}>
                        <p className="p mt-2">{ele?.not_received_yet}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
}
export default Registration_Report;
