import React from 'react'
import styles from "../Styles/Table.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";


function Service_peneatration_tbl({
  data
}

) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");


  return (
<Paper
        sx={{
          // width: index == 0 ? "40%" : "20%",
          // width: "94%",
          mb: 2,
          padding: "10px",
          // marginLeft:"5%"
          // height: "500px",
        }}
      >
        <TableContainer
        id={styles.table_contain_service}
         >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                backgroundColor: "#007cc3",
                color: "#444a4f !important",
                position: "sticky",
                top: 0,
                zIndex: "999",
              }}
            >
              <TableRow>
                <TableCell
                  id={styles.th_unit1_sales}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                  colspan={38}
                >
                  <h5
                    className="ml-2 mt-2 mb-2"
                    style={{
                      color: "white",
                      textAlign: "left",
                      marginLeft: "1%",

                    }}
                  >
                    {" "}
                    Service Penetration Report
                  </h5>
                </TableCell>

                  {/* <TableCell
                    id={styles.th_unit1_sales}
                    className="dept_head"
                    style={{ borderBottom: "1px solid #89BBE0" ,padding:"0px"}}
                    colSpan={2}
                  >
                 
                  </TableCell>
               
                <TableCell
                  id={styles.th_unit1_sales}
                  style={{ borderBottom: "1px solid #89BBE0" }}
                  colSpan={
                 4
                  }
                >

                
                </TableCell> */}
              </TableRow>
               <TableRow>
                  {/* <TableCell id={styles.th_unit1_sales} colspan={3}></TableCell>           */}
                  <TableCell rowSpan={2} id={styles.th_unit1_sales}>
                      <h6>Customer</h6>
                    </TableCell>
                    <TableCell rowSpan={2} id={styles.th_unit1_sales}>
                      <h6>Site</h6>
                    </TableCell>
                    <TableCell rowSpan={2} id={styles.th_unit1_sales}>
                      <h6>Client Type</h6>
                    </TableCell>
                  <TableCell id={styles.th_unit1_sales} colspan={28}>
                    <h5 style={{textAlign:"center"}}>Testing</h5>
                  </TableCell>
                  <TableCell id={styles.th_unit1_sales} colspan={7}>
                    <h5 style={{textAlign:"center"}} >Non Testing</h5>
                  </TableCell>
               </TableRow>
              <TableRow>
             

                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Water Testing" placement="top"><h6>W</h6></Tooltip> 
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Food Microbiology Testing" placement="top"><h6>FM</h6></Tooltip> 
                
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Environment Testing" placement="top"><h6>EN</h6></Tooltip> 
            
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Swab Testing" placement="top"><h6>S</h6></Tooltip> 
             
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Indoor Air Quality Testing" placement="top"><h6>IAQ</h6></Tooltip> 
              
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Nutritional Label" placement="top"><h6>NL</h6></Tooltip> 
                   
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Shelf Life Testing" placement="top"><h6>SL</h6></Tooltip> 
                  
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Food Chemical Testing" placement="top"><h6>FC</h6></Tooltip> 
                   
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Noise Testing" placement="top"><h6>NI</h6></Tooltip> 
                  
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Migration Testing" placement="top"><h6>MT</h6></Tooltip> 
                 
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Food Testing" placement="top"><h6>FT</h6></Tooltip> 
                
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Ambient Air Quality Testing" placement="top"><h6>AAQ</h6></Tooltip> 
                     
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Compressed Air Testing" placement="top"><h6>CA</h6></Tooltip> 
                  
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Work Place Monitoring" placement="top"><h6>WPM</h6></Tooltip> 
                  
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Air Corrosion Testing" placement="top"><h6>AC</h6></Tooltip> 
                     
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="DG stack Testing" placement="top"><h6>DG</h6></Tooltip> 
                
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Boiler Stack Testing" placement="top"><h6>BS</h6></Tooltip> 
                    
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Furnace Stack Testing" placement="top"><h6>FS</h6></Tooltip> 
                   
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Incinerator Stack Testing" placement="top"><h6>IS</h6></Tooltip> 
                
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Continuous Ambient Noise (24 hrs) Testing" placement="top"><h6>CAN</h6></Tooltip> 
                      
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Spot Noise Testing" placement="top"><h6>SN</h6></Tooltip> 
                     
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Light Intensity Testing" placement="top"><h6>LI</h6></Tooltip> 
                   
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Beverages Testing" placement="top"><h6>BT</h6></Tooltip> 
                    
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Effluent Water Testing" placement="top"><h6>EW</h6></Tooltip> 
            
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Soil Testing" placement="top"><h6>ST</h6></Tooltip> 
                  
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Fuel Testing" placement="top"><h6>FU</h6></Tooltip> 
                 
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Hourly Ambient Noise (24 hrs) Testing" placement="top"><h6>HAN</h6></Tooltip> 
                  
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Others" placement="top"><h6>OT</h6></Tooltip> 
                     
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Training" placement="top"><h6>TR</h6></Tooltip> 
                   
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Nutrition Value Calculation" placement="top"><h6>NVC</h6></Tooltip> 
            
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Audit" placement="top"><h6>AU</h6></Tooltip> 
                    
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Compliance" placement="top"><h6>CM</h6></Tooltip> 
                 
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Monitoring" placement="top"><h6>MN</h6></Tooltip> 
               
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Other" placement="top"><h6>OT</h6></Tooltip> 
                 
                    </TableCell>
                    <TableCell id={styles.th_unit1_sales}>
                    <Tooltip title="Food Founders Club" placement="top"><h6>FFC</h6></Tooltip> 
               
                    </TableCell>

              </TableRow>
           
            </TableHead>
            {data?.length == "0" ? (
              <TableBody>
                {" "}
                <TableRow>
                  <TableCell colSpan={38} className={styles.th_unit}>
                    {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                    <p style={{ textAlign: "center" }}>No Data Available</p>
                  </TableCell>
                  {/* </div> */}
                </TableRow>
              </TableBody>
            ) : (
              <TableBody id={styles.body_content}>
                {(rowsPerPage > 0
                  ? data?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                )?.map((ele) => (
                  <TableRow>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2" style={{ textAlign: "left",width:"200px",padding:"0px 10px"  }}>
                        {ele?.company_name}
                      </p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                       <p className="p mt-2" style={{ textAlign: "left",width:"200px",padding:"0px 10px" }}>{ele?.site}</p>
                    </TableCell>
                    <TableCell sx={{paddingLeft:"10px",paddingRight:"50px"}} className={styles.th_unit}>
                    <p className="p mt-2" style={{ textAlign: "left",width:"200px",padding:"0px 10px"  }}>{ele?.client_type}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Water_Testing == undefined?0:ele?.Water_Testing}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Food_Microbiology_Testing == undefined?0: ele?.Food_Microbiology_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Environment_Testing == undefined?0:ele?.Environment_Testing }</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Swab_Testing == undefined?0:ele?.Swab_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Indoor_Air_Quality_Testing == undefined?0:ele?.Indoor_Air_Quality_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Nutritional_Label == undefined?0:ele?.Nutritional_Label}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Shelf_Life_Testing == undefined?0:ele?.Shelf_Life_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Food_Chemical_Testing == undefined?0:ele?.Food_Chemical_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Noise_Testing == undefined?0:ele?.Noise_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Migration_Testing== undefined?0:ele?.Migration_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Food_Testing== undefined?0:ele?.Food_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Ambient_Air_Quality_Testing== undefined?0:ele?.Ambient_Air_Quality_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Compressed_Air_Testing== undefined?0:ele?.Compressed_Air_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Work_Place_Monitoring== undefined?0:ele?.Work_Place_Monitoring}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Air_Corrosion_Testing== undefined?0:ele?.Air_Corrosion_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.DG_stack_Testing== undefined?0:ele?.DG_stack_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Boiler_Stack_Testing== undefined?0:ele?.Boiler_Stack_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Furnace_Stack_Testing== undefined?0:ele?.Furnace_Stack_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Incinerator_Stack_Testing== undefined?0:ele?.Incinerator_Stack_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Continuous_Ambient_Noise_24_hrs_Testing== undefined?0:ele?.Continuous_Ambient_Noise_24_hrs_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Spot_Noise_Testing== undefined?0:ele?.Spot_Noise_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Light_Intensity_Testing== undefined?0:ele?.Light_Intensity_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Beverages_Testing== undefined?0:ele?.Beverages_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Effluent_Water_Testing== undefined?0:ele?.Effluent_Water_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Soil_Testing== undefined?0:ele?.Soil_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Fuel_Testing== undefined?0:ele?.Fuel_Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Hourly_Ambient_Noise_24__Testing== undefined?0:ele?.Hourly_Ambient_Noise_24__Testing}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Others== undefined?0:ele?.Others}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Training== undefined?0:ele?.Training}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Nutrition_Value_Calculation== undefined?0:ele?.Nutrition_Value_Calculation}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Audit== undefined?0:ele?.Audit}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Compliance== undefined?0:ele?.Compliance}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Monitoring== undefined?0:ele?.Monitoring}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Other== undefined?0:ele?.Other}</p> 
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                    <p className="p mt-2" >{ele?.Food_Founders_Club== undefined?0:ele?.Food_Founders_Club}</p> 
                    </TableCell>
                 
                   
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          id={styles.footer_div}
          class="footer_div"
          sx={{ backgroundColor: "#007cc3", color: "white" }}
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
  )
}

export default Service_peneatration_tbl