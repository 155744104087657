import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get_lab_data } from "../Redux/actions";
import styles from "../Styles/Table.module.css";
import loader from "../Media/ezgif.com-optimize.gif";

import MiniDrawer from "../components/Minidrawer";
import Lab_data_tbl from "../components/Lab_data_tbl";

function Lab_wise_data() {
  const dispatch = useDispatch();
  const storage = window.sessionStorage;
  let USER_GUID = storage.getItem("USER_GUID");
  const navigate = useNavigate();
  let Role_id = storage.getItem("Role_id");

  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  const [chem_show, setChem_Show] = useState(true);
  const [micro_show, setMicro_Show] = useState(false);
  const [instru_show, setInstru_Show] = useState(false);
  const [out_show, setOut_Show] = useState(false);
  const [environment_show, setEnvironment_Show] = useState(false);
  const [soft_show, setSoft_Show] = useState(false);

  const [chem_loading, setChem_Loading] = useState(true);
  const [micro_loading, setMicro_Loading] = useState(false);
  const [instru_loading, setInstru_Loading] = useState(false);
  const [out_loading, setOut_Loading] = useState(false);
  const [environment_loading, setEnvironment_Loading] = useState(false);
  const [soft_loading, setSoft_Loading] = useState(false);


  const [lab_data, setLab_data] = useState([]);
  const [load, setLoad] = useState(false);

  const [header, setHeader] = useState("Chemistry Lab");

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);

  useEffect(() => {

    // dispatch(get_lab_data(setChem_Data, "chem_lab", setChem_Load, status));
    // dispatch(get_lab_data(setMicro_Data, "micro_lab", setMicro_Load, status));
    // dispatch(
    //   get_lab_data(setInstru_Data, "instru_lab", setInstru_Load, status)
    // );
    // dispatch(
    //   get_lab_data(
    //     setOutsource_Data,
    //     "outsource_lab",
    //     setOutsource_Load,
    //     status
    //   )
    // );
    // dispatch(
    //   get_lab_data(
    //     setEnvironment_Data,
    //     "environment_lab",
    //     setEnvironment_Load,
    //     status
    //   )
    // );
    // dispatch(
    //   get_lab_data(
    //     setHard_copy_Data,
    //     "hard_copy",
    //     setHard_copy_Load,
    //     hard_copy_status
    //   )
    // );

    // const controller = new AbortController();
    // const signal = controller.signal;
  if(Role_id != ""){
    dispatch(get_lab_data(setLab_data,"chem_lab", setChem_Loading));
  }
    // return()=>{
    //   controller.abort();
    // }
  }, []);

  const handleClick = (param) => {
    if (param == "chem_lab") {
      setChem_Show(true);
      setMicro_Show(false);
      setInstru_Show(false);
      setOut_Show(false);
      setEnvironment_Show(false);
      setSoft_Show(false);
      setHeader("Chemistry Lab");     
      dispatch(get_lab_data(setLab_data,"chem_lab", setChem_Loading));
    } else if (param == "micro_lab") {
      setMicro_Show(true);
      setChem_Show(false);
      setInstru_Show(false);
      setOut_Show(false);
      setEnvironment_Show(false);
      setSoft_Show(false);
      setHeader("Microbiology Lab");
      dispatch(get_lab_data(setLab_data,"micro_lab", setMicro_Loading));
    } else if (param == "instru_lab") {
      setInstru_Show(true);
      setChem_Show(false);
      setMicro_Show(false);
      setOut_Show(false);
      setEnvironment_Show(false);
      setSoft_Show(false);
      setHeader("Instrumentation Lab");
      dispatch(get_lab_data(setLab_data,"instru_lab", setInstru_Loading));
    } else if (param == "outsource_lab") {
      setOut_Show(true);
      setChem_Show(false);
      setMicro_Show(false);
      setInstru_Show(false);
      setEnvironment_Show(false);
      setSoft_Show(false);
      setHeader("Outsource Lab");
      dispatch(get_lab_data(setLab_data,"outsource_lab", setOut_Loading));
    } else if (param == "environment_lab") {
      setEnvironment_Show(true);
      setChem_Show(false);
      setMicro_Show(false);
      setInstru_Show(false);
      setOut_Show(false);
      setSoft_Show(false);
      setHeader("Environment Lab");
      dispatch(get_lab_data(setLab_data,"environment_lab", setEnvironment_Loading));
    } else {
      let x= 1;
      // setSoft_Show(true);
      // setChem_Show(false);
      // setMicro_Show(false);
      // setInstru_Show(false);
      // setOut_Show(false);
      // setEnvironment_Show(false);
      // setHeader("Soft Copy Pending");
      // dispatch(get_lab_data(setLab_data,"HUB", setSoft_Loading));
    }
  };

  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
        <div className="row mt-2" id={styles.lab_container}>
          <div  className="col-sm-0 col-md-1"></div>
          <div
            className="col-sm-6 col-md-2"
            onClick={() => handleClick("chem_lab")}
            style={{
              backgroundColor: chem_show ? "#007cc3" : "#e9f3fb",
              color: chem_show ? "white" : "#007cc3",
           
            }}
          >
            Chemistry{" "}
          </div>
          <div
            className="col-sm-6 col-md-2"
            onClick={() => handleClick("micro_lab")}
            style={{
              backgroundColor: micro_show ? "#007cc3" : "#e9f3fb",
              color: micro_show ? "white" : "#007cc3",
           
            }}
          >
            Microbiology{" "}
          </div>
          <div
            className="col-sm-6 col-md-2"
            onClick={() => handleClick("instru_lab")}
            style={{
              backgroundColor: instru_show ? "#007cc3" : "#e9f3fb",
              color: instru_show ? "white" : "#007cc3",
           
            }}
          >
            Instrumentation{" "}
          </div>
          <div
            className="col-sm-6 col-md-2"
            onClick={() => handleClick("outsource_lab")}
            style={{
              backgroundColor: out_show ? "#007cc3" : "#e9f3fb",
              color: out_show ? "white" : "#007cc3",
           
            }}
          >
            Outsource{" "}
          </div>
          <div
            className="col-sm-6 col-md-2"
            onClick={() => handleClick("environment_lab")}
            style={{
              backgroundColor: environment_show ? "#007cc3" : "#e9f3fb",
              color: environment_show ? "white" : "#007cc3",
           
            }}
          >
            Environment{" "}
          </div>
          <div  className="col-sm-0 col-md-1"></div>
          {/* <div
            className="col-sm-6 col-md-2"
            onClick={() => handleClick("HUB")}
            style={{
              backgroundColor: soft_show ? "#007cc3" : "#e9f3fb",
              color: soft_show ? "white" : "#007cc3",
            }}
          >
            Soft Copy Pending{" "}
          </div> */}
        </div>
        <div className="row mt-5">
          {/* {load ? (
            <img src={loader} id={styles.loader2} />
          ) : (
            <Lab_data_tbl data={lab_data} header_name={header} />
          )} */}

        {chem_show?<>
          { chem_loading? <img src={loader} id={styles.loader2} />: <Lab_data_tbl data={lab_data} header_name={header} />}
        </>:
        micro_show?<>
            { micro_loading? <img src={loader} id={styles.loader2} />: <Lab_data_tbl data={lab_data} header_name={header} />}
        </>:
        instru_show?<>
        { instru_loading? <img src={loader} id={styles.loader2} />: <Lab_data_tbl data={lab_data} header_name={header} />}
        </>:
        out_show?<>
         {out_loading? <img src={loader} id={styles.loader2} />: <Lab_data_tbl data={lab_data} header_name={header} />}
        </>:
        environment_show?<>
        {environment_loading? <img src={loader} id={styles.loader2} />: <Lab_data_tbl data={lab_data} header_name={header} />}
        </>:
        // soft_show?<>
        //   {soft_loading? <img src={loader} id={styles.loader2} />: <Lab_data_tbl data={lab_data} header_name={header} />}
        // </>:
        <></>}



        </div>
        {/* { check_loading ? <img src={report_loader} id={styles.loader} />: */}
        {/* <div className="row">
          {chem_load ? (
            <img src={loader} id={styles.lab_loader_img} />
          ) : (
            <Lab_data_tbl data={chem_data} header_name={"Chem Lab Data"}  data_func={setChem_Data} 
              params={"chem_lab"} load_func={setChem_Load} 
            />
          )}
        </div>
        <div className="row mt-5">
          {micro_load ? (
            <img src={loader} id={styles.lab_loader_img} />
          ) : (
            <Lab_data_tbl
              data={micro_data}
              header_name={"Microbiology Lab Data"}
              data_func={setMicro_Data} 
              params={"micro_lab"} load_func={setMicro_Load} 
            />
          )}
        </div>
        <div className="row mt-5">
          {instru_load ? (
            <img src={loader} id={styles.lab_loader_img} />
          ) : (
            <Lab_data_tbl
              data={instru_data}
              header_name={"Instrumentation Lab Data"}
              data_func={setInstru_Data} 
              params={"instru_lab"} load_func={setInstru_Load} 
            />
          )}
        </div>
        <div className="row mt-5">
          {outsource_load ? (
            <img src={loader} id={styles.lab_loader_img} />
          ) : (
            <Lab_data_tbl
              data={outsource_data}
              header_name={"Outsource Lab Data"}
              data_func={setOutsource_Data} 
              params={"outsource_lab"} load_func={setOutsource_Load} 
            />
          )}
        </div>
        <div className="row mt-5">
          {environment_load ? (
            <img src={loader} id={styles.lab_loader_img} />
          ) : (
            <Lab_data_tbl
              data={environment_data}
              header_name={"Environmental Lab Data"}
              data_func={setEnvironment_Data} 
              params={"environment_lab"} load_func={setEnvironment_Load} 
            />
          )}
        </div>
        <div className="row mt-5 mb-5">
          {hard_copy_load ? (
            <img src={loader} id={styles.lab_loader_img} />
          ) : (
            <Lab_data_tbl
              data={hard_copy_data}
              header_name={"Hard Copy Data"}
              data_func={setHard_copy_Data} 
              params={"hard_copy"} load_func={setHard_copy_Load} 
            />
          )}
        </div> */}
        {/* } */}
      </div>
    </>
  );
}

export default Lab_wise_data;
