// Get requests

export const GET_REQUEST = "GET_REQUEST";
export const GET_SUCCESS = "GET_SUCCESS";
export const GET_ERROR = "GET_ERROR";

// Get Order Requests

export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_ERROR = "ORDER_ERROR";

// Get New Leads

export const LEADS_REQUEST = "LEADS_REQUEST";
export const LEADS_SUCCESS = "LEADS_SUCCESS";
export const LEADS_ERROR = "LEADS_ERROR";


// Get category Leads

export const CATEGORY_DATA_REQUEST = "CATEGORY_DATA_REQUEST";
export const CATEGORY_DATA_SUCCESS = "CATEGORY_DATA_SUCCESS";
export const CATEGORY_DATA_ERROR = "CATEGORY_DATA_ERROR";


// Get category Leads Created on

export const CATEGORY_DATA_CREATED_ON_REQUEST = "CATEGORY_DATA_CREATED_ON_REQUEST";
export const CATEGORY_DATA_CREATED_ON_SUCCESS = "CATEGORY_DATA_CREATED_ON_SUCCESS";
export const CATEGORY_DATA_CREATED_ON_ERROR = "CATEGORY_DATA_CREATED_ON_ERROR";


// Get category Leads Updated on

export const CATEGORY_DATA_UPDATED_ON_REQUEST = "CATEGORY_DATA_UPDATED_ON_REQUEST";
export const CATEGORY_DATA_UPDATED_ON_SUCCESS = "CATEGORY_DATA_UPDATED_ON_SUCCESS";
export const CATEGORY_DATA_UPDATED_ON_ERROR = "CATEGORY_DATA_UPDATED_ON_ERROR";


// Get category Leads Revenue

export const CATEGORY_DATA_REVENUE_REQUEST = "CATEGORY_DATA_REVENUE_REQUEST";
export const CATEGORY_DATA_REVENUE_SUCCESS = "CATEGORY_DATA_REVENUE_SUCCESS";
export const CATEGORY_DATA_REVENUE_ERROR = "CATEGORY_DATA_REVENUE_ERROR";


// Get User Data

export const USER_DATA_REQUEST = "USER_DATA_REQUEST"
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS"
export const USER_DATA_ERROR = "USER_DATA_ERROR"


// Get Individual User Data

export const INDIVIDUAL_USER_REQUEST = "INDIVIDUAL_USER_REQUEST"
export const INDIVIDUAL_USER_SUCCESS = "INDIVIDUAL_USER_SUCCESS"
export const INDIVIDUAL_USER_ERROR = "INDIVIDUAL_USER_ERROR"



// Get Hub Wise Data

export const HUB_DATA_REQUEST = "HUB_DATA_REQUEST"
export const HUB_DATA_SUCCESS = "HUB_DATA_SUCCESS"
export const HUB_DATA_ERROR = "HUB_DATA_ERROR"


// Geography Wise Data

export const GEOGRAPHY_DATA_REQUEST = "GEOGRAPHY_DATA_REQUEST"
export const GEOGRAPHY_DATA_SUCCESS = "GEOGRAPHY_DATA_SUCCESS"
export const GEOGRAPHY_DATA_ERROR = "GEOGRAPHY_DATA_ERROR"


// Chem Lab Data

export const LAB_DATA_REQUEST = "LAB_DATA_REQUEST"
export const LAB_DATA_SUCCESS = "LAB_DATA_SUCCESS"
export const LAB_DATA_ERROR = "LAB_DATA_ERROR"


// Hub Wise Lab Data

export const HUB_WISE_REQUEST = "HUB_WISE_REQUEST"
export const HUB_WISE_SUCCESS = "HUB_WISE_SUCCESS"
export const HUB_WISE_ERROR = "HUB_WISE_ERROR"


// Registration Report

export const REGISTRATION_DATA_REQUEST = "REGISTRATION_DATA_REQUEST"
export const REGISTRATION_DATA_SUCCESS = "REGISTRATION_DATA_SUCCESS"
export const REGISTRATION_DATA_ERROR = "REGISTRATION_DATA_ERROR"


// Operation Report

export const OPERATION_DATA_REQUEST = "OPERATION_DATA_REQUEST"
export const OPERATION_DATA_SUCCESS = "OPERATION_DATA_SUCCESS"
export const OPERATION_DATA_ERROR = "OPERATION_DATA_ERROR"


// Logistics Report

export const LOGISTICS_DATA_REQUEST = "LOGISTICS_DATA_REQUEST"
export const LOGISTICS_DATA_SUCCESS = "LOGISTICS_DATA_SUCCESS"
export const LOGISTICS_DATA_ERROR = "LOGISTICS_DATA_ERROR"


// NPS Dashboard

export const NPS_DATA_REQUEST = "NPS_DATA_REQUEST"
export const NPS_DATA_SUCCESS = "NPS_DATA_SUCCESS"
export const NPS_DATA_ERROR = "NPS_DATA_ERROR"


// Payment Report

export const PAYMENT_DATA_REQUEST = "PAYMENT_DATA_REQUEST"
export const PAYMENT_DATA_SUCCESS = "PAYMENT_DATA_SUCCESS"
export const PAYMENT_DATA_ERROR = "PAYMENT_DATA_ERROR"


// Transection Report

export const TRANSECTION_DATA_REQUEST = "TRANSECTION_DATA_REQUEST"
export const TRANSECTION_DATA_SUCCESS = "TRANSECTION_DATA_SUCCESS"
export const TRANSECTION_DATA_ERROR = "TRANSECTION_DATA_ERROR"


// KAE Planning

export const KAE_DATA_REQUEST = "KAE_DATA_REQUEST"
export const KAE_DATA_SUCCESS = "KAE_DATA_SUCCESS"
export const KAE_DATA_ERROR = "KAE_DATA_ERROR"


// Billing report

export const BILLING_DATA_REQUEST = "BILLING_DATA_REQUEST"
export const BILLING_DATA_SUCCESS = "BILLING_DATA_SUCCESS"
export const BILLING_DATA_ERROR = "BILLING_DATA_ERROR"

// HR Team S6

export const TEAM_S6_REQUEST = "TEAM_S6_REQUEST"
export const TEAM_S6_SUCCESS = "TEAM_S6_SUCCESS"
export const TEAM_S6_ERROR = "TEAM_S6_ERROR"


