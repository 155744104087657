import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useState } from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@mui/material";
import Swal from "sweetalert2";

import { DatePicker, Space } from "antd";
import {
  daily_invoice_report,
  get_user_list,
  monthly_invoice_report,
} from "../Latest_addition_redux/actions";
import Oh_pop_up from "../Modals/Oh_pop_up";

import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const { RangePicker } = DatePicker;

function Invoice_monthly_report() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");
  let dept = storage.getItem("DEPT");
  const admin_param = storage.getItem("admin_param");
  const [monthly_data, setMonthly_Data] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));

  const [salesperson_list, setSalesperson_List] = useState([]);
  const [selected_salesperson, setSelected_salesperson] = useState("all");

  const [total_amt, setTotal_Amt] = useState(0);

  const [sales_load, setSales_Load] = useState(false);

  const formattedMonth = dayjs(new Date());
  let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY");

  const [send_month, setSend_month] = useState(new_month);
  const [send_year, setSend_year] = useState(new_year);

  const [oh_data, setOh_Data] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const check_loading = useSelector((state) => state.data2?.loading);

  const dispatch = useDispatch();

  const get_salesperson_list = async () => {
    setSales_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/get_unique_salesperson`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            dept_id:
              dept == "Tech" || dept == "Admin" || dept == "Management"
                ? admin_param
                : dept == "FnB Sales"
                ? "1"
                : dept == "Corporate Sales"
                ? "2"
                : "all",
          }),
        }
      );
      // let res = await fetch(`https://reportapi.equinoxlab.com/corporate_salesperson`);
      let data = await res.json();
      setSales_Load(false);
      setSalesperson_List(data?.Data);
    } catch (error) {
      setSales_Load(false);
    }
  };

  const handleDateChange = (new_val) => {};

  function getText(data) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = data;
    return divContainer.textContent || divContainer.innerText || "";
  }

  // const handleSubmit = (e)=>{
  //   e.preventDefault();

  //     dispatch(monthly_invoice_report(setMonthly_Data,invoice_start_date,invoice_end_date,selected_salesperson,department))

  // }
  const handleMonth = (date) => {
    if (date) {
      setSelectedMonth(date);
      let formattedMon = moment(date?.$d)?.format("MM");
      let formattedYear = moment(date?.$d)?.format("YYYY");
      setSend_year(formattedYear);
      setSend_month(formattedMon);
    } else {
      const formattedMonth = dayjs(new Date());
      let new_month = moment(formattedMonth.$d).format("MM");
      let new_year = moment(formattedMonth.$d).format("YYYY");
      setSelectedMonth(formattedMonth);
      setSend_month(new_month);
      setSend_year(new_year);
    }
  };

  const handleOH = (new_data) => {
    setOh_Data(new_data);
    handleOpen();
  };

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      dispatch(
        monthly_invoice_report(
          setMonthly_Data,
          send_month,
          send_year,
          selected_salesperson,
          setTotal_Amt,
          dept
        )
      );
    }
  }, [send_month, selected_salesperson, send_year]);

  useEffect(() => {
    if (Role_id != "" && Role_id != null && Role_id != "null") {
      get_salesperson_list();
    }
  }, []);

  const columns = [
    {
      name: "salesperson",
      label: "salesperson",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "oh_number",
      label: "OH Number",
      options: {
        filter: false,
        sort: false,
        download: false,
        customHeadLabelRender: () => {
          return (
            <>
              <p style={{ textAlign: "center" }}>OH Number</p>
            </>
          );
        },

        setCellProps: () => ({
          style: { width: "200px", maxWidth: "200px" },
        }),
        customBodyRender: (row, data) => {
          if (row == "" || row == null) {
            return <>--</>;
          }
          let new_data = row?.split(",");

          if (new_data?.length > 2) {
            return (
              <>
                <p
                  onClick={() => handleOH(new_data)}
                  style={{ color: "#007cc3", cursor: "pointer" }}
                >
                  View
                </p>
              </>
            );
          }

          return (
            <>
              {new_data?.map((ele) => (
                <>
                  <Chip
                    label={ele}
                    color="primary"
                    sx={{
                      marginLeft: "4px",
                      marginTop: "5px",
                      borderRadius: "7px",
                      height: "25px",
                    }}
                    variant="outlined"
                  />
                </>
              ))}
            </>
          );
        },
      },
    },
    {
      name: "total_invoice",
      label: "invoice count",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "total_amount",
      label: "total invoice amount (₹)",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <MiniDrawer />
      {sales_load || check_loading ? (
        <img src={report_loader} id={styles.loader} />
      ) : (
        <div
          style={{
            marginLeft: "2%",
            marginTop: "2%",
            marginBottom: "3%",
            width: "96%",
          }}
          id="corporate_tbl"
        >
          <MUIDataTable
            title={
              dept == "Tech" || dept == "Admin" || dept == "Management" ? (
                <h4 style={{ textAlign: "left" }}>
                  Invoice Monthly Report ({" "}
                  {admin_param == "1" ? "FnB" : "Corporate"} )
                </h4>
              ) : (
                <h4 style={{ textAlign: "left" }}>Invoice Monthly Report</h4>
              )
            }
            data={monthly_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              print: false,
              search: false,
              filter: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              tableBodyMaxHeight: "600px",
              rowsPerPageOptions: [10, 25, 50, 100],
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customTableBodyFooterRender: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                data
              ) => {
                const startIndex = page * rowsPerPage;
                const endIndex = (page + 1) * rowsPerPage;
                return (
                  <TableFooter id={styles.table_footer}>
                    <TableRow>
                      {/* <TableCell colSpan={4}></TableCell> */}
                      <TableCell colSpan={3} id={styles.first_col}>
                        {/* <p style={{textAlign:"right"}}>Invoice Total (₹)</p> */}
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          Total (₹) :{" "}
                          {new Intl.NumberFormat("en-IN").format(total_amt)}
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },
              customToolbar: () => {
                return (
                  <>
                    <div style={{ display: "inline-flex" }}>
                      <select
                        className="form-select"
                        id={styles.user_drop_invoice}
                        style={{
                          fontSize: "14px",
                          width: "250px",
                          maxWidth: "auto",
                        }}
                        onChange={(e) =>
                          setSelected_salesperson(e.target.value)
                        }
                        value={selected_salesperson}
                        required
                      >
                        <option value="all" selected>
                          All salesperson
                        </option>
                        {salesperson_list?.map((ele) => (
                          <option value={ele.salesperson}>
                            {ele.salesperson}
                          </option>
                        ))}
                      </select>
                      <Space direction="vertical">
                        <DatePicker
                          onChange={handleMonth}
                          picker="month"
                          value={selectedMonth}
                          format="MMM-YYYY"
                        />
                      </Space>
                    </div>
                  </>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}

      <Oh_pop_up
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        data={oh_data}
      />
    </>
  );
}

export default Invoice_monthly_report;
