import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MiniDrawer from "../components/Minidrawer";
import { useDispatch } from "react-redux";
import styles from "../Styles/Table.module.css";
import {
  bde_reporting_person_hub_list,
  bde_reporting_person_list,
  get_bde_calls_data,
  get_bde_leads_data,
  get_bde_meeting_data,
  get_check_leads_data,
} from "../Latest_addition_redux/actions";
import BDE_dashboard_tbl from "../components/BDE_dashboard_tbl";
import BDE_dashboard_tbl_hub from "../components/BDE_dashboard_tbl_hub";

function BDE_dashboard() {
  const storage = window.sessionStorage;
  let department = storage.getItem("DEPT");
  let Role_id = storage.getItem("Role_id");

  const [alignment, setAlignment] = React.useState("s6_calls_done");
  const [header_name, setHeader_Name] = React.useState("Call Details");

  const [calls_load, setCalls_Load] = React.useState(false);
  const [calls_data, setCalls_Data] = React.useState([]);

  const [meeting_load, setMeeting_Load] = React.useState(false);
  const [meeting_data, setMeeting_Data] = React.useState([]);

  const [leads_load, setLeads_Load] = React.useState(false);
  const [leads_data, setLeads_Data] = React.useState([]);

  const [reporting_data, setReporting_Data] = React.useState([]);
  const [reporting_person, setReporting_Person] = React.useState(null);

  const [reporting_data_hub, setReporting_Data_Hub] = React.useState([]);
  const [reporting_person_hub,setReporting_person_hub] = React.useState(null)


  const [check_data, setCheck_Data] = React.useState([]);
  const [check_load, setCheck_Load] = React.useState(false);

  const dispatch = useDispatch();  

  const handleChange = (event, newAlignment) => {
    if (newAlignment != null) {
      if (newAlignment == "s6_calls_done") {
        setHeader_Name("Call Details");
      } else if (newAlignment == "s6_meeting_done") {
        setHeader_Name("Meeting Details");
      } else if (newAlignment == "s6_leads_added") {
        setHeader_Name("Lead Details");
      }
       else if (newAlignment == "s6_proposal_sent") {
        setHeader_Name("Proposal Sent");
      } else if (newAlignment == "invoice_creation_s6_report") {
        setHeader_Name("Invoice Revenue");
      }
      else if(newAlignment == "order_amount_s6_report"){
        setHeader_Name("Order Revenue");
      }
      else if(newAlignment == "s6_interested"){
        setHeader_Name("Interested");
      }
      else if(newAlignment == "s6_leads_converted"){
        setHeader_Name("Leads Converted");
      }
      else if(newAlignment == "s6_customer_by_salesperson"){
        setHeader_Name("Customer");
      }
       else {
        let x = 1;
      }
      setAlignment(newAlignment);
    }
  };

  React.useEffect(() => {
    
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    const controller = new AbortController();
    const signal = controller.signal;
  if(
    alignment == "invoice_creation_s6_report" || 
    alignment == "order_amount_s6_report" ||
    alignment == "s6_customer_by_salesperson"
  ){
    dispatch(
      get_check_leads_data(
        setCheck_Data,
        setCheck_Load,
        department,
        reporting_person_hub,
        alignment,
        signal
      )
    );
  }
  else{
    dispatch(
      get_check_leads_data(
        setCheck_Data,
        setCheck_Load,
        department,
        reporting_person,
        alignment,
        signal
      )
    );
  }

    return () => {
      controller.abort();
    };
  }
  }, [alignment,reporting_person,reporting_person_hub]);
  // }, [alignment, reporting_person]);

  React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     setAlignment("")
  //     setAlignment("s6_calls_done")
  // }, 10000);

  if(Role_id != "" && Role_id != null && Role_id != "null"){
  dispatch(bde_reporting_person_list(department, setReporting_Data));
  }

  // return () => clearInterval(interval);

  }, []);

  React.useEffect(()=>{
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    if(
      alignment == "invoice_creation_s6_report" ||
     alignment == "order_amount_s6_report" ||
     alignment == "s6_customer_by_salesperson" 
    ){
      dispatch(bde_reporting_person_hub_list(department,setReporting_Data_Hub))
    }
  }
  },[alignment])

  return (
    <>
      <MiniDrawer />
      <div className="container-fluid">
        <div className="row mt-4">
          {/* <div className="col-md-4" style={{ textAlign: "left" }}> */}
          <div className="col-md-2" style={{ textAlign: "left" }}>
            <p id={styles.header_sales} className="p mt-4">
              BDE Dashboard
            </p>
          </div>
          {/* <div className="col-sm-12 col-md-8"> */}
          <div className="col-sm-12 col-md-10"  >
            <ToggleButtonGroup
              id={styles.toggle_btn}
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton id={styles.btn_bde} value="s6_calls_done">
                Calls Done
              </ToggleButton>
              <ToggleButton id={styles.btn_bde} value="s6_meeting_done">
                Meetings Done
              </ToggleButton>
              <ToggleButton id={styles.btn_bde} value="s6_leads_added">
                Leads Added
              </ToggleButton>
              <ToggleButton id={styles.btn_bde} value="s6_proposal_sent">
                Proposal Sent
              </ToggleButton>
              <ToggleButton
                id={styles.btn_bde}
                value="s6_interested"
              >
                Interested
              </ToggleButton>
              <ToggleButton
                id={styles.btn_bde}
                value="s6_leads_converted"
              >
                Leads Converted
              </ToggleButton>
              <ToggleButton
                id={styles.btn_bde}
                value="s6_customer_by_salesperson"
              >
                Clients Added
              </ToggleButton>
              <ToggleButton id={styles.btn_bde} value="order_amount_s6_report">
                Order Revenue
              </ToggleButton>
              <ToggleButton
                id={styles.btn_bde}
                value="invoice_creation_s6_report"
              >
                Invoice Revenue
              </ToggleButton>
              {/* <ToggleButton value="Calls Done">Calls Done</ToggleButton>
      <ToggleButton value="Meeting Done">Meeting Done</ToggleButton>
      <ToggleButton value="Leads Added">Leads Added</ToggleButton> */}
            </ToggleButtonGroup>
          </div>
        </div>


       {(
        alignment == "invoice_creation_s6_report" || 
        alignment == "order_amount_s6_report" ||
        alignment == "s6_customer_by_salesperson"
      )?
       <div className="row mt-3">
       <BDE_dashboard_tbl_hub
         data={check_data}
         load_func={check_load}
         reporting_data={reporting_data_hub}
         reporting_person={reporting_person_hub}
         setReporting_Person={setReporting_person_hub}
         header_name={header_name}
       />
     </div>
      : <div className="row mt-3">
          <BDE_dashboard_tbl
            data={check_data}
            load_func={check_load}
            reporting_data={reporting_data}
            reporting_person={reporting_person}
            setReporting_Person={setReporting_Person}
            header_name={header_name}
            alignment={alignment}
          />
        </div>}

        {/* <div className="row mt-3">
        <BDE_dashboard_tbl
            data={check_data}
            load_func={check_load}
            reporting_data={reporting_data}
            reporting_person={reporting_person}
            setReporting_Person={setReporting_Person}
            header_name={header_name}
            alignment={alignment}
          />
        </div> */}

      </div>
    </>
  );
}

export default BDE_dashboard;
