import React from 'react'
import Invoice_daily_report from '../pages/Invoice_daily_report'

function Invoice_daily_fnb() {
  return (
    <>
      <Invoice_daily_report/>
    </>
  )
}

export default Invoice_daily_fnb
