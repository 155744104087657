import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/Minidrawer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { payment_report } from "../Redux/actions";
import Swal from "sweetalert2";
import styles from "../Styles/Table.module.css"
import { useNavigate } from "react-router-dom";

function Payment_report() {
  const storage = window.sessionStorage;
  let DEPT = storage.getItem("DEPT");
    const [deptId, setDeptId]= useState(
      DEPT == "Corporate Sales"
        ? "2":
        DEPT == "FnB Sales" ?"1": DEPT== "Accounts"?"5":""
    );
    const [paymentData, setPaymentData] = useState([])

    const dispatch = useDispatch();
    const data = useSelector((state) => state.data.data);
    const check_loading = useSelector((state) => state.data.loading);
    const check_error = useSelector((state) => state.data.error);

  
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);

    useEffect(() => {

     if(DEPT == "Corporate Sales" || DEPT == "FnB Sales" || DEPT == "Accounts"  || Role_id!= "" ){
      if(deptId != ""){
        dispatch(payment_report(deptId, setPaymentData));
      }

     }
      }, []);

    // const columns = [   
    //   {
    //     name: "Day",
    //     label: "Entity",
    //     options: {
    //       filter: false,
    //       sort: false,
    //     },
    //   },
    //    {
    //   name: "Total_Enteries",
    //   label: "Total Entries",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (row) => {
    //       return <p style={{ marginLeft: "15%", marginTop: "15px" }}>{row}</p>;
    //     },
    //   },
    // },
    // {
    //   name: "Approved",
    //   label: "Approved",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
        
    // {
    //   name: "Pending",
    //   label: "Pending",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "Adjusted",
    //   label: "Adjusted",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     download: false,
    //   },
    // },
    // {
    //   name: "Rejected",
    //   label: "Rejected",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (row) => {
    //       return <p style={{ marginLeft: "15%", marginTop: "15px" }}>{row}</p>;
    //     },
    //   },
    // },
   
    
    //   ];


    const columns=[
      {
        name: "Status",
        label: "Status",
        options: {
          filter: true,
          sort: false,
          customHeadLabelRender: () => {
            return (
              <>
           
                  {/* <div > */}
                    <p
                      style={{display: "flex",
                      alignItems: "center" ,fontSize: "15px",
                      marginTop: "8px",
                      marginBottom: "0.4rem",marginLeft:"4%"}}
                    >
                       Status
                   
                    </p>{" "}
                  {/* </div> */}
                
              </>
            );
          },
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: "white",
              zIndex: 0,
              minWidth:"100px",
              maxWidth:"100px"
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 1000
            }
          }),
    
          customBodyRender: (row) => {
            return (
              <div style={{ textAlign: "left" }}>
                <p style={{ marginLeft: "20%" }}>{row}</p>
              </div>
            );
          },
        },
      },
      {
        name: "M",
        label: "M",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
                  
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "M1",
        label: "M1",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "M2",
        label: "M2",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "M3",
        label: "M3",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "M4",
        label: "M4",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "M5",
        label: "M5",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "M6",
        label: "M6",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Q",
        label: "Q",
        options: {
          filter: false,
          sort: true,

          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Q1",
        label: "Q1",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Q2",
        label: "Q2",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Q3",
        label: "Q3",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Q4",
        label: "Q4",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //          color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Q5",
        label: "Q5",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Q6",
        label: "Q6",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Y",
        label: "Y",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Y1",
        label: "Y1",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
  
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Y2",
        label: "Y2",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Y3",
        label: "Y3",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Y4",
        label: "Y4",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Y5",
        label: "Y5",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
  
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
      {
        name: "Y6",
        label: "Y6",
        options: {
          filter: false,
          sort: true,
          // setCellProps: (cellValue) => ({
          //   style: {
          //     backgroundColor:
          //       Number(cellValue?.props?.children) >= 0 &&
          //       Number(cellValue?.props?.children) < 20
          //         ? "#dff3ff"
          //         : Number(cellValue?.props?.children) >= 20 &&
          //           Number(cellValue?.props?.children) < 40
          //         ? "#9fdcff"
          //         : Number(cellValue?.props?.children) >= 40 &&
          //           Number(cellValue?.props?.children) < 60
          //         ? "#60c5ff"
          //         : Number(cellValue?.props?.children) >= 60 &&
          //           Number(cellValue?.props?.children) < 80
          //         ? "#20aeff"
          //         : Number(cellValue?.props?.children) >= 80 &&
          //           Number(cellValue?.props?.children) <= 600
          //         ? "#008edf"
          //         : Number(cellValue?.props?.children) >= 600
          //         ? "#1D5D9B"
          //         : "white",
          //         color:((Number(cellValue?.props?.children) >= 80 &&
          //         Number(cellValue?.props?.children) <= 600)||Number(cellValue?.props?.children) >= 600)?"white":"black"
          //   },
          // }),
          customBodyRender: (row) => {
            return <>{row}</>;
          },
        },
      },
    ]

    const options = {
        filterType: "checkbox",
      };

      const handleDeptChange = (e) => {
        if (e.target.value == "") {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
  
          Toast.fire({
            icon: "error",
            title: "Please Select Department",
          });
        }
        else {
          setDeptId(e.target.value)
          dispatch(payment_report(e.target.value, setPaymentData));
        }
      }

  return (
    <>
    <MiniDrawer />
    {
      check_loading ? (
        <img id={styles.loader_sample} src={report_loader} alt="" /> 
      ) :
      (
        <div className='container-fluid'>
        <div className={styles.opertion_tbl} style={{ marginLeft: "2%", marginTop: "2%", width: "96%", marginBottom:"3%" }}>
            <MUIDataTable
              title={<h5 style={{ textAlign: "left" }}>Payment Report</h5>}
              data={paymentData}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                print: false,
                responsive: "standard",
                download: false,
                search: false,
                filter: false,
                fixedHeader: true,
                pagination:false,
                tableBodyMaxHeight: "60vh",
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                sortOrder: {
                  name: 'Q',
                  direction: 'desc'
                },
                customToolbar: () => {
                  return (
                    <>
                      <div style={{ display: "inline-flex" }}>
                        <select onChange={handleDeptChange} value={deptId} id={styles.payment_drop}>
                          <option value="" selected disabled>Select Department</option>
                          <option value="1">Sales-FnB</option>
                          <option value="2">Sales-Corporate</option>
                          <option value="5">Accounts</option>
                        </select>
                      </div>
                    </>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: deptId == ""?"Please Select Department":"Data Not Available",
                  },
                },
              }}
            />
          </div>
      
    </div>
      )
    }
   
    </>
  )
}
export default Payment_report;
