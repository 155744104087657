import React, { useEffect, useState } from "react";
import MUIDataTable from 'mui-datatables';
import styles from "../Styles/Table.module.css";
import { DateRangePicker, Pagination, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import MiniDrawer from "../components/Minidrawer";
import { useDispatch } from "react-redux";
import { get_assigned_marketing_data } from "../DataStudioRedux/actions";

function Assigned_marketing_performance() {
    const storage = window.sessionStorage;
    let Role_id = storage.getItem("Role_id");
    const dispatch = useDispatch();
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    const [params, setParams] = useState("MTD");
    const [range_flag, setRange_Flag] = useState(false);

    const [assigned_data,setAssigned_Data] = useState([]);
    const [assigned_load,setAssigned_Load] = useState(false);

    const handleDateChange = (new_val) => {
        if (new_val !== null) {
          setSelectedFromDate(new_val[0]);
          setSelectedToDate(new_val[1]);
          dispatch(
            get_assigned_marketing_data(
              setAssigned_Load,
              setAssigned_Data,
              new_val[0],
              new_val[1],
              params
            )
          );
        } else {
          setSelectedFromDate(new Date());
          setSelectedToDate(new Date());
          setParams("MTD");
          setRange_Flag(false);
          dispatch(
            get_assigned_marketing_data(
              setAssigned_Load,
              setAssigned_Data,
              new Date(),
              new Date(),
              "MTD"
            )
          );
        }
      };
    
      const handleParamChange = (e) => {
        setParams(e.target.value);
        if (e.target.value == "Custom Date") {
          setRange_Flag(true);
        } else {
          setSelectedFromDate(new Date());
          setSelectedToDate(new Date());
          setRange_Flag(false);
          dispatch(
            get_assigned_marketing_data(
              setAssigned_Load,
              setAssigned_Data,
              selectedFromDate,
              selectedToDate,
              e.target.value
            )
          );
        }
      };

      useEffect(() => {
        if(Role_id != "" && Role_id != null && Role_id != "null"){
        dispatch(
          get_assigned_marketing_data(
            setAssigned_Load,
            setAssigned_Data,
            selectedFromDate,
            selectedToDate,
            params
          )
        );
      }
      }, []);

    const options = {
        filterType: "checkbox",
      };
      const columns = [
        {
          name: "corporate_lead_count",
          label: `Corporate Lead Count`,
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    padding: "5px",
                  }}
                >
                  <span style={{ marginLeft: "7px" }}>{row}</span>
                </div>
              );
            },
          },
        },
    
        {
          name: "fnb_lead_count",
          label: `FnB Lead Count`,
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    padding: "5px",
                  }}
                >
                  <span style={{ marginLeft: "7px" }}>{row}</span>
                </div>
              );
            },
          },
        },
      ];
  return (
    <>
    <MiniDrawer />
    {assigned_load ? (
      <div className="container-fluid pt-3">
        <img src={report_loader} id={styles.loader} />
      </div>
    ) : (
      <div className="container-fluid pt-3">
        <div className="row mt-3">
          <MUIDataTable
            title={
              <h5 style={{ textAlign: "left" }}>
                Assigned Marketing Performance
              </h5>
            }
            data={assigned_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              // tableBodyMaxHeight: "50vh",
              rowsPerPageOptions: [10, 30, 50, 100],
              // rowsPerPage: "",
              pagination:false,
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
              customToolbar: () => {
                return (
                  <div style={{ display: "inline-flex" }}>
                    <select
                      onChange={handleParamChange}
                      value={params}
                      className="form-select"
                      style={{ marginRight: "20px", width: "160px" }}
                    >
                      <option value="" selected disabled>
                        Select Date Range
                      </option>
                      <option value="Today">Today</option>
                      <option value="MTD">MTD</option>
                      <option value="QTD">QTD</option>
                      <option value="YTD">YTD</option>
                      <option value="FYTD">FYTD</option>
                      <option value="Custom Date">Custom Date</option>
                    </select>
                    {range_flag ? (
                      <DateRangePicker
                        // defaultValue={[new Date(), new Date()]}
                        format="dd MMM yyyy"
                        value={[selectedFromDate, selectedToDate]}
                        onChange={handleDateChange}
                        style={{ width: "300px" }}
                        size="md"
                        placement="bottomEnd"
                        preventOverflow
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              },
            }}
          />
        </div>
      </div>
    )}
  </>
  )
}

export default Assigned_marketing_performance
