import React from 'react'
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";
import LinearProgress from "@mui/joy/LinearProgress";
import Box from "@mui/joy/Box";

function Top_services_tbl({data,service_amount_total}) {

    const get_total =()=>{
        let total_count = data?.reduce((acc,ele)=>{
           return acc+Number(ele?.total_amount)
        },0)
        return new Intl.NumberFormat("en-IN").format(total_count);
  }

    const options = {
        filterType: "checkbox",
      };
      const columns = [
    
        {
          name: "service_name",
          label: "Top 10 - Service Name",
          options: {
            filter: false,
            sort: false,
            customBodyRender:(row)=>{
              return(
                <div style={{textAlign:"left",padding:"7px"}}>
                  <span>{row}</span>
                </div>
              )
            }
          },
        },
        {
          name: "total_amount",
          label: "Total Amount (₹)",
          options: {
            filter: false,
            sort: true,           
          customBodyRender:(row)=>{
            return(
              <div style={{display:"flex"}}>
                <div style={{width:"160px",textAlign:"left"}}><p>{new Intl.NumberFormat("en-IN").format(row)}</p></div>
                   <Box
                        sx={{
                          bgcolor: "white",
                          width: "70%",
                          marginTop:"3px",marginRight:"10px"
                        }}
                      >
                        <LinearProgress
                          determinate
                          variant="outlined"
                          size="sm"
                          thickness={20}
                          value={data?.length == 0?0:row/service_amount_total*100}
                          // value={progress_data?.length == 0?0:progress_data?.TOTAL_SOA_COUNT*progress_data?.TOTAL_SOA_SCHEDULED_COUNT/100}
                          sx={{
                            "--LinearProgress-radius": "0px",
                            "--LinearProgress-progressThickness": "100%",
                            boxShadow: "sm",
                            borderColor: "white",
                            backgroundColor: "white",
                            color: "#007cc3",
                          }}
                        >
                        </LinearProgress>
                      </Box>
              </div>
            )
          }
          },
        },
  
      ];
  return (
    <div id="corporate_tbl">
    <MUIDataTable
                title={<h5 style={{ textAlign: "left" }}>Top 10 Services</h5>}
                data={data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  filter: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  // tableBodyMaxHeight: "50vh",
                  rowsPerPageOptions:[10,30,50,100],
                  // rowsPerPage: "",
                  pagination:false,
                  sortOrder: {
                    name: 'InvoiceAmount',
                    direction: 'desc'
                  },
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available"
                    },
                  },
                  customTableBodyFooterRender: (
                      count,
                      page,
                      rowsPerPage,
                      changeRowsPerPage,
                      changePage,
                      data
                    ) => {
                      const startIndex = page * rowsPerPage;
                      const endIndex = (page + 1) * rowsPerPage;
                      return (
                        <TableFooter id={styles.table_footer}>
                          <TableRow>
                            <TableCell id={styles.first_col}>
                              <p style={{textAlign:"left"}}>Total (₹)</p>
                            </TableCell>
                            <TableCell><p style={{textAlign:"center"}}>{get_total()}</p></TableCell>
                          
                          </TableRow>
                        </TableFooter>
                      );
                    },
                 
                }}
              />
      </div>
  )
}

export default Top_services_tbl