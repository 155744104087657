import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Sales_analysis.module.css";
import Monthly_sales from "../components/Monthly_sales";
import Quaterly_sales from "../components/Quaterly_sales";
import Yearly_sales from "../components/Yearly_sales";
import { useDispatch } from "react-redux";
import no_data_icon from "../Media/no_data_icon.gif";
import { DatePicker, Space } from "antd";
import {
  get_monthly_non_testing,
  get_monthly_non_testing_count,
  get_monthly_pie_chart,
  get_monthly_pie_chart_count,
  get_monthly_sales,
  get_monthly_sales_count,
  get_monthly_testing,
  get_monthly_testing_count,
  get_quaterly_non_testing,
  get_quaterly_non_testing_count,
  get_quaterly_pie_chart,
  get_quaterly_pie_chart_count,
  get_quaterly_sales,
  get_quaterly_sales_count,
  get_quaterly_testing,
  get_quaterly_testing_count,
  get_send_mail_graph,
  get_yearly_non_testing,
  get_yearly_non_testing_count,
  get_yearly_pie_chart,
  get_yearly_pie_chart_count,
  get_yearly_sales,
  get_yearly_sales_count,
  get_yearly_testing,
  get_yearly_testing_count,
} from "../Latest_addition_redux/actions";
import dayjs from "dayjs";
import moment from "moment";

import Sales_pieChart from "../components/Sales_pieChart_month";

import Sales_pieChart_quarter from "../components/Sales_pieChart_quarter";
import Sales_pieChart_year from "../components/Sales_pieChart_year";
import Monthly_sales_count from "../Quantity_Components/Monthly_sales_count";
import Quaterly_Sales_count from "../Quantity_Components/Quaterly_Sales_count";
import Yearly_sales_count from "../Quantity_Components/Yearly_sales_count";

function Sales_analysis() {
  const storage = window.sessionStorage;
  let department = storage.getItem("DEPT");
  let Role_id = storage.getItem("Role_id");

  const [timeSpan, setTimeSpan] = useState("month");
  const [monthly_flag, setMonthly_Flag] = useState(true);
  const [quaterly_flag, setQuaterly_Flag] = useState(false);
  const [yearly_flag, setYearly_Flag] = useState(false);
  const [load_data, setLoad_Data] = useState(false);

  const [monthly_data, setMonthly_Data] = useState([]);
  const [fnb_sales_data_month, setFnb_Sales_Data_Month] = useState([]);
  const [corporate_sales_data_month, setCorporate_Sales_Data_Month] = useState(
    []
  );

  const [fnb_sales_data_quater, setFnb_Sales_Data_Quater] = useState([]);
  const [corporate_sales_data_quater, setCorporate_Sales_Data_Quater] =
    useState([]);

  const [fnb_sales_data_year, setFnb_Sales_Data_Year] = useState([]);
  const [corporate_sales_data_year, setCorporate_Sales_Data_Year] = useState(
    []
  );

  const [years_data, setYears_Data] = useState([]);

  const formattedMonth = dayjs(new Date());
  let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY")?.toString();

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [send_month, setSend_month] = useState("ALL");
  const [send_year, setSend_year] = useState("ALL");

  const [quater_year_qData, setQuater_Year_qData] = useState("2024-2025");
  const [quater_year_yData, setQuater_Year_yData] = useState("2024-2025");

  const [date_flag, setDate_flag] = useState(false);

  const [pie_chart_month, setPie_Chart_Month] = useState("ALL");
  const [pie_chart_year, setPie_Chart_Year] = useState("ALL");
  const [pie_data_month, setPieData_Month] = useState([]);
  const [pie_data_quarter, setPieData_Quarter] = useState([]);
  const [pie_data_year, setPieData_Year] = useState([]);
  const [pie_load, setPie_Load] = useState(false);

  const [pie_month, setPie_Month] = useState(null);

  const [pie_dept_month, setPie_Dept_Month] = useState(department);
  const [pie_dept_quarter, setPie_Dept_Quarter] = useState(department);
  const [pie_dept_year, setPie_Dept_Year] = useState(department);

  const [quarter, setQuarter] = useState("ALL");

  const [toggle_flag, setToggle_Flag] = useState(false);

  // testing service usestates

  const [load_data_testing, setLoad_data_Testing] = useState(false);
  const [monthly_data_testing, setMonthly_Data_Testing] = useState([]);
  const [fnb_sales_data_month_testing, setFnb_Sales_Data_Month_testing] =
    useState([]);
  const [
    corporate_sales_data_month_testing,
    setCorporate_Sales_Data_Month_Testing,
  ] = useState([]);
  const [fnb_sales_data_quater_test, setFnb_Sales_Data_Quater_Test] = useState(
    []
  );
  const [
    corportate_sales_data_quater_test,
    setCorporate_Sales_Data_Quater_Test,
  ] = useState([]);
  const [fnb_sales_data_year_test, setFnb_Sales_Data_Year_Test] = useState([]);
  const [corportate_sales_data_year_test, setCorporate_Sales_Data_Year_Test] =
    useState([]);
  const [years_data_test, setYears_Data_Test] = useState([]);

  // Non Testing services usestates

  const [load_data_non_test, setLoad_data_Non_Test] = useState(false);
  const [monthly_data_non_test, setMonthly_Data_Non_Test] = useState([]);
  const [fnb_sales_data_month_non_test, setFnb_Sales_Data_Month_Non_Test] =
    useState([]);
  const [
    corporate_sales_data_month_non_test,
    setCorporate_Sales_Data_Month_Non_Test,
  ] = useState([]);

  const [fnb_sales_data_quater_non_test, setFnb_Sales_Data_Quater_Non_Test] =
    useState([]);
  const [
    corportate_sales_data_quater_non_test,
    setCorporate_Sales_Data_Quater_Non_Test,
  ] = useState([]);
  const [fnb_sales_data_year_non_test, setFnb_Sales_Data_Year_Non_Test] =
    useState([]);
  const [
    corportate_sales_data_year_non_test,
    setCorporate_Sales_Data_Year_Non_Test,
  ] = useState([]);
  const [years_data_non_test, setYears_Data_Non_Test] = useState([]);

  // for overall sales (count)

  const [load_data_count, setLoad_data_Count] = useState(false);
  const [monthly_data_count, setMonthly_Data_Count] = useState([]);
  const [fnb_sales_data_month_count, setFnb_Sales_Data_Month_Count] = useState(
    []
  );
  const [
    corporate_sales_data_month_count,
    setCorporate_Sales_Data_Month_Count,
  ] = useState([]);
  const [pie_data_month_count, setPieData_Month_Count] = useState([]);

  const [pie_load_count, setPie_Load_Count] = useState(false);
  const [fnb_sales_data_quater_count, setFnb_Sales_Data_Quater_Count] =
    useState([]);
  const [
    corporate_sales_data_quater_count,
    setCorporate_Sales_Data_Quater_Count,
  ] = useState([]);
  const [pie_data_quarter_count, setPieData_Quarter_Count] = useState([]);

  const [fnb_sales_data_year_count, setFnb_Sales_Data_Year_Count] = useState(
    []
  );
  const [corporate_sales_data_year_count, setCorporate_Sales_Data_Year_Count] =
    useState([]);
  const [pie_data_year_count, setPieData_Year_Count] = useState([]);

  // for Testing Service (count)

  const [load_data_testing_count, setLoad_data_Testing_Count] = useState(false);
  const [monthly_data_testing_count, setMonthly_Data_Testing_Count] = useState(
    []
  );
  const [
    fnb_sales_data_month_testing_count,
    setFnb_Sales_Data_Month_testing_Count,
  ] = useState([]);
  const [
    corporate_sales_data_month_testing_count,
    setCorporate_Sales_Data_Month_Testing_Count,
  ] = useState([]);

  const [
    fnb_sales_data_quaterly_testing_count,
    setFnb_Sales_Data_Quaterly_testing_Count,
  ] = useState([]);
  const [
    corporate_sales_data_quaterly_testing_count,
    setCorporate_Sales_Data_Quaterly_Testing_Count,
  ] = useState([]);

  const [fnb_sales_data_year_test_count, setFnb_Sales_Data_Year_Test_Count] =
    useState([]);
  const [
    corportate_sales_data_year_test_count,
    setCorporate_Sales_Data_Year_Test_Count,
  ] = useState([]);
  const [years_data_test_count, setYears_Data_Test_Count] = useState([]);

  // for Non Testing Service (count)

  const [load_data_non_testing_count, setLoad_data_Non_Testing_Count] =
    useState(false);
  const [monthly_data_non_testing_count, setMonthly_Data_Non_Testing_Count] =
    useState([]);
  const [
    fnb_sales_data_month_non_testing_count,
    setFnb_Sales_Data_Month_Non_testing_Count,
  ] = useState([]);
  const [
    corporate_sales_data_month_non_testing_count,
    setCorporate_Sales_Data_Month_Non_Testing_Count,
  ] = useState([]);

  const [
    fnb_sales_data_quaterly_non_testing_count,
    setFnb_Sales_Data_Quater_Non_Test_Count,
  ] = useState([]);
  const [
    corporate_sales_data_quaterly_non_testing_count,
    setCorporate_Sales_Data_Quater_Non_Test_Count,
  ] = useState([]);

  const [
    fnb_sales_data_year_non_test_count,
    setFnb_Sales_Data_Year_Non_Test_Count,
  ] = useState([]);
  const [
    corportate_sales_data_year_non_test_count,
    setCorporate_Sales_Data_Year_Non_Test_Count,
  ] = useState([]);
  const [years_data_non_test_count, setYears_Data_Non_Test_Count] = useState(
    []
  );

  const dispatch = useDispatch();

  const handleRevenue_Count = (val) => {
    setDate_flag(false);
    if (val == "count") {
      setToggle_Flag(true);
      if (timeSpan == "month") {
        // setSend_month("ALL");
        // setSend_year("ALL");
        // setPie_Dept_Month(department);
        // setSelectedMonth(null);
        dispatch(
          get_monthly_sales_count(
            setLoad_data_Count,
            setMonthly_Data_Count,
            setFnb_Sales_Data_Month_Count,
            setCorporate_Sales_Data_Month_Count,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_testing_count(
            setLoad_data_Testing_Count,
            setMonthly_Data_Testing_Count,
            setFnb_Sales_Data_Month_testing_Count,
            setCorporate_Sales_Data_Month_Testing_Count,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_non_testing_count(
            setLoad_data_Non_Testing_Count,
            setMonthly_Data_Non_Testing_Count,
            setFnb_Sales_Data_Month_Non_testing_Count,
            setCorporate_Sales_Data_Month_Non_Testing_Count,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_pie_chart_count(
            setPie_Load_Count,
            send_month,
            send_year,
            setPieData_Month_Count,
            pie_dept_month
          )
        );
      } else if (timeSpan == "quater") {
        // setQuater_Year_qData("2024-2025");
        // setQuarter("ALL");
        // setPie_Dept_Month(department);

        dispatch(
          get_quaterly_sales_count(
            setLoad_data_Count,
            setFnb_Sales_Data_Quater_Count,
            setCorporate_Sales_Data_Quater_Count,
            quater_year_qData,
          )
        );
        dispatch(
          get_quaterly_pie_chart_count(
            setPie_Load_Count,
            setPieData_Quarter_Count,
            quater_year_qData,
            quarter,
            pie_dept_quarter
          )
        );

        dispatch(
          get_quaterly_testing_count(
            setLoad_data_Testing_Count,
            setFnb_Sales_Data_Quaterly_testing_Count,
            setCorporate_Sales_Data_Quaterly_Testing_Count,
            quater_year_qData,
          )
        );

        dispatch(
          get_quaterly_non_testing_count(
            setLoad_data_Non_Test,
            setFnb_Sales_Data_Quater_Non_Test_Count,
            setCorporate_Sales_Data_Quater_Non_Test_Count,
            quater_year_qData,
          )
        );
      } else if (timeSpan == "year") {
        // setQuater_Year_yData("2024-2025");
        // setPie_Dept_Month(department);
        dispatch(
          get_yearly_sales_count(
            setLoad_data_Count,
            setFnb_Sales_Data_Year_Count,
            setCorporate_Sales_Data_Year_Count,
            setYears_Data
          )
        );
        dispatch(
          get_yearly_pie_chart_count(
            setPie_Load_Count,
            setPieData_Year_Count,
            quater_year_yData,
            pie_dept_year
          )
        );

        dispatch(
          get_yearly_testing_count(
            setLoad_data_Testing_Count,
            setFnb_Sales_Data_Year_Test_Count,
            setCorporate_Sales_Data_Year_Test_Count,
            setYears_Data_Test_Count
          )
        );

        dispatch(
          get_yearly_non_testing_count(
            setLoad_data_Non_Testing_Count,
            setFnb_Sales_Data_Year_Non_Test_Count,
            setCorporate_Sales_Data_Year_Non_Test_Count,
            setYears_Data_Non_Test_Count
          )
        );
      } else {
        let x = 1;
      }
    } else if (val == "revenue") {
      setToggle_Flag(false);
      if (timeSpan == "month") {
        // setSend_month("ALL");
        // setSend_year("ALL");
        // setPie_Dept_Month(department);
        // setSelectedMonth(null);
        dispatch(
          get_monthly_sales(
            setLoad_Data,
            setMonthly_Data,
            setFnb_Sales_Data_Month,
            setCorporate_Sales_Data_Month,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_pie_chart(
            setPie_Load,
            send_month,
            send_year,
            setPieData_Month,
            pie_dept_month
          )
        );
        dispatch(
          get_monthly_testing(
            setLoad_Data,
            setMonthly_Data,
            setFnb_Sales_Data_Month,
            setCorporate_Sales_Data_Month,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_non_testing(
            setLoad_data_Non_Test,
            setMonthly_Data_Non_Test,
            setFnb_Sales_Data_Month_Non_Test,
            setCorporate_Sales_Data_Month_Non_Test,
            send_month,
            send_year
          )
        );
      } else if (timeSpan == "quater") {
        // setQuater_Year_qData("2024-2025");
        // setQuarter("ALL");
        // setPie_Dept_Month(department);

        dispatch(
          get_quaterly_sales(
            setLoad_Data,
            setFnb_Sales_Data_Quater,
            setCorporate_Sales_Data_Quater,
            quater_year_qData
          )
        );

        dispatch(
          get_quaterly_pie_chart(
            setPie_Load,
            setPieData_Quarter,
            quater_year_qData,
            quarter,
            pie_dept_quarter
          )
        );

        dispatch(
          get_quaterly_testing(
            setLoad_data_Testing,
            setFnb_Sales_Data_Quater_Test,
            setCorporate_Sales_Data_Quater_Test,
            quater_year_qData
          )
        );

        dispatch(
          get_quaterly_non_testing(
            setLoad_data_Non_Test,
            setFnb_Sales_Data_Quater_Non_Test,
            setCorporate_Sales_Data_Quater_Non_Test,
            quater_year_qData
          )
        );
      } else if (timeSpan == "year") {
        // setQuater_Year_yData("2024-2025");
        // setPie_Dept_Month(department);

        dispatch(
          get_yearly_sales(
            setLoad_Data,
            setFnb_Sales_Data_Year,
            setCorporate_Sales_Data_Year,
            setYears_Data
          )
        );
        dispatch(
          get_yearly_pie_chart(
            setPie_Load,
            setPieData_Year,
            "2024-2025",
            pie_dept_year
          )
        );

        dispatch(
          get_yearly_testing(
            setLoad_data_Testing,
            setFnb_Sales_Data_Year_Test,
            setCorporate_Sales_Data_Year_Test,
            setYears_Data_Test
          )
        );

        dispatch(
          get_yearly_non_testing(
            setLoad_data_Non_Test,
            setFnb_Sales_Data_Year_Non_Test,
            setCorporate_Sales_Data_Year_Non_Test,
            setYears_Data_Non_Test
          )
        );
      } else {
        let x = 1;
      }
    } else {
      let x = 1;
    }
  };

  const handleFlag = (val) => {
    if (val == "month") {
      setTimeSpan("month");
      setMonthly_Flag(true);
      setQuaterly_Flag(false);
      setYearly_Flag(false);
      setDate_flag(false);
      setSend_month("ALL");
      setSend_year("ALL");
      setPie_Dept_Month("ALL");
      setSelectedMonth(null);
      if (toggle_flag) {
        dispatch(
          get_monthly_sales_count(
            setLoad_data_Count,
            setMonthly_Data_Count,
            setFnb_Sales_Data_Month_Count,
            setCorporate_Sales_Data_Month_Count,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_testing_count(
            setLoad_data_Testing_Count,
            setMonthly_Data_Testing_Count,
            setFnb_Sales_Data_Month_testing_Count,
            setCorporate_Sales_Data_Month_Testing_Count,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_non_testing_count(
            setLoad_data_Non_Testing_Count,
            setMonthly_Data_Non_Testing_Count,
            setFnb_Sales_Data_Month_Non_testing_Count,
            setCorporate_Sales_Data_Month_Non_Testing_Count,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_pie_chart_count(
            setPie_Load_Count,
            send_month,
            send_year,
            setPieData_Month_Count,
            pie_dept_month
          )
        );
      } else {
        dispatch(
          get_monthly_sales(
            setLoad_Data,
            setMonthly_Data,
            setFnb_Sales_Data_Month,
            setCorporate_Sales_Data_Month,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_pie_chart(
            setPie_Load,
            send_month,
            send_year,
            setPieData_Month,
            department
          )
        );
        dispatch(
          get_monthly_testing(
            setLoad_Data,
            setMonthly_Data,
            setFnb_Sales_Data_Month,
            setCorporate_Sales_Data_Month,
            send_month,
            send_year
          )
        );
        dispatch(
          get_monthly_non_testing(
            setLoad_data_Non_Test,
            setMonthly_Data_Non_Test,
            setFnb_Sales_Data_Month_Non_Test,
            setCorporate_Sales_Data_Month_Non_Test,
            send_month,
            send_year
          )
        );
      }
    } else if (val == "quater") {
      setTimeSpan("quater");
      setQuaterly_Flag(true);
      setMonthly_Flag(false);
      setYearly_Flag(false);
      setQuater_Year_qData("2024-2025");
      setQuarter("ALL");
      setPie_Dept_Month("ALL");
      setPie_Dept_Quarter("ALL")
      if (toggle_flag) {
        dispatch(
          get_quaterly_sales_count(
            setLoad_data_Count,
            setFnb_Sales_Data_Quater_Count,
            setCorporate_Sales_Data_Quater_Count,
            "2024-2025"
          )
        );
        dispatch(
          get_quaterly_pie_chart_count(
            setPie_Load_Count,
            setPieData_Quarter_Count,
            quater_year_qData,
            quarter,
            department
          )
        );

        dispatch(
          get_quaterly_testing_count(
            setLoad_data_Testing_Count,
            setFnb_Sales_Data_Quaterly_testing_Count,
            setCorporate_Sales_Data_Quaterly_Testing_Count,
            "2024-2025"
          )
        );

        dispatch(
          get_quaterly_non_testing_count(
            setLoad_data_Non_Test,
            setFnb_Sales_Data_Quater_Non_Test_Count,
            setCorporate_Sales_Data_Quater_Non_Test_Count,
            "2024-2025"
          )
        );
      } else {
        dispatch(
          get_quaterly_sales(
            setLoad_Data,
            setFnb_Sales_Data_Quater,
            setCorporate_Sales_Data_Quater,
            "2024-2025"
          )
        );

        dispatch(
          get_quaterly_pie_chart(
            setPie_Load,
            setPieData_Quarter,
            quater_year_qData,
            quarter,
            department
          )
        );

        dispatch(
          get_quaterly_testing(
            setLoad_data_Testing,
            setFnb_Sales_Data_Quater_Test,
            setCorporate_Sales_Data_Quater_Test,
            "2024-2025"
          )
        );

        dispatch(
          get_quaterly_non_testing(
            setLoad_data_Non_Test,
            setFnb_Sales_Data_Quater_Non_Test,
            setCorporate_Sales_Data_Quater_Non_Test,
            "2024-2025"
          )
        );
      }
    } else if (val == "year") {
      setTimeSpan("year");
      setYearly_Flag(true);
      setQuaterly_Flag(false);
      setMonthly_Flag(false);
      setQuater_Year_yData("2024-2025");
      setPie_Dept_Month("ALL");
      setPie_Dept_Year("ALL")
      if (toggle_flag) {
        dispatch(
          get_yearly_sales_count(
            setLoad_data_Count,
            setFnb_Sales_Data_Year_Count,
            setCorporate_Sales_Data_Year_Count,
            setYears_Data
          )
        );
        dispatch(
          get_yearly_pie_chart_count(
            setPie_Load_Count,
            setPieData_Year_Count,
            "2024-2025",
            department
          )
        );

        dispatch(
          get_yearly_testing_count(
            setLoad_data_Testing_Count,
            setFnb_Sales_Data_Year_Test_Count,
            setCorporate_Sales_Data_Year_Test_Count,
            setYears_Data_Test_Count
          )
        );

        dispatch(
          get_yearly_non_testing_count(
            setLoad_data_Non_Testing_Count,
            setFnb_Sales_Data_Year_Non_Test_Count,
            setCorporate_Sales_Data_Year_Non_Test_Count,
            setYears_Data_Non_Test_Count
          )
        );
      } else {
        dispatch(
          get_yearly_sales(
            setLoad_Data,
            setFnb_Sales_Data_Year,
            setCorporate_Sales_Data_Year,
            setYears_Data
          )
        );
        dispatch(
          get_yearly_pie_chart(
            setPie_Load,
            setPieData_Year,
            "2024-2025",
            department
          )
        );

        dispatch(
          get_yearly_testing(
            setLoad_data_Testing,
            setFnb_Sales_Data_Year_Test,
            setCorporate_Sales_Data_Year_Test,
            setYears_Data_Test
          )
        );

        dispatch(
          get_yearly_non_testing(
            setLoad_data_Non_Test,
            setFnb_Sales_Data_Year_Non_Test,
            setCorporate_Sales_Data_Year_Non_Test,
            setYears_Data_Non_Test
          )
        );
      }
    } else {
      let x = 1;
    }
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    if (toggle_flag) {
      dispatch(
        get_monthly_sales_count(
          setLoad_data_Count,
          setMonthly_Data_Count,
          setFnb_Sales_Data_Month_Count,
          setCorporate_Sales_Data_Month_Count,
          send_month,
          send_year
        )
      );
      dispatch(
        get_monthly_testing_count(
          setLoad_data_Testing_Count,
          setMonthly_Data_Testing_Count,
          setFnb_Sales_Data_Month_testing_Count,
          setCorporate_Sales_Data_Month_Testing_Count,
          send_month,
          send_year
        )
      );
      dispatch(
        get_monthly_non_testing_count(
          setLoad_data_Non_Testing_Count,
          setMonthly_Data_Non_Testing_Count,
          setFnb_Sales_Data_Month_Non_testing_Count,
          setCorporate_Sales_Data_Month_Non_Testing_Count,
          send_month,
          send_year
        )
      );
    } else {
      dispatch(
        get_monthly_sales(
          setLoad_Data,
          setMonthly_Data,
          setFnb_Sales_Data_Month,
          setCorporate_Sales_Data_Month,
          send_month,
          send_year
        )
      );

      dispatch(
        get_monthly_testing(
          setLoad_data_Testing,
          setMonthly_Data_Testing,
          setFnb_Sales_Data_Month_testing,
          setCorporate_Sales_Data_Month_Testing,
          send_month,
          send_year
        )
      );

      dispatch(
        get_monthly_non_testing(
          setLoad_data_Non_Test,
          setMonthly_Data_Non_Test,
          setFnb_Sales_Data_Month_Non_Test,
          setCorporate_Sales_Data_Month_Non_Test,
          send_month,
          send_year
        )
      );
    }
    // dispatch(
    //   get_send_mail_graph(
    //     fnb_sales_data_month,
    //     corporate_sales_data_month,
    //     pie_data_month,
    //     fnb_sales_data_month_testing,
    //     corporate_sales_data_month_testing,
    //     fnb_sales_data_month_non_test,
    //     corporate_sales_data_month_non_test
    //   )
    // );
    //  if(send_month!="ALL"){
    //   send_graph_mail();
    //  }
  }
  }, [send_month]);

  const send_graph_mail = async () => {
    try {
      let res = await fetch(
        "https://beta-hire.equinoxlab.com/api/Candidate_Dashboard.svc/send_mail",
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            // To: "athang.equinox@gmail.com",
            // To: "sharad.borde@equinoxlab.com",
            To: "pratikpaul.equinox@gmail.com",
            Subject: "TEST Graphs 1",
            MailBody:
              // `<p>Monthly Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_testing[1]},${fnb_sales_data_month_testing[2]},${fnb_sales_data_month_testing[3]},${fnb_sales_data_month_testing[4]},${fnb_sales_data_month_testing[5]},${fnb_sales_data_month_testing[6]},${fnb_sales_data_month_testing[7]},${fnb_sales_data_month_testing[8]},${fnb_sales_data_month_testing[9]},${fnb_sales_data_month_testing[10]},${fnb_sales_data_month_testing[11]},0],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_testing[1]},${corporate_sales_data_month_testing[2]},${corporate_sales_data_month_testing[3]},${corporate_sales_data_month_testing[4]},${corporate_sales_data_month_testing[5]},${corporate_sales_data_month_testing[6]},${corporate_sales_data_month_testing[7]},${corporate_sales_data_month_testing[8]},${corporate_sales_data_month_testing[9]},${corporate_sales_data_month_testing[10]},${corporate_sales_data_month_testing[11]},0],fill:false}]}}" />`
              `<div style='display:flex;justify-content:center;gap:2vh'><div><p>Overall Monthly Sale</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month}],fill:false}]},options:{plugins:{datalabels:{anchor:'top',align:'top',color:'black',font:{weight:'normal'}}}}}" /></div><div><p>Overall Monthly Sale Piechart</p><img style='height:270px;width:auto' src="https://quickchart.io/chart?c={type:'doughnut',data:{labels:['Testing Service','Non Testing Service'],datasets:[{data:[${pie_data_month[0].testing},${pie_data_month[0].non_testing}],fontColor:'white'}]},options:{plugins:{datalabels:{anchor:'top',align:'top',color:'black',font:{weight:'normal'}}}}}" /></div></div><div style='display:flex;justify-content:center;gap:2vh'><div><p>Monthly Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_testing}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_testing}],fill:false}]}}" /></div><div><p>Monthly Non Testing Service</p><img style='height:300px;width:auto' src="https://quickchart.io/chart?c={type:'line',data:{labels:['','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar'],datasets:[{label:'Fnb Sales',data:[${fnb_sales_data_month_non_test}],fill:false},{label:'corporate Sales',data:[${corporate_sales_data_month_non_test}],fill:false}]}}" /></div></div>`,
          }),
        }
      );
      let data = await res.json();
      storage.setItem("load_mail", 1);
    } catch (error) {
      ;
    }
  };

  // ,options:{plugins:{datalabels:{anchor:'top',align:'top',color:'black',font:{weight:'normal'}}}}

  useEffect(() => {
    let count = storage.getItem("load_mail");
    //  if(count !=1){
    //  send_graph_mail();
    //  }
  }, []);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    if (toggle_flag) {
      dispatch(
        get_monthly_pie_chart_count(
          setPie_Load_Count,
          send_month,
          send_year,
          setPieData_Month_Count,
          pie_dept_month
        )
      );
    } else {
      dispatch(
        get_monthly_pie_chart(
          setPie_Load,
          send_month,
          send_year,
          setPieData_Month,
          pie_dept_month
        )
      );
    }
  }
  }, [send_month, pie_dept_month]);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    if (toggle_flag) {
      dispatch(
        get_quaterly_pie_chart_count(
          setPie_Load_Count,
          setPieData_Quarter_Count,
          quater_year_qData,
          quarter,
          pie_dept_quarter
        )
      );
    } else {
      dispatch(
        get_quaterly_pie_chart(
          setPie_Load,
          setPieData_Quarter,
          quater_year_qData,
          quarter,
          pie_dept_quarter
        )
      );
    }
  }
  }, [quater_year_qData, pie_dept_quarter, quarter]);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    if (toggle_flag) {
      dispatch(
        get_yearly_pie_chart_count(
          setPie_Load_Count,
          setPieData_Year_Count,
          quater_year_yData,
          pie_dept_year
        )
      );
    } else {
      dispatch(
        get_yearly_pie_chart(
          setPie_Load,
          setPieData_Year,
          quater_year_yData,
          pie_dept_year
        )
      );
    }
  }
  }, [quater_year_yData, pie_dept_year]);

  const get_quaterly_func_pie = () => {
    dispatch(
      get_quaterly_pie_chart(
        setPie_Load,
        setPieData_Quarter,
        quater_year_qData,
        quarter,
        pie_dept_quarter
      )
    );
  };

  const handleMonth = (date, dateString) => {
    if (date) {
      setDate_flag(true);
      setSelectedMonth(date);
      let formattedMon = moment(date?.$d)?.format("MM");
      let formattedYear = moment(date?.$d)?.format("YYYY");
      setSend_year(formattedYear);
      setSend_month(formattedMon);
    } else {
      setDate_flag(false);
      setSelectedMonth(null);
      setSend_year("ALL");
      setSend_month("ALL");
    }
  };

  const handleQuater = (e) => {
    setQuater_Year_qData(e.target.value);
    if (toggle_flag) {
      dispatch(
        get_quaterly_sales_count(
          setLoad_data_Count,
          setFnb_Sales_Data_Quater_Count,
          setCorporate_Sales_Data_Quater_Count,
          e.target.value
        )
      );


      dispatch(
        get_quaterly_testing_count(
          setLoad_data_Testing_Count,
          setFnb_Sales_Data_Quaterly_testing_Count,
          setCorporate_Sales_Data_Quaterly_Testing_Count,
          e.target.value
        )
      );

      dispatch(
        get_quaterly_non_testing_count(
          setLoad_data_Non_Test,
          setFnb_Sales_Data_Quater_Non_Test_Count,
          setCorporate_Sales_Data_Quater_Non_Test_Count,
          e.target.value
        )
      );

    }else{
      dispatch(
        get_quaterly_sales(
          setLoad_Data,
          setFnb_Sales_Data_Quater,
          setCorporate_Sales_Data_Quater,
          e.target.value
        )
      );
  
      dispatch(
        get_quaterly_testing(
          setLoad_data_Testing,
          setFnb_Sales_Data_Quater_Test,
          setCorporate_Sales_Data_Quater_Test,
          e.target.value
        )
      );
      dispatch(
        get_quaterly_non_testing(
          setLoad_data_Non_Test,
          setFnb_Sales_Data_Quater_Non_Test,
          setCorporate_Sales_Data_Quater_Non_Test,
          e.target.value
        )
      );
    }
   
  };

  const handlePieMonth = (date, dateString) => {
    if (date) {
      // setDate_flag(true)
      setPie_Month(date);
      let formattedMon = moment(date?.$d)?.format("MM");
      let formattedYear = moment(date?.$d)?.format("YYYY");
      setPie_Chart_Year(formattedYear);
      setPie_Chart_Month(formattedMon);
    } else {
      setDate_flag(false);
      setPie_Month(null);
      setPie_Chart_Year("ALL");
      setPie_Chart_Month("ALL");
    }
  };

  return (
    <>
      {/* Overall Revenue */}
      <MiniDrawer />
      <div className="container-fluid ml-5 mt-1">
        <div
          id={styles.tab_contain}
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          }}
          className="row p-0 pb-2"
        >
          {/* <div  className='col-sm-0 col-md-9'></div> */}
          {/* <div
            className={yearly_flag ? "col-md-8" : "col-md-4"}
            style={{
              textAlign: "left",
              border: "1px solid white",
              pointerEvents: "none",
            }}
          >
            <p
              style={{ fontWeight: "bold", fontSize: "24px", color: "#007cc3" }}
              className="p mt-4"
            >
 
            </p>
          </div> */}
          <div id={styles.toggle_container} className="col-sm-12 col-md-2 mt-2">
            <div
              style={{
                backgroundColor: !toggle_flag ? "#007cc3" : "white",
                color: !toggle_flag ? "white" : "#007cc3",
              }}
              onClick={() => handleRevenue_Count("revenue")}
            >
              Revenue
            </div>
            <div
              style={{
                backgroundColor: toggle_flag ? "#007cc3" : "white",
                color: toggle_flag ? "white" : "#007cc3",
              }}
              onClick={() => handleRevenue_Count("count")}
            >
              Count
            </div>
          </div>

          {quaterly_flag ? (
            <div
              className="col-sm-12 col-md-2"
              style={{ textAlign: "left", border: "1px solid white" }}
            >
              {/* <span style={{ fontSize: "10px", color: "grey" }}>
                Select Year
              </span> */}
              <select
                style={{ width: "150px" }}
                className="form-select mt-4"
                name=""
                id=""
                value={quater_year_qData}
                onChange={handleQuater}
              >
                <option value="2015-2016">2015-16</option>
                <option value="2016-2017">2016-17</option>
                <option value="2017-2018">2017-18</option>
                <option value="2018-2019">2018-19</option>
                <option value="2019-2020">2019-20</option>
                <option value="2020-2021">2020-21</option>
                <option value="2021-2022">2021-22</option>
                <option value="2022-2023">2022-23</option>
                <option value="2023-2024">2023-24</option>
                <option value="2024-2025">2024-25</option>
              </select>
            </div>
          ) : (
            <></>
          )}

          {monthly_flag ? (
            <div
              className="col-sm-12 col-md-2 mt-4"
              style={{ border: "1px solid white" }}
            >
              <Space direction="vertical">
                <DatePicker
                  onChange={handleMonth}
                  picker="month"
                  value={selectedMonth}
                  format="MMM-YYYY"
                />
              </Space>
            </div>
          ) : (
            <></>
          )}
          <div
            onClick={() => handleFlag("month")}
            style={{
              backgroundColor: monthly_flag ? "#007cc3" : "white",
              color: monthly_flag ? "white" : "#007cc3",
              height: "40px",
            }}
            className="col-sm-12 col-md-1 pt-2 mt-4"
          >
            Monthly
          </div>
          <div
            onClick={() => handleFlag("quater")}
            style={{
              backgroundColor: quaterly_flag ? "#007cc3" : "white",
              color: quaterly_flag ? "white" : "#007cc3",
              height: "40px",
            }}
            className="col-sm-12 col-md-1 pt-2 mt-4"
          >
            Quarterly
          </div>
          <div
            onClick={() => handleFlag("year")}
            style={{
              backgroundColor: yearly_flag ? "#007cc3" : "white",
              color: yearly_flag ? "white" : "#007cc3",
              height: "40px",
            }}
            className="col-sm-12 col-md-1 pt-2 mt-4"
          >
            Yearly
          </div>
        </div>

        <div className="row mb-5 mt-5">
          {monthly_flag ? (
            <>
              {toggle_flag ? (
                <>
                  <div className="col-sm-12 col-md-7" id={styles.graph_container}>
                    <Monthly_sales_count
                      data={monthly_data_count}
                      fnb_sales_data={fnb_sales_data_month_count}
                      setFnb_Sales_Data={setFnb_Sales_Data_Month_Count}
                      corporate_sales_data={corporate_sales_data_month_count}
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Month_Count
                      }
                      handleMonth={handleMonth}
                      selectedMonth={selectedMonth}
                      load_monthly={load_data_count}
                      date_flag={date_flag}
                      width_flag={"overall"}
                    />
                  </div>
                  <div className="col-sm-12 col-md-5" id={styles.graph_container}>
                    {/* <Test_piechart/> */}

                    <Sales_pieChart
                      pie_load={pie_load_count}
                      pieData={pie_data_month_count}
                      handlePieMonth={handlePieMonth}
                      pie_month={pie_month}
                      pie_dept={pie_dept_month}
                      setPie_Dept={setPie_Dept_Month}
                      department={department}
                      count_flag= {true}
                    />
                  </div>
                  <div className="col-12">
                    <Monthly_sales_count
                      data={monthly_data_testing_count}
                      fnb_sales_data={fnb_sales_data_month_testing_count}
                      setFnb_Sales_Data={setFnb_Sales_Data_Month_testing_Count}
                      corporate_sales_data={
                        corporate_sales_data_month_testing_count
                      }
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Month_Testing_Count
                      }
                      handleMonth={handleMonth}
                      selectedMonth={selectedMonth}
                      load_monthly={load_data_testing_count}
                      date_flag={date_flag}
                      width_flag={"testing"}
                    />
                  </div>
                  <div className="col-12" id={styles.graph_container}>
                    <Monthly_sales_count
                      data={monthly_data_non_testing_count}
                      fnb_sales_data={fnb_sales_data_month_non_testing_count}
                      setFnb_Sales_Data={
                        setFnb_Sales_Data_Month_Non_testing_Count
                      }
                      corporate_sales_data={
                        corporate_sales_data_month_non_testing_count
                      }
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Month_Non_Testing_Count
                      }
                      handleMonth={handleMonth}
                      selectedMonth={selectedMonth}
                      load_monthly={load_data_non_testing_count}
                      date_flag={date_flag}
                      width_flag={"non_testing"}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-sm-12 col-md-7" id={styles.graph_container}>
                    <Monthly_sales
                      data={monthly_data}
                      fnb_sales_data={fnb_sales_data_month}
                      setFnb_Sales_Data={setFnb_Sales_Data_Month}
                      corporate_sales_data={corporate_sales_data_month}
                      setCorporate_Sales_Data={setCorporate_Sales_Data_Month}
                      handleMonth={handleMonth}
                      selectedMonth={selectedMonth}
                      load_monthly={load_data}
                      date_flag={date_flag}
                      width_flag={"overall"}
                    />
                  </div>
                  <div className="col-sm-12 col-md-5" id={styles.graph_container}> 
                    {/* <Test_piechart/> */}

                    <Sales_pieChart
                      pie_load={pie_load}
                      pieData={pie_data_month}
                      handlePieMonth={handlePieMonth}
                      pie_month={pie_month}
                      pie_dept={pie_dept_month}
                      setPie_Dept={setPie_Dept_Month}
                      department={department}
                    />
                  </div>
                  <div className="col-12" id={styles.graph_container}>
                    <Monthly_sales
                      data={monthly_data_testing}
                      fnb_sales_data={fnb_sales_data_month_testing}
                      setFnb_Sales_Data={setFnb_Sales_Data_Month_testing}
                      corporate_sales_data={corporate_sales_data_month_testing}
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Month_Testing
                      }
                      handleMonth={handleMonth}
                      selectedMonth={selectedMonth}
                      load_monthly={load_data_testing}
                      date_flag={date_flag}
                      width_flag={"testing"}
                    />
                  </div>
                  <div className="col-12" id={styles.graph_container}>
                    <Monthly_sales
                      data={monthly_data_non_test}
                      fnb_sales_data={fnb_sales_data_month_non_test}
                      setFnb_Sales_Data={setFnb_Sales_Data_Month_Non_Test}
                      corporate_sales_data={corporate_sales_data_month_non_test}
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Month_Non_Test
                      }
                      handleMonth={handleMonth}
                      selectedMonth={selectedMonth}
                      load_monthly={load_data}
                      date_flag={date_flag}
                      width_flag={"non_testing"}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}

          {quaterly_flag ? (
            <>
              <div className="row mt-5">
                {toggle_flag ? (
                  <>
                    {" "}
                    <div className="col-sm-12 col-md-7" id={styles.graph_container}>
                      <Quaterly_Sales_count
                        fnb_sales_data={fnb_sales_data_quater_count}
                        setFnb_Sales_Data={setFnb_Sales_Data_Quater_Count}
                        corporate_sales_data={corporate_sales_data_quater_count}
                        setCorporate_Sales_Data={setCorporate_Sales_Data_Quater}
                        load_data={load_data_count}
                        quater_year={quater_year_qData}
                        setQuater_Year={setQuater_Year_qData}
                        handleQuater={handleQuater}
                        width_flag={"overall"}
                      />
                      {/* <Overall_sales_pie_chart/> */}
                    </div>
                    <div className="col-sm-12 col-md-5" id={styles.graph_container}>
                      <Sales_pieChart_quarter
                        pie_load={pie_load_count}
                        pieData={pie_data_quarter_count}
                        setPie_Dept={setPie_Dept_Quarter}
                        quarter={quarter}
                        setQuarter={setQuarter}
                        pie_dept={pie_dept_quarter}
                        department={department}
                        count_flag= {true}
                      />
                    </div>
                    <div className="col-12" id={styles.graph_container}>
                      <Quaterly_Sales_count
                        fnb_sales_data={fnb_sales_data_quaterly_testing_count}
                        setFnb_Sales_Data={
                          setFnb_Sales_Data_Quaterly_testing_Count
                        }
                        corporate_sales_data={
                          corporate_sales_data_quaterly_testing_count
                        }
                        setCorporate_Sales_Data={
                          setCorporate_Sales_Data_Quaterly_Testing_Count
                        }
                        load_data={load_data_testing_count}
                        quater_year={quater_year_qData}
                        setQuater_Year={setQuater_Year_qData}
                        handleQuater={handleQuater}
                        width_flag={"testing"}
                      />
                    </div>
                    <div className="col-12" id={styles.graph_container}>
                      <Quaterly_Sales_count
                        fnb_sales_data={
                          fnb_sales_data_quaterly_non_testing_count
                        }
                        setFnb_Sales_Data={
                          setFnb_Sales_Data_Quater_Non_Test_Count
                        }
                        corporate_sales_data={
                          corporate_sales_data_quaterly_non_testing_count
                        }
                        setCorporate_Sales_Data={
                          setCorporate_Sales_Data_Quater_Non_Test_Count
                        }
                        load_data={load_data_non_testing_count}
                        quater_year={quater_year_qData}
                        setQuater_Year={setQuater_Year_qData}
                        handleQuater={handleQuater}
                        width_flag={"non_testing"}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-sm-12 col-md-7" id={styles.graph_container}>
                      <Quaterly_sales
                        fnb_sales_data={fnb_sales_data_quater}
                        setFnb_Sales_Data={setFnb_Sales_Data_Quater}
                        corporate_sales_data={corporate_sales_data_quater}
                        setCorporate_Sales_Data={setCorporate_Sales_Data_Quater}
                        load_data={load_data}
                        quater_year={quater_year_qData}
                        setQuater_Year={setQuater_Year_qData}
                        handleQuater={handleQuater}
                        width_flag={"overall"}
                      />
                      {/* <Overall_sales_pie_chart/> */}
                    </div>
                    <div className="col-sm-12 col-md-5" id={styles.graph_container}>
                      <Sales_pieChart_quarter
                        pie_load={pie_load}
                        pieData={pie_data_quarter}
                        setPie_Dept={setPie_Dept_Quarter}
                        quarter={quarter}
                        setQuarter={setQuarter}
                        pie_dept={pie_dept_quarter}
                        department={department}
                        
                      />
                    </div>
                    <div className="col-12" id={styles.graph_container}>
                      <Quaterly_sales
                        fnb_sales_data={fnb_sales_data_quater_test}
                        setFnb_Sales_Data={setFnb_Sales_Data_Quater_Test}
                        corporate_sales_data={corportate_sales_data_quater_test}
                        setCorporate_Sales_Data={
                          setCorporate_Sales_Data_Quater_Test
                        }
                        load_data={load_data_testing}
                        quater_year={quater_year_qData}
                        setQuater_Year={setQuater_Year_qData}
                        handleQuater={handleQuater}
                        width_flag={"testing"}
                      />
                    </div>
                    <div className="col-12" id={styles.graph_container}>
                      <Quaterly_sales
                        fnb_sales_data={fnb_sales_data_quater_non_test}
                        setFnb_Sales_Data={setFnb_Sales_Data_Quater_Non_Test}
                        corporate_sales_data={
                          corportate_sales_data_quater_non_test
                        }
                        setCorporate_Sales_Data={
                          setCorporate_Sales_Data_Quater_Non_Test
                        }
                        load_data={load_data_non_test}
                        quater_year={quater_year_qData}
                        setQuater_Year={setQuater_Year_qData}
                        handleQuater={handleQuater}
                        width_flag={"non_testing"}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {yearly_flag ? (
            <div className="row mt-5">
              {toggle_flag ? (
                <>
                  <div className="col-sm-12 col-md-7" id={styles.graph_container}>
                    <Yearly_sales_count
                      fnb_sales_data={fnb_sales_data_year_count}
                      setFnb_Sales_Data={setFnb_Sales_Data_Year_Count}
                      corporate_sales_data={corporate_sales_data_year_count}
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Year_Count
                      }
                      load_data={load_data_count}
                      years_data={years_data}
                      width_flag={"overall"}
                    />
                  </div>{" "}
                  <div className="col-sm-12 col-md-5" id={styles.graph_container}>
                    <Sales_pieChart_year
                      pie_load={pie_load_count}
                      pieData={pie_data_year_count}
                      pie_dept={pie_dept_year}
                      setPie_Dept={setPie_Dept_Year}
                      quater_year={quater_year_yData}
                      setQuater_Year={setQuater_Year_yData}
                      department={department}     
                        count_flag= {true}
                    />
                  </div>
                  <div className="col-12" id={styles.graph_container}>
                    <Yearly_sales_count
                      fnb_sales_data={fnb_sales_data_year_test_count}
                      setFnb_Sales_Data={setFnb_Sales_Data_Year_Test_Count}
                      corporate_sales_data={
                        corportate_sales_data_year_test_count
                      }
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Year_Test_Count
                      }
                      load_data={load_data_testing_count}
                      years_data={years_data_test_count}
                      width_flag={"testing"}
                    />
                  </div>
                  <div className="col-12" id={styles.graph_container}>
                    <Yearly_sales_count
                      fnb_sales_data={fnb_sales_data_year_non_test_count}
                      setFnb_Sales_Data={setFnb_Sales_Data_Year_Non_Test_Count}
                      corporate_sales_data={
                        corportate_sales_data_year_non_test_count
                      }
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Year_Non_Test_Count
                      }
                      load_data={load_data_non_testing_count}
                      years_data={years_data_non_test_count}
                      width_flag={"non_testing"}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-sm-12 col-md-7" id={styles.graph_container}>
                    <Yearly_sales
                      fnb_sales_data={fnb_sales_data_year}
                      setFnb_Sales_Data={setFnb_Sales_Data_Year}
                      corporate_sales_data={corporate_sales_data_year}
                      setCorporate_Sales_Data={setCorporate_Sales_Data_Year}
                      load_data={load_data}
                      years_data={years_data}
                      width_flag={"overall"}
                    />
                  </div>
                  <div className="col-sm-12 col-md-5" id={styles.graph_container}>
                    <Sales_pieChart_year
                      pie_load={pie_load}
                      pieData={pie_data_year}
                      pie_dept={pie_dept_year}
                      setPie_Dept={setPie_Dept_Year}
                      quater_year={quater_year_yData}
                      setQuater_Year={setQuater_Year_yData}
                      department={department}
                    />
                  </div>
                  <div className="col-12" id={styles.graph_container}>
                    <Yearly_sales
                      fnb_sales_data={fnb_sales_data_year_test}
                      setFnb_Sales_Data={setFnb_Sales_Data_Year_Test}
                      corporate_sales_data={corportate_sales_data_year_test}
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Year_Test
                      }
                      load_data={load_data_testing}
                      years_data={years_data_test}
                      width_flag={"testing"}
                    />
                  </div>
                  <div className="col-12" id={styles.graph_container}>
                    <Yearly_sales
                      fnb_sales_data={fnb_sales_data_year_non_test}
                      setFnb_Sales_Data={setFnb_Sales_Data_Year_Non_Test}
                      corporate_sales_data={corportate_sales_data_year_non_test}
                      setCorporate_Sales_Data={
                        setCorporate_Sales_Data_Year_Non_Test
                      }
                      load_data={load_data_non_test}
                      years_data={years_data_non_test}
                      width_flag={"non_testing"}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Sales_analysis;
