import React, { useEffect, useState } from "react";
import styles from "../Styles/NPSDashboard.module.css";
import MiniDrawer from "../components/Minidrawer";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { nps_report } from "../Redux/actions";
import report_loader from "../Media/ezgif.com-optimize.gif";
import { DatePicker, Space } from "antd";
import passives_icon from "../Media/passives_icon.png";
import detractor_icon from "../Media/detractor_icon.png";
import nps_score_icon from "../Media/nps_score_icon.png";
import no_data_icon from "../Media/no_data_icon.gif";
import marketing_icon from "../Media/marketing_icon.png";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { nps_percentage_yearly_data } from "../Latest_addition_redux/actions";
import Nps_percentage_graph from "../components/Nps_percentage_graph";

function NPS_Dashboard() {
  const formattedMonth = dayjs(new Date());
  let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY");
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");

  const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
  const [send_month, setSend_month] = useState(new_month);
  const [send_year, setSend_year] = useState(new_year);
  const [nps_Data, setNps_Data] = useState([]);
  const [monthFlag, setMonthFlag] = useState(false);
  const [nps_year,setNps_Year] = useState("2024-2025");
  const [nps_yearly_data,setNps_Yearly_Data] = useState([])
 const [nps_load,setNps_Load] = useState(false)

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const check_loading = useSelector((state) => state.data.loading);
  const check_error = useSelector((state) => state.data.error);

  
  let USER_GUID = storage.getItem("USER_GUID");

  const navigate = useNavigate();

  React.useEffect(() => {
    if (USER_GUID == "" || USER_GUID == null) {
      navigate("/");
    }
  }, [storage]);

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      dispatch(nps_report(setNps_Data, send_month, send_year));
    }
  }, [send_month]);

  useEffect(()=>{
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      dispatch(nps_percentage_yearly_data(nps_year,setNps_Yearly_Data,setNps_Load))
    }
  },[nps_year])

  const handleMonthChange = (new_mon) => {
    setSelectedMonth(new_mon);
    let formattedMon = moment(new_mon?.$d).format("MM");
    let formattedYear = moment(new_mon?.$d).format("YYYY");
    setSend_year(formattedYear);
    setSend_month(formattedMon);
    // if (send_year == formattedYear) {
    //   setMonthFlag(true);
    // }
  };
  const onChange = (date, dateString) => {
    if(date){
      setSelectedMonth(date);
      let formattedMon = moment(date?.$d)?.format("MM");
      let formattedYear = moment(date?.$d)?.format("YYYY");
      setSend_year(formattedYear);
      setSend_month(formattedMon);
    }else{
      const formattedMonth = dayjs(new Date());
      let new_month = moment(formattedMonth.$d).format("MM");
      let new_year = moment(formattedMonth.$d).format("YYYY");
      setSelectedMonth(formattedMonth)
      setSend_month(new_month)
      setSend_year(new_year)
    }

    // if (send_year == formattedYear) {
    //   setMonthFlag(true);
    // }
  };

  const chartSetting = {
    yAxis: [
      {
        label: "No. of Users",
      },
    ],
  };

  const chartData = [
    {
      rating: "1",
      users: Number(nps_Data.Rating_1) != 0 ? Number(nps_Data.Rating_1) : 0,
    },
    {
      rating: "2",
      users: Number(nps_Data.Rating_2) != 0 ? Number(nps_Data.Rating_2) : 0,
    },
    {
      rating: "3",
      users: Number(nps_Data.Rating_3) != 0 ? Number(nps_Data.Rating_3) : 0,
    },
    {
      rating: "4",
      users: Number(nps_Data.Rating_4) != 0 ? Number(nps_Data.Rating_4) : 0,
    },
    {
      rating: "5",
      users: Number(nps_Data.Rating_5) != 0 ? Number(nps_Data.Rating_5) : 0,
    },
    {
      rating: "6",
      users: Number(nps_Data.Rating_6) != 0 ? Number(nps_Data.Rating_6) : 0,
    },
    {
      rating: "7",
      users: Number(nps_Data.Rating_7) != 0 ? Number(nps_Data.Rating_7) : 0,
    },
    {
      rating: "8",
      users: Number(nps_Data.Rating_8) != 0 ? Number(nps_Data.Rating_8) : 0,
    },
    {
      rating: "9",
      users: Number(nps_Data.Rating_9) != 0 ? Number(nps_Data.Rating_9) : 0,
    },
    {
      rating: "10",
      users: Number(nps_Data.Rating_10) != 0 ? Number(nps_Data.Rating_10) : 0,
    },
  ];

  return (
    <>
      <MiniDrawer />
      {check_loading ? (
        <img src={report_loader} id={styles.loader} />
      ) : nps_Data.length == 0 ? (
        <>
        <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10%",
                  marginTop:"1%"
                }}
              >
                <Space direction="vertical">
                  <DatePicker
                    onChange={onChange}
                    picker="month"
                    value={selectedMonth}
                    format="MMM-YYYY"
                  />
                </Space>
              </div>
            </div>
        <div className={styles.noData}>
          <img
            src={no_data_icon}
            alt=""
            style={{ height: "200px", width: "200px" }}
          />
          <h4>No Data Added</h4>
        </div>
        </>
      ) : (
        <div className={`container-fluid ml-5 mt-2`}>
                   <div className="row">
          <div
              className="col-sm-12 col-md-2"
              style={{ textAlign: "left", border: "1px solid white" }}
            >
              <span style={{ fontSize: "12px", color: "grey" }}>
                Select Year
              </span>
              <select
                style={{ width: "150px" }}
                className="form-select"
                name=""
                id=""
                value={nps_year}
                onChange={(e)=>setNps_Year(e.target.value)}
              >
                <option value="2015-2016">2015-16</option>
                <option value="2016-2017">2016-17</option>
                <option value="2017-2018">2017-18</option>
                <option value="2018-2019">2018-19</option>
                <option value="2019-2020">2019-20</option>
                <option value="2020-2021">2020-21</option>
                <option value="2021-2022">2021-22</option>
                <option value="2022-2023">2022-23</option>
                <option value="2023-2024">2023-24</option>
                <option value="2024-2025">2024-25</option>
              </select>
            </div>
          </div>
            <div className="row mb-5">
          {nps_load ? 
        <img src={report_loader} id={styles.loader} />:
        nps_yearly_data.length == 0 ?
        <div className={styles.noData}>
        <img
          src={no_data_icon}
          alt=""
          style={{ height: "200px", width: "200px" }}
        />
        <h4>No Data Added</h4>
      </div>:
       <Nps_percentage_graph nps_yearly_data={nps_yearly_data} />}
          </div>
       <div className="row">
          <div className="col-md-3">
            <div
              className={`${styles.mainTabDiv} col-md-12`}
              style={{ marginTop: "0px" }}
            >
              <div className={`${styles.tabs_left} col-md-4`}>
                <img src={marketing_icon} alt="" className={styles.divIcons} />
              </div>
              <div className={`${styles.tabsCount} col-md-8`}>
                <p
                  style={{
                    fontWeight: "900",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  No. of Promoters
                </p>
                <h4 className={styles.npsCount}>
                  {nps_Data.Promoter ? nps_Data.Promoter : "0"}
                </h4>
              </div>
            </div>

            <div className={`${styles.mainTabDiv} col-md-12`}>
              <div className={`${styles.tabs_left} col-md-4`}>
                <img src={passives_icon} alt="" className={styles.divIcons} />
              </div>
              <div className={`${styles.tabsCount} col-md-8`}>
                <p
                  style={{
                    fontWeight: "900",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  No. of Passives
                </p>
                <h4 className={styles.npsCount}>
                  {nps_Data.Passifier ? nps_Data.Passifier : "0"}
                </h4>
              </div>
            </div>

            <div className={`${styles.mainTabDiv} col-md-12`}>
              <div className={`${styles.tabs_left} col-md-4`}>
                <img src={detractor_icon} alt="" className={styles.divIcons} />
              </div>
              <div className={`${styles.tabsCount} col-md-8`}>
                <p
                  style={{
                    fontWeight: "900",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  No. of Detractors
                </p>
                <h4 className={styles.npsCount}>
                  {nps_Data.Detractor ? nps_Data.Detractor : "0"}
                </h4>
              </div>
            </div>

            <div className={`${styles.mainTabDiv} col-md-12`}>
              <div className={`${styles.tabs_left} col-md-4`}>
                <img src={nps_score_icon} alt="" className={styles.divIcons} />
              </div>
              <div className={`${styles.tabsCount} col-md-8`}>
                <p
                  style={{
                    fontWeight: "900",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  NPS Score
                </p>
                <h4 className={styles.npsCount}>
                  {nps_Data.NPS ? nps_Data.NPS : "0"} %
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-9" style={{ marginBottom: "3%" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10%",
                }}
              >
                <Space direction="vertical">
                  <DatePicker
                    onChange={onChange}
                    picker="month"
                    value={selectedMonth}
                    format="MMM-YYYY"
                  />
                </Space>
              </div>
            </div>
            <div
            id="nps_graph"
              className="col-md-9 col-sm-10 col-xs-10"
              style={{
                height: "450px",
                // display: "flex",
                // alignItems: "flex-end",
                // marginTop:"20px",
                width: "900px",
              }}
            >
              {/* <BarChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                      ],
                      label:"NPS Rating",
                    },
                    
                  ]}
                  series={[
                    {
                      color: "#89BBE0",
                      // label:"No. of Users",
                      data: [
                        nps_Data.Rating_1 > 0 ? nps_Data.Rating_1 : 0,
                        nps_Data.Rating_2 > 0 ? nps_Data.Rating_2 : 0,
                        nps_Data.Rating_3 > 0 ? nps_Data.Rating_3 : 0,
                        nps_Data.Rating_4 > 0 ? nps_Data.Rating_4 : 0,
                        nps_Data.Rating_5 > 0 ? nps_Data.Rating_5 : 0,
                        nps_Data.Rating_6 > 0 ? nps_Data.Rating_6 : 0,
                        nps_Data.Rating_7 > 0 ? nps_Data.Rating_7 : 0,
                        nps_Data.Rating_8 > 0 ? nps_Data.Rating_8 : 0,
                        nps_Data.Rating_9 > 0 ? nps_Data.Rating_9 : 0,
                        nps_Data.Rating_10 > 0 ? nps_Data.Rating_10 : 0,
                      ],
                    },
                  ]}
                  {...chartSetting}
                  // width={500}
                  height={450} 
                /> */}

              <ResponsiveContainer>
                <BarChart
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis
                    dataKey="rating"
                    label={{
                      value: `Rating`,
                      style: { textAnchor: "middle" },
                      angle: 0,
                      position: "center",
                      offset: 0,
                    }}
                    height={100}
                  />
                  <YAxis
                    label={{
                      value: `No. of Users`,
                      style: { textAnchor: "middle" },
                      angle: -90,
                      position: "left",
                      offset: 0,
                    }}
                    height={100}
                  />
                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  <Bar
                    dataKey="users"
                    fill="#89BBE0"
                    activeBar={<Rectangle />}
                    label={{ position: "top" }}
                    // maxBarSize={400}
                    // maxHeight={400}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          </div>


 

        
        </div>
      )}
    </>
  );
}
export default NPS_Dashboard;
