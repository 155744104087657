import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import thunk from "redux-thunk";
import { dataReducer } from "../Redux/reducer";
import { latest_dataReducer } from "../Latest_addition_redux/reducer";
import { dataStudioReducer } from "../DataStudioRedux/reducer";


let rootReducer = combineReducers({
     data:dataReducer,
     data2:latest_dataReducer,
     data3:dataStudioReducer
});

export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
