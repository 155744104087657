import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import MiniDrawer from "../components/Minidrawer";
import Swal from "sweetalert2";
import "rsuite/dist/rsuite.min.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import {
  get_leadsource_createdOn_data,
  get_leadsource_updatedOn_data,
} from "../Latest_addition_redux/actions";
import { DateRangePicker } from "rsuite";

function Category_leadsourcewise_analysis() {
  const dispatch = useDispatch();
  const [cat_drop, setCat_Drop] = useState([]);
  const [source_drop, setSource_Drop] = useState([]);
  const [selected_cat, setSelected_Cat] = useState("ALL");
  const [selected_source, setSelected_Source] = useState("ALL");
  const [leadStage_drop, setLeadStage_drop] = useState([]);
  const [select_leadStage, setSelected_leadStage] = useState("ALL");
  const [status_drop, setStatus_Drop] = useState([]);
  const [select_leadStatus, setSelected_leadStatus] = useState("ALL");
  const [tab_selected, setTab_Selected] = useState("created_on");

  const [createdOn_total,setCreatedOn_Total] = useState(0);
  const [updatedOn_Total,setUpdatedOn_Total] = useState(0);

  const [params, setParams] = useState("MTD");
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [range_flag, setRange_Flag] = useState(false);

  const [created_on_load, setCreated_on_Load] = useState(false);
  const [created_on_data, setCreated_On_Data] = useState([]);

  const [updated_on_load, setUpdated_On_Load] = useState(false);
  const [updated_on_data, setUpdated_On_Data] = useState([]);

  const get_category = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_category");
      let data = await res.json();
      if (data.response?.code == "200") {
        setCat_Drop(data?.Data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {}
  };

  const get_source = async (val) => {
    try {
      let res = await fetch(
        "https://reportapi.equinoxlab.com/get_leadsource_by_category",
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            CATEGORY: val,
          }),
        }
      );
      let data = await res.json();
      if (data.response?.code == "200") {
        setSource_Drop(data?.Data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {}
  };

  const get_leadStage = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_stage");
      let data = await res.json();
      setLeadStage_drop(data.Data);
    } catch (error) {}
  };
  const get_leadStatus = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_lead_status");
      let data = await res.json();
      setStatus_Drop(data.Data);
    } catch (error) {}
  };

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      setParams("MTD");
      setRange_Flag(false);
    }
  };

  const handleParamChange = (e) => {
    setParams(e.target.value);
    if (e.target.value == "Custom Date") {
      setRange_Flag(true);
    } else {
      setSelectedFromDate(new Date());
      setSelectedToDate(new Date());
      setRange_Flag(false);
    }
  };

  const handleCategory = (e) => {
    setSelected_Cat(e.target.value);
    if (e.target.value == "ALL") {
      setSource_Drop([]);
      setSelected_Cat("ALL");
      setSelected_Source("ALL");
    } else {
      setSelected_Source("ALL");
      get_source(e.target.value);
    }
  };

  const handleLeadStageChange = (e) => {
    setSelected_leadStage(e.target.value);
  };

  const handleLeadStatusChange = (e) => {
    setSelected_leadStatus(e.target.value);
  };

  const handleSource = (e) => {
    if (e.target.value != "") {
      setSelected_Source(e.target.value);
    }
  };

  const handleCreatedOn = () => {
    // setCreatedOn_flag(true);
    // setUpdatedOn_flag(false);
    setTab_Selected("created_on");
  };
  const handleUpdatedOn = () => {
    // setUpdatedOn_flag(true);
    // setCreatedOn_flag(false);
    setTab_Selected("updated_on");
  };

  useEffect(() => {
    get_category();
    get_leadStage();
    get_leadStatus();
  }, []);

  useEffect(() => {

    if (tab_selected == "created_on") {
      const controller = new AbortController();
      const signal = controller.signal;
      dispatch(
        get_leadsource_createdOn_data(
          setCreated_on_Load,
          setCreated_On_Data,
          params,
          selectedFromDate,
          selectedToDate,
          selected_cat,
          selected_source,
          select_leadStage,
          setCreatedOn_Total,
          signal
        )
      );
      return () => {
        controller.abort();
      };
    } 

  }, [
    selected_cat,
    selected_source,
    selectedToDate,
    selectedFromDate,
    tab_selected,
    select_leadStage
  ]);


  useEffect(()=>{
    if (tab_selected == "updated_on") {
      const controller = new AbortController();
      const signal = controller.signal;
    dispatch(
      get_leadsource_updatedOn_data(
        setUpdated_On_Load,
        setUpdated_On_Data,
        params,
        selectedFromDate,
        selectedToDate,
        selected_cat,
        selected_source,
        select_leadStatus,
        setUpdatedOn_Total,
        signal
      )
    );
    return () => {
      controller.abort();
    };
  }
  },[ selected_cat,
    selected_source,
    selectedToDate,
    selectedFromDate,
    tab_selected,
  select_leadStatus
  ])

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "lead_cat",
      label: `Lead Category`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                padding: "5px 10px",
              }}
            >
              <span>{row}</span>
            </div>
          );
        },
      },
    },
    {
      name: "lead_source",
      label: `Lead Source`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                padding: "5px 10px",
              }}
            >
              <span>{row}</span>
            </div>
          );
        },
      },
    },
    {
      name: "lead_count",
      label: `Lead Count`,
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "5px",
          }}
        >
          <button
            className={
              tab_selected == "created_on"
                ? "btn btn-primary btn-sm"
                : "btn btn-outline-primary btn-sm"
            }
            style={{ marginRight: "10px" }}
            onClick={handleCreatedOn}
          >
            Created On
          </button>
          <button
            className={
              tab_selected == "updated_on"
                ? "btn btn-primary btn-sm"
                : "btn btn-outline-primary btn-sm"
            }
            style={{ marginRight: "10px" }}
            onClick={handleUpdatedOn}
          >
            Updated On
          </button>
          <div>
            <FormControl size="small" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={styles.table_dropdown}
                onChange={handleParamChange}
                value={params}
              >
                <MenuItem value="" selected disabled>
                  Select Date Range
                </MenuItem>

                <MenuItem value="Today">Today</MenuItem>
                <MenuItem value="MTD">MTD</MenuItem>
                <MenuItem value="QTD">QTD</MenuItem>
                <MenuItem value="YTD">YTD</MenuItem>
                <MenuItem value="FYTD">FYTD</MenuItem>
                <MenuItem value="Custom Date">Custom Date</MenuItem>
              </Select>
            </FormControl>
          </div>

          {range_flag ? (
            <div style={{ marginLeft: "10px" }}>
              <DateRangePicker
                // defaultValue={[new Date(), new Date()]}
                format="dd MMM yyyy"
                value={[selectedFromDate, selectedToDate]}
                onChange={handleDateChange}
                style={{ width: "250px" }}
                size="md"
                placement="bottomEnd"
                preventOverflow
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="row mt-3" id="analysis_tbl">
          {tab_selected == "created_on" && created_on_load || tab_selected == "updated_on" && updated_on_load ? (
            <img src={report_loader} id={styles.leads_loader} />
          ) : (
 
            <MUIDataTable
              title={
                <h5 style={{ textAlign: "left" }}>
                  Category Leadsourcewise Analysis
                  <button style={{marginLeft:"15px"}} id={styles.leads_container}>
                  Total : {tab_selected == "created_on"?createdOn_total:updatedOn_Total}
                </button>
                </h5>
              }
              data={tab_selected == "created_on"?created_on_data:updated_on_data}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                download: tab_selected == "created_on"?false:true,
                rowsPerPageOptions: [10, 30, 50, 100],
                // rowsPerPage: "",
                pagination: true,
                setTableProps: () => {
                  return {
                    padding: 0,
                  };
                },
                textLabels: {
                  body: {
                    noMatch: "Data Not Available",
                  },
                },
                customToolbar: () => {
                  return (
                    <div style={{ display: "inline-flex", gap: "10px" }}>
                      {tab_selected == "created_on" ? (
                        <FormControl size="small" fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={styles.table_dropdown}
                            value={select_leadStage}
                            onChange={handleLeadStageChange}
                          >
                            <MenuItem value="ALL">All Lead Stage</MenuItem>
                            {leadStage_drop?.map((ele) => (
                              <MenuItem value={ele.Lead_Stage}>
                                {ele.Lead_Stage}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl size="small" fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={styles.table_dropdown}
                            value={select_leadStatus}
                            onChange={handleLeadStatusChange}
                          >
                            <MenuItem value="ALL">All Status</MenuItem>
                            {status_drop?.map((ele) => (
                              <MenuItem value={ele.STATUS}>
                                {ele.STATUS}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <FormControl size="small" fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className={styles.table_dropdown}
                          value={selected_cat}
                          onChange={handleCategory}
                        >
                          <MenuItem value="ALL">All Category</MenuItem>
                          {cat_drop?.map((ele) => (
                            <MenuItem value={ele.Leadcat}>
                              {ele.Leadcat}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl size="small" fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className={styles.table_dropdown}
                          value={selected_source}
                          onChange={handleSource}
                        >
                          <MenuItem value="ALL" selected>
                            All Source
                          </MenuItem>
                          {source_drop?.map((ele) => (
                            <MenuItem value={ele.Leadsource}>
                              {ele.Leadsource}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  );
                },
              }}
            />
          
          )}
        </div>
      </div>
    </>
  );
}

export default Category_leadsourcewise_analysis;
