import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";
import styles from "../Styles/Table.module.css";
import { category_data } from "../Redux/actions";
import MiniDrawer from "../components/Minidrawer";
import { useNavigate } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { get_invoice_s6 } from "../Latest_addition_redux/actions";

function Invoice_s6() {
  const [invoice_data, setInvoice_Data] = useState([]);
  const data = useSelector((state) => state.data.data);
  const dispatch = useDispatch();

  const check_loading = useSelector((state) => state.data.loading);

  const [show, setShow] = useState(false);

  const storage = window.sessionStorage;
  let img = storage.getItem("profile_img");
  let USER_GUID = storage.getItem("USER_GUID");
  let Role_id = storage.getItem("Role_id");
  let DEPT_ID = storage.getItem("DEPT_ID");
  let DEPT = storage.getItem("DEPT");

  const navigate = useNavigate();

  React.useEffect(() => {
    if (USER_GUID == "" || USER_GUID == null) {
      navigate("/");
    }
  }, [storage]);

  useEffect(() => {
    dispatch(get_invoice_s6(setInvoice_Data, DEPT));
  }, []);

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "Leadcat",
      label: "Lead Category",
      options: {
        filter: true,
        sort: false,
 
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            minWidth:"200px",
            maxWidth:"200px"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101
          }
        }),
  
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "LeadSource",
      label: "Lead Source",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "250px", maxWidth: "250px" },
        }),
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left" }}>
              <p style={{ marginLeft: "30%" }}>{row}</p>
            </div>
          );
        },
      },
    },

    {
      name: "D",
      label: "D",
      options: {
        filter: false,
        sort: true,

        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 2000000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
          },
        }),

        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D1",
      label: "D1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D2",
      label: "D2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D3",
      label: "D3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D4",
      label: "D4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D5",
      label: "D5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "D6",
      label: "D6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "W",
      label: "W",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "W1",
      label: "W1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "W2",
      label: "W2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "W3",
      label: "W3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "W4",
      label: "W4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "W5",
      label: "W5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "W6",
      label: "W6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => {
          return (
            <>
              {!show ? (
                <div style={{ display: "flex" }} onClick={() => setShow(true)}>
                  <p
                    style={{display: "flex",
                    alignItems: "center" ,fontSize: "15px",
                    marginTop: "8px",
                    marginBottom: "0.4rem"}}
                  >
                    Q{" "}
                    <ArrowDownwardIcon sx={{height: "15px"}} />
                  </p>{" "}
                </div>
              ) : (
                <p style={{ verticalAlign: "middle",   marginTop: "0",
                marginBottom: "0.1rem" }}>Q</p>
              )}
            </>
          );
        },
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),

        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),

        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
                 Number(cellValue?.props?.children) >= 0 &&
              Number(cellValue?.props?.children) < 10000
                ? "#dff3ff"
                : Number(cellValue?.props?.children) >= 10000 &&
                  Number(cellValue?.props?.children) < 20000
                ? "#9fdcff"
                : Number(cellValue?.props?.children) >= 20000 &&
                  Number(cellValue?.props?.children) < 30000
                ? "#60c5ff"
                : Number(cellValue?.props?.children) >= 30000 &&
                  Number(cellValue?.props?.children) < 40000
                ? "#20aeff"
                : Number(cellValue?.props?.children) >= 40000 &&
                  Number(cellValue?.props?.children) <= 50000
                ? "#008edf"
                : Number(cellValue?.props?.children) >50000
                ? "#1D5D9B"
                : "white",
          },
        }),
        customBodyRender: (row) => {
          return <>{row}</>;
        },
      },
    },
  ];

  return (
    <>
      <MiniDrawer />
      {check_loading ? (
        <img src={report_loader} id={styles.loader} />
      ) : (
        <div className="container-fluid pt-3">
          <MUIDataTable
            title={
              <h5 style={{ textAlign: "left" }}>Invoice Creation</h5>
            }
            data={invoice_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: true,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              tableBodyMaxHeight: "550px",
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}

export default Invoice_s6;

