import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Corporate_oh.module.css";
import CreatedBy_oh_tbl from "../components/CreatedBy_oh_tbl";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Select2 from "react-select";

import Team_leader_oh_tbl from "../components/Team_leader_oh_tbl";
import Top_5client_oh_tbl from "../components/Top_5client_oh_tbl";
import Client_oh_expire from "../components/Client_oh_expire";


import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import report_loader from "../Media/ezgif.com-optimize.gif";

import {
  get_client_report_businessType_data,
  get_client_report_company_data,
  get_client_report_teamLead_data,
  get_corporate_exp_details_data,
  get_oh_count_graph_data,
  get_salesperson_oh_data,
} from "../DataStudioRedux/actions";
import Corporate_oh_count_graph from "../components/Corporate_oh_count_graph";

const customStyles = {
  option: (provided) => ({
    ...provided,
    textAlign: "left",
    fontSize: "14px",
  }),
};

function Corporate_client_oh_dashboard() {
  const dispatch = useDispatch();

  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");
  let dept = storage.getItem("DEPT");
  let admin_param = storage.getItem("admin_param");

  const [tab_selected, setTab_Selected] = useState("oh");
  let date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
  const [ohFromDate, setOhFromDate] = useState(new Date(y, m, 1));
  const [ohToDate, setOhToDate] = useState(new Date());
  const [selected_company, setSelected_Company] = useState([]);
  const [selected_business, setSelected_Business] = useState("all");
  const [show_Salesperson, setShow_Salesperson] = useState([]);

  const [oh_report_data, setOh_Report_Data] = useState([]);
  const [sales_person_data, setSales_Person_Data] = useState([]);
  const [client_report_data, setClient_Report_Data] = useState([]);
  const [teamLead_data, setTeamLead_Data] = useState([]);
  const [business_type_data, setBusiness_Type_Data] = useState([]);
  const [business_dropdown, setBusiness_Dropdown] = useState([]);
  const [company_list, setCompany_List] = useState([]);

  const [oh_report_load, setOh_Report_Load] = useState(false);
  const [sales_load, setSales_Load] = useState(false);
  const [client_report_load, setClient_Report_Load] = useState(false);
  const [teamLead_load, setTeamLead_Load] = useState(false);
  const [business_load, setBusiness_Load] = useState(false);
  const [client_load,setClient_Load] = useState(false)

  const [total_order_count, setTotal_Order_Count] = useState(0);
  const [total_order_value, seTotal_Order_Value] = useState(0);

  const [expire_order, setExpire_Order] = useState(0);
  const [new_customer_added, setNew_Customer_Added] = useState(0);

  const [graph_load,setGraph_Load] = useState(false);
  const [graph_data,setGraph_Data] = useState([])


  // OH Expire Dashboard

  const [ohExpireFromDate,setOhExpireFromDate] = useState(new Date(y, m, 1));
  const [ohExpireToDate,setOhExpireToDate] = useState(new Date());
  const [selected_Ohcompany, setSelected_OhCompany] = useState([]);
  const [selected_Ohbusiness, setSelected_OhBusiness] = useState("all");
  const [show_OhSalesperson, setShow_OhSalesperson] = useState([]);
  const [oh_expire_load,setOh_Expire_Load] = useState(false);
  const [oh_Expire_data,set_Oh_Expire_Data] = useState([])

  const handleOhDateChange = (new_val) => {
    if (new_val !== null) {
      setOhFromDate(new_val[0]);
      setOhToDate(new_val[1]);
    } else {
      setOhFromDate(new Date(y, m, 1));
      setOhToDate(new Date());
    }
  };

  const get_sales_person = async () => {
    setSales_Load(true);
    try {
      // let res = await fetch(
      //   `https://reportapi.equinoxlab.com/corporate_salesperson`
      // );
      let res = await fetch(`https://reportapi.equinoxlab.com/get_unique_salesperson`,{
        method:"POST",
        headers:{"Content-Type":"Application/Json"},
        body: JSON.stringify({
         "dept_id": (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
        })
      });
      let data = await res.json();
      setSales_Load(false);
      let dropdown_data = [];
      if (data?.Data?.length > 0) {
        for (let i = 0; i < data?.Data?.length; i++) {
          dropdown_data?.push({
            label: data?.Data[i]?.salesperson,
            value: data?.Data[i]?.salesperson,
          });
        }
        setSales_Person_Data(dropdown_data);
      }
    } catch (error) {
      setSales_Load(false);
    }
  };


  const get_business_type = async () => {
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/get_business_type`
      );
      let data = await res.json();

      setBusiness_Dropdown(data?.Data);
    } catch (error) {}
  };

  const get_client_list = async () => {
    setClient_Load(true)
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/list_oh_ordered_by_dept`,
        // `https://reportapi.equinoxlab.com/customers_list_oh_ordered`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            start_date: tab_selected == "oh"? moment(ohFromDate).format("YYYY-MM-DD"):moment(ohExpireFromDate).format("YYYY-MM-DD"),
            end_date: tab_selected == "oh"? moment(ohToDate).format("YYYY-MM-DD"):moment(ohExpireToDate).format("YYYY-MM-DD"),
              dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setClient_Load(false)
      let dropdown_data = [];
      if (data?.Data?.length > 0) {
        for (let i = 0; i < data?.Data?.length; i++) {
          dropdown_data?.push({
            label: data?.Data[i]?.company_name,
            value: data?.Data[i]?.company_name,
          });
        }
        setCompany_List(dropdown_data);
      }
    } catch (error) {
      setClient_Load(false)
      setCompany_List([]);
    }
  };

  const get_expire_order_value = async () => {
    let send_salesperson = [];
    for(let i=0; i<show_Salesperson?.length; i++){
      send_salesperson.push(show_Salesperson[i]?.value)
    }

    let send_company = [];
    for(let i=0; i<selected_company?.length; i++){
      send_company.push(selected_company[i]?.value)
    }

    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/exp_order_value_by_dept`,
        // `https://reportapi.equinoxlab.com/corporate_exp_order_value`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            start_date: moment(ohFromDate).format("YYYY-MM-DD"),
            end_date: moment(ohToDate).format("YYYY-MM-DD"),
            "salesperson": show_Salesperson?.length == 0?"all": send_salesperson?.toString(),
            "company_name":send_company?.length == 0?"all": send_company?.toString(),
            "business_type":selected_business,
             dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();
      setExpire_Order(data?.Data[0]?.total_order_value);
    } catch (error) {}
  };
  
  const get_new_customer_added_count = async () => {
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/new_clients_by_dept`,
        // `https://reportapi.equinoxlab.com/corporate_new_clients`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            start_date: moment(ohFromDate).format("YYYY-MM-DD"),
            end_date: moment(ohToDate).format("YYYY-MM-DD"),
            dept_id: (dept == "Tech" || dept == "Admin" || dept == "Management")? admin_param :dept == "FnB Sales"? "1":dept == "Corporate Sales" ? "2":"all"
          }),
        }
      );
      let data = await res.json();

      setNew_Customer_Added(data?.Data[0]?.count);
      // setNew_Customer_Added(data?.Data[0]?.customer_count);
    } catch (error) {}
  };

  const handleSalesperson = (e) => {
    setShow_Salesperson(e);
  };

  const handleCompany = (e) => {
    setSelected_Company(e);
  };

  useEffect(() => {
    get_sales_person();
    get_business_type();
  }, []);


  useEffect(()=>{
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    if(tab_selected == "oh"){
      get_new_customer_added_count();
      dispatch(get_oh_count_graph_data(setGraph_Load,setGraph_Data,ohFromDate,ohToDate,dept,admin_param))
    }
  }
  },[ohFromDate,ohToDate,tab_selected])

  useEffect(()=>{
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    get_client_list();
    }
  },[ohFromDate,ohToDate,tab_selected,ohExpireFromDate,ohExpireToDate])

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    if(tab_selected == "oh"){
    dispatch(
      get_salesperson_oh_data(
        setOh_Report_Load,
        setOh_Report_Data,
        ohFromDate,
        ohToDate,
        show_Salesperson,
        selected_company,
        selected_business,
        dept,
        admin_param
      )
    );
    dispatch(
      get_client_report_company_data(
        setClient_Report_Load,
        setClient_Report_Data,
        ohFromDate,
        ohToDate,
        show_Salesperson,
        selected_company,
        selected_business,
        dept,
        admin_param
      )
    );
    dispatch(
      get_client_report_teamLead_data(
        setTeamLead_Load,
        setTeamLead_Data,
        ohFromDate,
        ohToDate,
        show_Salesperson,
        selected_company,
        selected_business,
        dept,
        admin_param
      )
    );

    dispatch(
      get_client_report_businessType_data(
        setBusiness_Load,
        setBusiness_Type_Data,
        ohFromDate,
        ohToDate,
        show_Salesperson,
        selected_company,
        selected_business,
        dept,
        admin_param
      )
    );
    get_expire_order_value();
  }
}
  }, [show_Salesperson,ohFromDate, ohToDate, selected_company, selected_business,tab_selected]);

  useEffect(()=>{
    if(Role_id != "" && Role_id != null && Role_id != "null"){
    if(tab_selected == "oh_expire"){
      dispatch(get_corporate_exp_details_data(
        setOh_Expire_Load,
        set_Oh_Expire_Data,
        ohExpireFromDate,
        ohExpireToDate,
        show_OhSalesperson,
        selected_Ohcompany,
        selected_Ohbusiness,
        dept,
        admin_param
      ))
    }
  }
  },[ ohExpireFromDate,ohExpireToDate,show_OhSalesperson,selected_Ohcompany,selected_Ohbusiness,tab_selected])

  return (
    <>
      <MiniDrawer />
      {sales_load || client_load  || oh_expire_load ? (
        <div className="container-fluid" style={{ paddingLeft: "0.8vw" }}>
          <img src={report_loader} id={styles.main_loader} />
        </div>
      ) : (
        <div className="container-fluid" style={{ paddingLeft: "0.8vw" }}>
          <div className="row" id={styles.tabs_container}>
            <div className="col-4"></div>
            <div
              className="col-2"
              onClick={() => setTab_Selected("oh")}
              style={{
                color: tab_selected == "oh" ? "#007cc3" : "",
                borderBottom: tab_selected == "oh" ? "2px solid #007cc3" : "",
              }}
              id={styles.oh_tab}
            >
              Client OH dashboard
            </div>
            <div
              className="col-3"
              style={{
                color: tab_selected == "oh_expire" ? "#007cc3" : "",
                borderBottom:
                  tab_selected == "oh_expire" ? "2px solid #007cc3" : "",
              }}
              onClick={() => setTab_Selected("oh_expire")}
              id={styles.oh_tab}
            >
              Client OH expire dashboard
            </div>
            <div className="col-4"></div>
          </div>
          {tab_selected == "oh" ? (
            <>
              {" "}
              <div className="row mt-2">
                <h2 style={{ textAlign: "left", fontWeight: "500",fontSize:"27px" }}>
                  Client OH - Dashboard
                </h2>
              </div>
              <div className="row mt-2" style={{paddingLeft:"10px"}}>
                <div className="col-sm-12 col-md-4" id={styles.metrics_contain}>
                  <div className="row" id={styles.metrics_header}>
                    <h4>Metrics</h4>
                  </div>
                  <div className="row mt-2" id={styles.metrics_info_contain}>
                    <div className="col-1"></div>
                    <div className="col-5">
                      <div>
                        <h6>Order Value</h6>
                      </div>
                      <p>
                        {" "}
                        {total_order_value == undefined ||
                        total_order_value == null
                          ? 0
                          : new Intl.NumberFormat("en-IN").format(
                              total_order_value
                            )}
                      </p>
                    </div>
                    <div className="col-5">
                      <div>
                        <h6>Orders</h6>
                      </div>
                      <p>{total_order_count}</p>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="row mt-2" id={styles.metrics_info_contain}>
                    <div className="col-1"></div>
                    <div className="col-5">
                      <div>
                        <h6>Expiring Order Value</h6>
                      </div>
                      <p>       {expire_order == undefined ||
                        expire_order == null
                          ? 0
                          : new Intl.NumberFormat("en-IN").format(
                              expire_order
                            )}</p>
               
                    </div>
                    <div className="col-5">
                      <div>
                        <h6>New Customer Added</h6>
                      </div>
                      <p>{new_customer_added}</p>
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="row mt-2" id={styles.metrics_info_contain}>
                    <div className="col-1"></div>
                    <div className="col-5">
                      <div>
                        <h6>Sales Person</h6>
                      </div>
                      <p>{show_Salesperson?.length}</p>
                    </div>
                    <div className="col-5">
                      <div>
                        <h6>Client Count</h6>
                      </div>
                      <p>{company_list?.length}</p>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3">
                  {oh_report_load ? (
                    <img src={report_loader} id={styles.tbl_loader} />
                  ) : (
                    <CreatedBy_oh_tbl
                      data={oh_report_data}
                      setTotal_Order_Count={setTotal_Order_Count}
                      seTotal_Order_Value={seTotal_Order_Value}
                    />
                  )}
                </div>
                <div className="col-sm-12 col-md-5">
                  {business_load ? (
                    <img src={report_loader} id={styles.tbl_loader} />
                  ) : (
                    <>
                      <div className="row" id={styles.filter_container}>
                        {/* <div className="col-2"></div> */}
                        <div className="col-6">
                        <p>OH Date</p>
                          <DateRangePicker
                            format="dd MMM yyyy"
                            value={[ohFromDate, ohToDate]}
                            onChange={handleOhDateChange}
                            size="md"
                            style={{width:"100%"}}
                            placement="bottomEnd"
                            preventOverflow
                          />
                        </div>
                        <div className="col-6">
                          <p>Business Type</p>
                        <Box sx={{ width: "100%" }}>
                            <FormControl   size="small" fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                sx={{height:"35px"}}
                                value={selected_business}
                                onChange={(e) =>
                                  setSelected_Business(e.target.value)
                                }
                              >
                                <MenuItem value="all">All</MenuItem>
                                {business_dropdown?.map((ele) => (
                                  <MenuItem value={ele?.business_type}>
                                    {ele?.business_type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                        {/* <div className="col-2"></div> */}
                      </div>
                      <div className="row mt-3"  id={styles.filter_container}>
                        <div className="col-sm-12 col-md-6">
                        <p>Sales Person</p>
                          <Select2
                            isMulti
                            name="label"
                            options={sales_person_data}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={show_Salesperson}
                            onChange={handleSalesperson}
                            styles={customStyles}
                            maxMenuHeight="180px"
                          />
                        </div>
                        <div className="col-sm-12 col-md-6">
                        <p>Company Name</p>
                          <Select2
                            isMulti
                            name="label"
                            options={company_list}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={selected_company}
                            onChange={handleCompany}
                            styles={customStyles}
                            maxMenuHeight="180px"
                          />
                        </div>
                        {/* <div className="col-sm-12 col-md-4">
                         
                        </div> */}
                      </div>
                      <div className="row mt-4">
                        <div className="col-1"></div>
                        <div className="col-10" id={styles.sales_info_contain}>
                          <div className="row" id={styles.sales_header}>
                            <h6>Sales by Business Type</h6>
                          </div>
                          <div className="row mt-2" id={styles.sales_info_unit}>
                            <div className="col-1"></div>
                            <div className="col-5">
                              <div>
                                <h6>AMC</h6>
                              </div>
                              {business_type_data[1]?.total_order_value.toString()
                                ?.length > 10 ? (
                                <marquee
                                  id={styles.moving_no}
                                  style={{ marginTop: "-7px" }}
                                  scrollamount="2"
                                >
                                  {" "}
                                  {business_type_data[1]?.total_order_value ==
                                    undefined ||
                                  business_type_data[1]?.total_order_value ==
                                    null
                                    ? 0
                                    : new Intl.NumberFormat("en-IN").format(
                                        business_type_data[1]?.total_order_value
                                      )}
                                </marquee>
                              ) : (
                                <p>
                                  {business_type_data[1]?.total_order_value ==
                                    undefined ||
                                  business_type_data[1]?.total_order_value ==
                                    null
                                    ? 0
                                    : new Intl.NumberFormat("en-IN").format(
                                        business_type_data[1]?.total_order_value
                                      )}
                                </p>
                              )}
                            </div>
                            <div className="col-5">
                              <div>
                                <h6>One Time</h6>
                              </div>
                              {business_type_data[0]?.total_order_value.toString()
                                ?.length > 10 ? (
                                <marquee
                                  id={styles.moving_no}
                                  style={{ marginTop: "-7px" }}
                                  scrollamount="2"
                                >
                                  {" "}
                                  {business_type_data[0]?.total_order_value ==
                                    undefined ||
                                  business_type_data[0]?.total_order_value ==
                                    null
                                    ? 0
                                    : new Intl.NumberFormat("en-IN").format(
                                        business_type_data[0]?.total_order_value
                                      )}
                                </marquee>
                              ) : (
                                <p>
                                  {business_type_data[0]?.total_order_value ==
                                    undefined ||
                                  business_type_data[0]?.total_order_value ==
                                    null
                                    ? 0
                                    : new Intl.NumberFormat("en-IN").format(
                                        business_type_data[0]?.total_order_value
                                      )}
                                </p>
                              )}
                            </div>
                            <div className="col-1"></div>
                          </div>
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                className="row mt-3"
                style={{ padding: "5px 13px 0px 12px" }}
              >
                <div
                  className="col-12"
                  style={{
                    backgroundColor: "#007cc3",
                    color: "white",
                    padding: "7px",
                  }}
                >
                  <h5 style={{ fontWeight: "400" }}>
                    OH Count/Order Value / Sales Person
                  </h5>
                </div>
                <div style={{ width: "90vw", overflow: "auto", margin: "auto" }}>
                {graph_load?<img src={report_loader} id={styles.tbl_loader} />: <Corporate_oh_count_graph data={graph_data} />}
                </div>
              </div>
              <div className="row mt-2 mb-5">
                <div className="col-6">
                  {teamLead_load ? (
                    <img src={report_loader} id={styles.tbl_loader} />
                  ) : (
                    <Team_leader_oh_tbl data={teamLead_data} />
                  )}
                </div>
                <div className="col-6">
                  {client_report_load ? (
                    <img src={report_loader} id={styles.tbl_loader} />
                  ) : (
                    <Top_5client_oh_tbl data={client_report_data} />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <Client_oh_expire  
              data={oh_Expire_data}          
              sales_person_data={sales_person_data} 
              selectedFromDate={ohExpireFromDate}
              selectedToDate={ohExpireToDate}
              setSelectedFromDate={setOhExpireFromDate}
              setSelectedToDate={setOhExpireToDate}
              show_OhSalesperson={show_OhSalesperson}
              setShow_OhSalesperson={setShow_OhSalesperson}
              selected_Ohbusiness={selected_Ohbusiness}
              setSelected_OhBusiness={setSelected_OhBusiness}
              selected_Ohcompany={selected_Ohcompany}
              setSelected_OhCompany={setSelected_OhCompany}
              company_list={company_list}
              business_dropdown={business_dropdown}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Corporate_client_oh_dashboard;
