import React from 'react'
import MUIDataTable from 'mui-datatables';
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../Styles/Table.module.css";

function Top_5client_oh_tbl({data}) {
  const get_total = (val)=>{
  if(val == "value"){
    let total_count = data?.reduce((acc,ele)=>{
      return acc+Number(ele?.total_order_value)
   },0)
   return new Intl.NumberFormat("en-IN").format(total_count);
  }else{
    let total_count = data?.reduce((acc,ele)=>{
      return acc+Number(ele?.total_order)
   },0)
   return total_count;
  }
}
  const options = {
    filterType: "checkbox",
  };
  const columns = [

    {
      name: "company_name",
      label: `Top 5 - company name`,
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"left",fontSize:"12px",padding:"10px"}}>
              <>{row}</>
            </div>
          )
        }
      },
    },
  
    {
      name: "total_order",
      label: `Total Order`,
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"left",marginLeft:"10px"}}>
              <p>{row}</p>
            </div>
          )
        }
      },
    },
  
    {
      name: "total_order_value",
      label: `Order Value`,
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <div style={{textAlign:"left"}}>
          <p>{row == undefined ||
                      row == null
                        ? 0
                        : new Intl.NumberFormat("en-IN").format(
                            row
                          )}</p>
            </div>
          )
        }
      },
    },
  

  ];
  return (
    <div id="corporate_tbl">
    <MUIDataTable
                title={<h5 style={{ textAlign: "left" }}>Top 5 Clients Order Details</h5>}
                data={data}
                columns={columns}
                options={{
                  options: options,
                  selectableRows: "none",
                  viewColumns: false,
                  filter: false,
                  print: false,
                  responsive: "standard",
                  download: false,
                  fixedHeader: true,
                  pagination:false,
                  // tableBodyMaxHeight: "50vh",
                  rowsPerPageOptions:[10,30,50,100],
                  // rowsPerPage: "",
                  sortOrder: {
                    name: 'InvoiceAmount',
                    direction: 'desc'
                  },
                  setTableProps: () => {
                    return {
                      padding: 0,
                    };
                  },
                  textLabels: {
                    body: {
                      noMatch: "Data Not Available"
                    },
                  },
                  customTableBodyFooterRender: (
                      count,
                      page,
                      rowsPerPage,
                      changeRowsPerPage,
                      changePage,
                      data
                    ) => {
                      const startIndex = page * rowsPerPage;
                      const endIndex = (page + 1) * rowsPerPage;
                      return (
                        <TableFooter id={styles.table_footer}>
                          <TableRow>
                            <TableCell id={styles.first_col}>
                              <p style={{textAlign:"left"}}>Grand Total </p>
                            </TableCell>
                            <TableCell><p style={{textAlign:"left",marginLeft:"5px"}}>{get_total("count")}</p></TableCell>
                            <TableCell><p style={{textAlign:"left"}}>{get_total("value")}</p></TableCell>
                          
                          </TableRow>
                        </TableFooter>
                      );
                    },
                 
                }}
              />
      </div>
  )
}

export default Top_5client_oh_tbl
